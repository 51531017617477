
import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartBar, faUsers } from "@fortawesome/free-solid-svg-icons";
import {
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Table,
  Row,
  Col,
  Card
} from "reactstrap";
import { formatDateTime } from "../utils/functions";
import useGet from "../services/useGet";
import NoRecordsFound from "./common/noRecordsFound";
import TableLoader from "./common/tableLoader";
import TableHeader from "./common/tableHeader";
import CustomBarChart from "./graphs/barChart";
import CustomPieChart from "./graphs/pieChart";


const AffiliateDashboard = ({ currentUser }) => {
  const affiliateId = currentUser?.user?._id;
  const [pieData, setPieData] = useState([]);
  let totalCommission = 0;



  const { callApi: getCurrentMonthRefferal, data: currentMonthRefferal, isLoading } = useGet({
    endpoint: `admin/affiliatecommission/current-month-referrals?affiliateId=${affiliateId}`,
  });

  const { callApi: getAffiliatecommission, data: affiliatecommission } = useGet({
    endpoint: `admin/affiliatecommission/?affiliateId=${affiliateId}`,
  });


  // const debouncedSearchTerm = useDebounce(searchInput, 500);
  // useEffect(
  //     () => {
  //       if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
  //       else if (!debouncedSearchTerm) getCoupons();
  //     },
  //     [debouncedSearchTerm]
  //   );

  useEffect(() => {
    getAffiliatecommission()
  }, []);

  useEffect(() => {
    if (affiliatecommission && currentMonthRefferal) {
      setPieData([
        {
          _id: "Total",
          count: affiliatecommission.totalRecords
        },
        {
          _id: "Current Month",
          count: currentMonthRefferal.length
        }
      ]);
    }
  }, [affiliatecommission, currentMonthRefferal]);

  return (
    <div>
      <>
        <Card className="shadow-sm" color="light">
          <CardBody>
            <CardTitle tag="h4">
              <Row>
                <Col sm={12}>Dashboard</Col>
              </Row>
            </CardTitle>
            <Card className="shadow"  >
              <CardBody>
                <CardTitle tag="h4">
                  <Row>
                    <Col sm={12}>
                      <FontAwesomeIcon icon={faChartBar} className="mr-2" />
                      Current Month Referrals
                    </Col>
                  </Row>
                </CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                <Row>
                  <Col md={12} lg={8}>
                    <CustomBarChart
                      getData={getCurrentMonthRefferal}
                      data={currentMonthRefferal}
                      title="Referrals"
                    />
                  </Col>
                  <Col md={12} lg={4}>
                    <CustomPieChart
                      pieData={pieData}
                      cardLabel="Affiliate"
                    />
                  </Col>

                </Row>
              </CardBody>
            </Card>
            <br />
            <Card className="shadow" >
              <CardBody>
                <CardTitle tag="h4">
                  <Row style={{ alignItems: "center" }}>
                    <Col>
                      <FontAwesomeIcon icon={faUsers} className="mr-2" />
                      Customers
                    </Col>
                  </Row>
                </CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                <CardText>
                  <Table responsive striped bordered hover size="sm" className="shadow">
                    <TableHeader headers={[{ title: 'Name' }, { title: 'Email' }, { title: 'Ragistered At' }, { title: 'Plan / Price' }, { title: 'Commission' } /*userRole === SOURCES.USER_ROLE_ADMIN && { title: '' }*/]} />
                    <tbody>
                      {isLoading ?
                        <TableLoader colSpan="4" />
                        : (
                          <>
                            {affiliatecommission?.results && affiliatecommission?.results.length > 0 ? (
                              affiliatecommission?.results?.map((item, idx) => {
                                return (
                                  <tr key={idx} style={{ whiteSpace: "nowrap" }}>
                                    {item.User.length > 0 && item.User.map((val, key) => {
                                      // const commissionAmount = item.commissionAmount;
                                      // totalCommission += commissionAmount;
                                      return <>
                                        <td>{val.name}</td>
                                        <td>{val.email}</td>
                                        <td>{formatDateTime(val.createdAt)}</td>
                                        <td>
                                          {item.Plan.map((pl) => {
                                            return <>{pl.planName} / {pl.planPrice}</>
                                          })}
                                        </td>
                                        <td>{item.commissionAmount} $</td>
                                      </>
                                    })}
                                  </tr>

                                );
                                {/* <td colSpan="5"><strong>Total Commission:{totalCommission} $</strong></td> */ }
                              })
                            )
                              : (
                                <NoRecordsFound colSpan="5" />
                              )}
                          </>
                        )}
                    </tbody>

                  </Table>

                </CardText>
              </CardBody>
            </Card>
          </CardBody>
        </Card>
      </>
    </div >
  );
};

export default AffiliateDashboard;
