import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import SOURCES from "../../utils/constants";
import Paginate from "../common/pagination";
import PaymentHistoryList from "./modules/paymentHistoryList";
import HeaderSearchInput from "../common/headerSearchInput";

const PaymentHistory = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING,
  });
  const [searchInput, setSearchInput] = useState("");
  const [paymenthistory, setPaymenthistory] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getPayments,
    data: payments,
    isLoading: getPaymentLoading,
  } = useGet({
    endpoint: `/admin/paymenthistory?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `/admin/paymenthistory/search?q=${searchInput}`,
  });

  const isLoading = searchDataLoading || getPaymentLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getPayments();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getPayments();
  }, []);

  useEffect(() => {
    getPayments();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    setPaymenthistory(payments);
  }, [payments]);

  useEffect(() => {
    setPaymenthistory(searchData);
  }, [searchData]);
  return (
    <div>
      <Fade>
        <Card color="shadow">
          <CardBody>
            <CardTitle tag="h4">
              <Row>
                <Col sm={9}>
                  <FontAwesomeIcon icon={faHistory} className="mr-2" />
                  Customer Payment History
                </Col>
                <Col sm={3}>
                  <HeaderSearchInput
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
            <CardText>
              <PaymentHistoryList
                paymenthistory={paymenthistory}
                isLoading={isLoading}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
              />
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput &&
              payments?.totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
                <FormGroup>
                  <Paginate
                    setPagination={setPagination}
                    totalRecords={payments?.totalRecords}
                    isLoading={isLoading}
                  />
                </FormGroup>
              )}
          </div>
        </Card>
      </Fade>
    </div>
  );
};

export default PaymentHistory;
