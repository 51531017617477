import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_HUBSPOT = process.env.REACT_APP_INTEGRATION_HUBSPOT;

const validationSchema = yupValidationSchema({
  hubspotApiKey: { required: true, customName: "Api key" },
});

const HubspotForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [hubspotApiKey, setHubspotApiKey] = useState("");
  const [sendLeadsLimitHubspot, setSendLeadsLimitHubspot] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_HUBSPOT) {
          setHubspotApiKey(secretKey);
          setSendLeadsLimitHubspot(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleHubspotFormForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ hubspotApiKey }, { abortEarly: false });
      setLoading(true);
      await saveIntegration(
        {
          secretKey: hubspotApiKey,
          sendLeadsLimit: sendLeadsLimitHubspot,
          integrationCompany: DESTINATION_HUBSPOT,
        },
        MSG_DESTINATION.SAVE_HUBSPOT
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader headerTitle="Hubspot" headerImage="images/hubspot.png">
      <Form onSubmit={handleHubspotFormForm} autoComplete="off">
        <FormGroup>
          <FormText color="info">
            <strong> NOTE:</strong>You can find your API Key by navigating to
            Settings-&gt;Integration-&gt;Private Apps. Then create new private
            app to get the API key.
          </FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="Get API key from Hubspot Application"
                autocomplete="off"
                type="text"
                defaultValue={hubspotApiKey}
                value={hubspotApiKey}
                onChange={(e) => setHubspotApiKey(e.target.value)}
                invalid={errors.hubspotApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.hubspotApiKey} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitHubspot}
                onChange={(e) => setSendLeadsLimitHubspot(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleHubspotFormForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default HubspotForm;
