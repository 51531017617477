/**
 * NOTE: This is exactly same as GoHighLevel CRM because venue is underneath using gohighlevel API
 * Customer just want venue icon and title instead of GoHighLevel branding
 */
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import { handleYupValidationErrors, yupValidationSchema } from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_EVENT_INTELLIGENCE = process.env.REACT_APP_INTEGRATION_EVENT_INTELLIGENCE;

const validationSchema = yupValidationSchema({
  eventIntelligenceApiKey: { required: true, customName: "Api key" },
  eventIntelligencePartnerId: { required: true, customName: "Partner Id" },
  eventIntelligenceAccountName: { required: true, customName: "Account Name" },
});

const EventIntelligenceForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [eventIntelligenceApiKey, setEventIntelligenceApiKey] = useState("");
  const [eventIntelligencePartnerId, setEventIntelligencePartnerId] = useState("");
  const [eventIntelligenceAccountName, setEventIntelligenceAccountName] = useState("");
  const [sendLeadsLimitEventIntelligence, setSendLeadsLimitEventIntelligence] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, publicKey, destinationAccountEmail, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_EVENT_INTELLIGENCE) {
          setEventIntelligenceApiKey(secretKey);
          setEventIntelligencePartnerId(publicKey);
          setSendLeadsLimitEventIntelligence(leadSentConfig?.sentLimit);
          setEventIntelligenceAccountName(destinationAccountEmail);
        }
      }
    }
  }, [destinations]);

  const handleEventIntelligenceForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          eventIntelligenceApiKey,
          eventIntelligencePartnerId,
          eventIntelligenceAccountName,
        },
        { abortEarly: false }
      );
      setLoading(true);
      await saveIntegration(
        {
          secretKey: eventIntelligenceApiKey,
          publicKey: eventIntelligencePartnerId,
          destinationAccountEmail:eventIntelligenceAccountName,
          sendLeadsLimit: sendLeadsLimitEventIntelligence,
          integrationCompany: DESTINATION_EVENT_INTELLIGENCE,
        },
        MSG_DESTINATION.SAVE_EVENT_INTELLIGENCE
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader headerTitle="Event Intelligence" headerImage="images/eventIntelligence.png">
      <Form onSubmit={handleEventIntelligenceForm} autoComplete="off">
        <FormGroup>
          <FormText color="info"></FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="API key"
                autocomplete="off"
                type="text"
                defaultValue={eventIntelligenceApiKey}
                value={eventIntelligenceApiKey}
                onChange={(e) => setEventIntelligenceApiKey(e.target.value)}
                invalid={errors.eventIntelligenceApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.eventIntelligenceApiKey} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="partner_Id">Partner Id</Label>
              <Input
                id="partner_Id"
                name="partner_Id"
                placeholder="Partner Id"
                autocomplete="off"
                type="text"
                defaultValue={eventIntelligencePartnerId}
                value={eventIntelligencePartnerId}
                onChange={(e) => setEventIntelligencePartnerId(e.target.value)}
                invalid={errors.eventIntelligencePartnerId}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.eventIntelligencePartnerId} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="account_name">Account Name</Label>
              <Input
                id="account_name"
                name="account_name"
                placeholder="Account Name"
                autocomplete="off"
                type="text"
                defaultValue={eventIntelligenceAccountName}
                value={eventIntelligenceAccountName}
                onChange={(e) => setEventIntelligenceAccountName(e.target.value)}
                invalid={errors.eventIntelligenceAccountName}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.eventIntelligenceAccountName} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">How Many Leads Do You Want To Send Initially?</Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitEventIntelligence}
                onChange={(e) => setSendLeadsLimitEventIntelligence(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter handleSubmit={handleEventIntelligenceForm} loading={loading} />
      </Form>
    </DestinationHeader>
  );
};

export default EventIntelligenceForm;
