import React, { useEffect, useState } from "react";
import fileDownload from "js-file-download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDatabase,
  faDownload,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardText,
  CardTitle,
  Col,
  Fade,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from "reactstrap";
import usePost from "../../services/usePost";
import useDownloadFile from "../../services/useDownloadFile";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../utils/functions";
import ValidationErrorHandling from "../common/validationErrorHandling";
import Header from "../common/header";
import SubmitButton from "../common/submitButton";

const validationSchema = yupValidationSchema({
  securityCode: { required: true, customName: "Security Code", maxLength: 10 },
});

const DataBaseBackup = () => {
  const [securityCode, setSecurityCode] = useState("");
  const [zipFileName, setZipFileName] = useState("");
  const [loading, setLoading] = useState(false);
  const [inputType, setInputType] = useState(true);
  const [errors, setErrors] = useState("");

  const { callApi: sendSecurityCode, data: zipDetails } = usePost({
    endpoint: `admin/database/backup`,
  });

  const { callApi: callDownload, data: downloadedFile } = useDownloadFile({
    endpoint: `admin/database/backup?fileName=${zipFileName}`,
  });

  const togglePassword = () => {
    setInputType(inputType === true ? false : true);
  };

  const submitForm = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      await validationSchema.validate({ securityCode }, { abortEarly: false });
      const zipInfo = await sendSecurityCode(
        { securityCode },
        "Database Backup Successfully",
        true,
        false
      );
      setZipFileName(zipInfo.data);
      setSecurityCode("");
      setErrors({});
    } catch (err) {
      setErrors(handleYupValidationErrors(err));
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (downloadedFile) fileDownload(downloadedFile, "leadsimport.zip");
  }, [downloadedFile]);

  const downloadZip = async () => {
    console.log("yess");
    await callDownload();
  };
  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <CardTitle tag="h4">
            <Header icon={faDatabase} title="Database Backup" />
          </CardTitle>
          <CardText>
            <Form onSubmit={submitForm}>
              <Row>
                <Col lg={6} xs={12}>
                  <FormGroup>
                    <Label for="securityCode">Security Code</Label>
                    <InputGroup className="d-flex ">
                      {/* <div className='d-flex'> */}
                      <Input
                        id="securityCode"
                        className="form-control"
                        name="securityCode"
                        type={inputType ? "password" : "text"}
                        placeholder="Enter Your Security Code Here..."
                        value={securityCode}
                        onChange={(e) => setSecurityCode(e.target.value)}
                        required
                        invalid={errors.securityCode}
                        tabIndex={1}
                        autoFocus
                      />

                      <InputGroupText
                        style={{
                          borderLeft: "0",
                          marginLeft: "-3px",
                          borderRadius: "3px",
                        }}
                      >
                        <FontAwesomeIcon
                          title={
                            inputType === true
                              ? "Show Password"
                              : "Hide Password"
                          }
                          cursor="pointer"
                          icon={inputType === true ? faEye : faEyeSlash}
                          onClick={togglePassword}
                        />
                      </InputGroupText>
                      <ValidationErrorHandling
                        position={false}
                        error={errors.securityCode}
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </CardText>

          <CardFooter>
            <div style={{ display: "flex" }}>
              <SubmitButton
                cancleButton={false}
                onClick={submitForm}
                title="Backup Database"
                isLoading={loading}
                icon={faDatabase}
              />

              {zipFileName && (
                <Button
                  style={{ margin: "0px 2px" }}
                  color="primary"
                  onClick={downloadZip}
                >
                  <FontAwesomeIcon icon={faDownload} className="mr-2" />
                  Download Backup
                </Button>
              )}
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default DataBaseBackup;
