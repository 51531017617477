import moment from "moment";
import { showAlert } from "../redux/actions";
import LocalStorageService from "../utils/localStorageServices";
import SOURCES from "../utils/constants";
import * as Yup from 'yup';


export const formatDateTime = (datetime, format = "MMM Do YY, h:mm:ss", timezoneOffset = 0, keepLocalTime = false) => {

    return moment(datetime).utcOffset(timezoneOffset, keepLocalTime).format(format);
    //Make sure below lines must not active because we have already added User Timezone in backend query

    /*const currentUser = LocalStorageService.getCurrentUser();
    console.log('currentUser',currentUser);
    const offset = currentUser?.user?.timezone?.offset;

    if(offset) {
        // return moment(datetime).utcOffset(offset).format(format)
        return moment(datetime).format(format);
    }
    else {
        return moment(datetime).format(
        "MMM Do YY, h:mm:ss"
      )
    }//*/

}

export const formatDateTimeFromNow = (datetime) => {
    const currentUser = LocalStorageService.getCurrentUser();
    const offset = currentUser?.user?.timezone?.offset;
    return offset !== null ? moment(datetime).utcOffset(offset).fromNow() :
        moment(datetime).fromNow()
}

export const getMonthName = (monthNo) => {
    let monthName = '';
    switch (monthNo) {
        default:
        case 1: monthName = 'Jan'; break;
        case 2: monthName = 'Feb'; break;
        case 3: monthName = 'Mar'; break;
        case 4: monthName = 'Apr'; break;
        case 5: monthName = 'May'; break;
        case 6: monthName = 'Jun'; break;
        case 7: monthName = 'Jul'; break;
        case 8: monthName = 'Aug'; break;
        case 9: monthName = 'Sep'; break;
        case 10: monthName = 'Oct'; break;
        case 11: monthName = 'Nov'; break;
        case 12: monthName = 'Dec'; break;
    }
    return monthName;
}

export const formatDate = (datetime, defaultFormat = "MMM Do YY") => {
    return moment(datetime).format(
        defaultFormat
    )
}

export const formatLeadSource = (leadSource) => {
    if (leadSource) {
        leadSource = leadSource.replace("_", " ");
        return leadSource;
    }
    return null;
}

export const showUserNotification = (msg, type = 'success', show = true) => {
    return showAlert({
        show,
        type,
        msg
    })
}
/**
 * @desc Show new features to specific loggedIn users
 * @returns boolean
 */
export const visibleNewFeatures = () => {
    const whiteList = ['javedboqo@gmail.com', 'leok@franvest.com'];
    const currentUser = LocalStorageService.getCurrentUser();
    const loggedEmail = currentUser?.user?.email;
    let showNewFeature = false;
    for (let i = 0; i < whiteList.length; i++) {
        if (loggedEmail === whiteList[i]) {
            showNewFeature = true;
            break;
        }
    }
    return showNewFeature;
}

export const capitalizeFirstLetter = (str) => {
    let capitalized = str;
    if (str) {
        capitalized = str.charAt(0).toUpperCase() + str.slice(1);
        capitalized = capitalized.replace('_', ' ');
    }
    return capitalized;
}

export const getJobStatus = (status) => {
    let badgeStatus = "success";
    switch (status) {
        case SOURCES.JOB_STATUS_RUNNING:
            badgeStatus = "warning";
            break;
        case "finished":
            badgeStatus = "success";
            break;
        case "cancel":
            badgeStatus = "danger";
            break;
        default:
            break;
    }

    return badgeStatus;
}

export const listSearchable = (list, key, title) => {
    const lsList = [{ value: "", label: "Select One" }];
    list?.map?.((ls) => {
        if (Array.isArray(title)) {
            const multipleTitles = [];
            title.map((t) => {
                multipleTitles.push(capitalizeFirstLetter(ls[t]));
            });
            const label = multipleTitles.join(" - ");
            lsList.push({
                value: ls[key],
                label
            });
        } else {
            lsList.push({
                value: ls[key],
                label: capitalizeFirstLetter(ls[title])
            });
        }
    });
    return lsList;
}

export const yupValidationSchema = (fields, length = false) => {
    const schema = {};

    Object.keys(fields).forEach((fieldName) => {
        let fldName = fieldName;
        if (fields[fieldName]?.customName) fldName = fields[fieldName]?.customName;
        const fieldRules = fields[fieldName];
        let fieldValidators = Yup.string();

        if (fieldRules.email) fieldValidators = fieldValidators.email('Invalid email');

        if (fieldRules.required) fieldValidators = fieldValidators.required(`${fldName} is required`);

        if (fieldRules.maxLength) fieldValidators = fieldValidators.max(fieldRules.maxLength, `Maximum of ${fieldRules.maxLength} charcters allowed`)

        if (fieldRules.url) fieldValidators = fieldValidators.url()

        if (fieldRules.date) fieldValidators = fieldValidators.required('Date is required').matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format');

        if (fieldName === 'confirmPassword') fieldValidators = fieldValidators.oneOf([Yup.ref('password'), Yup.ref("newPassword")], 'Passwords must match');

        schema[fieldName] = fieldValidators;
        //   if (fieldRules.positive) {
        //     fieldValidators = fieldValidators.number().positive('Number must be positive');
        //   }

          if (fieldName === 'password') {
            fieldValidators = fieldValidators.required('Password is required').min(8, 'Password must be at least 8 characters long');
          }

    });

    return Yup.object().shape(schema);
};


export const handleYupValidationErrors = (err) => {
    if (err instanceof Yup.ValidationError) {
        const validationErrors = {};
        err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
        });
        console.log(validationErrors);
        return validationErrors;
    }
    return {};
};

export const checkYupError = (err) => {
    return (err instanceof Yup.ValidationError);
}

export const getDomain = () => {
    const isLocalhost = window.location.href.includes('localhost');
    if (isLocalhost) return 'http://localhost:3000/';
    else return 'https://app.leadsimport.com/';
}

export const formatNumber = (number, localizeTo = "en-US") => {
  return number.toLocaleString(localizeTo);
};

export const getCurrentYear = () => {
    return new Date().getFullYear();
}

