import { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClipboardList } from "@fortawesome/free-solid-svg-icons";
import DestinationHeader from "./common/destinationHeader";
import useGet from "../../../services/useGet";
import { useDispatch } from "react-redux";
import { showAlert } from "../../../redux/actions";
import SOURCES from "../../../utils/constants";
const DESTINATION_MAILCHIMP = process.env.REACT_APP_INTEGRATION_MAILCHIMP;

const validationSchema = yupValidationSchema({
  mailChimpApiKey: { required: true, customName: "Api key" },
  mailChimpSeverZone: { required: true, customName: "Server zone" },
});

const MailChimpForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [loadCampaigns, setLoadCampaigns] = useState(false);
  const [mailChimpApiKey, setMailChimpApiKey] = useState("");
  const [mailChimpSeverZone, setMailChimpServerZone] = useState("");
  const [campaignId, setCampaignId] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [sendLeadsLimitMailChimp, setSendLeadsLimitMailChimp] = useState(0);
  const [errors, setErrors] = useState({});

  const dispatch = useDispatch();

  const {
    callApi: getMailChimpCampaigns,
    data: campaigns,
    isLoading,
  } = useGet({
    endpoint: `integration/mailchimp-campaigns?apiKey=${mailChimpApiKey}&serverZone=${mailChimpSeverZone}`,
  });

  useEffect(() => {
    getCampaigns();
  }, []);

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          integrationCompany,
          secretKey,
          publicKey,
          locations,
          leadSentConfig,
        } = dest;
        if (integrationCompany === DESTINATION_MAILCHIMP) {
          setMailChimpServerZone(publicKey);
          setMailChimpApiKey(secretKey);
          setSendLeadsLimitMailChimp(leadSentConfig?.sentLimit);
          locations && setCampaignId(locations);
          setLoadCampaigns(true);
        }
      }
    }
  }, [destinations]);

  useEffect(() => {
    if (loadCampaigns) getCampaigns(mailChimpSeverZone, mailChimpApiKey);
  }, [loadCampaigns]);

  useEffect(() => {
    campaigns && setCampaignList(campaigns);
  }, [campaigns]);

  const getCampaigns = (mailChimpSeverZone, mailChimpApiKey) => {
    console.log(mailChimpSeverZone, mailChimpApiKey);
    if (mailChimpSeverZone && mailChimpApiKey) getMailChimpCampaigns();
  };

  const handleCampaigns = (e) => {
    const selectedCampaignId = e.target.id;

    if (e.target.checked)
      setCampaignId((PrevCampaignId) => [
        ...PrevCampaignId,
        selectedCampaignId,
      ]);
    else {
      const temparr = campaignId.filter((camp) => camp !== selectedCampaignId);
      setCampaignId(temparr);
    }
  };

  const handleMailChimpForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { mailChimpSeverZone, mailChimpApiKey },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      const result = await saveIntegration(
        {
          secretKey: mailChimpApiKey,
          publicKey: mailChimpSeverZone,
          locations: campaignId,
          sendLeadsLimit: sendLeadsLimitMailChimp,
          integrationCompany: DESTINATION_MAILCHIMP,
        },
        MSG_DESTINATION.SAVE_MAILCHIMP,
        true,
        false
      );

      dispatch(
        showAlert({
          show: true,
          type: result?.status === SOURCES.HTTP_CODE_200 ? "success" : "danger",
          msg:
            result?.status === SOURCES.HTTP_CODE_200
              ? MSG_DESTINATION.SAVE_MAILCHIMP
              : result?.data?.error?.message,
        })
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DestinationHeader
      headerTitle="MailChimp"
      headerImage="images/mailchimp.png"
    >
      <Form onSubmit={handleMailChimpForm} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="Get API key from MailChimp Application"
                autocomplete="off"
                type="text"
                defaultValue={mailChimpApiKey}
                value={mailChimpApiKey}
                onChange={(e) => {
                  setMailChimpApiKey(e.target.value);
                  const serverZoneParts = e.target.value.split("-")[1];
                  setMailChimpServerZone(serverZoneParts);
                }}
                invalid={errors.mailChimpApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.mailChimpApiKey} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="server_zone">
                Server Zone{" "}
                <small style={{ color: "#17a2b8" }}>
                  (https://<span style={{color:"red"}}>SERVER_ZONE</span>.admin.mailchimp.com)
                </small>
              </Label>
              <Input
                id="server_zone"
                name="server_zone"
                placeholder="Get server zone from MailChimp Application"
                autocomplete="off"
                type="text"
                defaultValue={mailChimpSeverZone}
                value={mailChimpSeverZone}
                onChange={(e) => setMailChimpServerZone(e.target.value)}
                invalid={errors.mailChimpSeverZone}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.mailChimpSeverZone} />
            </FormGroup>
          </Col>

          <Col lg={12}>
            <FormGroup>
              <Button
                onClick={() => {
                  getCampaigns(mailChimpSeverZone, mailChimpApiKey);
                }}
                className="mb-2"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Spinner
                    className="mr-2"
                    color="light"
                    size="sm"
                    children=""
                  />
                ) : (
                  <FontAwesomeIcon icon={faClipboardList} className="mr-2" />
                )}
                Get Campaigns
              </Button>
              {campaignList && campaignList?.length > 0 && (
                <Table responsive striped bordered hover size="sm">
                  <thead>
                    <tr style={{ whiteSpace: "nowrap" }}>
                      <th>Select Campaign</th>
                      <th>Campaign</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaignList &&
                      campaignList?.map((camp, idx) => {
                        return (
                          <tr style={{ whiteSpace: "nowrap" }}>
                            <td>
                              <Input
                                style={{ margin: 0, position: "static" }}
                                checked={campaignId?.includes(
                                  camp?.id?.toString()
                                )}
                                type="checkbox"
                                id={camp?.id}
                                onChange={handleCampaigns}
                              />
                            </td>
                            <td>{camp?.name}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              )}
            </FormGroup>
            {/* <FormGroup>
              <Label for="campaign_id">Campaign Id</Label>
              <Input
                id="campaign_id"
                name="campaign_id"
                placeholder="Get Campaign Id from MailChimp Application"
                autocomplete="off"
                type="text"
                defaultValue={mailChimpCampaignId}
                value={mailChimpCampaignId}
                onChange={(e) => setMailChimpCampaignId(e.target.value)}
                invalid={errors.mailChimpCampaignId}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.mailChimpCampaignId} />
            </FormGroup> */}
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitMailChimp}
                onChange={(e) => setSendLeadsLimitMailChimp(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleMailChimpForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default MailChimpForm;
