import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Button, Card, CardBody, CardTitle, Col, Fade, FormGroup, Input, Label, Row, Spinner } from 'reactstrap';
import useGet from '../services/useGet';
import usePost from '../services/usePost';

const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_LIVE;

const LeadFormComponent = () => {
    // const [captchaToken, setCaptchaToken] = useState();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uniqueCode = queryParams.get('uniqueCode');

    const {
        callApi: getWebLink,
        data: weblink
    } = useGet({
        endpoint: `/weblink/present/webform?uniqueCode=${uniqueCode}`,
    });

    const {
        callApi: saveWebForm,
        isLoading: saveLoading,
        processSuccess,
    } = usePost({ endpoint: `/weblink/present/webform` });


    const initialValues = {
        firstName: '',
        firstNameRequired: weblink?.settings?.firstNameRequired,
        firstNameHidden: weblink?.settings?.firstNameHidden,
        lastName: '',
        lastNameRequired: weblink?.settings?.lastNameRequired,
        lastNameHidden: weblink?.settings?.lastNameHidden,
        phone: '',
        phoneRequired: weblink?.settings?.phoneRequired,
        phoneHidden: weblink?.settings?.phoneHidden,
        email: '',
        emailRequired: weblink?.settings?.emailRequired,
        emailHidden: weblink?.settings?.emailHidden,
        eventName: '',
        eventNameRequired: weblink?.settings?.eventNameRequired,
        eventNameHidden: weblink?.settings?.eventNameHidden,
        eventDate: '',
        eventDateRequired: weblink?.settings?.eventDateRequired,
        eventDateHidden: weblink?.settings?.eventDateHidden,
        eventType: '',
        eventTypeRequired: weblink?.settings?.eventTypeRequired,
        eventTypeHidden: weblink?.settings?.eventTypeHidden,
        budget: '',
        budgetRequired: weblink?.settings?.budgetRequired,
        budgetHidden: weblink?.settings?.budgetHidden,
        guestCount: '',
        guestCountRequired: weblink?.settings?.guestCountRequired,
        guestCountHidden: weblink?.settings?.guestCountHidden,
        company: '',
        companyRequired: weblink?.settings?.companyRequired,
        companyHidden: weblink?.settings?.companyHidden,
        address: '',
        addressRequired: weblink?.settings?.addressRequired,
        addressHidden: weblink?.settings?.addressHidden,
        notesInquiry: '',
        notesInquiryRequired: weblink?.settings?.notesInquiryRequired,
        notesInquiryHidden: weblink?.settings?.notesInquiryHidden,
    }

    useEffect(() => {
        getWebLink()
    }, []);


    const handleSubmitForm = React.useCallback(async (values) => {
        const captchaToken = await executeRecaptcha("submit_form");
        //   console.log('token',captchaToken)
        const Post = {
            uniqueCode,
            firstName: values.firstName,
            lastName: values.lastName,
            phone: values.phone,
            email: values.email,
            eventDate: values.eventDate,
            eventYear: values.eventYear,
            eventType: values.eventType,
            eventName: values.eventName,
            guestCount: values.guestCount,
            budget: values.budget,
            company: values.company,
            address: values.address,
            inquiry: values.notesInquiry,
            captchaToken
        }
        saveWebForm(Post)

    }, [executeRecaptcha]);

    return (
        <Fade>
        <Row style={{ marginRight: "0px" }}>
            <Col>
                <Card style={{ border: "none" }}>
                    <CardBody>
                        <CardTitle tag={"h3"} className='text-left font-weight-bold'>{weblink?.formName}</CardTitle>
                        {processSuccess ?
                            <div style={{ display: "grid", placeItems: "center", color: '#17a2b8' }}>
                                <h5>Contact submitted successfully!</h5>
                            </div>
                            :
                            <Formik
                                initialValues={initialValues}
                                enableReinitialize
                                onSubmit={(values) => {
                                    handleSubmitForm(values)
                                }}
                            >
                                {({
                                    values,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                }) => (
                                    <form onSubmit={handleSubmit} >
                                        <FormGroup >
                                            <Row>
                                                {!values.firstNameHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>First Name {values.firstNameRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='text'
                                                            id="firstName"
                                                            name="firstName"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.firstName}
                                                            autoFocus
                                                        />
                                                    </Col>
                                                }
                                                {!values.lastNameHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>Last Name {values.lastNameRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='text'
                                                            id="lastName"
                                                            name="lastName"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.lastName}
                                                        />
                                                    </Col>
                                                }
                                            </Row>
                                        </FormGroup>

                                        <FormGroup>
                                            <Row>
                                                {!values.phoneHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>Phone {values.phoneRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='text'
                                                            id="phone"
                                                            name="phone"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.phone}

                                                        />
                                                    </Col>
                                                }
                                                {!values.emailHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>Email {values.emailRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='email'
                                                            id="email"
                                                            name="email"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.email}

                                                        />
                                                    </Col>
                                                }
                                            </Row>


                                        </FormGroup>

                                        {!values.eventNameHidden &&
                                            <Row>
                                                <Col sm="6" lg="6" md="6">
                                                    <FormGroup>
                                                        <Label>Event Name {values.eventNameRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='text'
                                                            id="eventName"
                                                            name="eventName"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.eventName}


                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }

                                        <FormGroup>
                                            <Row>
                                                {!values.eventDateHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>Event Date {values.eventDateRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='date'
                                                            id="eventDate"
                                                            name="eventDate"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values?.eventDate}
                                                        />
                                                    </Col>
                                                }
                                                {!values.eventTypeHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>Event Type {values.eventTypeRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='text'
                                                            id="eventType"
                                                            name="eventType"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.eventType}

                                                        />
                                                    </Col>
                                                }
                                            </Row>
                                        </FormGroup>

                                        <FormGroup>
                                            <Row>
                                                {!values.budgetHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>Budget {values.budgetRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='number'
                                                            id="budget"
                                                            name="budget"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.budget}

                                                        />
                                                    </Col>
                                                }
                                                {!values.guestCountHidden &&
                                                    <Col sm="6" lg="6" md="6">
                                                        <Label>Guest Count {values.guestCountRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='number'
                                                            id="guestCount"
                                                            name="guestCount"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.guestCount}
                                                        />
                                                    </Col>
                                                }
                                            </Row>
                                        </FormGroup>

                                        {!values.companyHidden &&
                                            <Row>
                                                <Col sm="6" lg="6" md="6">
                                                    <FormGroup>
                                                        <Label>Company {values.companyRequired ? "*" : ''}</Label>
                                                        <Input
                                                            type='text'
                                                            id="company"
                                                            name="company"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.company}

                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }

                                        {!values.addressHidden &&
                                            <Row>
                                                <Col sm="6" lg="6" md="6">
                                                    <FormGroup>
                                                        <Label>Service Address {values.addressRequired ? "*" : ''}</Label>
                                                        <Input
                                                            type='address'
                                                            id="address"
                                                            name="address"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.address}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }

                                        {!values.notesInquiryHidden &&
                                            <Row>
                                                <Col sm="6" lg="6" md="6">
                                                    <FormGroup>
                                                        <Label>Notes / Inquiry {values.notesInquiryRequired ? "*" : ""}</Label>
                                                        <Input
                                                            type='textarea'
                                                            id="notesInquiry"
                                                            name="notesInquiry"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.notesInquiry}
                                                        />
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                        }

                                        {/* <FormGroup>
                                            <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                                                <GoogleReCaptcha
                                                    onVerify={(token) => {
                                                        setCaptchaToken(token);
                                                    }}
                                                />
                                            </GoogleReCaptchaProvider>
                                        </FormGroup> */}

                                        {weblink &&
                                            <Button type="submit" className='w-20 py-2 mt-4' color="primary" disabled={processSuccess} >
                                                Submit
                                                {saveLoading && (
                                                    <Spinner
                                                        className="ml-2"
                                                        color="light"
                                                        size="sm"
                                                        children=""
                                                    />
                                                )}
                                            </Button>
                                        }
                                    </form>)}
                            </Formik>
                        }

                    </CardBody>
                </Card>
            </Col>
        </Row>
        </Fade>
    );
};

const LeadGenerateForm = () => {

    return (
        <>
            <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                <LeadFormComponent />
            </GoogleReCaptchaProvider>
        </>

    )
}

export default LeadGenerateForm