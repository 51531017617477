import { SCREEN_SIZE } from "../actions";

const initialState = {
  isMobile: false
};
const ScreenReducer = (state = initialState, action) => {
  if (action.type === SCREEN_SIZE) {
    return {
      ...state,
      isMobile: action.payload,
    };
  }
  return state;
};
export default ScreenReducer;