import { useState } from "react";
import { useHistory } from "react-router-dom";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  CardFooter,
  InputGroup,
  Fade,
} from "reactstrap";
import Popup from "./common/popup";
import usePost from "../services/usePost";
import LocalStorageService from "../utils/localStorageServices";
import { MSG_SUBSCRIPTION } from "../utils/message";
import SubmitButton from "./common/submitButton";
import Header from "./common/header";

const localStorageServices = LocalStorageService.getService();

const SubscriptionCancel = ({ currentUser }) => {
  const history = useHistory();
  const [toggleModal, setToggleModal] = useState(false);
  const [cancelReasonOther, setCancelReasonOther] = useState("");
  const [stateData, setStateData] = useState({
    isLoading: false,
    ServiceNotWorking: false,
    NotEnoughLeadsIncoming: false,
    TooComplicated: false,
    TooExpensive: false,
    ChangedCancelledCRMSoftware: false,
    NoLongerUsing3rdPartyLeads: false,
    Other: false,
  });

  const { callApi: cancelSubscription } = usePost({
    endpoint: `payment/cancel/subscription`,
  });

  const handleForm = async (e) => {
    e.preventDefault();
    setToggleModal(true);
  };

  const cancelUserSubscription = async () => {
    const response = await cancelSubscription(
      {
        cancelReasons: stateData,
        cancelReasonOther,
      },
      MSG_SUBSCRIPTION.CANCLE_SUBSCRIPTION,
      true
    );
    setToggleModal(false);
    if (response.status === 200) {
      currentUser.user.paymentStatus = false;
      localStorageServices.setCurrentUser(currentUser);
      setTimeout(() => {
        history.push("/profile");
      }, 2000);
    }
  };

  const toggleReason = (reason) => {
    setStateData((prevState) => ({
      ...prevState,
      [reason]: !prevState[reason],
    }));
  };

  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <Header icon={faTimes} title="Cancel Subscription" />

          <CardText>
            {currentUser.user.paymentStatus === true && currentUser.user.stripeSubscriptionId ? (
              <Form onSubmit={handleForm}>
                <FormGroup switch className="mt-3">
                  <InputGroup>
                    <Input
                      type="checkbox"
                      id={`reason_ServiceNotWorking`}
                      checked={stateData.ServiceNotWorking}
                      onChange={() => toggleReason("ServiceNotWorking")}
                    />
                    <Label check for={`reason_ServiceNotWorking`}>
                      Service Not Working
                    </Label>
                  </InputGroup>
                </FormGroup>

                <FormGroup switch className="mt-3">
                  <InputGroup>
                    <Input
                      type="checkbox"
                      id={`reason_NotEnoughLeadsIncoming`}
                      checked={stateData.NotEnoughLeadsIncoming}
                      onClick={() => toggleReason("NotEnoughLeadsIncoming")}
                    />
                    <Label check for={`reason_NotEnoughLeadsIncoming`}>
                      Not Enough Leads Incoming
                    </Label>
                  </InputGroup>
                </FormGroup>

                <FormGroup switch className="mt-3">
                  <InputGroup>
                    <Input
                      type="switch"
                      id={`reason_TooComplicated`}
                      checked={stateData.TooComplicated}
                      onClick={() => toggleReason("TooComplicated")}
                    />
                    <Label check for={`reason_TooComplicated`}>
                      Too Complicated
                    </Label>
                  </InputGroup>
                </FormGroup>

                {/* <FormGroup switch>
                  <Input
                    type="switch"
                    id={"reason_" + stateData.TooComplicated}
                    checked={stateData.TooComplicated}
                    onClick={() => {
                      setStateData({
                        ...stateData,
                        TooComplicated: !stateData.TooComplicated,
                      });
                    }}
                  />
                  <Label check>Too Complicated</Label>
                </FormGroup> */}

                <FormGroup switch className="mt-3">
                  <Input
                    type="switch"
                    id={`reason_TooExpensive`}
                    checked={stateData.TooExpensive}
                    onClick={() => toggleReason("TooExpensive")}
                  />
                  <Label check for={`reason_TooExpensive`}>
                    Too Expensive
                  </Label>
                </FormGroup>

                {/* <FormGroup switch>
                  <Input
                    type="switch"
                    checked={stateData.TooExpensive}
                    onClick={() => {
                      setStateData({
                        ...stateData,
                        TooExpensive: !stateData.TooExpensive,
                      });
                    }}
                  />
                  <Label check>Too Expensive</Label>
                </FormGroup> */}

                <FormGroup switch className="mt-3">
                  <InputGroup>
                    <Input
                      type="switch"
                      id={`reason_ChangedCancelledCRMSoftware`}
                      checked={stateData.ChangedCancelledCRMSoftware}
                      onClick={() => toggleReason("ChangedCancelledCRMSoftware")}
                    />
                    <Label check for={`reason_ChangedCancelledCRMSoftware`}>
                      Changed/Cancelled CRM Software
                    </Label>
                  </InputGroup>
                </FormGroup>

                {/* <FormGroup switch>
                  <Input
                    type="switch"
                    checked={stateData.ChangedCancelledCRMSoftware}
                    onClick={() => {
                      setStateData({
                        ...stateData,
                        ChangedCancelledCRMSoftware:
                          !stateData.ChangedCancelledCRMSoftware,
                      });
                    }}
                  />
                  <Label check>Changed/Cancelled CRM Software</Label>
                </FormGroup> */}

                <FormGroup switch className="mt-3">
                  <InputGroup>
                    <Input
                      type="switch"
                      id={`reason_NoLongerUsing3rdPartyLeads`}
                      checked={stateData.NoLongerUsing3rdPartyLeads}
                      onClick={() => toggleReason("NoLongerUsing3rdPartyLeads")}
                    />
                    <Label check for={`reason_NoLongerUsing3rdPartyLeads`}>
                      No Longer Using 3rd Party Leads
                    </Label>
                  </InputGroup>
                </FormGroup>

                {/* <FormGroup switch>
                  <Input
                    type="switch"
                    checked={stateData.NoLongerUsing3rdPartyLeads}
                    onClick={() => {
                      setStateData({
                        ...stateData,
                        NoLongerUsing3rdPartyLeads:
                          !stateData.NoLongerUsing3rdPartyLeads,
                      });
                    }}
                  />
                  <Label check>No Longer Using 3rd Party Leads</Label>
                </FormGroup> */}

                <FormGroup switch className="mt-3">
                  <Input
                    type="switch"
                    id={`reason_Other`}
                    checked={stateData.Other}
                    onClick={() => toggleReason("Other")}
                  />
                  <Label check for={`reason_Other`}>
                    Other
                  </Label>
                </FormGroup>

                {/* <FormGroup switch>
                  <Input
                    type="switch"
                    checked={stateData.Other}
                    onClick={() => {
                      setStateData({ ...stateData, Other: !stateData.Other });
                    }}
                  />
                  <Label check>Other</Label>
                </FormGroup> */}

                {stateData.Other && (
                  <FormGroup switch className="mb-3 mt-3">
                    <Input
                      type="textarea"
                      value={cancelReasonOther}
                      onChange={(e) => setCancelReasonOther(e.target.value)}
                    />
                    <Label check></Label>
                  </FormGroup>
                )}
                <CardFooter>
                  <FormGroup>
                    <SubmitButton
                      title="Cancel Subscription"
                      color="danger"
                      onClick={handleForm}
                      cancleButton={false}
                      disabled={!Object.values(stateData).filter((data) => data === true).length}
                    />
                  </FormGroup>
                </CardFooter>
              </Form>
            ) : (
              <Alert color="dark">You don't have active subscription.</Alert>
            )}
          </CardText>
        </CardBody>
      </Card>

      <Popup
        showPopup={toggleModal}
        hidePopup={() => setToggleModal(false)}
        actionMethod={cancelUserSubscription}
        title="Cancel Subscription"
        promtMessage="Are you sure you want to cancel Subscription?"
      />
    </Fade>
  );
};

export default SubscriptionCancel;
