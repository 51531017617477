import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faLock, faSignOutAlt, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import LocalStorageService from "../utils/localStorageServices";
import { Link } from "react-router-dom";
import SOURCES from "../utils/constants";

const localStorageServices = LocalStorageService.getService();

const Topbar = ({ toggleSidebar }) => {
  const currentUser = localStorageServices.getCurrentUser();
  const userType = currentUser.user?.userType
  const [topbarIsOpen, setTopbarOpen] = useState(false);
  const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const [toggle, setToggle] = useState(false);
  const loggedUser = localStorageServices.getCurrentUser().user;

  const logOut = () => {
    localStorageServices.clearToken();
    window.location.reload();
  };

  
  return (
    <Navbar
      color="light"
      light
      className="navbar shadow-sm p-3 mb-5 bg-white"
      expand="md"
    >
      <Button color="primary" onClick={toggleSidebar} >
        <FontAwesomeIcon icon={faBars} size="sm" />
      </Button>
      <NavbarToggler onClick={toggleTopbar} style={{ fontSize: '1rem', padding: '5px 6px' }} />
      <Collapse isOpen={topbarIsOpen} navbar >
        <Nav className="ml-auto mt-2" style={{ textAlign: "end" }} navbar>
          <NavItem >
            <Dropdown isOpen={toggle} toggle={() => setToggle(!toggle)} >
              <DropdownToggle className="nav-user" caret>
                  {loggedUser.name}
              </DropdownToggle>
              <DropdownMenu end>
                {userType !== SOURCES.USER_ROLE_AFFILIATE &&
                  <>
                    <DropdownItem header>
                      <NavLink tag={Link} to={"/profile"}>
                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                        Profile
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem header>
                      <NavLink tag={Link} to={"/changepassword"}>
                        <FontAwesomeIcon icon={faLock} className="mr-2" />
                        Change Password
                      </NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                  </>
                }
                <DropdownItem color="info" onClick={logOut}>
                  <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                  Sign Out
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </NavItem>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default Topbar;
