import React from "react";

const Footer = () => {
  return (
    <div className="text-center p-4 text-primary">
      Copyright {(new Date().getFullYear())} LeadsImport.com. All Rights Reserved.
    </div>
  );
};
export default Footer;
