
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Collapse,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import Paginate from "../common/pagination";
import AdvanceSearch from "../common/advanceSearch"
import PaginateSearch from "../common/paginationSearch";
import LeadViaCSVList from "./modules/leadViaCSVList";
import LeadViaCSVDetail from "./modules/leadViaCSVDetail";
import { MSG_LEADS_VIA_CSV } from "../../utils/message";
import usePost from "../../services/usePost";
import Popup from "../common/popup";
import HeaderSearchInput from "../common/headerSearchInput";

const LeadsImportedViaCSV = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });
  const [paginationSearch, setPaginationSearch] = useState({
    offsetSearch: 0,
    limitSearch: SOURCES.DEFAULT_RECORDS_SEARCH
  });


  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)
  const [leadsImported, setLeadsImported] = useState();
  const [leadDetail, setLeadDetail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [toggleDeleteBtn, setToggleDeleteBtn] = useState(false)
  const [leadList, setLeadList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [toggleModal, setToggleModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const toggle = () => setDropdownOpen((prevState) => !prevState);


  const [advanceSearch, setAdvanceSearch] = useState({
    advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    startDate: "",
    endDate: "",
    leadSource: "",
    searchTerm: "",
  });
  const [totalRecords, setTotalRecords] = useState(0);

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `leadimportcsv/search?offset=${paginationSearch.offsetSearch}
    &limit=${paginationSearch.limitSearch}
    &q=${searchInput}
    &advance_search=${advanceSearch.advance_search_enabled}
    &startdate=${advanceSearch.startDate}
    &enddate=${advanceSearch.endDate}
    &leadsource=${advanceSearch.leadSource}
    &term=${advanceSearch.searchTerm}`,
  });

  const { callApi: getLeadSourcesOptions, data: leadSource } = useGet({
    endpoint: `leadsource/options`,
  });

  const {
    callApi: getLeadsImportedCsv,
    data: leadsCsvData,
    isLoading: getLeadsLoading,
  } = useGet({
    endpoint: `leadimportcsv?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });


  const {
    callApi: deleteSelectedLeadImportedCsv,
    processSuccess: removeSelectedLeadSuccess,
  } = usePost({
    endpoint: `leadimportcsv/remove-lead-bulk`,
  });

  const {
    callApi: deleteLeadImportedCsv,
    processSuccess: removeSuccess,
  } = usePost({
    endpoint: `leadimportcsv/remove-lead`,
  });

  const isLoading = searchDataLoading || getLeadsLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const dataExist = leadsImported && leadsImported.results.length > 0 ? true : false;

  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
      else if (!debouncedSearchTerm) getLeadsImportedCsv();
    },
    [debouncedSearchTerm]
  );


  useEffect(() => {
    setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
    setShowAdvanceSearch(false);
  }, [searchInput])

  useEffect(() => {
    if ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM].indexOf(advanceSearch.advance_search_enabled) >= 0) {
      getLeadsImportedCsv();
      getLeadSourcesOptions();
      if (advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM) setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
    }
  }, [pagination.offset, pagination.limit, sortBy, sortOrder, advanceSearch.advance_search_enabled]);


  useEffect(() => {
    if ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT].indexOf(advanceSearch.advance_search_enabled) >= 0) getSearchData();
  }, [paginationSearch.offsetSearch, paginationSearch.limitSearch]);


  useEffect(() => {
    setLeadsImported(leadsCsvData);
  }, [leadsCsvData]);

  useEffect(() => {
    if (leadsImported && totalRecords <= 0) setTotalRecords(leadsImported?.totalReords);
  }, [leadsImported])

  useEffect(() => {
    setLeadsImported(searchData);
  }, [searchData]);

  const viewDetail = (item) => {
    setLeadDetail(item);
    setShowDetail(true);
  };

  useEffect(() => {
    if (removeSelectedLeadSuccess) {
      setToggleModal(false);
      getLeadsImportedCsv()
    }
  }, [removeSelectedLeadSuccess])

  const removeBulkLeadsImportedCSV = async () => {
    const selectedLeads = leadList.results.filter(item => item.isChecked);
    if (selectedLeads.length === 0) {
      return;
    }

    const ids = selectedLeads.map(item => item._id);
    deleteSelectedLeadImportedCsv({
      leadIDs: ids
    }, MSG_LEADS_VIA_CSV.DELETE);

  };

  return (
    <>
      <div>
        <Fade>
        <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
          <CardBody>
            <CardTitle>
              <Row className="justify-content-between">
                <Col md={12} lg={toggleDeleteBtn ? 8 : 9} style={{ display: "flex" }} tag={"h4"}>
                  <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                  Leads Imported Via CSV
                </Col>
                <Col md={12} lg={toggleDeleteBtn ? 4 : 3}>
                  <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                    <HeaderSearchInput
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        setShowAdvanceSearch(true);
                      }}
                      style={{
                        minWidth: "max-content"
                      }}
                    >
                      <FontAwesomeIcon icon={faFilter} className="mr-2" />
                      Advance Search
                    </Button>
                    {toggleDeleteBtn ?
                      <Dropdown isOpen={dropdownOpen} toggle={toggle} direction="down">
                        <DropdownToggle caret color="danger">Action</DropdownToggle>
                        <DropdownMenu dark={true}>
                          <DropdownItem onClick={() => setToggleModal(true)}>
                            Bulk Delete
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                      : null}
                  </div>
                  <Collapse isOpen={showAdvanceSearch}>
                    <AdvanceSearch
                      searchInput={searchInput}
                      isOpen={showAdvanceSearch}
                      advanceSearch={advanceSearch}
                      setShowAdvanceSearch={setShowAdvanceSearch}
                      setAdvanceSearch={setAdvanceSearch}
                      getSearchData={getSearchData}
                      filteredData={{ key: "LEADS-VIA-CSV", data: leadSource }}
                    />
                  </Collapse>
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
            </CardSubtitle>
            <CardText>
              <LeadViaCSVList
                isLoading={isLoading}
                dataExist={dataExist}
                leadsImported={leadsImported}
                viewDetail={viewDetail}
                getLeadsImportedCsv={getLeadsImportedCsv}
                setToggleDeleteBtn={setToggleDeleteBtn}
                setLeadList={setLeadList}
                leadList={leadList}
                setSelectAll={setSelectAll}
                selectAll={selectAll}
                setDeleteId={setDeleteId}
                deleteId={deleteId}
                deleteLeadImportedCsv={deleteLeadImportedCsv}
                removeSuccess={removeSuccess}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
              />
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput && [SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM].indexOf(advanceSearch.advance_search_enabled) >= 0 && totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
              <FormGroup>
                <Paginate
                  setPagination={setPagination}
                  totalRecords={totalRecords}
                  isLoading={isLoading}
                />
              </FormGroup>
            )}

            {
              ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT].indexOf(advanceSearch.advance_search_enabled) >= 0 || searchInput) && totalRecords > SOURCES.DEFAULT_RECORDS_SEARCH &&
              <FormGroup>
                <PaginateSearch
                  setPaginationSearch={setPaginationSearch}
                  totalRecords={leadsImported?.totalReords}
                />
              </FormGroup>
            }

          </div>
        </Card>
        </Fade>
        {leadDetail && (
          <LeadViaCSVDetail
            showDetail={showDetail}
            setShowDetail={setShowDetail}
            leadDetail={leadDetail}
            setDeleteId={setDeleteId}
            deleteId={deleteId}
            deleteLeadImportedCsv={deleteLeadImportedCsv}
            removeSuccess={removeSuccess}
          />
        )
        }
      </div>
      {
        toggleModal && <Popup
          title="Delete Lead(s)"
          promtMessage="Are you sure you want to delete Lead(s)?"
          showPopup={toggleModal}
          hidePopup={() => setToggleModal(false)}
          actionMethod={removeBulkLeadsImportedCSV} />
      }

    </>
  );
};

export default LeadsImportedViaCSV;