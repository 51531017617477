import { Spinner } from "reactstrap"

const TableLoader = ({colSpan , className="text-center" }) => {
    return (
        <tr style={{ whiteSpace: "nowrap" }}>
            <td colSpan={colSpan} className={className}>
                <Spinner
                    className="ml-2"
                    children=""
                ></Spinner>
            </td>
        </tr>
    )
}

export default TableLoader