import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    FormGroup,
    Row,
    Col,
    Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import Paginate from "../common/pagination";
import HotLeadDetail from "./modules/hotLeadDetail";
import HotLeadList from "./modules/hotLeadList";
import HeaderSearchInput from "../common/headerSearchInput";

const HotLeads = () => {
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: SOURCES.DEFAULT_RECORDS_LISTING
    });
    const [hotLeadsImported, setHotLeadsImported] = useState();
    const [hotLeadDetail, setHotLeadDetail] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [totalRecords, setTotalRecords] = useState(0);
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(1);

    const {
        callApi: getSearchData,
        data: searchData,
        isLoading: searchDataLoading,
    } = useGet({
        endpoint: `leadinfo/hotlead-search?q=${searchInput}`,
    });

    const {
        callApi: getLeadsImported,
        data: hotLeadsData,
        isLoading: getLeadsLoading,
    } = useGet({
        endpoint: `leadinfo/hotlead?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    });

    const isLoading = searchDataLoading || getLeadsLoading;
    const debouncedSearchTerm = useDebounce(searchInput, 500);
    const dataExist = hotLeadsImported && hotLeadsImported.results.length > 0 ? true : false;

    useEffect(
        () => {
            if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
            else if (!debouncedSearchTerm) getLeadsImported();
        }, [debouncedSearchTerm]
    );

    useEffect(() => {
        getLeadsImported();
    }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

    useEffect(() => {
        setHotLeadsImported(hotLeadsData);
    }, [hotLeadsData]);

    useEffect(() => {
        if (hotLeadsImported && totalRecords <= 0) setTotalRecords(hotLeadsImported?.totalReords);
    }, [hotLeadsImported])

    useEffect(() => {
        setHotLeadsImported(searchData);
    }, [searchData]);

    const viewDetail = (item) => {
        setHotLeadDetail(item);
        setShowDetail(true);
    };

    return (
        <div>
            <Fade>
            <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
                <CardBody>
                    <CardTitle>
                        <Row className="justify-content-between">
                            <Col lg={9} style={{ display: "flex" }} tag={"h4"}>
                                <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                                Hot Leads
                            </Col>
                            <Col lg={3}>
                                <HeaderSearchInput
                                    value={searchInput}
                                    onChange={(e) => setSearchInput(e.target.value)}
                                />
                            </Col>
                        </Row>
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                    </CardSubtitle>
                    <CardText>
                        <HotLeadList
                            isLoading={isLoading}
                            dataExist={dataExist}
                            hotLeadsImported={hotLeadsImported}
                            viewDetail={viewDetail}
                            setSortBy={setSortBy}
                            sortBy={sortBy}
                            setSortOrder={setSortOrder}
                            sortOrder={sortOrder}
                        />
                    </CardText>
                </CardBody>
                <div className="px-4">
                    {!searchInput && totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
                        <FormGroup>
                            <Paginate
                                setPagination={setPagination}
                                totalRecords={totalRecords}
                                isLoading={isLoading}
                            />
                        </FormGroup>
                    )}
                </div>
            </Card>
            </Fade>
            {
                hotLeadDetail &&
                <HotLeadDetail
                    hotLeadDetail={hotLeadDetail}
                    showDetail={showDetail}
                    setShowDetail={setShowDetail}
                />
            }

        </div>
    );
};

export default HotLeads;