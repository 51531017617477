import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import {
  Table,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Fade,
} from "reactstrap";
import useGet from '../services/useGet';
import NoRecordsFound from './common/noRecordsFound';
import TableHeader from './common/tableHeader';
import TableLoader from './common/tableLoader';
import useDebounce from '../utils/customHooks';
import { formatDateTime } from '../utils/functions';
import Paginate from './common/pagination';
import SOURCES from '../utils/constants';
import HeaderSearchInput from './common/headerSearchInput';

const ActivityLog = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });
  const [totalRecords, setTotalRecords] = useState(0);
  const [activeLogs, setActiveLogs] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(-1);

  const { callApi: getUserLogs, data: userLog, isLoading: getUserLogsLoader } = useGet({
    endpoint: `reports/userlog?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const { callApi: getSearchData, data: searchData, isLoading: searchDataLoading } = useGet({
    endpoint: `reports/userlog/search?q=${searchInput}`,
  });

  const isLoading = getUserLogsLoader || searchDataLoading;
  const dataExist = activeLogs.results && activeLogs.results.length > 0 ? true : false;
  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) {
      getSearchData();
    } else if (!debouncedSearchTerm) {
      getUserLogs();
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getUserLogs();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    setActiveLogs(userLog);
  }, [userLog]);

  useEffect(() => {
    if (activeLogs) {
      setTotalRecords(activeLogs.totalRecords);
    }
  }, [activeLogs]);

  useEffect(() => {
    setActiveLogs(searchData);
  }, [searchData]);

  const sortData = (title) => {
    setSortBy(title);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <CardTitle>
            <Row className="justify-content-between">
              <Col lg={9} style={{ display: "flex", alignItems: "center" }} tag={"h4"}>
                <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                Activity Log
              </Col>
              <Col lg={3}>
                <HeaderSearchInput
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </Col>
            </Row>
          </CardTitle>
          <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader
              headers={[{ title: "Description" }, { title: "Occurred At", showIcon: true }]}
              sortData={sortData}
              setSortBy={setSortBy}
              sortBy={sortBy}
              sortOrder={sortOrder}
            />
            <tbody>
              {isLoading ? (
                <TableLoader colSpan="5" />
              ) : (
                <>
                  {dataExist ? (
                    activeLogs.results.map((log, idx) => (
                      <tr id={idx} style={{ whiteSpace: "nowrap" }} key={idx}>
                        <td>{log.description}</td>
                        <td>{formatDateTime(log?.dateAsPerUserTimezone)}</td>
                      </tr>
                    ))
                  ) : (
                    <NoRecordsFound colSpan="5" />
                  )}
                </>
              )}
            </tbody>
          </Table>
        </CardBody>
        <div className="px-4">
          {!searchInput && totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
            <FormGroup>
              <Paginate
                setPagination={setPagination}
                totalRecords={totalRecords}
                isLoading={isLoading}
              />
            </FormGroup>
          )}
        </div>
      </Card>
    </Fade>
  );
};

export default ActivityLog;
