import { Table } from 'reactstrap';
import { formatDateTime, formatDateTimeFromNow } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';

const PaymentHistoryList = ({ 
  isLoading, 
  history,
  setSortBy,
  sortBy,
  setSortOrder,
  sortOrder 

}) => {

  const sortData = (title) => {
    setSortBy(title)
    setSortOrder(sortOrder === 1 ? -1 : 1)
  }

  return (
    <Table responsive striped bordered hover size="sm" className="shadow">
      <TableHeader
        sortData={sortData}
        setSortBy={setSortBy}
        sortBy={sortBy}
        sortOrder={sortOrder}
        headers=
        {[
          {
            title: 'Payment At'
            , showIcon: true
          },
          {
            title: 'Payment Gateway'
            , showIcon: true
          },
          {
            title: 'Amount'
            , showIcon: true
          },
          {
            title: 'Description'
            , showIcon: true
          }
        ]}
      />
      <tbody>
        {isLoading ?
          <TableLoader colSpan="4" />
          :
          <>
            {
              history && history.length > 0 ?
                history?.map((item, idx) => {
                  return (
                    <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                      <td>
                        {formatDateTime(item.createdAt)}
                        <br />
                        <small>
                          {formatDateTimeFromNow(item.createdAt)}
                        </small>
                      </td>
                      <td>{item.paymentGateway}</td>
                      <td>{item.amount} $</td>
                      <td>{item.description}</td>
                    </tr>
                  );
                })
                :
                <NoRecordsFound colSpan="4" />
            }
          </>
        }
      </tbody>
    </Table>
  )
}

export default PaymentHistoryList