import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Row,
  Col,
  Button,
  Collapse,
  Fade,
} from "reactstrap";
import Paginate from "../common/pagination";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import SOURCES from "../../utils/constants";
import AdvanceSearch from "../common/advanceSearch";
import PaginateSearch from "../common/paginationSearch";
import LeadDetail from "./modules/leadDetail";
import LeadImportedList from "./modules/leadImportedList";
import HeaderSearchInput from "../common/headerSearchInput";

const LeadsImported = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING,
  });
  const [paginationSearch, setPaginationSearch] = useState({
    offsetSearch: 0,
    limitSearch: SOURCES.DEFAULT_RECORDS_SEARCH,
  });
  const [leadsImported, setLeadsImported] = useState([]);
  const [leadDetail, setLeadDetail] = useState(null);
  const [showDetail, setshowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [advanceSearch, setAdvanceSearch] = useState({
    advance_search_enabled:
      SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    startDate: "",
    endDate: "",
    leadSource: "",
    searchTerm: "",
    user: "",
  });
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getLeadSourcesOptions,
    data: leadSource,
    isLoading: getLeadSourcesOptionsLoader,
  } = useGet({
    endpoint: `leadsource/options`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `leadinfo/search?offset=
    ${paginationSearch.offsetSearch}
    &limit=${paginationSearch.limitSearch}
    &q=${searchInput}
    &advance_search=${advanceSearch.advance_search_enabled}
    &startdate=${advanceSearch.startDate}
    &enddate=${advanceSearch.endDate}
    &leadsource=${advanceSearch.leadSource}
    &term=${advanceSearch.searchTerm}
    &userEmail=${advanceSearch.user}`,
  });

  const {
    callApi: getLeadsImported,
    data: leadsData,
    isLoading: getLeadsLoading,
  } = useGet({
    endpoint: `leadinfo?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const isLoading =
    searchDataLoading || getLeadsLoading || getLeadSourcesOptionsLoader;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const dataExist =
    leadsImported && leadsImported?.results?.length > 0 ? true : false;

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getLeadsImported();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setAdvanceSearch({
      ...advanceSearch,
      advance_search_enabled:
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    });
    setShowAdvanceSearch(false);
  }, [searchInput]);

  useEffect(() => {
    if (
      advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER ||
      advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM
    ) {
      getLeadsImported();
      getLeadSourcesOptions();
      if (
        advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM
      )
        setAdvanceSearch({
          ...advanceSearch,
          advance_search_enabled:
            SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
        });
    }
  }, [
    pagination.offset,
    pagination.limit,
    advanceSearch.advance_search_enabled,
    sortBy,
    sortOrder,
  ]);

  useEffect(() => {
    if (
      advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER ||
      advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT
    )
      getSearchData();
  }, [paginationSearch.offsetSearch, paginationSearch.limitSearch]);

  useEffect(() => {
    setLeadsImported(leadsData);
  }, [leadsData]);

  useEffect(() => {
    setLeadsImported(searchData);
  }, [searchData]);

  const viewDetail = (item) => {
    setLeadDetail(item);
    setshowDetail(true);
  };

  return (
    <div>
      <Fade>
        <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
          <CardBody>
            <CardTitle>
              <Row>
                <Col lg={9} style={{ display: "flex" }} tag={"h4"}>
                  <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                  Leads Imported
                </Col>
                <Col lg={3} md={"auto"} sm={"auto"}>
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <HeaderSearchInput
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <Button
                      color="primary"
                      onClick={() => setShowAdvanceSearch(true)}
                      style={{
                        minWidth: "max-content",
                      }}
                    >
                      <FontAwesomeIcon icon={faFilter} className="mr-2" />
                      Advance Search
                    </Button>
                  </div>
                  <Collapse
                    isOpen={showAdvanceSearch}
                    delay={{
                      show: 5000,
                      hide: 5000,
                    }}
                  >
                    <AdvanceSearch
                      searchInput={searchInput}
                      isOpen={showAdvanceSearch}
                      advanceSearch={advanceSearch}
                      setShowAdvanceSearch={setShowAdvanceSearch}
                      setAdvanceSearch={setAdvanceSearch}
                      getSearchData={getSearchData}
                      filteredData={{ key: "LEAD-SOURCES", data: leadSource }}
                    />
                  </Collapse>
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
            <CardText>
              <LeadImportedList
                isLoading={isLoading}
                dataExist={dataExist}
                leadsImported={leadsImported}
                viewDetail={viewDetail}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
              />
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput &&
              [
                SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
                SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM,
              ].indexOf(advanceSearch.advance_search_enabled) >= 0 &&
              leadsImported?.totalReords > SOURCES.DEFAULT_RECORDS_LISTING && (
                <FormGroup>
                  <Paginate
                    setPagination={setPagination}
                    totalRecords={leadsImported?.totalReords}
                    isLoading={isLoading}
                  />
                </FormGroup>
              )}

            {([
              SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER,
              SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT,
            ].indexOf(advanceSearch.advance_search_enabled) >= 0 ||
              searchInput) &&
              leadsImported?.totalReords > SOURCES.DEFAULT_RECORDS_SEARCH && (
                <FormGroup>
                  <PaginateSearch
                    setPaginationSearch={setPaginationSearch}
                    totalRecords={leadsImported?.totalReords}
                  />
                </FormGroup>
              )}
          </div>
        </Card>
      </Fade>
      {leadDetail && (
        <LeadDetail
          leadDetail={leadDetail}
          showDetail={showDetail}
          setshowDetail={setshowDetail}
        />
      )}
    </div>
  );
};

export default LeadsImported;
