import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { faInfo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Table } from 'reactstrap';
import { formatDateTime } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';
import Popup from '../../common/popup';
import { MSG_LEADS_VIA_CSV } from "../../../utils/message"

const LeadViaCSVList = ({
    setDeleteId,
    deleteId,
    deleteLeadImportedCsv,
    removeSuccess,
    isLoading,
    dataExist,
    leadsImported,
    viewDetail,
    getLeadsImportedCsv,
    setLeadList,
    setToggleDeleteBtn,
    leadList,
    setSelectAll,
    selectAll,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
}) => {
    // const [deleteId, setDeleteId] = useState(0);
    const [toggleModal, setToggleModal] = useState(false);

    // const {
    //     callApi: deleteLeadImportedCsv,
    //     processSuccess: removeSuccess,
    // } = usePost({
    //     endpoint: `leadimportcsv/remove-lead`,
    // });

    useEffect(() => {
        if (removeSuccess) {
            setToggleModal(false);
            getLeadsImportedCsv()
        }
    }, [removeSuccess])

    useEffect(() => {
        setLeadList(leadsImported);
    }, [leadsImported]);

    useEffect(() => {
        let isLeadChecked = false;
        leadList?.results?.map((res) => {
            if (res?.isChecked) isLeadChecked = true;
        });
        setToggleDeleteBtn(isLeadChecked);
    }, [leadList])

    const removeLeadsImportedCSV = async () => {
        deleteLeadImportedCsv({
            id: deleteId
        }, MSG_LEADS_VIA_CSV.DELETE);
    };

    const handleSelectAll = (event) => {
        const checked = event.target.checked;
        setToggleDeleteBtn(checked);

        setSelectAll(checked);
        setLeadList(prevState => ({
            ...prevState,
            results: prevState?.results.map(item => {
                item.isChecked = checked;
                return item;
            })
        }));
    };

    const handleCheckboxChange = (event) => {
        const { id, checked } = event.target;

        if (checked) setToggleDeleteBtn(true);
        setLeadList(prevState => ({
            ...prevState,
            results: prevState?.results.map(item => {
                if (item._id === id) item.isChecked = checked;
                return item
            })
        }))
    };

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    return (
        <>
            <Table responsive striped size='sm' bordered hover className="shadow">
                <TableHeader headers={[
                    {
                        title:
                            <Input
                                type="checkbox"
                                checked={selectAll}
                                onChange={handleSelectAll}
                                className="m-0 position-static text-center"
                            />,
                        style: { textAlign: "center", width: "1%" }
                    },
                    {
                        title: 'Name'
                        , showIcon: true
                    },
                    {
                        title: 'Email'
                        , showIcon: true
                    },
                    {
                        title: 'Phone'
                        , showIcon: true
                    },
                    {
                        title: 'Event Name'
                        , showIcon: true
                    },
                    {
                        title: 'Imported At'
                        , showIcon: true
                    },
                    { style: { width: "1%" } }]}
                    sortData={sortData}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <tbody>
                    {
                        isLoading ?
                            <TableLoader colSpan="8" />
                            :
                            <>
                                {
                                    dataExist
                                        ?
                                        (leadList?.results && leadList?.results?.map((item, idx) => {
                                            return (
                                                <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                                    <td style={{ textAlign: "center" }}>
                                                        <Input
                                                            type="checkbox"
                                                            id={item._id}
                                                            checked={item.isChecked}
                                                            onChange={(e) => handleCheckboxChange(e)}
                                                            className="m-0 position-static text-center"
                                                        />
                                                    </td>
                                                    <td>
                                                        <Link to="#" onClick={() => viewDetail(item)}>
                                                            {
                                                                item.fullName ?
                                                                    item.fullName
                                                                    :
                                                                    item?.firstName + " " + item?.lastName
                                                            }
                                                        </Link>
                                                    </td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.phone}</td>
                                                    <td>{item?.eventName}</td>
                                                    <td>{formatDateTime(item?.dateAsPerUserTimezone)}</td>
                                                    <td>
                                                        <NavLink
                                                            to="#"
                                                            onClick={() => viewDetail(item)}
                                                            className="mx-2"
                                                        >
                                                            <FontAwesomeIcon icon={faInfo} />
                                                        </NavLink>

                                                        <NavLink
                                                            to="#"
                                                            style={{ color: "#dc3545" }}
                                                            onClick={() => {
                                                                setDeleteId(item?._id);
                                                                setToggleModal(true);
                                                            }}
                                                            className="mx-2"
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} />
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            );
                                        }))
                                        :
                                        <NoRecordsFound colSpan="7" />
                                }
                            </>
                    }
                </tbody>
            </Table>
            {toggleModal && <Popup
                title="Delete Lead"
                promtMessage="Are you sure you want to delete Lead?"
                showPopup={toggleModal}
                hidePopup={() => setToggleModal(false)}
                actionMethod={removeLeadsImportedCSV} />}
        </>
    )
}

export default LeadViaCSVList