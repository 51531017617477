import { useState } from "react";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Spinner } from "reactstrap";
import { useKey } from "../../services/useKey";

const Popup = ({ actionMethod, showPopup, hidePopup, title = "Delete", promtMessage = "Are you sure you want to delete?" }) => {
  const [loader, setLoader] = useState(false);

  useKey(() => {
    hidePopup()
  }, "Escape")

  useKey(() => {
    setLoader(true);
    actionMethod()
  }, "Enter")

  return (
    <div>
      <Modal isOpen={showPopup}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <div dangerouslySetInnerHTML={{ __html: promtMessage }} >
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => { setLoader(true); actionMethod() }}>
            Yes {loader && <Spinner className="ml-2" color="light" size="sm" children="" />}
          </Button>{" "}
          <Button onClick={hidePopup}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default Popup;