import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_CATEREASE = process.env.REACT_APP_INTEGRATION_CATEREASE;

const validationSchema = yupValidationSchema({
  webformLink: { required: true, customName: "Webform link", url: true },
});

const CatereaseForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [webformLink, setWebformLink] = useState("");
  const [sendLeadsLimitCaterease, setSendLeadsLimitCaterease] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_CATEREASE) {
          setWebformLink(secretKey);
          setSendLeadsLimitCaterease(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleCatereaseForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ webformLink }, { abortEarly: false });
      setLoading(true);
      await saveIntegration(
        {
          secretKey: webformLink,
          sendLeadsLimit: sendLeadsLimitCaterease,
          integrationCompany: DESTINATION_CATEREASE,
        },
        MSG_DESTINATION.SAVE_CATEREASE
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    // <div className="rounded border shadow p-3 my-3">

    <DestinationHeader headerTitle="Caterease" headerImage="images/caterease.png">
      <Form onSubmit={handleCatereaseForm} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="webform_link">
                Webhook Link{" "}
                <small style={{ color: "#17a2b8" }}>
                  (<strong>Sample Link:</strong>{" "}
                  https://inquiries.catereasewebtools.com/25XXXX-XXXX-XXXX-XXXX-XXXXXXXDC/123/)
                </small>
              </Label>
              <Input
                id="webform_link"
                name="webform_link"
                placeholder="Get Webform link from Caterease Application"
                autocomplete="off"
                type="url"
                defaultValue={webformLink}
                value={webformLink}
                onChange={(e) => setWebformLink(e.target.value)}
                invalid={errors.webformLink}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.webformLink} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitCaterease}
                onChange={(e) => setSendLeadsLimitCaterease(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleCatereaseForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
    // </div>
  );
};

export default CatereaseForm;
