import React, { useState, useEffect } from "react";
import {
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Table,
  Row,
  Col,
  Badge,
  NavLink,
  Card,
  Fade,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import { capitalizeFirstLetter, formatDateTime, getJobStatus } from "../utils/functions";
import Modal from "./common/modal";
import useGet from "../services/useGet";
import usePost from "../services/usePost";
import Popup from "./common/popup";
import LocalStorageService from "./../utils/localStorageServices";
import SOURCES from "../utils/constants";
import NoRecordsFound from "./common/noRecordsFound";
import TableLoader from "./common/tableLoader";
import TableHeader from "./common/tableHeader";
import CustomBarChart from "./graphs/barChart";
import CustomPieChart from "./graphs/pieChart";
import Notification from "./common/notification";
import { MSG_DASHBORD } from "../utils/message";

const localStorageService = LocalStorageService.getService();

const Dashboard = ({ currentUser }) => {
  const userTimezone = currentUser?.user?.timezone?.name;
  const history = useHistory();

  const [toggleModal, setToggleModal] = useState(false);
  const [toggleModalInfo, setToggleModalInfo] = useState(false);
  const [jobId, setJobId] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [notificationStatus] = useState("unread");


  const { callApi: getDestinations, data: destinations } = useGet({
    endpoint: `integration?countDest=1`,
  });

  const { callApi: getJobs, data: jobsData, isLoading } = useGet({
    endpoint: `jobs`,
  });

  const { callApi: jobCancel } = usePost({
    endpoint: `jobs/cancel`,
  });

  const { callApi: getNotifications, data: notifications } = useGet({
    endpoint: `notification/list/filter?readStatus=${notificationStatus}`,
  });

  const { callApi: getLastWeekImportedLeads, data: lastWeekLeads } = useGet({
    endpoint: `leadinfo/imported/lastweek`,
  });

  const { callApi: getLastWeekImportedLeadsPieChart, data: lastWeekLeadsPie } = useGet({
    endpoint: `leadinfo/imported/groupby/leadsource`,
  });


  useEffect(() => {
    getJobs();
    setUserRole(localStorageService.getCurrentUser().user.userRole);
    getDestinations();
    getNotifications();
    getLastWeekImportedLeadsPieChart();
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.user.userRole !== SOURCES.USER_ROLE_ADMIN) {
      if (destinations && destinations.length === 0) setToggleModalInfo(true);
    }
  }, [destinations]);

  const cancelJob = async () => {
    const postData = {
      jobId,
    };
    jobCancel(postData, MSG_DASHBORD.CANCLE_JOB);
    setToggleModal(false);
    setTimeout(() => {
      getJobs();
    }, 2000);
  };
  return (
    <div>
      <>
        {currentUser?.user && currentUser?.user?.paymentStatus === false && (
          <Notification msg={
            <>
              Please pay your outstandings. <Badge color="success" style={{ "cursor": "pointer" }} onClick={() => history.push("/payment")}>Pay now </Badge>
            </>
          }
          />
        )}

        {notifications && notifications.length > 0 && (
          <Notification msg={
            <>
              You have <Badge color="warning" style={{ "cursor": "pointer" }} onClick={() => history.push("/notifications")}>{notifications.length} </Badge> new notifications.
            </>
          }
          />
        )}

        {
          (userTimezone === null || userTimezone === "") && (
            <Notification msg={
              <>
                You have not setup your timezone.
                <Badge
                  color="warning"
                  style={{ "cursor": "pointer", "marginLeft": "5px" }}
                  onClick={() => history.push("/profile")}>Setup now</Badge>
              </>

            }
            />
          )
        }
        <Fade>
          <Card className="shadow-sm" color="shadow">
            <CardBody>
              <CardTitle tag="h4">
                <Row>
                  <Col sm={12}>Dashboard</Col>
                </Row>
              </CardTitle>
              <Card className="shadow"  >
                <CardBody>
                  <CardTitle tag="h4">
                    <Row>
                      <Col sm={12}>
                        <FontAwesomeIcon icon={faHistory} className="mr-2" />
                        Last 14 Days Leads Imported
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                  <Row>

                    <Col md={12} lg={8}>
                      <CustomBarChart
                        getData={getLastWeekImportedLeads}
                        data={lastWeekLeads}
                        title="Leads"
                      />
                    </Col>
                    <Col md={12} lg={4}>
                      <CustomPieChart
                        pieData={lastWeekLeadsPie}
                        cardLabel="Leads"
                      />
                    </Col>

                  </Row>
                </CardBody>
              </Card>
              <br />
              <Card className="shadow" >
                <CardBody>
                  <CardTitle tag="h4">
                    <Row>
                      <Col sm={12}>
                        <FontAwesomeIcon icon={faHistory} className="mr-2" />
                        Latest Leads Imported Jobs
                      </Col>
                    </Row>
                  </CardTitle>
                  <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                  <CardText>

                    <Table responsive striped bordered hover size="sm" className="shadow">
                      <TableHeader headers={[{ title: 'Job Name' }, { title: 'Job Status' }, { title: 'Leads Imported' }, { title: 'Processed At' } /*userRole === SOURCES.USER_ROLE_ADMIN && { title: '' }*/]} />
                      <tbody>
                        {isLoading ?
                          <TableLoader colSpan="4" />
                          : (
                            <>
                              {jobsData && jobsData?.infos.length > 0 ?
                                jobsData?.infos.map((item, idx) => {
                                  let badgeStatus = getJobStatus(item?.jobStatus);

                                  return ((userRole === SOURCES.USER_ROLE_ADMIN || item?.JobInformation.length > 0) &&
                                    <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                      <td>{item?.jobName}</td>
                                      <td>
                                        <Badge color={badgeStatus}>
                                          {capitalizeFirstLetter(item?.jobStatus)}
                                        </Badge>
                                      </td>
                                      <td><NavLink href="/leadsImported">{item?.JobInformation.length}</NavLink></td>
                                      <td>{formatDateTime(item.dateAsPerUserTimezone)}</td>
                                      {/* {
                                      userRole === SOURCES.USER_ROLE_ADMIN &&
                                      (
                                        <td>
                                          {item.jobStatus === SOURCES.JOB_STATUS_RUNNING &&
                                            <NavLink href="#">
                                              <span
                                                className="btn btn-danger"
                                                onClick={() => {
                                                  setJobId(item._id);
                                                  setToggleModal(true);
                                                }}
                                              >
                                                Cancel Job
                                              </span>
                                            </NavLink>
                                          }
                                        </td>
                                      )
                                    } */}
                                    </tr>
                                  );
                                })
                                :
                                <NoRecordsFound colSpan="4" />
                              }
                            </>
                          )}
                      </tbody>
                    </Table>
                  </CardText>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
        </Fade>
        {toggleModal && (
          <Popup
            showPopup={toggleModal}
            hidePopup={() => setToggleModal(false)}
            actionMethod={cancelJob}
            title="Cancel Job"
            promtMessage="Are you sure you want to cancel this Job?"
          />
        )}

        {toggleModalInfo && (
          <Modal
            showPopup={toggleModalInfo}
            hidePopup={() => setToggleModalInfo(false)}
            title="Getting Started"
            //message="Welcome!<br/> Next steps is Configure Destinations,Alerts, Autoresponds before creating lead import job(s)"
            message='Welcome!
            Setup is just 4 easy steps to start automatically importing leads into your system, alert staff and autorespond to inquiries. <br/><br/>  
            
            1.  Set <a href="/destinations" target="_blank"><b>Destinations</b></a> to connect your CRM.<br/>
            2.  Add <a href="/leadsources" target="_blank"><b>Lead Import Jobs</b></a> by selecting your lead source and adding your username & password.<br/>
            3.  Create <a href="/autoresponders" target="_blank"><b>Autoresponders</b></a> to send leads from any portal with custom message.<br/>
            4.  Finally, set <a href="/alerts" target="_blank"><b>Alerts</b></a> for your team with SMS and/or Emails when new leads arrive.'
          />
        )}
      </>
    </div>
  );
};

export default Dashboard;
