import { useEffect, useState } from "react";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, CardFooter, Fade } from "reactstrap";
import { useDispatch } from "react-redux";
import useGet from "../services/useGet";
import usePost from "../services/usePost";
import GenericTabs from "./common/tab";
import SubmitButton from "./common/submitButton";
import Header from "./common/header";
import LocalStorageService from "../utils/localStorageServices";
import { showUserNotification } from "../utils/functions";
import SettingBasics from "./forms/settings/basic";
import SettingBussinessHours from "./forms/settings/bussinessHours";
import SOURCES from "../utils/constants";

const localStorageServices = LocalStorageService.getService();

const Settings = () => {
  const [settingMaxUploadLimit, setSettingMaxUploadLimit] = useState("");
  const [settingAutomaticallyAcceptLead, setSettingAutomaticallyAcceptLead] = useState("y");
  const [days, setDays] = useState();

  const dispatch = useDispatch();
  const currentUser = localStorageServices.getCurrentUser();
  const timeZone = currentUser?.user?.timezone?.name;
  const paymentplan = currentUser?.user?.paymentPlanId?.planCode;
  const defaultStartTime = "9:00";
  const defaultEndTime = "18:00";

  const resetDays = () => {
    setDays([
      {
        name: "Monday",
        day: "Mon",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
      {
        name: "Tuesday",
        day: "Tue",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
      {
        name: "Wednesday",
        day: "Wed",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
      {
        name: "Thursday",
        day: "Thu",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
      {
        name: "Friday",
        day: "Fri",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
      {
        name: "Saturday",
        day: "Sat",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
      {
        name: "Sunday",
        day: "Sun",
        startTime: defaultStartTime,
        endTime: defaultEndTime,
      },
    ]);
  };

  const settingOptions = {
    settingKeys: {
      MAX_LEADS_UPLOAD_VIA_CSV: "MAX_LEADS_UPLOAD_VIA_CSV",
      ACCEPT_LEADS_IMPORTED_VIA_LEAD_FORM: "ACCEPT_LEADS_IMPORTED_VIA_LEAD_FORM",
      BUSSINESS_HOURS: "BUSSINESS_HOURS",
    },
    settingAutomaticallyAcceptLead: [
      { id: "y", val: "Yes" },
      { id: "n", val: "No" },
    ],
  };

  const { callApi: getSetting, data: settings } = useGet({
    endpoint: `user/settings`,
  });

  const { callApi: saveSetting, isLoading } = usePost({
    endpoint: `user/settings`,
  });

  useEffect(() => {
    getSetting();
    resetDays();
  }, []);

  useEffect(() => {
    if (settings) {
      settings.forEach((setting) => {
        const { settingKey, settingValue } = setting;

        switch (settingKey) {
          case settingOptions.settingKeys.MAX_LEADS_UPLOAD_VIA_CSV:
            setSettingMaxUploadLimit(settingValue);
            break;

          case settingOptions.settingKeys.ACCEPT_LEADS_IMPORTED_VIA_LEAD_FORM:
            setSettingAutomaticallyAcceptLead(settingValue);
            break;

          case settingOptions.settingKeys.BUSSINESS_HOURS:
            setDays(settingValue);
            break;

          default:
            break;
        }
      });
    }
  }, [settings]);

  const parseTimeString = (timeString) => {
    if (timeString) {
      const [hours, minutes] = timeString.split(":");
      return hours && minutes ? new Date(0, 0, 0, hours, minutes, 0, 0) : null;
    }
    return null;
  };

  const saveLeadSetting = async () => {
    const invalidDays = days.filter(({ startTime, endTime, day }) => {
      const [start, end] = [parseTimeString(startTime), parseTimeString(endTime)];
      return !(start && end && start < end && (end - start) / (60 * 60 * 1000) <= 10);
    });

    const isDurationValid = invalidDays.length === 0;
    const filteredInvalidDays = invalidDays.map((days) => days.day);
    if (!isDurationValid) {
      dispatch(
        showUserNotification(
          `Business hours must not be greater than 10 hours on ${filteredInvalidDays}`,
          "danger"
        )
      );
      return;
    }

    await saveSetting({
      settings: [
        {
          settingKey: settingOptions?.settingKeys.ACCEPT_LEADS_IMPORTED_VIA_LEAD_FORM,
          settingValue: settingAutomaticallyAcceptLead,
        },
        {
          settingKey: settingOptions?.settingKeys.BUSSINESS_HOURS,
          settingValue: days,
        },
      ],
    });
  };

  const handleTimeChange = (idx, field, value) => {
    const updateTime = [...days];
    updateTime[idx][field] = value;
    setDays(updateTime);
  };
  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <Header icon={faCogs} title="Settings" />
          <GenericTabs
            tabs={[
              {
                tabId: "1",
                title: "Basics",
                icon: "images/tabs/lead-setting.png",
                component: (
                  <SettingBasics
                    settingMaxUploadLimit={settingMaxUploadLimit}
                    setSettingAutomaticallyAcceptLead={setSettingAutomaticallyAcceptLead}
                    settingOptions={settingOptions}
                    settingAutomaticallyAcceptLead={settingAutomaticallyAcceptLead}
                  />
                ),
              },
              [SOURCES.PAYMENT_PLAN_CODE.P_5.toString(), SOURCES.PAYMENT_PLAN_CODE.P_4.toString()].includes(paymentplan?.toString()) &&
                {
                tabId: "2",
                title: "Business Hours",
                icon: "images/tabs/setting-bussiness-hours.png",
                component: (
                  <SettingBussinessHours
                    timeZone={timeZone}
                    days={days}
                    handleTimeChange={handleTimeChange}
                  />
                ),
              },
            ]}
          />
        </CardBody>
        <CardFooter>
          <SubmitButton onClick={saveLeadSetting} isLoading={isLoading} cancleButton={false} />
        </CardFooter>
      </Card>
    </Fade>
  );
};

export default Settings;
