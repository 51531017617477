import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Row,
  Col,
  Fade
} from "reactstrap";
import Paginate from "../common/pagination";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import SOURCES from "../../utils/constants";
import CancelledSubscriptionList from "./modules/cancelledSubscriptionList";
import HeaderSearchInput from "../common/headerSearchInput";

const SubscriptionsCancelled = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });
  const [subscriptionList, setSubscriptionList] = useState();
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `admin/subscription/cancelled/search?q=${searchInput}`,
  });

  const {
    callApi: getSubscriptionCancelled,
    data: subscriptionCancelledList,
    isLoading: getLeadsLoading,
  } = useGet({
    endpoint: `admin/subscription/cancelled?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const isLoading = searchDataLoading || getLeadsLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getSubscriptionCancelled();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getSubscriptionCancelled();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    setSubscriptionList(subscriptionCancelledList);
  }, [subscriptionCancelledList]);

  useEffect(() => {
    setSubscriptionList(searchData);
  }, [searchData]);


  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <CardTitle tag="h4">
            <Row>
              <Col sm={9}>
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Subscriptions Cancelled
              </Col>
              <Col sm={3}>
                <HeaderSearchInput
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </Col>
            </Row>
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
          <CardText>
            <CancelledSubscriptionList
              isLoading={isLoading}
              subscriptionList={subscriptionList}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
          </CardText>
        </CardBody>
        <div className="px-4">
          {!searchInput && subscriptionList?.totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
            <FormGroup>
              <Paginate
                setPagination={setPagination}
                totalRecords={subscriptionList?.totalRecords}
                isLoading={isLoading}
              />
            </FormGroup>
          )}
        </div>
      </Card>
    </Fade>
  );
};

export default SubscriptionsCancelled;
