import axios from "axios";
import { useDispatch } from "react-redux";
import { showAlert } from "../redux/actions";
import LocalStorageService from "../utils/localStorageServices";
const { useState } = require("react");
const localStorageService = LocalStorageService.getService();


const useDownloadFile = ({
    endpoint,
    type = "GET"
}) => {

    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState(false);

    const dispatch = useDispatch();
    const getData = async (params) => {
        try {
            setLoading(true);
            const token = localStorageService.getAccessToken();
            let apiEndpoint = endpoint;
            // console.log('params',params)
            if (params) {
                apiEndpoint = apiEndpoint + '?';
                params.map((param, i) => {
                    // console.log('this param',param)
                    apiEndpoint += `${param.key}=${param.val}&`;
                })
            }
            //  console.log('apiEndpoint',apiEndpoint)
            const result = await axios({
                url: `${process.env.REACT_APP_API_URL}/${apiEndpoint}`,
                type,
                responseType: "blob",
                headers: {
                    'x-access-token': token
                }
            });
            if (result) {
                const data = result.data;
                setData(data);
            }
        } catch (e) {
            dispatch(
                showAlert({
                    show: true,
                    type: "danger",
                    msg: e?.response?.data?.data?.error,
                })
            );
        } finally {
            setLoading(false);
        }
    };
    return {
        callApi: getData,
        isLoading,
        data
    }
}

export default useDownloadFile;