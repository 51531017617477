import React from "react";
import {
  Card,
  CardBody,
  Col,
  Fade,
  FormGroup,
  Input,
  Row,
  Spinner,
} from "reactstrap";

const MailChimpCampaignsModules = ({
  getMailChimpSelectedCampaignsLoader,
  setCampaignIdMailChimp,
  mailChimpSelectedCampaigns,
  extraInfo,
}) => {
  return (
    <Fade>
      <Card className="shadow" style={{ marginTop: "10px" }}>
        <CardBody>
          <Row>
            <Col sm={6} md={7} lg={7}>
              <FormGroup>
                <legend>
                  <h5>Choose mailChimp campaign to Send Leads</h5>
                </legend>

                {getMailChimpSelectedCampaignsLoader ? (
                  <Spinner className="ml-2" children="" />
                ) : (
                  <Input
                    id="mailchimpCampain"
                    name="select"
                    type="select"
                    className="w-100 p-2 mr-2"
                    onChange={(e) => setCampaignIdMailChimp(e.target.value)}
                  >
                    <option value="">Select mailchimp campaign</option>
                    {mailChimpSelectedCampaigns &&
                      mailChimpSelectedCampaigns.map((opt) => {
                        return (
                          <option
                            value={opt.id}
                            selected={
                              extraInfo?.campaignIdMailChimp &&
                              opt?.id.toString() ===
                                extraInfo?.campaignIdMailChimp.toString()
                            }
                          >
                            {opt.name}
                          </option>
                        );
                      })}
                  </Input>
                )}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default MailChimpCampaignsModules;
