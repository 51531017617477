import React,{ useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faInfo, faTrash, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, CardTitle, CardText, Table, Row, Col, Button, Badge, ButtonGroup, Fade } from "reactstrap";
import { capitalizeFirstLetter, formatDateTime } from "../../../utils/functions";
import { useKey } from "../../../services/useKey";
import SOURCES from "../../../utils/constants";
import Popup from "../../common/popup";
import usePost from "../../../services/usePost";
import TableRow from "../../common/tableRow";


const AffiliateDetails = ({ affiliateDetail, showDetail, setShowDetail, getAffiliate }) => {

    const [toggleModalOptions, setToggleModalOptions] = useState({
        showModal: false,
        title: "",
        postMessage: "",
        actionMethod: ""
    });

    const { callApi: approvedRejectAffiliate, processSuccess } = usePost({
        endpoint: `affiliate/approve-reject`,
    });

    const handleApprovedReject = async (id, status) => {
        try {
            await approvedRejectAffiliate({ id, status })

        } catch (e) { }
        finally {
            setToggleModalOptions({ ...toggleModalOptions, showModal: false })
        }
    }

    useEffect(() => {
        if (processSuccess) {
            setShowDetail(false)
            getAffiliate()
        }
    }, [processSuccess])


    useKey(() => {
        setShowDetail(false)
    }, "Escape")

    return (
        <Fade>
            <Card color="light" className={`${showDetail ? "d-block" : "d-none"}`}>
                <CardBody>
                    <CardTitle tag="h4">
                        <Row className="justify-content-between">
                            <Col md={"auto"} xs={"auto"}>
                                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                                {capitalizeFirstLetter(affiliateDetail?.userType)} Detail
                            </Col>

                            <Col md={"auto"} xs={"auto"}>
                                <ButtonGroup>
                                    {affiliateDetail?.userStatus === "pending" && affiliateDetail?.userStatus !== "reject" &&
                                        <>
                                            <Button
                                                className="mr-1"
                                                color="success"
                                                title='Accept'
                                                size='sm'
                                                onClick={() => {
                                                    setToggleModalOptions({
                                                        ...toggleModalOptions,
                                                        showModal: true,
                                                        title: "Approve Affiliate/Partner",
                                                        postMessage: "Are you sure you want to approve affiliate/partner?",
                                                        actionMethod: () => handleApprovedReject(affiliateDetail?._id, SOURCES.STATUS_APPROVED)
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faCheck} size='sm' />
                                            </Button>

                                            <Button
                                                className="mr-1"
                                                color='danger'
                                                title='Reject'
                                                size='sm'
                                                onClick={() => {
                                                    setToggleModalOptions({
                                                        ...toggleModalOptions,
                                                        showModal: true,
                                                        title: "Reject Affiliate/Partner",
                                                        postMessage: "Are you sure you want to reject affiliate/partner?",
                                                        actionMethod: () => handleApprovedReject(affiliateDetail?._id, SOURCES.STATUS_REJECT)
                                                    });
                                                }}
                                            >
                                                <FontAwesomeIcon icon={faTrash} size='sm' />
                                            </Button>
                                        </>
                                    }


                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            setShowDetail(false)
                                        }}

                                        size="sm"
                                    >
                                        <FontAwesomeIcon
                                            icon={faWindowClose}
                                            size="sm"
                                        />
                                    </Button>
                                </ButtonGroup>
                            </Col>

                        </Row>
                    </CardTitle>
                    <CardText>
                        <Table responsive striped bordered hover size="sm">
                            <TableRow label="Name" value={affiliateDetail?.name} />
                            <TableRow label="Email" value={affiliateDetail?.email} />
                            <TableRow label="Phone" value={affiliateDetail?.phone} />
                            <TableRow label="Company" value={affiliateDetail?.companyName} />
                            <TableRow label="Company URL" value={affiliateDetail?.companyUrl} />
                            <TableRow label="Bussiness Type" value={affiliateDetail?.bussinessType} />
                            <TableRow label="Type" value={capitalizeFirstLetter(affiliateDetail?.userType)} />
                            <TableRow label="Stripe Onboarded"
                                value={
                                    <Badge color={affiliateDetail?.stripeConnectedStatus === "progress" ? "danger" : "success"}>
                                        {capitalizeFirstLetter(affiliateDetail?.stripeConnectedStatus)}
                                    </Badge>
                                } />
                            <TableRow label="User Status"
                                value={
                                    <Badge color={affiliateDetail?.userStatus === SOURCES.STATUS_APPROVED ? "success" : "danger"}>
                                        {capitalizeFirstLetter(affiliateDetail?.userStatus)}
                                    </Badge>
                                } />
                            <TableRow label="Commission Type" value={capitalizeFirstLetter(affiliateDetail?.commissionType)} />
                            <TableRow label="Commission Value"
                                value={
                                    affiliateDetail?.commissionValue + (affiliateDetail?.commissionType === "percentage" && "%")
                                } />
                            <TableRow label="Created At" value={formatDateTime(affiliateDetail?.createdAt)} />
                            <TableRow label="Additional Information" value={affiliateDetail?.additionalInformation} classNameTD="break-word" />




                            {/* <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Name</th>
                                <td>
                                    {affiliateDetail?.name}
                                </td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Email</th>
                                <td>{affiliateDetail?.email}</td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Phone</th>
                                <td>{affiliateDetail?.phone}</td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Company</th>
                                <td>{affiliateDetail?.companyName}</td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Company URL</th>
                                <td>{affiliateDetail?.companyUrl}</td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Bussiness Type</th>
                                <td>{affiliateDetail?.bussinessType}</td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Type</th>
                                <td>{capitalizeFirstLetter(affiliateDetail?.userType)}</td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Stripe Onboarded</th>
                                <td>
                                    <Badge color={affiliateDetail?.stripeConnectedStatus === "progress" ? "danger" : "success"}>
                                        {capitalizeFirstLetter(affiliateDetail?.stripeConnectedStatus)}
                                    </Badge>
                                </td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>User Status</th>
                                <td>
                                    <Badge color={affiliateDetail?.userStatus === SOURCES.STATUS_APPROVED ? "success" : "danger"}>
                                        {capitalizeFirstLetter(affiliateDetail?.userStatus)}
                                    </Badge>
                                </td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Commission Type</th>
                                <td>{capitalizeFirstLetter(affiliateDetail?.commissionType)}</td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Commission Value</th>
                                <td>
                                    {affiliateDetail?.commissionValue}
                                    {affiliateDetail?.commissionType === "percentage" && "%"}
                                </td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Created At</th>
                                <td>{formatDateTime(affiliateDetail?.createdAt)}</td>
                            </tr>
                            <tr style={{ whiteSpace: "break-word" }}>
                                <th>Additional Information</th>
                                <td style={{ whiteSpace: "break-word" }}>{affiliateDetail?.additionalInformation}</td>
                            </tr> */}
                        </Table>
                    </CardText>
                </CardBody>
            </Card>

            {toggleModalOptions.showModal && (
                <Popup
                    showPopup={toggleModalOptions.showModal}
                    hidePopup={() => setToggleModalOptions({ ...toggleModalOptions, showModal: false })}
                    actionMethod={toggleModalOptions.actionMethod}
                    title={toggleModalOptions.title}
                    promtMessage={toggleModalOptions.postMessage}
                />
            )}
        </Fade>
    )
}

export default AffiliateDetails