import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPlus,
  faUser,
  faUserShield,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
  FormGroup,
  Row,
  Fade,
  Button,
} from "reactstrap";
import Select from "react-select";
import Paginate from "../common/pagination";
import usePost from "../../services/usePost";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import UserList from "./modules/userList";
import LocalStorageService from "../../utils/localStorageServices";
import HeaderSearchInput from "../common/headerSearchInput";
import { setCurrentUser } from "../../redux/actions";
import { useDispatch } from "react-redux";

const TestAccounts = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING,
  });

  const [userList, setUserlist] = useState();
  const [selectUsers, setSelectUsers] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [showDetail, setshowDeatail] = useState(false);
  const [leadDetail, setLeadDetail] = useState(null);
  const [deleteId, setDeleteId] = useState();
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [addNewTestUser, setAddNewTestUser] = useState(false);

  const localStorageServices = LocalStorageService.getService();
  const history = useHistory();
  const dispatch = useDispatch();
  const { callApi: getUsers, data: users } = useGet({
    endpoint: `admin/users?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const { callApi: getSearchData, data: searchData } = useGet({
    endpoint: `admin/users/search?q=${searchInput}&searchTestUsers=${true}`,
  });

  const { callApi: accountStatus, processSuccess } = usePost({
    endpoint: `admin/status`,
  });

  const { callApi: getPaymentPlans } = useGet({
    endpoint: `payment/plans`,
  });

  const { callApi: addTestingAccounts } = usePost({
    endpoint: `user/toggle-test-account`,
  });

  const { callApi: loginUser } = usePost({
    endpoint: `admin/login-as-user`,
  });

  const { callApi: toggleTestAccount } = usePost({
    endpoint: `user/toggle-test-account`,
  });

  const loginAsUser = async (email) => {
    const userData = await loginUser({ email });
    localStorageServices.clearToken();
    localStorageServices.setCurrentUser(userData);

    dispatch(setCurrentUser(userData));
    history.push("/dashboard");
  };

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    getPaymentPlans();
  }, [window.location.pathname]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getUsers();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getUsers();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    if (users?.users) {
      const filteredData = users?.users.filter((ele) => ele.isTestAccount);
      if (filteredData) setUserlist(filteredData);
    }

    if (searchData && searchInput) setUserlist(searchData?.users);
  }, [users, searchData]);

  const handleOnChange = async (e) => {
    const term = e.target.value;
    if (term.length >= 2) {
      setSearchInput(term);
    } else {
      if (term.length === 0) setSearchInput("");
    }
  };

  const handleAddSelectedUser = async () => {
    const respAdd = await addTestingAccounts(
      {
        userId: selectUsers,
        status: true,
      },
      "Testing account added successfully",
      true
    );
    if (respAdd?.status === SOURCES.HTTP_CODE_200) {
      setAddNewTestUser(false);
      getUsers();
      setSelectUsers();
    }
  };

  const handleRemoveTestAccount = async (userId) => {
    const respRemove = await toggleTestAccount(
      {
        userId,
        status: false,
      },
      "Test account removed successfully",
      true
    );
    if (respRemove?.status === SOURCES.HTTP_CODE_200) getUsers();
  };

  return (
    <Fade>
      <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
        <CardBody>
          <CardTitle>
            <Row>
              <Col tag="h4">
                <FontAwesomeIcon icon={faUser} className="mr-2" />
                Test Accounts
              </Col>
            </Row>

            <Row style={{ justifyContent: "space-between" }}>
              <Col lg={"auto"}>
                <Button
                  color="primary"
                  className="my-1"
                  onClick={() => setAddNewTestUser(!addNewTestUser)}
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Add Test Account
                </Button>
              </Col>
              <Col lg={"auto"}>
                <HeaderSearchInput onChange={handleOnChange} />
              </Col>
            </Row>
            {addNewTestUser && (
              <Row>
                <Col lg={12}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                      gap: "10px",
                    }}
                  >
                    <FormGroup className="w-100 align-items-center">
                      <Select
                        placeholder="Select One"
                        onChange={(selectedOption) =>
                          setSelectUsers(selectedOption.value)
                        }
                        options={users?.users.map((item) => ({
                          value: item._id,
                          label: item.name,
                        }))}
                        value={users?.users?.find(
                          (option) => option.value === selectUsers
                        )}
                      />
                    </FormGroup>
                    <Button
                      disabled={!selectUsers}
                      className="d-flex align-items-center"
                      color="primary"
                      onClick={handleAddSelectedUser}
                    >
                      <FontAwesomeIcon icon={faPlus} className="mr-2" />
                      Add
                    </Button>
                  </div>
                </Col>
              </Row>
            )}
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
          <CardText>
            <UserList
              userList={userList}
              leadDetail={leadDetail}
              setshowDeatail={setshowDeatail}
              showDetail={showDetail}
              setLeadDetail={setLeadDetail}
              getUsers={getUsers}
              getSearchData={getSearchData}
              searchInput={searchInput}
              accountStatus={accountStatus}
              loginAsUser={loginAsUser}
              processSuccess={processSuccess}
              setDeleteId={setDeleteId}
              deleteId={deleteId}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
              testAccount={true}
              handleRemoveTestAccount={handleRemoveTestAccount}
            />
          </CardText>
        </CardBody>
        <div className="px-4">
          {!searchInput &&
            userList?.count > SOURCES.DEFAULT_RECORDS_LISTING && (
              <FormGroup>
                <Paginate
                  setPagination={setPagination}
                  totalRecords={userList?.count}
                />
              </FormGroup>
            )}
        </div>
      </Card>
    </Fade>
  );
};

export default TestAccounts;
