import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Row,
  Col,
  Fade,
  FormGroup,
  Table,
  Input,
} from "reactstrap";
import Select from "react-select";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import PaymentHistoryList from "./modules/paymentHistoryList";
import HeaderSearchInput from "../common/headerSearchInput";
import TableHeader from "../common/tableHeader";
import TableLoader from "../common/tableLoader";
import NoRecordsFound from "../common/noRecordsFound";
import { formatNumber, getCurrentYear, getMonthName } from "../../utils/functions";


const IncomeReports = () => {
  const [allyears, setAllYears] = useState();
  const [selectedYear, setSelectedYear] = useState({
    label: getCurrentYear().toString(),
    value: getCurrentYear(),
  });

  const [totalAnnualIncome, setTotalAnnualIncome] = useState(null);

  const {
    callApi: getReportMonthWise,
    data: reportData,
    isLoading: isLoading,
  } = useGet({
    endpoint: `admin/payment-monthwise?q=${selectedYear?.value}`,
  });

  useEffect(() => {
    const startYear = 2021;
    const endYear = new Date().getFullYear();
    const years = Array.from({ length: endYear - startYear + 1 }, (v, k) => startYear + k).reverse();

    const yearOptions = years.map((year) => ({ value: year, label: year.toString() }));

    setAllYears(yearOptions);
  }, []);

  useEffect(() => {
    if (selectedYear) getData();
  }, [selectedYear]);

  const getData = async () => {
    await getReportMonthWise();
  };

  useEffect(() => {
    if (reportData) {
      const totalIncome = reportData?.reduce((sum, item) => sum + item.totalAmount, 0);
      setTotalAnnualIncome(totalIncome);
    }
  }, [reportData]);



  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const renderPaymentHistory = () => {
    return months.map((month, idx) => {
      const reportForMonth = reportData.find((data) => data._id.month === month);

      return (
        <>
          <tr key={idx} style={{ whiteSpace: "nowrap" }}>
            <td>{getMonthName(month)}</td>
            <td>{reportForMonth ? `$ ${formatNumber(reportForMonth.totalAmount)}` : "-"}</td>
          </tr>
          {month === 12 && (
            <tr>
              <td>
                <strong>Total</strong>
              </td>
              <td>
                <strong>$ { formatNumber(totalAnnualIncome)}</strong>
              </td>
            </tr>
          )}
        </>
      );
    });
  };


  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <CardTitle tag="h4">
            <Row>
              <Col sm={9} style={{ display: "flex", alignItems: "center" }} tag={"h4"}>
                <FontAwesomeIcon icon={faHistory} className="mr-2" />
                Monthwise Payment History
              </Col>
              <Col sm={3} tag={"h5"}>
                <FormGroup>

                  <Select
                    value={selectedYear}
                    onChange={(e) => {
                      setSelectedYear(e);
                    }}
                    options={allyears}
                    placeholder="Select Year"
                    styles={{ height: "30px", padding: "0px" }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardTitle>
          <CardText>
            <Table responsive striped bordered hover size="sm" className="shadow">
              <TableHeader
                headers={[
                  { title: "Month", showIcon: false },
                  { title: "Income ", showIcon: false },
                ]}
              />
              <tbody>
                {isLoading ? (
                  <TableLoader colSpan="6" />
                ) : (
                  <>{reportData?.length > 0 ? renderPaymentHistory() : <NoRecordsFound colSpan="6" />}</>
                )}
              </tbody>
            </Table>
          </CardText>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default IncomeReports;
