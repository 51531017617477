import { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "react-datepicker/dist/react-datepicker.css";
import LocalStorageService from "../../utils/localStorageServices";
import useGet from "../../services/useGet";
import { useKey } from "../../services/useKey";
import SOURCES from "../../utils/constants";
import { listSearchable } from "../../utils/functions";
import SubmitButton from "./submitButton";

const localStorageServices = LocalStorageService.getService();

const AdvanceSearch = ({
  setShowAdvanceSearch,
  setAdvanceSearch,
  advanceSearch,
  getSearchData,
  filteredData = { key: "LEAD-SOURCES", data: {} },
}) => {
  const [searched, setSearched] = useState(false);
  const [userList, setUserList] = useState();
  const [leadSourceList, setLeadSourceList] = useState(null);
  const [destinationList, setDestinationList] = useState([]);
  const currentUser = localStorageServices.getCurrentUser();
  const userRole = currentUser.user.userRole;

  const { callApi: getUsers, data: users } = useGet({
    endpoint: `admin/users?ignorePagination=true`,
  });

  useKey(() => setSearched(true), "Enter");

  useKey(() => {
    setShowAdvanceSearch(false);
    setAdvanceSearch({
      ...advanceSearch,
      advance_search_enabled: searched
        ? SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM
        : SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    });
  }, "Escape");

  useEffect(() => {
    if (userRole === SOURCES.USER_ROLE_ADMIN) getUsers();
    if (searched === true) {
      setAdvanceSearch({
        ...advanceSearch,
        advance_search_enabled:
          SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER,
      });
      setSearched(false);
    }
  }, [searched]);

  useEffect(() => {
    if (
      advanceSearch.advance_search_enabled ===
      SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER
    ) {
      getSearchData();
      setAdvanceSearch({
        ...advanceSearch,
        advance_search_enabled:
          SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT,
      });
    }
  }, [advanceSearch.advance_search_enabled]);

  useEffect(() => {
    if (advanceSearch.startDate)
      setAdvanceSearch({ ...advanceSearch, endDate: advanceSearch.startDate });
  }, [advanceSearch.startDate]);

  useEffect(() => {
    if (filteredData?.data) {
      if (filteredData?.key === "DESTINATION") {
        const desList = listSearchable(
          filteredData?.data,
          "integrationCompany",
          "integrationCompany"
        );
        setDestinationList(desList);
      } else {
        const lsList = listSearchable(filteredData?.data, "key", "title");
        setLeadSourceList(lsList);
      }

      if (users) {
        const usList = listSearchable(users?.users, "email", ["name", "email"]);
        setUserList(usList);
      }
    }
  }, [filteredData?.data, users]);

  let termClassNames = filteredData?.hideTermInput ? "my-2 d-none" : "my-2";

  return (
    <>
      <Card className="shadow">
        <CardBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <DatePicker
                  className="w-100 py-1 px-2 border-none"
                  placeholderText="Start Date"
                  selected={advanceSearch.startDate}
                  onChange={(date) =>
                    setAdvanceSearch({ ...advanceSearch, startDate: date })
                  }
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <DatePicker
                  placeholderText="End Date"
                  className="w-100 py-1 px-2"
                  selected={advanceSearch.endDate}
                  onChange={(date) =>
                    setAdvanceSearch({ ...advanceSearch, endDate: date })
                  }
                />
              </FormGroup>
            </Col>
          </Row>
          {filteredData?.key === "LEAD-SOURCES" && (
            <>
              <Col lg={12} style={{ padding: "0px" }}>
                <FormGroup>
                  {leadSourceList && (
                    <Select
                      placeholder="Filter Via Lead Source"
                      timezonesLoading={false}
                      options={leadSourceList}
                      onChange={(opt) =>
                        setAdvanceSearch({
                          ...advanceSearch,
                          leadSource: opt.value,
                        })
                      }
                      value={leadSourceList.filter((option) => {
                        return option.value === advanceSearch.leadSource;
                      })}
                    />
                  )}
                </FormGroup>
              </Col>
              {userRole === SOURCES.USER_ROLE_ADMIN && (
                <Col lg={12} style={{ padding: "0px" }}>
                  <FormGroup>
                    {userList && (
                      <Select
                        placeholder="Filter Via User"
                        timezonesLoading={false}
                        options={userList}
                        onChange={(opt) => {
                          setAdvanceSearch({
                            ...advanceSearch,
                            user: opt.value,
                          });
                        }}
                        value={userList.filter((option) => {
                          return option.value === advanceSearch.user;
                        })}
                      />
                    )}
                  </FormGroup>
                </Col>
              )}
            </>
          )}

          {filteredData?.key === "DESTINATION" && (
            <Col lg={12} style={{ padding: "0px" }}>
              <FormGroup>
                <Select
                  placeholder="Filter Via Destination"
                  timezonesLoading={false}
                  options={destinationList}
                  onChange={(opt) => {
                    setAdvanceSearch({
                      ...advanceSearch,
                      destination: opt.value,
                    });
                  }}
                  value={destinationList.filter((option) => {
                    return option.value === advanceSearch.destination;
                  })}
                />
                {/* <Input
                  id="destinations"
                  name="select"
                  type="select"
                  className="w-100 p-2 mt-2 border rounded"
                  onChange={(e) => {
                    setAdvanceSearch({ ...advanceSearch, destination: e.target.value });
                  }}
                  tabIndex={4}
                >
                  <option value="">Filter Via Destination</option>
                  {filteredData?.data &&
                    filteredData?.data.map((opt) => {
                      return (
                        <option
                          value={opt.integrationCompany}
                          selected={opt.integrationCompany === advanceSearch.destination}
                        >
                          {capitalizeFirstLetter(opt.integrationCompany)}
                        </option>
                      );
                    })}
                </Input> */}
              </FormGroup>
            </Col>
          )}

          <Input
            placeholder="Enter Name,Email or Phone"
            value={advanceSearch.searchTerm}
            className={termClassNames}
            onChange={(e) =>
              setAdvanceSearch({ ...advanceSearch, searchTerm: e.target.value })
            }
            tabIndex={4}
          />
          <CardFooter>
            <FormGroup
              style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}
            >
              <SubmitButton
                title=" Search"
                icon={faSearch}
                onClick={() => {
                  setSearched(true);
                }}
                cancleButtonOnClick={() => {
                  setShowAdvanceSearch(false);
                  setAdvanceSearch({
                    ...advanceSearch,
                    advance_search_enabled: searched
                      ? SOURCES.ADVANCE_SEARCH_FIELDS
                          .SEARCH_TYPE_CLOSED_SEARCH_FORM
                      : SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
                  });
                }}
              />
              {/* <Button color="primary" onClick={() => {
                setSearched(true)
                // getSearchData();
              }}>
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                Search
              </Button>
              <Button color="secondary" onClick={() => {
                setShowAdvanceSearch(false);
                setAdvanceSearch({ ...advanceSearch, advance_search_enabled: searched ? SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM : SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER })
              }}>
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Close
                </Button> */}
            </FormGroup>
          </CardFooter>
        </CardBody>
      </Card>
    </>
  );
};

export default AdvanceSearch;
