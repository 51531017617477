import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBroadcastTower, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Row,
    Col,
    FormGroup,
    Button,
    Fade,
} from "reactstrap";
import Paginate from "./../common/pagination";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import AffiliateList from "./modules/affiliateList";
import usePost from "../../services/usePost";
import { MSG_USER } from "../../utils/message";
import AffiliateDetails from "./modules/affiliateDetails";
import AffiliateForm from "../forms/admin/affiliateForm";
import HeaderSearchInput from "../common/headerSearchInput";


const Affiliate = () => {

    const [pagination, setPagination] = useState({
        offset: 0,
        limit: SOURCES.DEFAULT_RECORDS_LISTING
    });
    const [id, setId] = useState(null);
    const [affiliateList, setAffiliatelist] = useState();
    const [searchInput, setSearchInput] = useState("");
    const [showDetail, setShowDetail] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [deleteId, setDeleteId] = useState(0);
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(1);
    const [affiliateDetail, setAffiliateDetail] = useState(null);

    const { callApi: getAffiliate, data: affiliate, isLoading: affiliateLoading } = useGet({
        endpoint: `affiliate?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    });

    const { callApi: getSearchData, data: searchData, isLoading: searchDataLoading, } = useGet({
        endpoint: `affiliate/search?q=${searchInput}`
    });

    const { callApi: removeAffiliate, processSuccess: removeSuccess } = usePost({
        endpoint: `affiliate/delete`,
    });

    const deleteAffiliate = async () => {
        await removeAffiliate({ id: deleteId }, MSG_USER.DELETE);
    };

    const debouncedSearchTerm = useDebounce(searchInput, 500);

    useEffect(() => {
        if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
        else if (!debouncedSearchTerm) getAffiliate();
    }, [debouncedSearchTerm]);

    useEffect(() => {
        getAffiliate();
    }, [pagination.offset, pagination.limit, sortBy, sortOrder]);


    useEffect(() => {
        setAffiliatelist(affiliate);
    }, [affiliate]);


    useEffect(() => {
        if (searchInput && searchData) setAffiliatelist(searchData)
    }, [searchData]);

    useEffect(() => {
        if (!showForm) setId(null);
    }, [showForm])

    const handleOnChange = async (e) => {
        const term = e.target.value;
        if (term.length >= 2) setSearchInput(term);
        else {
            if (term.length === 0) setSearchInput("");
        }
    };

    const viewDetail = (item) => {
        setAffiliateDetail(item);
        setShowDetail(true)
    }

    const isLoading = affiliateLoading || searchDataLoading;

    return (
        <div>
            {showForm && (
                <AffiliateForm
                    setShowForm={setShowForm}
                    getAffiliate={getAffiliate}
                    affiliateId={id}
                />
            )}
            <>
                {!showForm &&
                    <>
                    <Fade>
                        <Card color="shadow" className={`${showDetail ? 'd-none' : 'd-block'}`}>
                            <CardBody>
                                <CardTitle>
                                    <Row>
                                        <Col sm={9} tag="h4">
                                            <FontAwesomeIcon icon={faBroadcastTower} className="mr-2" />
                                            Affiliates / Partners
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Button
                                                color="primary"
                                                className="my-1"
                                                onClick={() => setShowForm(true)}
                                            >
                                                <FontAwesomeIcon icon={faPlus} className="mr-2"/>
                                                New Affiliate / Partner
                                            </Button>
                                        </Col>
                                        <Col lg={3} md={3} sm={3}>
                                            <HeaderSearchInput
                                                onChange={handleOnChange}
                                            />
                                        </Col>
                                    </Row>
                                </CardTitle>
                                <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                                <CardText>
                                    <AffiliateList
                                        affiliateList={affiliateList}
                                        isLoading={isLoading}
                                        setShowDetail={setShowDetail}
                                        showDetail={showDetail}
                                        getAffiliate={getAffiliate}
                                        viewDetail={viewDetail}
                                        removeSuccess={removeSuccess}
                                        setDeleteId={setDeleteId}
                                        setSortBy={setSortBy}
                                        sortBy={sortBy}
                                        setSortOrder={setSortOrder}
                                        sortOrder={sortOrder}
                                        deleteAffiliate={deleteAffiliate}
                                        setShowForm={setShowForm}
                                        setId={setId}
                                    />
                                </CardText>
                            </CardBody>
                            <div className="px-4">
                                {!searchInput && affiliate?.totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
                                    <FormGroup>
                                        <Paginate
                                            setPagination={setPagination}
                                            totalRecords={affiliate?.totalRecords}
                                            isLoading={isLoading}
                                        />
                                    </FormGroup>
                                )}
                            </div>

                        </Card>
                        </Fade>
                        {affiliateDetail
                            &&
                            <AffiliateDetails
                                getAffiliate={getAffiliate}
                                affiliateDetail={affiliateDetail}
                                showDetail={showDetail}
                                setShowDetail={setShowDetail}
                            />
                        }
                    </>}
            </>
        </div>
    );
};

export default Affiliate;