import { useEffect, useState } from "react";
import { faEnvelopeOpenText, faReply,faPlus } from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, Row, Col, Fade } from "reactstrap";
import useGet from "../services/useGet";
import usePost from "../services/usePost";
import Header from "./common/header";
import AutoresponderForm from "./forms/autorespondersForm";
import AutoresponderList from "./modules/autoresponderList";

const Autoresponder = ({ showFormInitial = false, parentMethod = null }) => {
  const [showForm, setShowForm] = useState(showFormInitial);
  const [id, setId] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const { callApi: getAutoResponders, data: autoresponders, isLoading } = useGet({
    endpoint: `autoresponder?sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: cloneAutoresponder
  } = usePost({
    endpoint: `autoresponder/clone`,
  });

  useEffect(() => {
    getAutoResponders();
  }, [sortBy, sortOrder]);

  const doCloneAutoresponder = async (id) => {
    const resClone = await cloneAutoresponder({ id }, "Autoresponder clone successfully");
    if (resClone?._id) {
      setId(resClone?._id);
      setShowForm(true);
      getAutoResponders();
    }
  }

  return (
    <div>
      {showForm ? (
        <Fade>
          <AutoresponderForm
            showFormInitial={showFormInitial}
            setShowForm={setShowForm}
            setId={setId}
            getAutoResponders={getAutoResponders}
            recordId={id}
            parentMethod={parentMethod}
            doCloneAutoresponder={doCloneAutoresponder}
          />
        </Fade>
      )
        : 
          <Row>
            <Col sm="12" className="mb-2">
              <Fade>
                <Card color="light">
                  <CardBody>
                    <Header
                      icon={faReply}
                      buttonIcon={faPlus}
                      title="Autoresponders"
                      buttonTitle="New Autoresponder"
                      setShowForm={setShowForm}
                    />
                    <AutoresponderList
                      setId={setId}
                      setShowForm={setShowForm}
                      getAutoResponders={getAutoResponders}
                      autoresponders={autoresponders}
                      isLoading={isLoading}
                      recordId={id}
                      doCloneAutoresponder={doCloneAutoresponder}
                      setSortBy={setSortBy}
                      sortBy={sortBy}
                      setSortOrder={setSortOrder}
                      sortOrder={sortOrder}
                    />
                  </CardBody>
                </Card>
              </Fade>
            </Col>
          </Row>
        }
    </div >
  );
};

export default Autoresponder;
