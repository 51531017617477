import { Card, CardBody, FormGroup, Label, Row, Col } from "reactstrap";
import TimePicker from "react-time-picker/dist/TimePicker";

const SettingBussinessHours = ({ timeZone, days, handleTimeChange }) => {
  return (
    <Card className="shadow" style={{ marginTop: "10px" }}>
      <CardBody>
        <Row>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label>Your's Timezone:</Label>
              <Label className="ml-2">
                <strong>{timeZone}</strong>
              </Label>
            </FormGroup>
          </Col>

          <Col sm={12}>
            {days?.map((data, idx) => (
              <Row style={{ alignItems: "center" }}>
                <Col sm={4}>
                  <FormGroup className="d-flex justify-content-between">
                    <Label check for={data?.day}>
                      {data?.name}
                    </Label>
                  </FormGroup>
                </Col>

                <Col sm={4}>
                  <FormGroup>
                    <Label for="startTime">Start Time</Label>
                    <TimePicker
                      className="w-100"
                      id="startTime"
                      format="HH:mm"
                      value={data.startTime}
                      onChange={(e) => handleTimeChange(idx, "startTime", e)}
                    />
                  </FormGroup>
                </Col>

                <Col sm={4}>
                  <FormGroup>
                    <Label for="endTime">End Time</Label>
                    <TimePicker
                      className="w-100"
                      id="endTime"
                      format="HH:mm"
                      value={data.endTime || ""}
                      onChange={(e) => handleTimeChange(idx, "endTime", e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default SettingBussinessHours;
