import "bootstrap/dist/css/bootstrap.min.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronUp,
  faEnvelope,
  faExchangeAlt,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardText,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  Button,
  Spinner,
  Fade,
} from "reactstrap";
import { useKey } from "../../services/useKey";
import usePost from "../../services/usePost";
import useGet from "../../services/useGet";
import Header from "../common/header";
import { MSG_CHANGE_USER_EMAIL } from "../../utils/message";
import ValidationErrorHandling from "../common/validationErrorHandling";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../utils/functions";
import SubmitButton from "../common/submitButton";
import ChangeUserEmailDetail from "./modules/changeUserEmailDetail";

const validationSchema = yupValidationSchema({
  currentEmail: { email: true, required: true, customName: "Current email" },
  newEmail: { email: true, required: true, customName: "New email" },
});

const ChangeEmail = ({ showFormInitial = false, parentMethod = null }) => {
  const [currentEmail, setCurrentEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [showDetail, setShowDetail] = useState(false);
  const [errors, setErrors] = useState("");

  const { callApi: changeUserEmail, isLoading } = usePost({
    endpoint: `admin/change/user/email`,
  });

  const {
    callApi: getUserEmail,
    data: userDetail,
    isLoading: userEmailLoading,
  } = useGet({ endpoint: `admin/user-detail?email=${currentEmail}` });

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { currentEmail, newEmail },
        { abortEarly: false }
      );

      const postData = {
        currentEmail,
        newEmail,
      };

      changeUserEmail(postData, MSG_CHANGE_USER_EMAIL.CHANGE_EMAIL);
      setErrors({});
    } catch (err) {
      setErrors(handleYupValidationErrors(err));
    }
  };

  useKey(submitForm(), "Enter");

  return (
    <Fade>
      <Row>
        <Col sm="12" className="mb-2">
          <Card color="shadow">
            <CardBody>
              <Header icon={faExchangeAlt} title="Change User Email" />
              <CardText>
                <Form onSubmit={submitForm}>
                  <Row>
                    <Col sm={6}>
                      <FormGroup>
                        <Label for="alertVia">Current Email</Label>
                        <Input
                          id="currentEmail"
                          name="currentEmail"
                          type="text"
                          placeholder="Enter Current Email Address"
                          className="w-100 p-2 "
                          onChange={(e) =>
                            setCurrentEmail(e.target.value.trim())
                          }
                          invalid={errors.currentEmail}
                          value={currentEmail}
                          tabIndex={1}
                          autoFocus
                        />
                        <ValidationErrorHandling error={errors.currentEmail} />
                      </FormGroup>
                    </Col>

                    <Col sm={6}>
                      <FormGroup>
                        <Label for="alertVia">New Email</Label>
                        <Input
                          id="newEmail"
                          name="newEmail"
                          type="text"
                          placeholder="Enter New Email Address"
                          className="w-100 p-2 "
                          onChange={(e) => setNewEmail(e.target.value.trim())}
                          invalid={errors.newEmail}
                          value={newEmail}
                          tabIndex={2}
                        />
                        <ValidationErrorHandling error={errors.newEmail} />
                      </FormGroup>
                    </Col>
                    {showDetail && (
                      <Col>
                        {userEmailLoading ? (
                          <center style={{ padding: "10px 0px" }}>
                            <Spinner children="" />
                          </center>
                        ) : (
                          <ChangeUserEmailDetail
                            userDetail={userDetail}
                            setShowDetail={setShowDetail}
                          />
                        )}
                      </Col>
                    )}
                  </Row>

                  <CardFooter>
                    <FormGroup
                      style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}
                    >
                      <Button
                        color="secondary"
                        disabled={currentEmail.length <= 0}
                        onClick={() => {
                          if (currentEmail && !showDetail) getUserEmail();
                          setShowDetail(!showDetail);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            showDetail && currentEmail
                              ? faChevronUp
                              : faInfoCircle
                          }
                          className="mr-2"
                        />
                        {showDetail && currentEmail
                          ? "Hide User Detail"
                          : "Show User Detail"}
                      </Button>

                      <SubmitButton
                        title="Change Email"
                        icon={faEnvelope}
                        isLoading={isLoading}
                        disabled={currentEmail.length <= 0 && newEmail.length <= 0}
                        cancleButton={false}
                        color={currentEmail.length <= 0 && newEmail.length <= 0? "secondary": "primary"}
                      />
                    </FormGroup>
                  </CardFooter>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Fade>
  );
};

export default ChangeEmail;
