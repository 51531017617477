import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

const ModalDialog = ({ children, showPopup, hidePopup, title = "Info", message = "" }) => {
  return (
    <div>
      <Modal isOpen={showPopup}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody className="py-0" >
          <div dangerouslySetInnerHTML={{ __html: message }} ></div>
        </ModalBody>
        <ModalFooter>
          {children}
          <Button onClick={hidePopup}>Close</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}
export default ModalDialog;