import { Table } from "reactstrap";
import { formatDateTime, formatDateTimeFromNow } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';

const PaymentHistoryList = ({ paymenthistory, isLoading, setSortBy, sortBy, setSortOrder, sortOrder }) => {
    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    return (
        <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader
                headers={[{ title: 'Customer Name', showIcon: true }, { title: 'Customer Email', showIcon: true }, { title: 'Payment At', showIcon: true }, { title: 'Payment Gateway', showIcon: true }, { title: 'Amount', showIcon: true }, { title: 'Description', showIcon: true }]}
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <tbody>
                {isLoading ?
                    <TableLoader colSpan="6" />
                    :
                    <>
                        {
                            paymenthistory?.payments?.length > 0 ?
                                paymenthistory?.payments.map((item, idx) => {
                                    return (
                                        <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                            <td>{item.User.name}</td>
                                            <td>{item.userEmail}</td>
                                            <td>
                                                {formatDateTime(item?.createdAt)}
                                                <br />
                                                <small>
                                                    {formatDateTimeFromNow(item?.createdAt)}
                                                </small>
                                            </td>
                                            <td>{item?.paymentGateway}</td>
                                            <td>${item?.amount} </td>
                                            <td>{item?.description}</td>
                                        </tr>
                                    );
                                })
                                :
                                <NoRecordsFound colSpan="6" />
                        }
                    </>
                }
            </tbody>
        </Table>
    )
}

export default PaymentHistoryList