import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setScreen } from "../redux/actions";
import Header from "./header";
import Sidebar from "./sidebar";

const Layout = ({ children }) => {
  const dispatch = useDispatch();
  const [sidebarIsOpen, setSidebarOpen] = useState(true);
  const isMobile = window.innerWidth < 720 ? true : false;
  const toggleSidebar = () => {  
    if(isMobile) setSidebarOpen(!sidebarIsOpen);
  }
  useEffect(()=>{
    //***** If mobile view then hide sidebar by default
    if(isMobile) setSidebarOpen(!sidebarIsOpen);
  dispatch(
    setScreen(isMobile)
  );
},[]);

  return (
    <div
      className="d-flex flex-row-reverse"
      style={{
        backgroundColor: "#f3f3f3",
        // overflowY: "auto",
        overflowX: "hidden",
        // height:"100%"
      }}
    >
      <Header
        children={children}
        sidebarIsOpen={sidebarIsOpen}
        toggleSidebar={() => setSidebarOpen(!sidebarIsOpen)}
      />

      <Sidebar toggle={toggleSidebar} isOpen={sidebarIsOpen} />
    </div>
  );
};

export default Layout;
