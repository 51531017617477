import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Table, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SOURCES from '../../../utils/constants';
import { capitalizeFirstLetter, formatDate } from '../../../utils/functions';
import usePost from '../../../services/usePost';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';
import NoRecordsFound from '../../common/noRecordsFound';
import Popup from '../../common/popup';
import { MSG_COUPONS } from '../../../utils/message';

const CouponsList = ({ isLoading, couponInfo, setId, getCoupon, setShowForm, getCoupons, setShowDetail, setCouponId, setSortBy,
    sortBy,
    setSortOrder,
    sortOrder }) => {
    const { DISCOUNT_FIXED } = SOURCES;
    const [deleteId, setDeleteId] = useState(0);
    const [toggleModal, setToggleModal] = useState(false);

    const {
        callApi: deleteCoupon,
        processSuccess: removeSuccess,
    } = usePost({
        endpoint: `coupon/delete`,
    });

    useEffect(() => {
        if (removeSuccess) {
            setToggleModal(false);
            getCoupons();
        }
    }, [removeSuccess]);

    const removeCoupon = async () => {
        const postData = {
            id: deleteId,
        };
        deleteCoupon(postData, MSG_COUPONS.DELETE);
    };

    const handleEdit = async (id) => {
        await getCoupon([{ key: "id", val: id }]);
        setId(id);
        setShowForm(true)
    };

    const handleDetails = async (id) => {
        setCouponId(id);
        setShowDetail(true)
    };

    const showUsageAllowed = (val) => {
        return val === 0 ? 'No Limit' : val;
    }

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    return (
        <>
            <Table responsive striped bordered hover size="sm" className="shadow">
                <TableHeader
                    headers={[{ title: 'Coupon Code', showIcon: true }, { title: 'Type', showIcon: true }, { title: 'Amount', showIcon: true }, { title: 'Expiry Date', showIcon: true }, { title: 'Usage', showIcon: true }, { title: 'Total Usage Allowed' }, { title: 'Usage History' }, { style: { width: "1%" } }]}
                    sortData={sortData}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <tbody>
                    {isLoading ?
                        <TableLoader colSpan="8" />
                        :
                        <>
                            {couponInfo && couponInfo?.results.length > 0 ?
                                couponInfo.results.map((res, idx) => {
                                    return (
                                        <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                            <td>

                                                <Link to="#" onClick={() => handleEdit(res._id)}>{res?.code}</Link></td>
                                            <td>{capitalizeFirstLetter(res?.couponType)}</td>
                                            <td>
                                                {res?.couponType === DISCOUNT_FIXED
                                                    ? res?.amount
                                                    : res?.amount + "%"}
                                            </td>
                                            <td>{res?.expiryDate && formatDate(res?.expiryDate)}</td>
                                            <td>{res?.usage}</td>
                                            <td>{showUsageAllowed(res?.usageAllowed)}</td>
                                            <td><Link onClick={() => {
                                                handleDetails(res._id)
                                            }}>Show History</Link></td>
                                            <td style={{ display: "flex" }}>
                                                <NavLink
                                                    href="#"
                                                    onClick={() => {
                                                        handleEdit(res._id);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faPen} />
                                                </NavLink>
                                                {res?.usage === 0 && (
                                                    <NavLink href="#">
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            style={{ color: "#dc3545" }}
                                                            onClick={() => {
                                                                setDeleteId(res._id);
                                                                setToggleModal(true);
                                                            }}
                                                        />
                                                    </NavLink>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                                :
                                <NoRecordsFound colSpan="7" />
                            }
                        </>
                    }
                </tbody>
            </Table>

            {toggleModal && (
                <Popup
                    showPopup={toggleModal}
                    hidePopup={() => setToggleModal(false)}
                    actionMethod={removeCoupon}
                    title="Delete Coupon"
                    promtMessage="Are you sure you want to delete this Coupon?"
                />
            )}
        </>
    )
}

export default CouponsList