import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  capitalizeFirstLetter,
  checkYupError,
  handleYupValidationErrors,
  showUserNotification,
  yupValidationSchema,
} from "../utils/functions";
import usePost from "../services/usePost";
import ValidationErrorHandling from "./common/validationErrorHandling";

const validationSchema = yupValidationSchema({
  name: { required: true, customName: "Name" },
  email: { email: true, required: true, customName: "Email" },
});
const Affiliate = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyUrl, setCompanyUrl] = useState("");
  const [bussinessType, setBussinessType] = useState("");
  const [additionalInformation, setAdditionalInformation] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userType = queryParams.get("type");

  const { callApi: addAffiliatePartner, processSuccess } = usePost({
    endpoint: `affiliate`,
  });

  const clearForm = () => {
    setEmail("");
    setName("");
    setCompanyName("");
    setCompanyUrl("");
    setBussinessType("");
    setAdditionalInformation("");
  };
  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ email, name }, { abortEarly: false });
      const Post = {
        userType,
        email,
        name,
        companyName,
        companyUrl,
        bussinessType,
        additionalInformation,
      };
      setLoading(true);
      setErrors({});
      await addAffiliatePartner(Post, "Saved successfully");
    } catch (err) {
      setErrors(handleYupValidationErrors(err));
      setLoading(false);
      if (!checkYupError(err)) {
        dispatch(
          showUserNotification(
            "Unexpected error occurred please try again",
            "danger"
          )
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (processSuccess) clearForm();
  }, [processSuccess]);

  return (
    <Row className="m-0">
      <Col sm="3" className="mb-2"></Col>
      <Col sm="6" className="mb-2 m-4">
        <div className="brand-logo text-center mb-4">
          <img src="/logo.png" alt="Leads Import" width="110px" />
        </div>
        <Card className="light shadow mt-2 p-4">
          <CardBody>
            <CardTitle tag="h4">
              {capitalizeFirstLetter(userType)} Information
            </CardTitle>
            <CardText>
              <Form>
                <FormGroup>
                  <Input
                    className="form-control form-control-lg"
                    id="name"
                    name="name"
                    placeholder="Full name"
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    autoComplete="off"
                    autoFocus
                    invalid={errors.name}
                  />
                  <ValidationErrorHandling error={errors.name} />
                </FormGroup>

                <FormGroup>
                  <Input
                    id="email"
                    name="email"
                    placeholder="Email address"
                    className="form-control form-control-lg"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="off"
                    invalid={errors.email}
                  />
                  <ValidationErrorHandling error={errors.email} />
                </FormGroup>

                <FormGroup>
                  <Input
                    id="companyName"
                    name="companyName"
                    placeholder="Company name"
                    className="form-control form-control-lg"
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    autoComplete="off"
                  />
                </FormGroup>

                <FormGroup>
                  <Input
                    id="companyUrl"
                    name="companyUrl"
                    placeholder="Company URL"
                    className="form-control form-control-lg"
                    type="url"
                    value={companyUrl}
                    onChange={(e) => setCompanyUrl(e.target.value)}
                    autoComplete="off"
                  />
                </FormGroup>

                <FormGroup>
                  <Input
                    id="bussinessType"
                    name="bussinessType"
                    placeholder="Bussiness type"
                    className="form-control form-control-lg"
                    type="text"
                    value={bussinessType}
                    onChange={(e) => setBussinessType(e.target.value)}
                    autoComplete="off"
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="additionalInformation">
                    Please add any additional information so we can understand
                    how you will be promoting the affiliation/partnership with
                    LeadsImport and how we can support your efforts
                  </Label>
                  <Input
                    id="additionalInformation"
                    name="additionalInformation"
                    type="textarea"
                    rows="6"
                    value={additionalInformation}
                    onChange={(e) => setAdditionalInformation(e.target.value)}
                  />
                </FormGroup>
                {/* <FormGroup>
                                    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                                        <GoogleReCaptcha
                                            onVerify={(token) => {
                                                setCaptchaToken(token);
                                            }}
                                        />
                                    </GoogleReCaptchaProvider>

                                </FormGroup> */}
                <div className="mt-3">
                  <button
                    type="submit"
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={handleForm}
                    disabled={loading}
                  >
                    Submit
                    {loading && (
                      <Spinner
                        className="ml-2"
                        color="light"
                        size="sm"
                        children=""
                      />
                    )}
                  </button>
                </div>
              </Form>
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Affiliate;
