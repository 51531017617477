import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { Table } from "reactstrap";
import { formatDateTime, formatLeadSource } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';

const LeadImportedList = ({ isLoading, dataExist, leadsImported, viewDetail, setSortBy, sortBy, setSortOrder, sortOrder }) => {

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    return (
        <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader
                headers={[{ title: 'Client Email', showIcon: true }, { title: 'Lead Source', showIcon: true }, { title: 'Name', showIcon: true }, { title: 'Email', showIcon: true }, { title: 'Phone', showIcon: true }, { title: 'Event Name', showIcon: true }, { title: 'Imported At', showIcon: true }, { title: '' }]}
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <tbody>
                {
                    isLoading ?
                        <TableLoader colSpan="8" />
                        :
                        <>
                            {dataExist ?
                                leadsImported?.results.map((item, idx) => {
                                    return (
                                        <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                            <th>{item?.userEmail}</th>
                                            <th className="autoCapital" scope="row">{formatLeadSource(item?.leadSource)}</th>
                                            <td>
                                                <Link to="#" onClick={() => viewDetail(item)}>
                                                    {
                                                        item.fullName ?
                                                            item.fullName
                                                            :
                                                            item?.firstName + " " + item?.lastName
                                                    }
                                                </Link>
                                            </td>
                                            <td>{item?.email}</td>
                                            <td>{item?.phone}</td>
                                            <td>{item?.eventName}</td>
                                            <td>{formatDateTime(item?.createdAt)}</td>
                                            <td style={{ textAlign: "center", fontSize: "18px" }}>
                                                <NavLink to="#" onClick={() => viewDetail(item)}>
                                                    <FontAwesomeIcon
                                                        icon={faInfo}
                                                        className="m-2"
                                                        title='View Detail'
                                                    />
                                                </NavLink>
                                            </td>
                                        </tr>
                                    );
                                })
                                :
                                (
                                    <NoRecordsFound colSpan="8" />
                                )
                            }
                        </>
                }
            </tbody>
        </Table>
    )
}

export default LeadImportedList