import React from 'react'
import { Table } from "reactstrap";
import { formatDateTime, formatLeadSource } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';

const CancelledSubscriptionList = ({
    isLoading,
    subscriptionList,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
}) => {

    const getReasonTitle = (reason, otherReason) => {
        let reasons = "";
        if (reason.ChangedCancelledCRMSoftware === true)
            reasons = "Changed Cancelled CRM Software<br/>";
        if (reason.NoLongerUsing3rdPartyLeads === true)
            reasons += "No Longer Using 3rd Party Leads<br/>";
        if (reason.NotEnoughLeadsIncoming === true)
            reasons += "Not Enough Leads Incoming<br/>";
        if (reason.ServiceNotWorking === true)
            reasons += "Service Not Working<br/>";
        if (reason.TooComplicated === true) reasons += "Too Complicated<br/>";
        if (reason.TooExpensive === true) reasons += "Too Expensive<br/>";
        if (reason.Other === true) {
            reasons += `Other: ${otherReason}<br/>`;
        }
        return reasons;
    };

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    return (
        <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader
                headers={[{ title: 'Cancelled By', showIcon: true }, { title: 'Cancelled Reason', showIcon: true }, { title: 'Cancelled At', showIcon: true }]}
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <tbody>
                {isLoading ?
                    <TableLoader colSpan="3" />
                    :
                    <>
                        {subscriptionList && subscriptionList?.logs.length > 0 ? (
                            subscriptionList.logs.map((item, idx) => {
                                return (
                                    <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                        <td className="autoCapital">
                                            {formatLeadSource(item?.userEmail)}
                                        </td>
                                        <td>
                                            <span
                                                dangerouslySetInnerHTML={{
                                                    __html: getReasonTitle(
                                                        item?.cancelReasons,
                                                        item?.cancelReasonOther
                                                    ),
                                                }}
                                            />
                                        </td>
                                        <td>{formatDateTime(item?.createdAt)}</td>
                                    </tr>
                                );
                            })
                        ) :
                            <NoRecordsFound colSpan="3" />
                        }
                    </>
                }
            </tbody>
        </Table>
    )
}

export default CancelledSubscriptionList