import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    FormGroup,
    Row,
    Col,
    Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import SOURCES from "../../utils/constants";
import Paginate from "../common/pagination";
import AutoresponseSentList from "./modules/autoresponseSentList";
import AutoresponseSentDetail from "./modules/autoresponseSentDetail";
import HeaderSearchInput from "../common/headerSearchInput";

const AutoresponseSent = () => {
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: SOURCES.DEFAULT_RECORDS_LISTING
    });

    const [autoresponseSentList, setAutoresponseSentList] = useState();
    const [autoresponseSentDetailId, setAutoresponseSentDetailId] = useState(null);
    const [showDetail, setShowDetail] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(1);

    const {
        callApi: getAutoresponceSentList,
        data: autoresponseSentData,
        isLoading: getautoresponseSentLoading,
    } = useGet({
        endpoint: `reports/autoresponder/sent/list?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    });

    const {
        callApi: getAutoresponceSentDetail,
        data: autoresponseSentDetail,
        isLoading: autoresponseSentDetailLoading,
    } = useGet({
        endpoint: `reports/autoresponder/sent/list?id=${autoresponseSentDetailId}`,
    });

    const {
        callApi: getSearchData,
        data: searchData,
        isLoading: searchDataLoading,
    } = useGet({
        endpoint: `reports/autoresponder/sent/search?q=${searchInput}`,
    });

    const isLoading = searchDataLoading || getautoresponseSentLoading;
    const debouncedSearchTerm = useDebounce(searchInput, 500);
    const dataExist = autoresponseSentList && autoresponseSentList?.results?.length > 0 ? true : false;

    useEffect(() => {
        if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
        else if (!debouncedSearchTerm) getAutoresponceSentList();
    },
        [debouncedSearchTerm]
    );

    useEffect(() => {
        getAutoresponceSentList();
    }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

    useEffect(() => {
        setAutoresponseSentList(autoresponseSentData);
    }, [autoresponseSentData]);

    useEffect(() => {
        setAutoresponseSentList(searchData);
    }, [searchData]);

    useEffect(() => {
        if (autoresponseSentDetailId) getAutoresponceSentDetail();
    }, [autoresponseSentDetailId]);


    const viewDetail = (id) => {
        setAutoresponseSentDetailId(id);
        setShowDetail(true);
    };

    return (
        <div>
            <Fade>
                <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
                    <CardBody>
                        <CardTitle>
                            <Row>
                                <Col lg={9} style={{ display: "flex", alignItems: "center" }} tag={"h4"}>
                                    <FontAwesomeIcon icon={faBell} className="mr-2" />
                                    Autoresponse Sent
                                </Col>

                                <Col lg={3}>
                                    <HeaderSearchInput
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                </Col>
                            </Row>
                            <Row className="justify-content-between">

                            </Row>
                        </CardTitle>
                        <CardSubtitle className="mb-2 text-muted" tag="h6">
                        </CardSubtitle>
                        <CardText>
                            <AutoresponseSentList
                                isLoading={isLoading}
                                dataExist={dataExist}
                                autoresponseSentList={autoresponseSentList}
                                viewDetail={viewDetail}
                                setSortBy={setSortBy}
                                sortBy={sortBy}
                                setSortOrder={setSortOrder}
                                sortOrder={sortOrder}
                            />
                        </CardText>
                    </CardBody>
                    <div className="px-4">
                        {!searchInput && autoresponseSentList?.totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
                            <FormGroup>
                                <Paginate
                                    setPagination={setPagination}
                                    totalRecords={autoresponseSentList?.totalRecords}
                                    isLoading={isLoading}
                                />
                            </FormGroup>
                        )}
                    </div>
                </Card>
            </Fade>
            {autoresponseSentDetail && (
                <Fade>
                    <AutoresponseSentDetail
                        showDetail={showDetail}
                        setShowDetail={setShowDetail}
                        autoresponseSentDetailLoading={autoresponseSentDetailLoading}
                        autoresponseSentDetail={autoresponseSentDetail}
                    />
                </Fade>
            )
            }
        </div>
    );
};

export default AutoresponseSent;