import { useEffect } from "react"

export const useKey = (callback, key) => {
    useEffect(() => {
        const handle = (e) => {
            if (e.code === key) {
                callback()
            }
        }
        document.addEventListener("keydown", handle, false)
        return () => document.removeEventListener("keydown", handle, false)
    }, [callback,key])
}