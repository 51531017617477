import React, { useEffect } from 'react'
import { Link, NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import { faCheck, faInfo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateTime } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';

const LeadViaFormList = ({
    isLoading,
    dataExist,
    leadsImported,
    viewDetail,
    setLeadList,
    leadList,
    getLeadsImportedForm,
    processSuccess,
    handleAcceptReject,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
}) => {

    useEffect(() => {
        if (processSuccess) getLeadsImportedForm()
    }, [processSuccess])

    useEffect(() => {
        setLeadList(leadsImported);
    }, [leadsImported]);

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }

    return (
        <>
            <Table responsive striped size='sm' bordered hover className="shadow">
                <TableHeader
                    sortData={sortData}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                    headers={[
                        {
                            title: 'Name'
                            , showIcon: true
                        },
                        {
                            title: 'Email'
                            , showIcon: true
                        },
                        {
                            title: 'Phone'
                            , showIcon: true
                        },
                        {
                            title: 'Event Name'
                            , showIcon: true
                        },
                        {
                            title: 'Imported At'
                            , showIcon: true
                        },
                        { style: { width: "1%" } }
                    ]}

                />
                <tbody>
                    {
                        isLoading ?
                            <TableLoader colSpan="8" />
                            :
                            <>
                                {
                                    dataExist
                                        ?
                                        (leadList?.results && leadList?.results.map((item, idx) => {
                                            return (
                                                <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                                    <td>
                                                        <Link to="#" onClick={() => viewDetail(item)}>
                                                            {
                                                                item.fullName ?
                                                                    item.fullName
                                                                    :
                                                                    item?.firstName + " " + item?.lastName
                                                            }
                                                        </Link>
                                                    </td>
                                                    <td>{item?.email}</td>
                                                    <td>{item?.phone}</td>
                                                    <td>{item?.eventName}</td>
                                                    <td>{formatDateTime(item?.dateAsPerUserTimezone)}</td>
                                                    <td>
                                                        <NavLink
                                                            to="#"
                                                            onClick={() => viewDetail(item)}
                                                            className="ml-2"
                                                        >
                                                            <FontAwesomeIcon icon={faInfo} />
                                                        </NavLink>
                                                        <NavLink
                                                            to="#"
                                                            style={{ color: "green" }}
                                                            onClick={() => handleAcceptReject(item?._id)}
                                                            className="mx-3"
                                                        >
                                                            <FontAwesomeIcon icon={faCheck} title='Accept' />
                                                        </NavLink>
                                                        <NavLink
                                                            to="#"
                                                            style={{ color: "#dc3545" }}
                                                            onClick={() => handleAcceptReject(item?._id, 'REJECT')}
                                                            className="mr-2"
                                                        >
                                                            <FontAwesomeIcon icon={faTrashAlt} title='Reject' />
                                                        </NavLink>
                                                    </td>
                                                </tr>
                                            );
                                        }))
                                        :
                                        <NoRecordsFound colSpan="7" />
                                }
                            </>
                    }
                </tbody>
            </Table>
            {/* {toggleModal && <Popup showPopup={toggleModal} hidePopup={() => setToggleModal(false)} actionMethod={} />} */}
        </>
    )
}

export default LeadViaFormList