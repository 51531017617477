import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_CHECKCHERRY = process.env.REACT_APP_INTEGRATION_CHECKCHERRY;

const validationSchema = yupValidationSchema({
  checkCherryApiKey: { required: true, customName: "API Key" },
  checkCherryAppId: { required: true, customName: "App ID" },
  checkCherryAppSecret: { required: true, customName: "App Secret" },
});

const CheckCherryForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [checkCherryApiKey, setCheckCherryApiKey] = useState("");
  const [checkCherryAppId, setCheckCherryAppId] = useState("");
  const [checkCherryAppSecret, setCheckCherryAppSecret] = useState("");
  const [sendLeadsLimitCheckCherry, setSendLeadsLimitCheckCherry] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_CHECKCHERRY) {
          setCheckCherryApiKey(dest.destinationAccountPassword);
          setCheckCherryAppId(dest.publicKey);
          setCheckCherryAppSecret(dest.secretKey);
          setSendLeadsLimitCheckCherry(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleCheckCherryForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          checkCherryApiKey,
          checkCherryAppId,
          checkCherryAppSecret,
          sendLeadsLimitCheckCherry,
        },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      await saveIntegration(
        {
          publicKey: checkCherryAppId,
          secretKey: checkCherryAppSecret,
          sendLeadsLimit: sendLeadsLimitCheckCherry,
          destinationAccountEmail: null,
          destinationAccountPassword: checkCherryApiKey,
          integrationCompany: DESTINATION_CHECKCHERRY,
          agentProvider: null,
          locations: [],
        },
        MSG_DESTINATION.SAVE_CHECKCHERRY
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DestinationHeader
      headerTitle="Check Cherry"
      headerImage="images/checkcherry.png"
    >
      <Form onSubmit={handleCheckCherryForm} autoComplete="off">
        <FormGroup>
          <FormText color="info">
            <strong> NOTE:</strong>You can find your API Private Key by
            navigating to Manage-&gt;Website Integration from dashboard. To get
            APP ID and APP Secret please contact Check Cherry at{" "}
            <i>
              <Link
                onClick={() =>
                  (window.location = "mailto:support@checkcherry.com")
                }
              >
                support@checkcherry.com
              </Link>
            </i>
          </FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="app_id">App Id</Label>
              <Input
                id="app_id"
                name="app_id"
                placeholder="Get APP ID from Check Cherry"
                autocomplete="off"
                type="text"
                defaultValue={checkCherryAppId}
                value={checkCherryAppId}
                onChange={(e) => setCheckCherryAppId(e.target.value)}
                invalid={errors.checkCherryAppId}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.checkCherryAppId} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="app_secret">App Secret</Label>
              <Input
                id="app_secret"
                name="app_secret"
                placeholder="Get APP SECRET from Check Cherry"
                autocomplete="off"
                type="text"
                defaultValue={checkCherryAppSecret}
                value={checkCherryAppSecret}
                onChange={(e) => setCheckCherryAppSecret(e.target.value)}
                invalid={errors.checkCherryAppSecret}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.checkCherryAppSecret} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitCheckCherry}
                onChange={(e) => setSendLeadsLimitCheckCherry(e.target.value)}
                autoFocus
                invalid={errors.sendLeadsLimitCheckCherry}
                autoComplete="off"
              />
              <ValidationErrorHandling
                error={errors.sendLeadsLimitCheckCherry}
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleCheckCherryForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default CheckCherryForm;
