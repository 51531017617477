import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Input,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import SOURCES from "../../utils/constants";
import Paginate from "../common/pagination";
import EmailLogList from "./modules/emailLogList";
import EmailLogDetail from "./modules/emailLogDetail";

const EmailLogs = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING,
  });

  const [emailLogs, setEmailLogs] = useState();
  const [emailLogDetail, setEmailLogDetail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `admin/email/log/search?q=${searchInput}`,
  });

  const {
    callApi: getEmailLogs,
    data: emailLogList,
    isLoading: getLogLoading,
  } = useGet({
    endpoint: `admin/email/log?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const isLoading = searchDataLoading || getLogLoading;

  const debouncedSearchTerm = useDebounce(searchInput, 500);
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getEmailLogs();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getEmailLogs();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    setEmailLogs(emailLogList);
  }, [emailLogList]);

  useEffect(() => {
    setEmailLogs(searchData);
  }, [searchData]);

  const viewDetail = (item) => {
    setEmailLogDetail(item);
    setShowDetail(true);
  };
  return (
    <div>
      <Fade>
        <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
          <CardBody>
            <CardTitle tag="h4">
              <Row>
                <Col sm={9}>
                  <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                  Email Log
                </Col>
                <Col sm={3}>
                  <div style={{ position: "relative" }}>
                    <FontAwesomeIcon
                      icon={faSearch}
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "10px",
                        transform: "translateY(-50%)",
                        fontSize: "1rem",
                        color: "#8888",
                        pointerEvents: "none",
                      }}
                    />
                    <Input
                      placeholder="Search"
                      value={searchInput}
                      className="my-2"
                      onChange={(e) => setSearchInput(e.target.value)}
                      style={{ paddingLeft: "30px" }}
                    />
                  </div>
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
            <CardText>
              <EmailLogList
                emailLogs={emailLogs}
                isLoading={isLoading}
                viewDetail={viewDetail}
                sortBy={sortBy}
                setSortBy={setSortBy}
                sortOrder={sortOrder}
                setSortOrder={setSortOrder}
              />
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput &&
              emailLogs?.totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
                <FormGroup>
                  <Paginate
                    setPagination={setPagination}
                    totalRecords={emailLogs?.totalRecords}
                    isLoading={isLoading}
                  />
                </FormGroup>
              )}
          </div>
        </Card>
      </Fade>
      {emailLogDetail && (
        <EmailLogDetail
          emailLogDetail={emailLogDetail}
          showDetail={showDetail}
          setShowDetail={setShowDetail}
        />
      )}
    </div>
  );
};

export default EmailLogs;
