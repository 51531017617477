import React, { useEffect, useState } from 'react'
import { faPen,faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import usePost from '../../services/usePost';
import { formatDateTime } from '../../utils/functions';
import NoRecordsFound from '../common/noRecordsFound';
import Popup from '../common/popup';
import TableHeader from '../common/tableHeader';
import TableLoader from '../common/tableLoader';

const LeadGenerateFormList = ({ setShowForm, weblink, setId, getWebLink, getWebLinkDetails, getWebLinkLoader }) => {
    const [deleteId, setDeleteId] = useState(0);
    const [toggleModal, setToggleModal] = useState(false);
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(1);

    const {
        callApi: deleteWebLink,
        processSuccess: removeSuccess,
    } = usePost({
        endpoint: `weblink/delete`,
    });


    useEffect(() => {
        if (removeSuccess) {
            setToggleModal(false);
            getWebLink();
        }
    }, [removeSuccess])

    const isLoading = getWebLinkLoader

    const handleEdit = async (id) => {
        getWebLinkDetails([{ key: "id", val: id }])
        setShowForm(true)
        setId(id);
    };

    const removeWebLink = async () => {
        const postData = {
            id: deleteId,
        };
        await deleteWebLink(postData, "Webform deleted sucessfully");
    };

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }

    return (
        <>
            <Table responsive striped bordered hover size="sm" className="shadow">
                <TableHeader
                    headers={[{ title: "Form Name" }, { title: "Created At" }, { style: { width: "1%" } }]}
                    sortData={sortData}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <tbody>
                    {
                        isLoading ?
                            <TableLoader colSpan="5" />
                            :
                            <>
                                {weblink?.results && weblink?.results.length > 0 ?
                                    weblink?.results.map((wl, idx) => {
                                        return (
                                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>

                                                <td>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            handleEdit(wl?._id);
                                                        }}
                                                    >
                                                        {wl.formName}
                                                    </NavLink>
                                                </td>
                                                <td>
                                                    {formatDateTime(wl.updatedAt)}
                                                </td>
                                                <td>
                                                   
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            handleEdit(wl?._id);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPen} className="m-2" />
                                                    </NavLink>
                                                    <NavLink
                                                        to="#"
                                                        onClick={() => {
                                                            setDeleteId(wl?._id);
                                                            setToggleModal(true);
                                                        }}>
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            style={{ color: "#dc3545" }}
                                                            className="m-2"

                                                        />
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :
                                    <NoRecordsFound colSpan="5" />
                                }
                            </>
                    }
                </tbody>
            </Table>
            {toggleModal && (
                <Popup
                    showPopup={toggleModal}
                    hidePopup={() => setToggleModal(false)}
                    actionMethod={removeWebLink}
                    title="Delete Contact Request Form"
                    promtMessage="Are you sure you want to delete Contact Request Form?"
                />
            )}
        </>
    )
}

export default LeadGenerateFormList