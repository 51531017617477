import { interceptor } from "../utils/interceptor";
import { useDispatch } from "react-redux";
import { showAlert } from "../redux/actions";
const { useState } = require("react");

const axiosInstance = interceptor();

const usePost = ({
  endpoint
}) => {

  const [isLoading, setLoading] = useState(false);
  const [processSuccess, setProcessSuccess] = useState(false);
  const [data] = useState(false);

  const dispatch = useDispatch();
  const doPost = async (postData, successMessage = null, returnFullResponse = false, showResponseMsg = true) => {
    try {
      setProcessSuccess(false); //this is very important 
      setLoading(true);
      const result = await axiosInstance.post(endpoint, postData);
      // console.log('result', result);
      setProcessSuccess(true);
      if (showResponseMsg) {
        let msg = '';
        if (successMessage) msg = successMessage;
        //##### If no custom success message pass then we have to show success message returned by API
        if (typeof result?.data?.data !== undefined && typeof result?.data?.data === 'string') msg = result?.data?.data;
        if (msg) {
          dispatch(
            showAlert({
              show: true,
              type: "success",
              msg
            })
          );
        }
      }
      if (returnFullResponse) return result.data;
      else return result.data.data;

    } catch (e) {
      setProcessSuccess(false);
      if (showResponseMsg) {
        dispatch(
          showAlert({
            show: true,
            type: "danger",
            msg: e?.response?.data?.data?.error,
          })
        );
      }
      if (returnFullResponse) return e.response.data;
    } finally {
      setLoading(false);
    }
  };
  //   console.log('retur',processSuccess)
  return {
    callApi: doPost,
    isLoading,
    data,
    processSuccess
  }
}

export default usePost;