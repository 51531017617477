import { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_DJEVENT_PLANNER =
  process.env.REACT_APP_INTEGRATION_DJEVENTPLANNER;

const validationSchema = yupValidationSchema({
  djUsername: { required: true, customName: "Email" },
  djPassword: { required: true, customName: "Password" },
});

const DJEventPlannerForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [djUsername, setDJUsername] = useState("");
  const [djPassword, setDJPassword] = useState("");
  const [sendLeadsLimitDJ, setSendLeadsLimitDJ] = useState(0);
  const [standaloneDomain, setStandaloneDomain] = useState("");
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          integrationCompany,
          destinationAccountEmail,
          destinationAccountPassword,
          leadSentConfig,
          publicKey,
        } = dest;
        if (integrationCompany === DESTINATION_DJEVENT_PLANNER) {
          setDJUsername(destinationAccountEmail);
          setDJPassword(destinationAccountPassword);
          setSendLeadsLimitDJ(leadSentConfig?.sentLimit);
          setStandaloneDomain(publicKey);
        }
      }
    }
  }, [destinations]);

  const handelDJEventPlannerForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { djUsername, djPassword },
        { abortEarly: false }
      );
      setLoading(true);
      await saveIntegration(
        {
          destinationAccountEmail: djUsername,
          destinationAccountPassword: djPassword,
          sendLeadsLimit: sendLeadsLimitDJ,
          integrationCompany: DESTINATION_DJEVENT_PLANNER,
          publicKey: standaloneDomain,
          locations: [],
        },
        MSG_DESTINATION.SAVE_DJEVENTPLANNER
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader
      headerTitle="DJ Event Planner"
      headerImage="images/djevent.jpeg"
    >
      <Form onSubmit={handelDJEventPlannerForm} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="username">Email</Label>
              <Input
                id="username"
                name="username"
                placeholder="Username"
                autocomplete="off"
                type="email"
                defaultValue={djUsername}
                value={djUsername}
                onChange={(e) => setDJUsername(e.target.value)}
                invalid={errors.djUsername}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.djUsername} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                placeholder="Password"
                autocomplete="off"
                type="password"
                defaultValue={djPassword}
                value={djPassword}
                onChange={(e) => setDJPassword(e.target.value)}
                invalid={errors.djPassword}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.djPassword} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitDJ}
                onChange={(e) => setSendLeadsLimitDJ(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="standalone_domain">
                Standalone Domain Integrated with DJEvent Planner(if any)
              </Label>
              <Input
                id="standalone_domain"
                name="standalone_domain"
                placeholder="Standalone Domain Integrated with DJEvent Planner"
                type="text"
                value={standaloneDomain}
                onChange={(e) => setStandaloneDomain(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handelDJEventPlannerForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default DJEventPlannerForm;
