import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faPlus, faCreditCard } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Table,
  Button,
  Badge,
  Fade
} from "reactstrap";
import { getMonthName } from "../../utils/functions";
import useGet from "../../services/useGet";
import NoRecordsFound from "../common/noRecordsFound";
import TableLoader from "../common/tableLoader";
import TableHeader from "../common/tableHeader";


const Invoices = () => {
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const history = useHistory();

  const { callApi: getInvoices, data: invoices, isLoading } = useGet({
    endpoint: `invoice/list?sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const payInvoice = async (invoiceId) => {
    history.push("/paymentInvoice?invoice=" + invoiceId);
  }

  useEffect(() => {
    getInvoices();
  }, [sortBy, sortOrder]);

  const sortData = (title) => {
    setSortBy(title)
    setSortOrder(sortOrder === 1 ? -1 : 1)
  }


  const dataExist = invoices && invoices.length > 0 ? true : false;

  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <CardTitle tag="h4">
            <FontAwesomeIcon icon={faHistory} className="mr-2" />
            Invoices
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
          </CardSubtitle>
          <CardText>
            <Table responsive striped bordered hover size="sm" className="shadow">
              <TableHeader
                headers={[{ title: 'Invoice Month', showIcon: true }, { title: 'Leads Imported', showIcon: true }, { title: 'Lead Price', showIcon: true }, { title: 'Amount', showIcon: true }, { title: '' }]}
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
              />
              <tbody>
                {
                  isLoading ?
                    <TableLoader colSpan="5" />
                    :
                    <>
                      {dataExist ?
                        invoices.map((item, idx) => {
                          return (
                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                              <td>{getMonthName(item.invoiceMonth)},{item.invoiceYear}</td>
                              <td>{item.totalLeadsImported}</td>
                              <td>{item.perLeadPrice}</td>
                              <td>{item.totalAmount}</td>
                              <td>{item.paymentStatus === false ? <Button  color="btn btn-primary" onClick={() => payInvoice(item._id)}>
                                <FontAwesomeIcon icon={faCreditCard} />
                                {" "} Pay Now
                              </Button>
                                : <Badge color="success">
                                  Paid
                                </Badge>}</td>
                            </tr>
                          );
                        })
                        :
                        <NoRecordsFound colSpan="5" />
                      }
                    </>
                }
              </tbody>
            </Table>
          </CardText>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default Invoices;


