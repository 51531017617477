import axios from "axios";
import LocalStorageService from "./localStorageServices";
import SOURCES from "./constants";
const localStorageService = LocalStorageService.getService();

export const interceptor = () => {
  const axiosInstance = axios.create({
    // baseURL: "http://localhost:5000/api",
    baseURL: process.env.REACT_APP_API_URL
  });
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorageService.getAccessToken();
      if (token) {
        config.headers["x-access-token"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const statusCode = error?.response?.status;
      const responseData = error?.response?.data;

      if (statusCode === SOURCES.HTTP_CODE_401 && responseData?.message === "invalid algorithm") {
        localStorageService.setToken("");
        window.location.reload();
      }
      else if (statusCode === SOURCES.HTTP_CODE_401 && responseData?.data?.error.includes("token expired")) {
        localStorageService.clearToken();
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance;
};