import { Link } from 'react-router-dom';
import { Table } from "reactstrap";
import NoRecordsFound from '../../../common/noRecordsFound';
import TableHeader from '../../../common/tableHeader';
import TableLoader from '../../../common/tableLoader';

const NoLeadsSendList = ({ isLoading, dataExist, noLeadsSend, viewDetail, setSortBy, sortBy, setSortOrder, sortOrder }) => {
    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    return (
        <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader
                headers={[
                    { title: 'Client Email', showIcon: false }, 
                    { title: 'Client Name', showIcon: false },                     
                    { title: 'Client Phone', showIcon: false }, 
                    { title: 'On Trial', showIcon: false }, 
                    { title: 'Count', showIcon: false }
                ]}
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <tbody>
                {
                    isLoading ?
                        <TableLoader colSpan="8" />
                        :
                        <>
                            {dataExist ?
                                noLeadsSend?.map((item, idx) => {
                                    {console.log(item)}
                                    return (                                        
                                        <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                            <td>
                                            <Link to="#" onClick={() => viewDetail(item)}>{item.userEmail}</Link>
                                                
                                                </td>
                                            <td>{item?.user?.name}</td>
                                            <td>{item?.user?.phone}</td>
                                            <td>{item?.user?.isTrial}</td>
                                            <td>
                                            <Link to="#" onClick={() => viewDetail(item)}>{item.count}</Link>
                                            </td>                                            
                                        </tr>
                                    );
                                })
                                :
                                (
                                    <NoRecordsFound colSpan="5" />
                                )
                            }
                        </>
                }
            </tbody>
        </Table>
    )
}

export default NoLeadsSendList