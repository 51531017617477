/**
 * NOTE: This is exactly same as GoHighLevel CRM because venue is underneath using gohighlevel API
 * Customer just want venue icon and title instead of GoHighLevel branding
 */
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import { handleYupValidationErrors, yupValidationSchema } from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_VENUE = process.env.REACT_APP_INTEGRATION_VENUE;

const validationSchema = yupValidationSchema({
  venueApiKey: { required: true, customName: "Api key" },
});

const VenueForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [venueApiKey, setVenueApiKey] = useState("");
  const [sendLeadsLimitVenue, setSendLeadsLimitVenue] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_VENUE) {
          setVenueApiKey(secretKey);
          setSendLeadsLimitVenue(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleVenueForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ venueApiKey }, { abortEarly: false });
      setLoading(true);
      await saveIntegration(
        {
          secretKey: venueApiKey,
          sendLeadsLimit: sendLeadsLimitVenue,
          integrationCompany: DESTINATION_VENUE,
        },
        MSG_DESTINATION.SAVE_VENUE
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader headerTitle="Venue -  Scaling System" headerImage="images/venue.png">
      <Form onSubmit={handleVenueForm} autoComplete="off">
        <FormGroup>
          <FormText color="info"></FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="API key"
                autocomplete="off"
                type="text"
                defaultValue={venueApiKey}
                value={venueApiKey}
                onChange={(e) => setVenueApiKey(e.target.value)}
                invalid={errors.venueApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.venueApiKey} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">How Many Leads Do You Want To Send Initially?</Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitVenue}
                onChange={(e) => setSendLeadsLimitVenue(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter handleSubmit={handleVenueForm} loading={loading} />
      </Form>
    </DestinationHeader>
  );
};

export default VenueForm;
