import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { faCreditCard, faMoneyBill } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardText,
  Fade,
  Form,
  FormGroup,
  Table,
} from "reactstrap";
import CheckoutForm from "./stripe/CheckoutForm";
import usePost from "../services/usePost";
import useGet from "../services/useGet";
import { getMonthName, showUserNotification } from "../utils/functions";
import { MSG_INVOICES } from "../utils/message";
import SubmitButton from "./common/submitButton";
import TableRow from "./common/tableRow";
import Header from "./common/header";

const PaymentInvoice = () => {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search)
  const invoice_id = queryParams.get("invoice");
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [invoiceId] = useState(invoice_id);
  const [invoiceFor, setInvoiceFor] = useState("");

  const { callApi: getInvoice, data: invoiceDetail } = useGet({
    endpoint: `invoice/detail?invoiceId=${invoiceId}`,
  });

  const {
    callApi: payInvoice
  } = usePost({
    endpoint: `/payment/invoice`,
  });

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    if (invoiceDetail) {
      const invoiceDuration = getMonthName(invoiceDetail?.invoiceMonth) + ',' + invoiceDetail?.invoiceYear;
      setInvoiceFor(invoiceDuration);
    }
  }, [invoiceDetail])


  const handleForm = async (event) => {
    event.preventDefault();

    // const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      // console.log("no stripe");
      return;
    }
    setLoading(true);
    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);
    if (result.error) {
      setLoading(false);
      console.log(result.error.message);
    } else {
      // pass the token to your backend API
      const cardToken = result.token;
      try {
        const responsePayment = await payInvoice({ cardToken, amount: invoiceDetail?.totalAmount, invoiceId }, MSG_INVOICES.PAID, true);
        setLoading(false);
        if (responsePayment?.status === 200) setTimeout(() => { history.push("/invoices"); }, 2000);

      } catch (err) {
        setLoading(false);
        dispatch(showUserNotification("Invoice payment failed", "danger"));
      }
    }
  };
  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <Header
            icon={faMoneyBill}
            title="Pay Invoice"
          />

          <CardText>
            <Form onSubmit={handleForm}>
              <Table responsive striped bordered hover size="sm">
                <TableRow label="Invoice Month" value={invoiceFor} />
                <TableRow label="Invoice Amount" value={`${invoiceDetail?.totalAmount} $`} />

                <tr style={{ whiteSpace: "nowrap" }}>
                  <th>Enter Card Details</th>
                  <td>
                    <FormGroup style={{ margin: "0px" }} >
                      <CheckoutForm />
                    </FormGroup>
                  </td>
                </tr>
              </Table>
              <SubmitButton
                type="submit"
                color="primary"
                title="Pay Now"
                onClick={handleForm}
                icon={faCreditCard}
                isLoading={loading}
                cancleButton={false}
                goBackButton={true}
                goBackButtonOnClick={() => history.push("/invoices")}
              />
            </Form>
          </CardText>
        </CardBody>
      </Card>
    </Fade>
  );
};
export default PaymentInvoice;



   
