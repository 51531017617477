import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input, Row, Col, FormText } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_HONEYBOOK = process.env.REACT_APP_INTEGRATION_HONEYBOOK;

const validationSchema = yupValidationSchema({
  honeyBookUsername: { required: true, email: true, customName: "Email" },
  honeyBookPassword: { required: true, customName: "Password" },
});
const HoneybookForm = ({
  saveIntegration,
  destinations,
  zapierSetup = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [honeyBookZapierWebhook, setHoneyBookZapierWebhook] = useState("");
  const [honeyBookUsername, setHoneyBookUsername] = useState("");
  const [honeyBookPassword, setHoneyBookPassword] = useState("");
  const [sendLeadsLimitHoneyBook, setSendLeadsLimitHoneyBook] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          publicKey,
          integrationCompany,
          destinationAccountEmail,
          destinationAccountPassword,
          leadSentConfig,
        } = dest;
        if (integrationCompany === DESTINATION_HONEYBOOK) {
          setHoneyBookZapierWebhook(publicKey);
          setHoneyBookUsername(destinationAccountEmail);
          setHoneyBookPassword(destinationAccountPassword);
          setSendLeadsLimitHoneyBook(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleHoneyBookForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { honeyBookUsername, honeyBookPassword },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      await saveIntegration(
        {
          destinationAccountEmail: honeyBookUsername,
          destinationAccountPassword: honeyBookPassword,
          sendLeadsLimit: sendLeadsLimitHoneyBook,
          integrationCompany: DESTINATION_HONEYBOOK,
          locations: [],
        },
        MSG_DESTINATION.SAVE_HONEYBOOK
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader
      headerTitle="HoneyBook"
      headerImage="images/honeybook.png"
    >
      <Form onSubmit={handleHoneyBookForm} autoComplete="off">
        <Row>
          <Col lg={12} md={12} sm={12}>
            <FormGroup>
              <Label for="honeyBookWebhook">Zapier Webhook</Label>
              <Input
                id="honeyBookWebhook"
                name="honeyBookWebhook"
                placeholder="Zapier webhook"
                autocomplete="off"
                type="text"
                defaultValue={honeyBookZapierWebhook}
                value={honeyBookZapierWebhook}
                autoComplete="off"
                readOnly
              />
            </FormGroup>
          </Col>
          {/* <Col lg={6}>
                        <FormGroup>
                            <Label for="username">Email</Label>
                            <Input
                                id="username"
                                name="username"
                                placeholder="Username"
                                autocomplete="off"
                                type="email"
                                defaultValue={honeyBookUsername}
                                value={honeyBookUsername}
                                onChange={(e) => {
                                    setHoneyBookUsername(e.target.value);
                                }}
                                invalid={errors.honeyBookUsername}
                                autoComplete="off"
                            />
                            <ValidationErrorHandling error={errors.honeyBookUsername} />
                        </FormGroup>
                    </Col>
                    <Col lg={6}>
                        <FormGroup>
                            <Label for="password">Password</Label>
                            <Input
                                placeholder="Password"
                                autocomplete="off"
                                type="password"
                                defaultValue={honeyBookPassword}
                                value={honeyBookPassword}
                                onChange={(e) => {
                                    setHoneyBookPassword(e.target.value);
                                }}
                                invalid={errors.honeyBookPassword}
                                autoComplete="off"
                            />
                            <ValidationErrorHandling error={errors.honeyBookPassword} />
                        </FormGroup>
                    </Col> */}
          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitHoneyBook}
                onChange={(e) => setSendLeadsLimitHoneyBook(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        {zapierSetup === null ? (
          <>
            <FormGroup>
              <FormText color="danger">
                <strong> NOTE:</strong> To send lead(s) to{" "}
                <strong>HoneyBook</strong> we need <strong>Zapier</strong>{" "}
                credentials. You can add them{" "}
                <Link className=" text-right" to="/zapierConfigration">
                  here
                </Link>
              </FormText>
            </FormGroup>
          </>
        ) : (
          <>
            {zapierSetup?.zapCreatedByAdmin === true ? (
              <DestinationFooter
                handleSubmit={handleHoneyBookForm}
                loading={loading}
              />
            ) : (
              <FormGroup>
                <FormText color="danger">
                  Your <strong>Zapier</strong> setup not yet completed by our
                  team. You will be notified once done.
                </FormText>
              </FormGroup>
            )}
          </>
        )}
      </Form>
    </DestinationHeader>
  );
};

export default HoneybookForm;
