import React, { useState } from "react";
const TableRow = ({
  label,
  value,
  colSpanTD,
  colSpanTH,
  classNameTD = "",
  classNameTH = "",
  styleTD = { whiteSpace: "break-spaces", position: 'relative' },
  style = { whiteSpace: "nowrap" },
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  let displayValue;
  if (typeof value === "string") {
    displayValue = isExpanded ? value : value.slice(0, 100);
  } else {
    displayValue = value
  }
  const onMouseHover = (e, textDecoration, color) => {
    if (e?.target?.style) {
      e.target.style.textDecoration = textDecoration;
      e.target.style.color = color;
    }
  };
  return (
    <>
      <tr style={style}>
        {label &&
          <th
            colSpan={colSpanTH !== undefined ? colSpanTH : null}
            className={classNameTH ? classNameTH : null}
          >
            {label}
          </th>
        }
        <td
          colSpan={colSpanTD !== undefined ? colSpanTD : null}
          className={classNameTD ? classNameTD : null}
          style={styleTD}
        >
          {displayValue}
          {typeof value === "string" && value.length > 100 && (
            <span
              onClick={toggleExpansion}
              style={{ cursor: "pointer", color: "#8f8e8e", fontSize: "0.95rem" }}
              onMouseEnter={(e) => onMouseHover(e, "underline", "#3d3d3d")}
              onMouseLeave={(e) => onMouseHover(e, "none", "#8f8e8e")}
            >
              {isExpanded ? " ...Read Less" : " ...Read More"}
            </span>
          )}
        </td>
      </tr>
    </>
  );
};
export default TableRow;