import { combineReducers } from "redux";
import LoginReducer from "./login";
import AlertReducer from "./alert";
import ScreenReducer from "./screen";

const rootReducer = combineReducers({
  LoginReducer,
  AlertReducer,
  ScreenReducer
});
export default rootReducer;