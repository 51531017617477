import { Card, CardBody, CardTitle, CardText, Table, Row, Col, Button, Fade } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { formatDateTime, formatLeadSource } from "../../../utils/functions";
import { useKey } from "../../../services/useKey";
import TableRow from "../../common/tableRow";


const LeadDetail = ({ leadDetail, showDetail, setshowDetail }) => {

    useKey(() => {
        setshowDetail(false)
    }, "Escape")

    return (
        <Fade>
            <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
                <CardBody>
                    <CardTitle tag="h4">
                        <Row className="justify-content-between">
                            <Col md={"auto"} xs={"auto"}>
                                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                                Lead Detail
                            </Col>

                            <Col md={"auto"} xs={"auto"}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setshowDetail(false)
                                    }}

                                    size="sm"
                                >
                                    <FontAwesomeIcon
                                        icon={faWindowClose}
                                        size="sm"
                                    />
                                </Button>
                            </Col>

                        </Row>
                    </CardTitle>
                    <CardText>
                        <Table responsive striped bordered hover size="sm">
                            {leadDetail.photo && (
                                <TableRow
                                    colSpanTH={1}
                                    label={
                                        <img src={leadDetail.photo} alt="Lead" width={100} style={{ borderRadius: "50%" }} />
                                    }
                                />
                            )}
                            <TableRow label="Name" value={leadDetail.fullName ? leadDetail.fullName : `${leadDetail.firstName} ${leadDetail.lastName}`} />
                            <TableRow label="Email" value={leadDetail?.email} />
                            <TableRow label="Phone" value={leadDetail?.phone} />
                            <TableRow label="Address" value={leadDetail?.address} />
                            <TableRow label="Event Name" value={leadDetail?.eventName} />
                            <TableRow label="Event Type" value={leadDetail?.eventType} />
                            <TableRow label="Event Date" value={leadDetail?.eventDate} />
                            <TableRow label="Event Time" value={leadDetail?.eventTime} />
                            <TableRow label="Guest Count" value={leadDetail?.guestCount} />
                            <TableRow label="Company" value={leadDetail?.company} />
                            <TableRow label="Imported At" value={formatDateTime(leadDetail?.createdAt)} />
                            <TableRow label="Source" value={formatLeadSource(leadDetail?.leadSource)} classNameTD="autoCapital" />
                            <TableRow label="Notes/Inquiry" value={leadDetail?.inquiry} styleTD={{whiteSpace:"normal"}}/>

                            {/*  {leadDetail.photo && (
                            <tr style={{ whiteSpace: "nowrap" }}>
                                <th colSpan={2}>
                                    <img src={leadDetail.photo} alt="Lead" width={100} style={{ borderRadius: "50%" }} />
                                </th>
                            </tr>
                        )}
                         <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Name</th>
                            <td>
                                {
                                    leadDetail.fullName ? leadDetail.fullName : `${leadDetail.firstName} ${leadDetail.lastName}`
                                }
                            </td>
                        </tr> */}
                            {/* <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Email</th>
                            <td>{leadDetail?.email}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Phone</th>
                            <td>{leadDetail?.phone}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Event Name</th>
                            <td>{leadDetail?.eventName}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Event Type</th>
                            <td>{leadDetail?.eventType}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Event Date</th>
                            <td>{leadDetail?.eventDate}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Event Time</th>
                            <td>{leadDetail?.eventTime}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Guest Count</th>
                            <td>{leadDetail?.guestCount}</td>
                        </tr>
                        <tr>
                            <th>Company</th>
                            <td>{leadDetail?.company}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Imported At</th>
                            <td>

                                {formatDateTime(leadDetail?.createdAt)}</td>
                        </tr> */}
                            {/* <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Source</th>
                            <td className="autoCapital">{formatLeadSource(leadDetail?.leadSource)}</td>
                        </tr> */}
                            {/* <tr>
                            <th>Notes/Inquiry</th>
                            <td >{leadDetail?.inquiry}</td>
                        </tr> */}
                        </Table>
                    </CardText>
                </CardBody>
            </Card>
        </Fade>
    )
};

export default LeadDetail;