import { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import useGet from "../../../services/useGet";
import { MSG_DESTINATION } from "../../../utils/message";
import {
  handleYupValidationErrors,
  listSearchable,
  yupValidationSchema,
} from "../../../utils/functions";
import DestinationFooter from "./common/destinationFooter";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_CONVERSATE = process.env.REACT_APP_INTEGRATION_CONVERSATE;

const validationSchema = yupValidationSchema({
  conversateAccountEmail: { required: true, email: true, customName: "Email" },
  conversateAccountPassword: { required: true, customName: "Password" },
  inbox: { required: true, customName: "Inbox" },
  agent: { required: true, customName: "Agent" },
});

const ConversateForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [conversateAccountEmail, setConversateAccountEmail] = useState("");
  const [conversateAccountPassword, setConversateAccountPassword] =
    useState("");
  const [initialMessage, setInitialMessage] = useState("");
  const [sendLeadsLimitConversate, setSendLeadsLimitConversate] = useState(0);
  const [conversateAccounts, setConversateAccounts] = useState([]);
  const [conversateAccount, setConversateAccount] = useState("");
  const [inboxes, setInboxes] = useState([]);
  const [inbox, setInbox] = useState("");
  const [destinationInbox, setDestinationInbox] = useState("");
  const [agents, setAgents] = useState([]);
  const [agent, setAgent] = useState("");
  const [destinationAgent, setDestinationAgent] = useState("");
  const [errors, setErrors] = useState({});
  const [conversateloading, setConversateLoading] = useState({
    loaderSave: false,
    loaderLocation: false,
    inbox: false,
    agent: false,
    fetchInboxAgent: false,
  });

  const { callApi: getInbox, data: inboxData } = useGet({
    endpoint: `integration/conversate-inboxes?destinationAccountEmail=${conversateAccountEmail}&destinationAccountPassword=${conversateAccountPassword}&accountId=${conversateAccount}`,
  });

  const { callApi: getSwitchAccounts, data: accountsData } = useGet({
    endpoint: `integration/conversate-accounts?destinationAccountEmail=${conversateAccountEmail}&destinationAccountPassword=${conversateAccountPassword}`,
  });

  const { callApi: getAgent, data: agentsData } = useGet({
    endpoint: `integration/conversate-agents?destinationAccountEmail=${conversateAccountEmail}&destinationAccountPassword=${conversateAccountPassword}&accountId=${conversateAccount}`,
  });

  const getAccounts = async () => {
    setConversateLoading({
      ...conversateloading,
      accounts: true,
    });
    await getSwitchAccounts();
    setConversateLoading({
      ...conversateloading,
      accounts: false,
    });
  };

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          integrationCompany,
          leadSentConfig,
          destinationAccountEmail,
          destinationAccountPassword,
        } = dest;
        if (integrationCompany === DESTINATION_CONVERSATE) {
          setConversateAccountEmail(destinationAccountEmail);
          setSendLeadsLimitConversate(leadSentConfig?.sentLimit);
          setConversateAccountPassword(destinationAccountPassword);
          setInboxes(dest?.inboxProvider);
          // const inboxList = listSearchable(dest?.inboxProvider,"id","name");
          // setInboxes(inboxList);

          if (dest?.locations[0]) {
            setDestinationInbox(dest.locations[0]);
            setInbox(dest.locations[0]);
          }

          if (dest?.conversateAccounts) {
            // const usList = listSearchable(dest?.conversateAccounts, "id", "name");
            // setConversateAccounts(usList);
            setConversateAccounts(dest?.conversateAccounts);
          }

          setAgents(dest?.agentProvider);
          // const agentList = listSearchable(dest?.agentProvider,"id","name");
          // setAgents(agentList);
          if (dest?.extraInfo) {
            setDestinationAgent(dest?.extraInfo?.agent);
            setAgent(dest?.extraInfo?.agent);
            setInitialMessage(dest?.extraInfo?.initialMessage);
            setConversateAccount(dest?.extraInfo?.account_id);
          }
        }
      }
    }
  }, [destinations]);

  useEffect(() => {
    if (inboxData) {
      // setInboxes(inboxData);
      const inboxList = listSearchable(inboxData, "id", "name");
      setInboxes(inboxList);
      setConversateLoading({
        ...conversateloading,
        inbox: false,
        fetchInboxAgent: false,
      });
    }
    if (agentsData) {
      // setAgents(agentsData);
      const agentList = listSearchable(agentsData, "id", "name");
      setAgents(agentList);
      setConversateLoading({
        ...conversateloading,
        agent: false,
        fetchInboxAgent: false,
      });
    }
  }, [inboxData, agentsData]);

  useEffect(() => {
    if (conversateloading.fetchInboxAgent && conversateAccount) {
      getInbox();
      getAgent();
    }
  }, [conversateloading.fetchInboxAgent, conversateAccount]);

  useEffect(() => {
    if (accountsData) {
      const usList = listSearchable(accountsData, "id", "name");
      setConversateAccounts(usList);
      // setConversateAccounts(accountsData);
    }
  }, [accountsData]);

  const handleConversateForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { conversateAccountEmail, conversateAccountPassword, inbox, agent },
        { abortEarly: false }
      );
      setLoading(true);
      await saveIntegration(
        {
          destinationAccountEmail: conversateAccountEmail,
          destinationAccountPassword: conversateAccountPassword,
          integrationCompany: DESTINATION_CONVERSATE,
          sendLeadsLimit: sendLeadsLimitConversate,
          locations: [inbox],
          extraInfo: { agent, initialMessage, account_id: conversateAccount },
          inboxProvider: inboxes,
          agentProvider: agents,
          conversateAccounts,
        },
        MSG_DESTINATION.SAVE_CONVERSATE
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DestinationHeader
      headerTitle="Conversate"
      headerImage="images/conversate.png"
    >
      <Form onSubmit={handleConversateForm} autoComplete="off">
        <FormGroup>
          <Row>
            <Col lg="6">
              <Label for="email">Email</Label>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                autocomplete="off"
                type="email"
                value={conversateAccountEmail}
                onChange={(e) => setConversateAccountEmail(e.target.value)}
                invalid={errors.conversateAccountEmail}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.conversateAccountEmail} />
            </Col>

            <Col lg="6">
              <Label for="name">Password</Label>
              <Input
                placeholder="Password"
                autocomplete="off"
                type="password"
                value={conversateAccountPassword}
                onChange={(e) => setConversateAccountPassword(e.target.value)}
                invalid={errors.conversateAccountPassword}
                autoComplete="off"
              />
              <ValidationErrorHandling
                error={errors.conversateAccountPassword}
              />
            </Col>
          </Row>
        </FormGroup>

        <FormGroup>
          <div
            style={{
              display: "flex",
              gap: "8px",
              marginBottom: "8px",
              flexWrap: "wrap",
            }}
          >
            <Button
              onClick={() => {
                getAccounts();
              }}
            >
              {conversateloading.accounts ||
              conversateloading.fetchInboxAgent ? (
                <Spinner className="mr-2" color="light" size="sm" children="" />
              ) : (
                <FontAwesomeIcon icon={faUsers} className="mr-2" />
              )}
              Get Account(s)
            </Button>

            {conversateAccounts && (
              <Select
                isDisabled={
                  (conversateloading.accounts ||
                    conversateloading.fetchInboxAgent) &&
                  true
                }
                placeholder="Select account"
                timezonesLoading={false}
                options={conversateAccounts}
                onChange={(opt) => {
                  setConversateAccount(opt.value);
                  if (opt.value) {
                    setConversateLoading({
                      ...conversateloading,
                      fetchInboxAgent: true,
                    });
                  }
                }}
                value={
                  conversateAccounts.constructor === Array &&
                  conversateAccounts.filter((option) => {
                    return (
                      option.value?.toString() === conversateAccount?.toString()
                    );
                  })
                }
              />
            )}
          </div>
        </FormGroup>

        <FormGroup>
          <Row>
            <Col lg="6">
              <FormGroup>
                <Label for="inbox">Inbox</Label>
                {inboxes && (
                  <Select
                    id="inbox"
                    placeholder="Select inbox"
                    timezonesLoading={false}
                    options={inboxes}
                    onChange={(opt) => setInbox(opt.value)}
                    value={
                      inboxes.constructor === Array &&
                      inboxes.filter((option) => {
                        return (
                          option.value?.toString() ===
                            destinationInbox?.toString() ||
                          option.value?.toString() === inbox?.toString()
                        );
                      })
                    }
                  />
                )}
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <Label for="agent">Agent</Label>
                {agents && (
                  <Select
                    placeholder="Select agent"
                    timezonesLoading={false}
                    options={agents}
                    onChange={(opt) => setAgent(opt.value)}
                    value={
                      agents.constructor === Array &&
                      agents.filter((option) => {
                        return (
                          option.value?.toString() ===
                            destinationAgent?.toString() ||
                          option.value?.toString() === agent?.toString()
                        );
                      })
                    }
                  />
                )}
              </FormGroup>
            </Col>

            <Col lg="6">
              <FormGroup>
                <Label for="send_leads_initially">
                  How Many Leads Do You Want To Send Initially?
                </Label>
                <Input
                  id="send_leads_initially"
                  name="send_leads_initially"
                  placeholder="Total lead(s) send initially"
                  type="text"
                  value={sendLeadsLimitConversate}
                  onChange={(e) => setSendLeadsLimitConversate(e.target.value)}
                  autoFocus
                  invalid={errors.sendLeadsLimitConversate}
                  autoComplete="off"
                />
                <ValidationErrorHandling
                  error={errors.sendLeadsLimitConversate}
                />
              </FormGroup>
            </Col>

            <Col lg="12">
              <FormGroup>
                <Label for="initialMessage">
                  Enter Initial Message (If any)
                </Label>
                <Input
                  id="initialMessage"
                  name="initialMessage"
                  placeholder="Write Initial Message Here..."
                  type="textarea"
                  value={initialMessage}
                  rows="4"
                  onChange={(e) => setInitialMessage(e.target.value)}
                  autoFocus
                  autoComplete="off"
                />
              </FormGroup>
            </Col>
          </Row>
        </FormGroup>

        <DestinationFooter
          handleSubmit={handleConversateForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default ConversateForm;
