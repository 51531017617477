import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCopy,
  faMailBulk,
  faPen,
  faSignInAlt,
  faSpinner,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Badge, Button, NavLink } from "reactstrap";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TableHeader from "../../common/tableHeader";
import TableLoader from "../../common/tableLoader";
import NoRecordsFound from "../../common/noRecordsFound";
import Popup from "../../common/popup";
import { capitalizeFirstLetter, getDomain } from "../../../utils/functions";
import SOURCES from "../../../utils/constants";
import usePost from "../../../services/usePost";
import LocalStorageService from "../../../utils/localStorageServices";

const AffiliateList = ({
  isLoading,
  getAffiliate,
  affiliateList,
  deleteAffiliate,
  removeSuccess,
  setDeleteId,
  setSortBy,
  sortBy,
  setSortOrder,
  sortOrder,
  viewDetail,
  setShowForm,
  setId,
}) => {
  const localStorageServices = LocalStorageService.getService();
  const history = useHistory();
  const [toggleModal, setToggleModal] = useState(false);
  const [copyClipboardText, setCopyClipboardText] = useState("");
  const [loadingStates, setLoadingStates] = useState(
    affiliateList?.results?.map(() => false) || []
  );

  const { callApi: emailStripeOnBoardLink } = usePost({
    endpoint: `affiliate/email/stripe-onboard-link`,
  });

  const { callApi: emailReferralLink } = usePost({
    endpoint: `affiliate/email/referral-link`,
  });

  const { callApi: loginAffiliate } = usePost({
    endpoint: `admin/login-as-affiliate`,
  });

  useEffect(() => {
    if (removeSuccess) {
      setToggleModal(false);
      getAffiliate();
    }
  }, [removeSuccess]);

  const sortData = (title) => {
    setSortBy(title);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  const handleEdit = async (id) => {
    setShowForm(true);
    setId(id);
  };

  const loginAsAffiliate = async (affiliateId) => {
    const userData = await loginAffiliate({ affiliateId });
    //console.log("userData", userData)

    if (userData?.user?.userStatus === SOURCES.STATUS_APPROVED) {
      localStorageServices.clearToken();
      localStorageServices.setCurrentUser(userData);
      history.push("/dashboard-affiliate");
    }
  };

  const copyStripeOnboardLink = (code) => {
    const host = getDomain();
    navigator.clipboard.writeText(`${host}stripeonboard?code=${code}`);
    setCopyClipboardText(code);
    setTimeout(() => {
      setCopyClipboardText("");
    }, 2000);
  };

  const copyRefLink = (code) => {
    const host = getDomain();
    navigator.clipboard.writeText(`${host}signup?ref=${code}`);
    setCopyClipboardText(code);
    setTimeout(() => {
      setCopyClipboardText("");
    }, 2000);
  };

  const copyLinkComponent = (uniqueCode, type) => {
    return (
      <NavLink
        href="#"
        style={{ textAlign: "center" }}
        onClick={() => {
          if (type === "onboard-link") copyStripeOnboardLink(uniqueCode);
          else copyRefLink(uniqueCode);
        }}
      >
        {copyClipboardText === uniqueCode ? (
          <Badge color="success">Copied!</Badge>
        ) : (
          <FontAwesomeIcon
            title="Copy Link"
            icon={faCopy}
            style={{ color: "green" }}
          />
        )}
      </NavLink>
    );
  };

  const handleEmailClick = async (index, id, type) => {
    setLoadingStates((prevLoadingStates) => {
      const newLoadingStates = [...prevLoadingStates];
      newLoadingStates[index] = true;
      return newLoadingStates;
    });

    try {
      if (type === "onboard-link") {
        await emailStripeOnBoardLink({ id });
      } else {
        await emailReferralLink({ id });
      }
    } catch (error) {
      setLoadingStates((prevLoadingStates) => {
        const newLoadingStates = [...prevLoadingStates];
        newLoadingStates[index] = false;
        return newLoadingStates;
      });
    } finally {
      setLoadingStates((prevLoadingStates) => {
        const newLoadingStates = [...prevLoadingStates];
        newLoadingStates[index] = false;
        return newLoadingStates;
      });
    }
  };

  const sentEmail = (index, id, type) => {
    return (
      <NavLink
        href="#"
        style={{ textAlign: "center" }}
        onClick={() => handleEmailClick(index, id, type)}
      >
        {loadingStates[index] ? (
          <FontAwesomeIcon
            title="Loading..."
            icon={faSpinner}
            spin
            style={{ color: "green" }}
          />
        ) : (
          <FontAwesomeIcon
            title="Email"
            icon={faMailBulk}
            style={{ color: "green" }}
          />
        )}
      </NavLink>
    );
  };
  // alert("juuuuu")

  return (
    <>
      <Table responsive striped bordered hover size="sm" className="shadow">
        <TableHeader
          headers={[
            { title: "Name", showIcon: true },
            { title: "Email", showIcon: true },
            { title: "Company", showIcon: true },
            { title: "Type", showIcon: true },
            { title: "Stripe Connected", showIcon: true },
            { title: "Onboarding Link", showIcon: false },
            { title: "Ref. Link", showIcon: false },
            { title: "" },
            { title: "" },
          ]}
          sortData={sortData}
          setSortBy={setSortBy}
          sortBy={sortBy}
          sortOrder={sortOrder}
        />
        <tbody>
          {isLoading ? (
            <TableLoader colSpan="8" />
          ) : (
            <>
              {affiliateList?.results && affiliateList?.results?.length > 0 ? (
                affiliateList?.results?.map((item, idx) => {
                  return (
                    <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                      <td>
                        <Link to="#" onClick={() => viewDetail(item)}>
                          {item.name}
                        </Link>
                      </td>
                      <td>{item.email}</td>
                      <td>{item.companyName}</td>
                      <td>{capitalizeFirstLetter(item.userType)}</td>
                      <td className="text-center">
                        <Badge
                          color={
                            item.stripeConnectedStatus ===
                            SOURCES.AFFILIATE_ONBOARD_STATUS_PROGRESS
                              ? "danger"
                              : "success"
                          }
                        >
                          {capitalizeFirstLetter(item.stripeConnectedStatus)}
                        </Badge>
                      </td>
                      <td>
                        {item.stripeConnectedStatus ===
                          SOURCES.AFFILIATE_ONBOARD_STATUS_PROGRESS && (
                          <div className="d-flex align-items-center justify-content-center">
                            {copyLinkComponent(item.uniqueCode, "onboard-link")}
                            {sentEmail(idx, item._id, "onboard-link")}
                          </div>
                        )}
                      </td>
                      <td>
                        {item.stripeConnectedStatus ===
                          SOURCES.AFFILIATE_ONBOARD_STATUS_COMPLETED && (
                          <div className="d-flex align-items-center justify-content-center">
                            {copyLinkComponent(
                              item.uniqueCode,
                              "referral-link"
                            )}
                            {sentEmail(idx, item._id, "referral-link")}
                          </div>
                        )}
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <NavLink
                            title="Edit"
                            href="#"
                            onClick={() => {
                              handleEdit(item?._id);
                            }}
                          >
                            <FontAwesomeIcon icon={faPen} />
                          </NavLink>

                          <NavLink href="#">
                            <FontAwesomeIcon
                              title="Delete"
                              icon={faTrashAlt}
                              style={{ color: "#dc3545" }}
                              onClick={() => {
                                setDeleteId(item?._id);
                                setToggleModal(true);
                              }}
                            />
                          </NavLink>
                        </div>
                      </td>
                      <td>
                        {" "}
                        {item.stripeConnectedStatus ===
                        SOURCES.AFFILIATE_ONBOARD_STATUS_COMPLETED ? (
                          <Button
                            Button
                            color="primary"
                            onClick={() => loginAsAffiliate(item._id)}
                          >
                            <FontAwesomeIcon
                              icon={faSignInAlt}
                              className="mr-2"
                            />
                            Login
                          </Button>
                        ) : (
                          <div className="ml-5"></div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <NoRecordsFound colSpan="9" />
              )}
            </>
          )}
        </tbody>
      </Table>
      {toggleModal && (
        <Popup
          showPopup={toggleModal}
          hidePopup={() => setToggleModal(false)}
          actionMethod={deleteAffiliate}
          title="Delete Affiliate/Partner"
          promtMessage="Are you sure you want to delete affiliate/partner?"
        />
      )}
    </>
  );
};
export default AffiliateList;
