import React from 'react'
import { Link, NavLink } from 'react-router-dom';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'reactstrap';
import { formatDateTime, formatLeadSource } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';

const NewLeadAlertList = ({
    isLoading,
    dataExist,
    newLeadAlertImported,
    viewDetail,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
}) => {
    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }

    return (
        <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader
                headers={[{ title: 'Lead Source', showIcon: true }, { title: 'Alert (Email/Sms)' }, { title: 'Lead(s)', showIcon: true }, { title: 'Imported At', showIcon: true }, { title: "" }]}
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <tbody>
                {
                    isLoading ?
                        <TableLoader colSpan="5" />
                        :
                        <>
                            {
                                dataExist
                                    ?
                                    (newLeadAlertImported?.results?.map((item, idx) => {
                                        return (
                                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                                <td className="autoCapital" scope="row">
                                                    <NavLink to="#" onClick={() => viewDetail(item)}>
                                                        {formatLeadSource(item?.leadSourceId?.leadSource)}
                                                    </NavLink>
                                                </td>
                                                <td>{item.alertEmail === null ? item.alertSms : item.alertEmail}</td>
                                                <td>{item?.leadsImported}</td>
                                                <td>{formatDateTime(item?.createdAt)} </td>
                                                <td style={{ textAlign: "center", fontSize: "18px" }} >
                                                    <NavLink to="#" onClick={() => viewDetail(item)}>
                                                        <FontAwesomeIcon
                                                            icon={faInfo}
                                                            className="m-1"
                                                        />
                                                    </NavLink>

                                                </td>
                                            </tr>
                                        );
                                    }))
                                    :
                                    <NoRecordsFound colSpan="5" />
                            }
                        </>
                }
            </tbody>
        </Table>

    )
}

export default NewLeadAlertList