import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_RELEVENTFUL = process.env.REACT_APP_INTEGRATION_RELEVENTFUL;

const validationSchema = yupValidationSchema({
  releventfulApiKey: { required: true, customName: "Api key" },
});

const ReleventfulForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [releventfulApiKey, setReleventfulApiKey] = useState("");
  const [sendLeadsLimitReleventful, setSendLeadsLimitReleventful] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_RELEVENTFUL) {
          setReleventfulApiKey(secretKey);
          setSendLeadsLimitReleventful(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleReleventfulForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { releventfulApiKey },
        { abortEarly: false }
      );
      setLoading(true);
      await saveIntegration(
        {
          secretKey: releventfulApiKey,
          sendLeadsLimit: sendLeadsLimitReleventful,
          integrationCompany: DESTINATION_RELEVENTFUL,
        },
        MSG_DESTINATION.SAVE_RELEVENTFUL
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader
      headerTitle="Releventful"
      headerImage="images/releventful.png"
    >
      <Form onSubmit={handleReleventfulForm} autoComplete="off">
        <FormGroup>
          <FormText color="info">
            <strong> NOTE:</strong>You can find your API Key by navigating to My
            Settings-&gt;Integration-&gt;Zapier Setup from dashboard.
          </FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="Get API key from Releventful Application"
                autocomplete="off"
                type="text"
                defaultValue={releventfulApiKey}
                value={releventfulApiKey}
                onChange={(e) => setReleventfulApiKey(e.target.value)}
                invalid={errors.releventfulApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.releventfulApiKey} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitReleventful}
                onChange={(e) => setSendLeadsLimitReleventful(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleReleventfulForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default ReleventfulForm;
