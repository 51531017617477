import { interceptor } from "../utils/interceptor";
import { useDispatch } from "react-redux";
import { showAlert } from "../redux/actions";
const { useState } = require("react");

const axiosInstance = interceptor();

const useGet = ({
    endpoint
}) => {

    const [ isLoading, setLoading ] = useState(false);
    const [ data, setData ] = useState(false);

    const dispatch = useDispatch();
    const getData = async (params) => {
        try {
            setLoading(true);
            let apiEndpoint = endpoint;
            // console.log('params',params)
            if(params){
                apiEndpoint = apiEndpoint+'?';
                params.map((param,i) => {
                    // console.log('this param',param)
                    apiEndpoint += `${param.key}=${param.val}&`;
                })
            }
            // console.log('apiEndpoint',apiEndpoint)
          const result = await axiosInstance.get(apiEndpoint);
          if (result) {
            // console.log(result);
            const data = result.data.data;
            setData(data);
          }
        } catch (e) {
          dispatch(
            showAlert({
              show: true,
              type: "danger",
              msg: e?.response?.data?.data?.error,
            })
          );
        } finally {
            setLoading(false);
        }
      };

      return {
          callApi: getData,
          isLoading,
          data
      }
}

export default useGet;