import React, { useEffect, useState } from 'react'
import { faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Table, Badge, NavLink } from "reactstrap";
import useGet from '../../services/useGet';
import usePost from '../../services/usePost';
import { MSG_LEADSOURCE } from '../../utils/message';
import NoRecordsFound from '../common/noRecordsFound';
import Popup from '../common/popup';
import TableHeader from '../common/tableHeader';
import TableLoader from '../common/tableLoader';

const LeadSourceList = ({
    getLeadSources,
    setShowForm,
    setId,
    getLeadSourcesLoader,
    jobs, setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
}) => {
    const [toggleModal, setToggleModal] = useState(false);
    const [deleteId, setDeleteId] = useState(0);


    const {
        callApi: deleteLeadSource,
        processSuccess: removeSuccess,
    } = usePost({
        endpoint: `leadsource/delete`,
    });

    const { callApi: getLeadSourcesOptions, data: leadSource, isLoading: getLeadSourcesOptionsLoader } = useGet({
        endpoint: `leadsource/options`,
    });
    const isLoading = getLeadSourcesOptionsLoader || getLeadSourcesLoader;

    useEffect(() => {
        getLeadSourcesOptions()
    }, [])

    useEffect(() => {
        if (removeSuccess) {
            setToggleModal(false);
            getLeadSources();
        }
    }, [removeSuccess]);

    const getLeadSourceTitle = (leadsourceKey) => {
        return leadSource && leadSource.map((ls, i) => {
            return ls.key === leadsourceKey ? ls.title : "";
        });
    };

    const handleEdit = async (id) => {
        setShowForm(true);
        setId(id);
    };

    const removeLeadSource = () => {
        deleteLeadSource({
            id: deleteId
        }, MSG_LEADSOURCE.DELETE);
    };

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }


    return (
        <>
            <Table responsive striped bordered hover size="sm" className="shadow">
                <TableHeader
                    headers={[{ title: "Lead Source", showIcon: true }, { title: "Job Name", showIcon: true }, { title: "Email/Username", showIcon: true }, { title: "Active", showIcon: true }, { style: { width: "1%" } }]}
                    sortData={sortData}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <tbody>
                    {
                        isLoading ?
                            <TableLoader colSpan="5" />
                            :
                            <>
                                {jobs && jobs.length > 0 ?
                                    jobs.map((job, idx) => {
                                        return (
                                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                                <td>{getLeadSourceTitle(job.leadSource)}</td>
                                                <td>
                                                    <Link onClick={() => {
                                                        handleEdit(job._id);
                                                    }}>
                                                        {job.jobName}
                                                    </Link>
                                                </td>
                                                <td>{job.username}</td>
                                                <td>
                                                    {job.active === true ? (
                                                        <Badge color="success" pill>
                                                            Yes
                                                        </Badge>
                                                    ) : (
                                                        <Badge class="danger" pill>
                                                            No
                                                        </Badge>
                                                    )}
                                                </td>
                                                <td className="d-flex">
                                                    <NavLink
                                                        href="#"
                                                        onClick={() => {
                                                            handleEdit(job._id);
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faPen} />
                                                    </NavLink>
                                                    <NavLink href="#">
                                                        <FontAwesomeIcon
                                                            icon={faTrashAlt}
                                                            style={{ color: "#dc3545" }}
                                                            onClick={() => {
                                                                setDeleteId(job._id);
                                                                setToggleModal(true);
                                                            }}
                                                        />
                                                    </NavLink>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :
                                    <NoRecordsFound colSpan="5" />
                                }
                            </>
                    }
                </tbody>
            </Table>

            {toggleModal && <Popup 
            title='Delete Import Job'
            promtMessage='Are you sure you want to delete Import Job?'
            showPopup={toggleModal} 
            hidePopup={() => setToggleModal(false)} 
            actionMethod={removeLeadSource} 
            />}
        </>
    )
}

export default LeadSourceList