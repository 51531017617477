import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faUser, faUserNinja } from '@fortawesome/free-solid-svg-icons';
import { Link, NavLink } from 'react-router-dom';
import { Table } from 'reactstrap';
import { formatDateTime, formatLeadSource } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';

const LeadList = ({
  isLoading,
  dataExist,
  leadsImported,
  viewDetail,
  getLeadsImported,
  processSuccess,
  saveHotLeads,
  setSortBy,
  sortBy,
  setSortOrder,
  sortOrder

}) => {

  useEffect(() => {
    getLeadsImported();
  }, [processSuccess])

  const sortData = (title) => {
    setSortBy(title)
    setSortOrder(sortOrder === 1 ? -1 : 1)
  }

  return (
    <Table responsive striped bordered hover size="sm" className="shadow">
      <TableHeader
        headers={[{ title: 'Lead Source', showIcon: true }, { title: 'Job', showIcon: true }, { title: 'Name', showIcon: true }, { title: 'Email', showIcon: true }, { title: 'Phone', showIcon: true }, { title: 'Event Name', showIcon: true }, { title: 'Imported At', showIcon: true }, { title: "", style: { width: "1%" } }]}
        sortData={sortData}
        setSortBy={setSortBy}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
      <tbody>
        {
          isLoading ?
            <TableLoader colSpan="8" />
            :
            <>
              {
                dataExist
                  ?
                  (leadsImported.results.map((item, idx) => {
                    return (
                      <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                        <th className="autoCapital" scope="row">{formatLeadSource(item?.leadSource)}</th>
                        {/* <td>{item?.LeadSource[0]?.jobName}</td> */}
                        <td>{item?.LeadSource?.jobName}</td>
                        <td>
                          <Link to="#" onClick={() => viewDetail(item)}>
                            {
                              item.fullName ?
                                item.fullName
                                :
                                item?.firstName + " " + item?.lastName
                            }
                          </Link>
                        </td>
                        <td>{item?.email}</td>
                        <td>{item?.phone}</td>
                        <td>{item?.eventName}</td>
                        <td>{formatDateTime(item?.dateAsPerUserTimezone)} </td>
                        <td>
                          <NavLink
                            to="#"
                            onClick={() => viewDetail(item)}
                            className="mx-2"
                          >
                            <FontAwesomeIcon icon={faInfo} />
                          </NavLink>

                          <NavLink
                            to="#"
                            title="Mark/unmark Hot Lead"
                            className="mx-2"
                            onClick={async () => {
                              await saveHotLeads({ leadId: item?._id, isHotLead: !item?.isHotLead })
                            }}>
                            <FontAwesomeIcon
                              icon={item?.isHotLead === true ? faUserNinja : faUser}
                              color={item?.isHotLead === true ? "#dc3545" : "#007bff"}
                            />
                          </NavLink>
                        </td>
                      </tr>
                    );
                  }))
                  :
                  <NoRecordsFound colSpan="8" />
              }
            </>
        }
      </tbody>
    </Table>

  )
}

export default LeadList