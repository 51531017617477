import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Input,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  CardFooter,
} from "reactstrap";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useKey } from "../../../services/useKey";
import SOURCES from "../../../utils/constants";
import SubmitButton from "../../common/submitButton";
import useGet from "../../../services/useGet";

const AdvanceSearchUser = ({
  setShowAdvanceSearch,
  setAdvanceSearch,
  advanceSearch,
  getAdvanceSearchData,
  paymentPlans,
}) => {
  const [searched, setSearched] = useState(false);

  const {
    callApi: getLeadSourcesOptions,
    data: leadSource,
    isLoading: getLeadSourcesOptionsLoader,
  } = useGet({
    endpoint: `leadsource/options`,
  });

  useKey(() => setSearched(true), "Enter");
  useKey(() => {
    setShowAdvanceSearch(false);
    setAdvanceSearch({
      ...advanceSearch,
      advance_search_enabled: searched
        ? SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM
        : SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    });
  }, "Escape");

  useEffect(() => {
    getLeadSourcesOptions();
  }, []);

  useEffect(() => {
    if (searched === true) {
      setAdvanceSearch({
        ...advanceSearch,
        advance_search_enabled:
          SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER,
      });
      setSearched(false);
    }
  }, [searched]);

  useEffect(() => {
    if (
      advanceSearch.advance_search_enabled ===
      SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER
    ) {
      getAdvanceSearchData();
      setAdvanceSearch({
        ...advanceSearch,
        advance_search_enabled:
          SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT,
      });
    }
  }, [advanceSearch.advance_search_enabled]);

  return (
    <>
      <Card className="shadow">
        <CardBody>
          <Row>
            <Col sm={6}>
              <FormGroup>
                <Label for="plan">Plan</Label>
                <Input
                  type="select"
                  value={advanceSearch.plan}
                  tabIndex={1}
                  autoFocus={true}
                  id="plan"
                  name="plan"
                  className=" w-100 p-2 mt-2 border rounded"
                  onChange={(e) =>
                    setAdvanceSearch({
                      ...advanceSearch,
                      plan: e.target.value,
                    })
                  }
                >
                  <option value="">Select One</option>
                  {paymentPlans &&
                    paymentPlans.map((plan) => {
                      return <option value={plan._id}>{plan.planName}</option>;
                    })}
                </Input>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label for="verified">Verified</Label>
                <Input
                  type="select"
                  value={advanceSearch.status}
                  tabIndex={2}
                  autoFocus={true}
                  id="verified"
                  name="verified"
                  className="w-100 p-2 mt-2 border rounded"
                  onChange={(e) =>
                    setAdvanceSearch({
                      ...advanceSearch,
                      status: e.target.value,
                    })
                  }
                >
                  <option value="">Select One</option>
                  <option value="y">Verified</option>
                  <option value="n">Unverified</option>
                </Input>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label for="trial">Trial</Label>
                <Input
                  type="select"
                  value={advanceSearch.trial}
                  tabIndex={3}
                  autoFocus={true}
                  id="trial"
                  name="trial"
                  className="w-100 p-2 mt-2 border rounded"
                  onChange={(e) =>
                    setAdvanceSearch({
                      ...advanceSearch,
                      trial: e.target.value,
                    })
                  }
                >
                  <option value="">Select One</option>
                  <option value="y">Yes</option>
                  <option value="n">No</option>
                </Input>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label for="status">Status</Label>
                <Input
                  type="select"
                  value={advanceSearch.userStatus}
                  tabIndex={4}
                  autoFocus={true}
                  id="status"
                  name="status"
                  className="w-100 p-2 mt-2 border rounded"
                  onChange={(e) =>
                    setAdvanceSearch({
                      ...advanceSearch,
                      userStatus: e.target.value,
                    })
                  }
                >
                  <option value="">Select One</option>
                  <option value="active">Active</option>
                  <option value="deleted">Deleted</option>
                  <option value="suspended">Suspended</option>
                  <option value="trial">Trial</option>
                  <option value="trial_end">Trial End</option>
                </Input>
              </FormGroup>
            </Col>

            <Col sm={6}>
              <FormGroup>
                <Label for="leadSource">Lead Source</Label>
                <Input
                  type="select"
                  value={advanceSearch.leadSource}
                  tabIndex={5}
                  autoFocus={true}
                  id="leadSource"
                  name="leadSource"
                  className="w-100 p-2 mt-2 border rounded"
                  onChange={(e) =>
                    setAdvanceSearch({
                      ...advanceSearch,
                      leadSource: e.target.value,
                    })
                  }
                >
                  <option value="">Select One</option>
                  {leadSource &&
                    leadSource.map((ls) => (
                      <option value={ls.key}>{ls.title}</option>
                    ))}
                </Input>
              </FormGroup>
            </Col>
          </Row>

          <CardFooter>
            <FormGroup>
              <SubmitButton
                title="Search"
                icon={faSearch}
                tabIndex={6}
                tabIndexCancelButton={7}
                onClick={() => {
                  setSearched(true);
                }}
                cancleButtonOnClick={() => {
                  setShowAdvanceSearch(false);
                  setAdvanceSearch({
                    ...advanceSearch,
                    advance_search_enabled: searched
                      ? SOURCES.ADVANCE_SEARCH_FIELDS
                          .SEARCH_TYPE_CLOSED_SEARCH_FORM
                      : SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
                  });
                }}
              />
              {/* <Button color="primary" onClick={() => {
                setSearched(true)
              }}>
                <FontAwesomeIcon icon={faSearch} className="mr-2" />
                Search
              </Button>
              <Button color="secondary" className="ml-2" onClick={() => {
                setShowAdvanceSearch(false);
                setAdvanceSearch({ ...advanceSearch, advance_search_enabled: searched ? SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM : SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER })
              }}
              >
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Close
              </Button> */}
            </FormGroup>
          </CardFooter>
        </CardBody>
      </Card>
    </>
  );
};

export default AdvanceSearchUser;
