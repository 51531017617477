// import './styles/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import ShowAlert from "./components/common/alert";
import { useSelector } from 'react-redux';
import {Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js";

import Routes from "./routes/index"

const stripePromise = loadStripe(process.env.REACT_APP_APP_ENV === "staging" ? process.env.REACT_APP_STRIPE_PUB_KEY_TEST : process.env.REACT_APP_STRIPE_PUB_KEY_LIVE);

function App() {
  
  const notificationInfo = useSelector((state) => state.AlertReducer.alert);
  // console.log(notificationInfo, "inofff")

  return (
   <>
   <Elements stripe={stripePromise}>
    <Routes/>
    {notificationInfo.show && 
      <ShowAlert 
        type={notificationInfo.type} 
        msg={notificationInfo.msg} 
      />
    }
    </Elements>
   </>
  );
}

export default App;
