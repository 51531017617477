import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faCreditCard,
  faExchangeAlt,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Badge,
  Label,
  Input,
  Spinner,
  Row,
  Col,
  CardFooter,
  Fade,
} from "reactstrap";
import Select from "react-select";
import useGet from "../services/useGet";
import Popup from "./common/popup";
import usePost from "../services/usePost";
import SOURCES from "../utils/constants";
import LocalStorageService from "../utils/localStorageServices";
import {
  formatDateTime,
  handleYupValidationErrors,
  yupValidationSchema,
} from "../utils/functions";
import { useKey } from "../services/useKey";
import { MSG_PROFILE } from "../utils/message";
import ValidationErrorHandling from "./common/validationErrorHandling";
import SubmitButton from "./common/submitButton";

const localStorageServices = LocalStorageService.getService();

const validationSchema = yupValidationSchema({
  name: { required: true, customName: "Name" },
});

const Profile = () => {
  const currentUser = localStorageServices.getCurrentUser();
  const isTrialUser = currentUser?.user?.isTrial;
  const userStatus = currentUser?.user?.userStatus;

  const [toggleModal, setToggleModal] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentBadge, setPaymentBadge] = useState("warning");
  const [paymentPlan, setPaymentPlan] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [timezoneList, setTimezoneList] = useState([]);
  const [timezoneSelected, setTimezoneSelected] = useState(
    "America/Los_Angeles"
  );
  const [timezoneSelectedOffset, setTimezoneSelectedOffset] = useState(-7);
  const [registeredAt, setRegisteredAt] = useState("");
  const [couponId, setCouponId] = useState(null);
  const [errors, setErrors] = useState("");

  const {
    callApi: getProfile,
    data: userData,
    isLoading,
  } = useGet({
    endpoint: `user/me?email=${currentUser.user.email}`,
  });

  const { callApi: getCouponDetail, data: couponData } = useGet({
    endpoint: `coupon/detail/${couponId}`,
  });

  const { callApi: planCancel } = usePost({
    endpoint: `payment/cancel/subscription`,
  });

  const { callApi: saveProfile, isLoading: saveProfileLoader } = usePost({
    endpoint: `user/profile`,
  });

  const { callApi: getTimezones, data: timezones } = useGet({
    endpoint: `common/timezones`,
  });

  useEffect(() => {
    getProfile();
    setUserRole(currentUser.user.userRole);
    getTimezones();
  }, []);

  useEffect(() => {
    if (userData) {
      setEmail(userData[0]?.email);
      setName(userData[0]?.name);
      setPhone(userData[0]?.phone);
      setCompany(userData[0]?.company);
      setTimezoneSelected(userData[0]?.timezone?.name);
      setPaymentBadge(
        userData[0]?.paymentStatus === true ? "success" : "warning"
      );
      setPaymentStatus(
        userData[0]?.paymentStatus === true ? "Completed" : "Pending"
      );
      setPaymentPlan(userData[0]?.PaymentPlanInfo[0]?.planName);
      setRegisteredAt(formatDateTime(userData[0]?.createdAt));

      if (userData[0]?.couponId) setCouponId(userData[0]?.couponId);
    }
  }, [userData]);

  useEffect(() => {
    if (couponId) getCouponDetail();
  }, [couponId]);

  useEffect(() => {
    if (timezones) {
      const tzList = [];
      timezones.map((tz) => {
        tzList.push({
          label: `${tz.text} ${tz.utc[0]}`,
          value: tz.utc[0],
        });
      });
      setTimezoneList(tzList);
    }
  }, [timezones]); //*/

  useEffect(() => {
    timezones &&
      timezones.map((tz) => {
        if (tz.utc[0] === timezoneSelected)
          setTimezoneSelectedOffset(tz.offset);
      });
  }, [timezoneSelected]);

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ name }, { abortEarly: false });
      await saveProfile(
        {
          name,
          email,
          phone,
          company,
          timezone: { name: timezoneSelected, offset: timezoneSelectedOffset },
        },
        MSG_PROFILE.UPDATE
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    }
  };

  const cancelPlan = async () => {
    await planCancel({}, MSG_PROFILE.CANCLE_PLAN);
    setToggleModal(false);
    setTimeout(() => {
      getProfile();
    }, 2000);
  };

  useKey(handleForm(), "Enter");

  return (
    <Fade>
      <Card color="shadow">
        {isLoading ? (
          <center>
            <Spinner className="ml-2" children=""></Spinner>
          </center>
        ) : (
          <>
            <CardBody className="d-flex justify-content-center gap-1">
              <Row className="w-100" style={{ gap: "10px" }}>
                <Col lg="3" md="12">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "6px",
                      border: "1px solid rgba(0,0,0,.125)",
                      padding: "10px 20px",
                      height: "100%",
                      borderRadius: "6px",
                      flex: "1",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    className="shadow"
                  >
                    {userRole !== SOURCES.USER_ROLE_ADMIN && (
                      <>
                        <CardTitle
                          tag="h3"
                          className="d-flex flex-column justify-content-end align-items-center pt-3"
                        >
                          <FontAwesomeIcon icon={faUser} className="mr-2" />
                          Profile
                        </CardTitle>
                        <div style={{ textAlign: "center" }}>
                          <div>
                            <Label
                              for="Name"
                              style={{ fontWeight: "600", fontSize: "18px" }}
                            >
                              {userData[0]?.name}
                            </Label>
                          </div>
                        </div>
                        <div style={{ textAlign: "center" }}>
                          <div className="d-flex flex-column">
                            <Label for="paymentStatus">Payment Status</Label>
                            <Badge
                              color={paymentBadge}
                              className="ml-1"
                              style={{ padding: "5px 0", borderRadius: "20px" }}
                            >
                              {paymentStatus}
                            </Badge>
                          </div>
                          {paymentStatus === "Pending" && (
                            <Link className="ml-2" to="/payment">
                              <FontAwesomeIcon
                                icon={faCreditCard}
                                className="mr-1 "
                              />
                              Pay Now
                            </Link>
                          )}
                        </div>

                        {paymentStatus !== "Pending" && (
                          <div style={{ textAlign: "center" }}>
                            <div className="d-flex flex-column">
                              <Label for="company">Current Payment Plan</Label>
                              <Badge
                                color="warning"
                                className="ml-1"
                                style={{
                                  padding: "5px 0",
                                  borderRadius: "20px",
                                }}
                              >
                                {paymentPlan}
                              </Badge>
                            </div>

                            {couponData && couponData?.upgradeToPlanId && (
                              <div className="d-flex flex-column">
                                <Label for="company">Promo Applied</Label>
                                <Badge
                                  color="danger"
                                  className="ml-1"
                                  style={{
                                    padding: "5px 0",
                                    borderRadius: "20px",
                                  }}
                                >
                                  {couponData?.code}
                                </Badge>
                              </div>
                            )}

                            {paymentStatus === "Completed" && (
                              <div className="d-flex flex-column">
                                {isTrialUser === "y" && (
                                  <Label for="paymentStatus">
                                    You are currently on Trial version
                                  </Label>
                                )}

                                <Link className="ml-2" to="/payment">
                                  <FontAwesomeIcon
                                    icon={
                                      isTrialUser === "n"
                                        ? faExchangeAlt
                                        : faCreditCard
                                    }
                                    className="mr-1  "
                                  />
                                  {isTrialUser === "n"
                                    ? "Change Plan"
                                    : "Update Payment Now"}
                                </Link>
                              </div>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </Col>

                <Col>
                  <CardText
                    style={{
                      border: "1px solid rgba(0,0,0,.125)",
                      padding: "10px 20px",
                      height: "fit-content",
                      borderRadius: "6px",
                    }}
                    className="shadow"
                  >
                    <Form onSubmit={handleForm}>
                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="email">
                              Email
                              {userRole !== SOURCES.USER_ROLE_ADMIN && (
                                <small>
                                  <i>
                                    &nbsp;To update your email address, please
                                    contact support.
                                  </i>
                                </small>
                              )}
                            </Label>
                            <Input
                              id="email"
                              name="email"
                              placeholder="Email"
                              type="email"
                              readOnly
                              value={email}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="name">Name</Label>
                            <Input
                              id="name"
                              name="name"
                              placeholder="Name"
                              type="text"
                              onChange={(e) => setName(e.target.value)}
                              value={name}
                              autoFocus
                              invalid={errors.name}
                            />
                            <ValidationErrorHandling error={errors.name} />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="phone">Phone</Label>
                            <Input
                              id="phone"
                              name="phone"
                              placeholder="Phone number"
                              type="text"
                              onChange={(e) => {
                                setPhone(e.target.value);
                              }}
                              value={phone}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="company">Company</Label>
                            <Input
                              id="company"
                              name="company"
                              placeholder="Company name"
                              type="text"
                              onChange={(e) => setCompany(e.target.value)}
                              value={company}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="phone">Timezone</Label>
                            {timezoneList && (
                              <Select
                                isLoading={false}
                                options={timezoneList}
                                onChange={(opt) =>
                                  setTimezoneSelected(opt.value)
                                }
                                value={timezoneList.filter(function (option) {
                                  return option.value === timezoneSelected;
                                })}
                              />
                            )}
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <FormGroup>
                            <Label for="registeredAt">Registered At</Label>
                            <Input
                              id="registeredAt"
                              name="registeredAt"
                              type="text"
                              readOnly="readOnly"
                              value={registeredAt}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <CardFooter>
                        <FormGroup
                          style={{
                            display: "flex",
                            gap: "12px",
                            flexWrap: "wrap",
                            alignItems: "center",
                          }}
                        >
                          <SubmitButton
                            isLoading={saveProfileLoader}
                            cancleButton={false}
                          />
                          {/* <Button
                            type="submit"
                            color="primary"
                            onClick={handleForm}
                          >
                             <FontAwesomeIcon icon={faSave} className="mr-2" />
                            Save Changes
                            {saveProfileLoader && (
                              <Spinner
                                className=""
                                color="light"
                                size="sm"
                                children=""
                              />
                            )}
                          </Button> */}

                          <Link className=" text-right" to="/changepassword">
                            <FontAwesomeIcon icon={faKey} className="mr-1 " />
                            Change Password
                          </Link>
                        </FormGroup>
                      </CardFooter>
                    </Form>
                  </CardText>
                </Col>

                {userStatus === SOURCES.USER_STATUS_SUSPENDED && (
                  <Col lg="12">
                    <Card color="light">
                      <CardBody>
                        <CardText>
                          <FontAwesomeIcon
                            style={{ color: "#dc3545" }}
                            icon={faBan}
                          />{" "}
                          Your account is suspended{" "}
                          <Link to="/payment">Pay Now</Link> to reactivate!
                        </CardText>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            </CardBody>
          </>
        )}
      </Card>
      {!toggleModal && (
        <Popup
          showPopup={toggleModal}
          hidePopup={() => setToggleModal(false)}
          actionMethod={cancelPlan}
          title="Cancel Plan"
          promtMessage="Are you sure you want to cancel this Plan?"
        />
      )}
    </Fade>
  );
};

export default Profile;
