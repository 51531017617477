import React, { useEffect, useState } from 'react'
import { Card, CardBody, CardFooter, CardText, CardTitle, Col, Fade, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBroadcastTower, faSave } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { checkYupError, handleYupValidationErrors, showUserNotification, yupValidationSchema } from '../../../utils/functions';
import { useKey } from '../../../services/useKey';
import useGet from '../../../services/useGet';
import usePost from '../../../services/usePost';
import ValidationErrorHandling from './../../common/validationErrorHandling';
import SubmitButton from '../../common/submitButton';


const validationSchema = yupValidationSchema({
    name: { required: true, customName: "Name" },
    email: { email: true, required: true, customName: "Email" },
    commissionType: { required: true, customName: "Commission type" },
    commissionValue: { required: true, customName: "Commission value" },
    type: { required: true, customName: "Type" },
});
const AffiliateForm = ({ setShowForm, getAffiliate, affiliateId = null }) => {
    const dispatch = useDispatch();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyUrl, setCompanyUrl] = useState("");
    const [bussinessType, setBussinessType] = useState("");
    const [commissionType, setCommissionType] = useState("");
    const [additionalInformation, setAdditionalInformation] = useState("")
    const [sendStripeOnBoardLink, setSendStripeOnBoardLink] = useState(false)
    const [type, setType] = useState("");
    const [commissionValue, setCommissionValue] = useState("");
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState({})

    const { callApi: addAffiliatePartner, processSuccess } = usePost({
        endpoint: `affiliate`,
    });

    const { callApi: getAffiliatePartner, data: affiliateDetail } = useGet({
        endpoint: `affiliate/detail?id=${affiliateId}`
    });

    const clearForm = () => {
        setEmail("")
        setName("")
        setPhone("")
        setCompanyName("")
        setCompanyUrl("")
        setCompanyUrl("")
        setCommissionType("")
        setCommissionValue("")
        setType("")
        setAdditionalInformation("")
    }
    const handleForm = async (e) => {
        e.preventDefault();
        try {
            await validationSchema.validate(
                {
                    email,
                    name,
                    commissionValue,
                    commissionType,
                    type
                },
                { abortEarly: false }
            );

            const Post = {
                id: affiliateId,
                userType: type,
                email,
                name,
                phone,
                companyName,
                companyUrl,
                additionalInformation,
                bussinessType,
                commissionType,
                commissionValue,
                emailStripeOnboardLink: `${sendStripeOnBoardLink ? "y" : "n"}`
            }
            setLoading(true);
            setErrors({})
            await addAffiliatePartner(Post, "Affiliate/Partner saved successfully");
            setShowForm(false)
            getAffiliate()

        } catch (err) {
            setErrors(handleYupValidationErrors(err));
            setLoading(false);
            if (!checkYupError(err)) {
                dispatch(
                    showUserNotification(
                        "Unexpected error occurred please try again",
                        "danger"
                    )
                );
            }
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (processSuccess) clearForm()
    }, [processSuccess])

    useEffect(() => {
        if (affiliateId) getAffiliatePartner();
    }, [affiliateId]);

    useEffect(() => {
        if (affiliateDetail) {
            setEmail(affiliateDetail?.email)
            setName(affiliateDetail?.name)
            setPhone(affiliateDetail?.phone)
            setCompanyName(affiliateDetail?.companyName)
            setCompanyUrl(affiliateDetail?.companyUrl)
            setBussinessType(affiliateDetail?.bussinessType)
            setCommissionType(affiliateDetail?.commissionType)
            setCommissionValue(affiliateDetail?.commissionValue)
            setType(affiliateDetail?.userType)
            setAdditionalInformation(affiliateDetail?.additionalInformation)
        }
    }, [affiliateDetail]);

    useKey(() => {
        setShowForm(false);
        clearForm();
    }, "Escape");

    useKey(handleForm(), "Enter");

    return (
        <Fade>
            <Row>
                <Col sm="12" className="mb-2">
                    <Card className="shadow">
                        <CardBody>
                            <CardTitle tag="h4">
                                <FontAwesomeIcon icon={faBroadcastTower} className="mr-2" />
                                Affiliates / Partners
                            </CardTitle>
                            <CardText>
                                <Form >
                                    <div className='border shadow-sm rounded-lg p-4 my-3' >
                                        <Row>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Name</Label>
                                                    <Input
                                                        id="name"
                                                        name="name"
                                                        placeholder="Name"
                                                        type="text"
                                                        value={name}
                                                        onChange={(e) => {
                                                            setName(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                        autoFocus
                                                        invalid={errors.name}
                                                    />
                                                    <ValidationErrorHandling error={errors.name} />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Email</Label>
                                                    <Input
                                                        id="email"
                                                        name="email"
                                                        placeholder="Email Address"

                                                        type="email"
                                                        value={email}
                                                        onChange={(e) => {
                                                            setEmail(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                        invalid={errors.email}
                                                    />
                                                    <ValidationErrorHandling error={errors.email} />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Phone</Label>
                                                    <Input
                                                        id="phone"
                                                        name="phone"
                                                        placeholder="Phone Number"

                                                        type="phone"
                                                        value={phone}
                                                        onChange={(e) => {
                                                            setPhone(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Company Name</Label>
                                                    <Input
                                                        id="companyName"
                                                        name="companyName"
                                                        placeholder="Company Name"

                                                        type="text"
                                                        value={companyName}
                                                        onChange={(e) => {
                                                            setCompanyName(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Company URL</Label>
                                                    <Input
                                                        id="companyURL"
                                                        name="companyURL"
                                                        placeholder="Company URL"
                                                        type="url"
                                                        value={companyUrl}
                                                        onChange={(e) => {
                                                            setCompanyUrl(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Bussiness Type</Label>
                                                    <Input
                                                        id="bussinessType"
                                                        name="bussinessType"
                                                        placeholder="Bussiness Type"
                                                        type="text"
                                                        value={bussinessType}
                                                        onChange={(e) => {
                                                            setBussinessType(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Commission Type</Label>
                                                    <Input
                                                        id="commissionType"
                                                        name="commissionType"
                                                        placeholder="Commission Type"
                                                        className="form-control form-control-md"
                                                        type="select"
                                                        invalid={errors.commissionType}
                                                        value={commissionType}
                                                        onChange={(e) => {
                                                            setCommissionType(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    >
                                                        <option value="">Commission Type</option>
                                                        <option value="flat">Flat Fee</option>
                                                        <option value="percentage">Percentage </option>
                                                    </Input>
                                                    <ValidationErrorHandling error={errors.commissionType} />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Commission Value</Label>
                                                    <Input
                                                        id="commissionValue"
                                                        name="commissionValue"
                                                        placeholder="Commission Value"
                                                        invalid={errors.commissionValue}
                                                        type="number"
                                                        min={0}
                                                        value={commissionValue}
                                                        onChange={(e) => {
                                                            setCommissionValue(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    />
                                                    <ValidationErrorHandling error={errors.commissionValue} />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label for="">Type</Label>
                                                    <Input
                                                        id="type"
                                                        name="type"
                                                        placeholder="Type"
                                                        invalid={errors.type}
                                                        className="form-control form-control-md"
                                                        type="select"
                                                        value={type}
                                                        onChange={(e) => {
                                                            setType(e.target.value);
                                                        }}
                                                        autoComplete="off"
                                                    >
                                                        <option value="">Type</option>
                                                        <option value="affiliate">Affiliate</option>
                                                        <option value="partner">Partner </option>
                                                    </Input>
                                                    <ValidationErrorHandling error={errors.type} />
                                                </FormGroup>
                                            </Col>

                                            <Col lg={12}>
                                                <FormGroup>
                                                    <Label for=''>Please add any additional information so we can understand how you will be promoting the affiliation/partnership with LeadsImport and how we can support your efforts</Label>
                                                    <Input
                                                        id="additionalInformation"
                                                        name="additionalInformation"
                                                        type='textarea'
                                                        rows="4"
                                                        value={additionalInformation}
                                                        onChange={(e) => {
                                                            setAdditionalInformation(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>
                                            </Col>
                                            {!affiliateDetail &&
                                                <Col lg={12}>
                                                    <FormGroup check>
                                                        <Input
                                                            id="sendStripeOnBoardLink"
                                                            name="sendStripeOnBoardLink"
                                                            type="checkbox"
                                                            checked={sendStripeOnBoardLink}
                                                            onChange={(e) => {
                                                                setSendStripeOnBoardLink(e.target.checked);
                                                                // console.log(sendStripeOnBoardLink)
                                                            }}
                                                        />
                                                        {" "}
                                                        <Label check for="sendStripeOnBoardLink">Do you want to send Stripe onboarding link to affiliate/partner?</Label>
                                                    </FormGroup>
                                                </Col>
                                            }

                                        </Row>
                                    </div>

                                    <CardFooter>
                                        <FormGroup>
                                            <SubmitButton
                                                type="submit"
                                                color="primary"
                                                cancleButtonOnClick={() => {
                                                    setShowForm(false);
                                                    clearForm();
                                                }}
                                                onClick={handleForm}
                                                isLoading={loading}
                                                title="Save"
                                                icon={faSave}
                                            />
                                            {/* <Button
                                            type="submit"
                                            color="primary"
                                            onClick={handleForm}
                                            disabled={loading}
                                        >
                                             <FontAwesomeIcon icon={faSave} className="mr-2" />
                                            Save Changes
                                            {loading && (
                                                <Spinner
                                                    className="ml-2"
                                                    color="light"
                                                    size="sm"
                                                    children=""
                                                />
                                            )}
                                        </Button>
                                        <Button
                                            color="secondary"
                                            className="ml-2"
                                            onClick={() => {
                                                setShowForm(false);
                                            }}
                                        >
                                            <FontAwesomeIcon icon={faTimes} className="mr-2" />
                                            Cancel
                                        </Button> */}

                                        </FormGroup>
                                    </CardFooter>
                                </Form>
                            </CardText>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Fade>
    )
}

export default AffiliateForm