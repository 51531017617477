import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faFileImport,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  Fade,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { NavLink } from "react-router-dom";
import useGet from "../services/useGet";
import usePost from "../services/usePost";
import { capitalizeFirstLetter } from "../utils/functions";
import GenericTabs from "./common/tab";
import SubmitButton from "./common/submitButton";
import Header from "./common/header";

const LeadsImportCsv = () => {
  const [destinationId, setDestinationId] = useState([]);
  const [csvFile, setCsvFile] = useState(null);
  const [autoResponder, setAutoResponder] = useState(null);
  const [autoresponderId, setAutoResponderId] = useState("");

  const {
    callApi: getDestinations,
    data: destinations,
    isLoading: getDestinationsLoader,
  } = useGet({
    endpoint: `integration`,
  });

  const { callApi: leadsImportViaCsv, isLoading } = usePost({
    endpoint: `leadimportcsv`,
  });
  const { callApi: getAutoResponders, data: autoresponders } = useGet({
    endpoint: `autoresponder`,
  });

  useEffect(() => {
    getDestinations();
    getAutoResponders();
  }, []);

  const showAutoResponder = (autoresponderId) => {
    setAutoResponderId(autoresponderId);
    if (autoresponderId)
      autoresponders.map((auto) => {
        auto?._id === autoresponderId && setAutoResponder(auto?.message);
      });
    else setAutoResponder(null);
  };

  const handleDestinations = (e) => {
    if (e.target.checked) setDestinationId([...destinationId, e.target.id]);
    else {
      const temparr = destinationId.filter((dec) => dec !== e.target.id);
      setDestinationId(temparr);
    }
  };

  const handleCSVfile = async (e) => {
    e.preventDefault();
    setCsvFile(e.target.files[0]);
  };

  const handleSubmit = () => {
    if (csvFile) {
      const formData = new FormData();
      formData.append("destinations", [destinationId]);
      formData.append("file", csvFile);
      formData.append("autoresponderId", autoresponderId);
      leadsImportViaCsv(formData);
    }
  };

  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <Header icon={faFileImport} title="Leads Import Via CSV" />
          <Form onSubmit={handleCSVfile}>
            <GenericTabs
              tabs={[
                {
                  tabId: "1",
                  title: "Upload CSV File",
                  icon: "images/tabs/uploadcsv.png",
                  className: "",
                  component: (
                    <div className="p-3 my-3 border rounded shadow">
                      <FormGroup>
                        <Row>
                          <Col sm={12} lg={6}>
                            <Input
                              style={{ padding: "0px", height: "auto" }}
                              type="file"
                              accept=".csv"
                              id="csv"
                              name="csv"
                              onChange={(e) => handleCSVfile(e)}
                            />
                          </Col>
                        </Row>
                      </FormGroup>

                      <NavLink to="files/leads-sample.csv" download="" target="_blank">
                        <FontAwesomeIcon icon={faFileDownload} className="mr-2" size="sm" />
                        Download sample CSV file
                      </NavLink>
                    </div>
                  ),
                },
                {
                  tabId: "2",
                  title: "Choose Autoresponder",
                  icon: "images/tabs/autoresponse.png",
                  className: "",
                  component: (
                    <div className="p-3 my-3 border rounded shadow">
                      <FormGroup>
                        <Row>
                          <Col sm={12} lg={6}>
                            <Input
                              id="exampleSelect"
                              name="select"
                              type="select"
                              className="w-100 p-2"
                              onChange={(e) => showAutoResponder(e.target.value)}
                            >
                              <option className="w-100" value="">
                                Select One
                              </option>
                              {autoresponders &&
                                autoresponders.map((auto) => {
                                  return (
                                    <option className="w-100" value={auto?._id}>
                                      {auto?.name}
                                    </option>
                                  );
                                })}
                            </Input>
                          </Col>
                        </Row>
                        {autoResponder && (
                          <Card className="my-3">
                            <CardBody>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: autoResponder,
                                }}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </FormGroup>
                    </div>
                  ),
                },
                {
                  tabId: "3",
                  title: "Choose Destination(s)",
                  icon: "images/tabs/destination.png",
                  className: "",
                  component: (
                    <div className="p-3 my-3 border rounded shadow">
                      {/* <FormGroup>
                        {getDestinationsLoader && (
                          <Spinner className="ml-2" size="sm" children="" />
                        )}
                        {destinations &&
                          destinations.map((des, idx) => {
                            return (
                              <FormGroup check>
                                <Input
                                  type="checkbox"
                                  id={des.integrationCompany}
                                  onChange={handleDestinations}
                                  tabIndex={6}
                                />
                                <Label check for={des.integrationCompany}>
                                  {capitalizeFirstLetter(
                                    des.integrationCompany
                                  )}
                                </Label>
                              </FormGroup>
                            );
                          })}
                      </FormGroup> */}

                      <FormGroup>
                        {getDestinationsLoader && <Spinner className="ml-2" size="sm" />}
                        <Row>
                          <Col sm={6}>
                            {destinations &&
                              destinations.slice(0, Math.ceil(destinations.length / 2)).map((des, idx) => (
                                <FormGroup check key={des.integrationCompany} className="mt-3">
                                  <Input
                                    type="checkbox"
                                    id={des.integrationCompany}
                                    onChange={handleDestinations}
                                    tabIndex={6}
                                  />
                                  <Label check for={des.integrationCompany}>
                                    {capitalizeFirstLetter(des.integrationCompany)}
                                  </Label>
                                </FormGroup>
                              ))}
                          </Col>
                          <Col sm={6}>
                            {destinations &&
                              destinations.slice(Math.ceil(destinations.length / 2)).map((des, idx) => (
                                <FormGroup check key={des.integrationCompany} className="mt-3">
                                  <Input
                                    type="checkbox"
                                    id={des.integrationCompany}
                                    onChange={handleDestinations}
                                    tabIndex={6}
                                  />
                                  <Label check for={des.integrationCompany}>
                                    {capitalizeFirstLetter(des.integrationCompany)}
                                  </Label>
                                </FormGroup>
                              ))}
                          </Col>
                        </Row>
                      </FormGroup>
                    </div>
                  ),
                },
              ]}
            />

            <CardFooter>
              <SubmitButton
                icon={faFileImport}
                onClick={handleSubmit}
                title="Start Import"
                isLoading={isLoading}
                cancleButton={false}
              />
            </CardFooter>
          </Form>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default LeadsImportCsv;
