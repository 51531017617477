import { ACTION_LOGIN } from "../actions";


const initialState = {
  user: {},
  redirectPath: '/dashboard'
};
const LoginReducer = (state = initialState, action) => {
  if (action.type === ACTION_LOGIN) {
    return {
      ...state,
      user: action.payload,
    };
  }
  
  return state;
};
export default LoginReducer;