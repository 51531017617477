import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import usePost from "../services/usePost";
import { showUserNotification } from "../utils/functions";

const VerifyAccount = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const email = queryParams.get("email");
  const code = queryParams.get("code");
  const dispatch = useDispatch();
  const history = useHistory();

  const { callApi: verifyAccount, processSuccess: verifySuccess } = usePost({
    endpoint: `user/verifyAccount`,
  });

  useEffect(() => {
    const postData = {
      email,
      code,
    };

    verifyAccount(postData, "Account verified");
  }, []);

  useEffect(() => {
    if (verifySuccess) {
      dispatch(showUserNotification("Account verified successfully"));
      setTimeout(() => {
        history.push("/");
      }, 2000);
    } else {
      dispatch(
        showUserNotification(
          "Account verification failed! Please try again",
          "danger"
        )
      );
    }
  }, [verifySuccess]);

  return <></>;
};

export default VerifyAccount;
