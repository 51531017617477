import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Row,
  Col,
  Button,
  ButtonGroup,
} from "reactstrap";
import { useKey } from '../../../services/useKey';
import { formatDateTime } from '../../../utils/functions';
import { MSG_LEADS_VIA_CSV } from '../../../utils/message';
import Popup from '../../common/popup';
import TableRow from '../../common/tableRow';

const LeadViaCSVDetail = ({ showDetail, setShowDetail, leadDetail, setDeleteId, deleteId, deleteLeadImportedCsv, removeSuccess }) => {
  const [toggleModal, setToggleModal] = useState(false);

  useKey(() => {
    setShowDetail(false);
  }, "Escape")

  const removeLeadsImportedCSV = async () => {
    deleteLeadImportedCsv({
      id: deleteId
    }, MSG_LEADS_VIA_CSV.DELETE);

  };

  useEffect(() => {
    if (removeSuccess) {
      setShowDetail(false);
      setToggleModal(false)
    }
  }, [removeSuccess])


  return (
    <>
      <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
        <CardBody>
          <CardTitle tag="h4">
            <Row className='justify-content-between align-items-center flex-nowrap'>
              <Col md={"auto"} xs={"auto"}>
                <div className='d-flex'>
                  <FontAwesomeIcon icon={faInfo} className="mr-2" />
                  <h4>Lead Detail</h4>
                </div>
              </Col>
              <Col md={"auto"} xs={"auto"}>
                <ButtonGroup>
                  <Button
                    onClick={() => {
                      setDeleteId(leadDetail?._id);
                      setToggleModal(true);
                    }}
                    color="danger"
                    className='mr-2'
                    size='sm'
                  >
                    <FontAwesomeIcon icon={faTrash} size='sm' />
                  </Button>
                  <Button
                    onClick={() => setShowDetail(false)}
                    color="primary"
                    size='sm'
                  >
                    <FontAwesomeIcon icon={faWindowClose} size='sm' />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardTitle>

          <CardText>
            <Card color="shadow mb-3">
              <CardBody>
                <CardTitle tag="h5">
                  <Row>
                    <Col sm={12}>
                      Basic Information
                    </Col>
                  </Row>
                </CardTitle>
                <CardText>
                  <Table responsive size="sm">
                    {leadDetail?.photo && (
                      <TableRow
                        colSpanTH={2}
                        label={
                          <img src={leadDetail.photo} alt="Lead" width={100} />
                        }
                      />
                    )}
                    <TableRow label="Name"
                      value={
                        leadDetail.fullName
                          ?
                          leadDetail.fullName
                          :
                          `${leadDetail.firstName} ${leadDetail.lastName}`
                      }
                    />
                    <TableRow label="Email" value={leadDetail?.email} />
                    <TableRow label="Phone" value={leadDetail?.phone} />
                    <TableRow label="Imported At" value={formatDateTime(leadDetail?.dateAsPerUserTimezone)} />                   
                  </Table>
                </CardText>
              </CardBody>
            </Card>
            <Card color="shadow">
              <CardBody>
                <CardTitle tag="h5">
                  <Row>
                    <Col sm={12}>
                      Event Information
                    </Col>
                  </Row>
                </CardTitle>
                <CardText>
                  <Table responsive size="sm">
                  <TableRow label="Event Name" value={leadDetail?.eventName} />
                  <TableRow label="Event Type" value={leadDetail?.eventType} />
                  <TableRow label="Event Date" value={leadDetail?.eventDate} />
                  <TableRow label="Guest Count" value={leadDetail?.guestCount} />
                  <TableRow label="Company" value={leadDetail?.company} />
                  <TableRow label="Imported At" value={formatDateTime(leadDetail?.createdAt)} />
                  <TableRow label="Notes/Inquiry" value={leadDetail?.inquiry} />                    
                  </Table>
                </CardText>
              </CardBody>
            </Card>



          </CardText>
        </CardBody>
      </Card>
      {toggleModal && <Popup showPopup={toggleModal} hidePopup={() => setToggleModal(false)} actionMethod={removeLeadsImportedCSV} />}
    </>
  )
}

export default LeadViaCSVDetail