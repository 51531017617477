import { Card, CardBody, CardTitle, CardSubtitle, CardText, Table, Row, Col, Button, Fade } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter, formatLeadSource } from "../../../utils/functions";
import { useKey } from "../../../services/useKey";
import TableRow from "../../common/tableRow";

const ScrappingErrorDetail = ({ errorDetail, showDetail, setshowDetail }) => {

    useKey(() => {
        setshowDetail(false)
    }, "Escape")

    return (
        <Fade>
            <Card color="shadow" className={`${showDetail ? 'd-block' : 'd-none'}`}>
                <CardBody>
                    <CardTitle tag="h4">
                        <Row className="justify-content-between">
                            <Col md={"auto"} xs={"auto"}>
                                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                                Error Detail
                            </Col>

                            <Col md={"auto"} xs={"auto"}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setshowDetail(false);
                                    }}
                                    size="sm"
                                >
                                    <FontAwesomeIcon
                                        icon={faWindowClose}
                                        size="sm"
                                    />
                                </Button>
                            </Col>
                        </Row>
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                    <CardText>
                        <Table responsive striped bordered hover size="sm">
                            <TableRow label="Lead Source" value={formatLeadSource(capitalizeFirstLetter(errorDetail?.leadSource))} />
                            <TableRow label="Error" value={errorDetail?.errorLog} />

                            {/* <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Lead Source</th>
                            <td>{formatLeadSource(capitalizeFirstLetter(errorDetail?.leadSource))}</td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Error</th>
                            <td>{errorDetail?.errorLog}</td>
                        </tr> */}
                        </Table>
                    </CardText>
                </CardBody>
            </Card>
        </Fade>
    );
};

export default ScrappingErrorDetail;