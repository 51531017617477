import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_GOHIGHLEVEL = process.env.REACT_APP_INTEGRATION_GOHIGHLEVEL;

const validationSchema = yupValidationSchema({
  goHighLevelApiKey: { required: true, customName: "Api key" },
});

const GoHighLevelForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [goHighLevelApiKey, setGoHighLevelApiKey] = useState("");
  const [sendLeadsLimitGoHighLevel, setSendLeadsLimitGoHighLevel] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_GOHIGHLEVEL) {
          setGoHighLevelApiKey(secretKey);
          setSendLeadsLimitGoHighLevel(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleGoHighLevelFormForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { goHighLevelApiKey },
        { abortEarly: false }
      );
      setLoading(true);
      await saveIntegration(
        {
          secretKey: goHighLevelApiKey,
          sendLeadsLimit: sendLeadsLimitGoHighLevel,
          integrationCompany: DESTINATION_GOHIGHLEVEL,
        },
        MSG_DESTINATION.SAVE_GOHIGHLEVEL
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader
      headerTitle="GoHighLevel"
      headerImage="images/gohighlevel.png"
    >
      <Form onSubmit={handleGoHighLevelFormForm} autoComplete="off">
        <FormGroup>
          <FormText color="info">
            <strong> NOTE:</strong>You can get your API key from Setting-&gt;
            Bussiness Profile
          </FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="Get API key from GoHighLevel Application"
                autocomplete="off"
                type="text"
                defaultValue={goHighLevelApiKey}
                value={goHighLevelApiKey}
                onChange={(e) => setGoHighLevelApiKey(e.target.value)}
                invalid={errors.goHighLevelApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.goHighLevelApiKey} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitGoHighLevel}
                onChange={(e) => setSendLeadsLimitGoHighLevel(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleGoHighLevelFormForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default GoHighLevelForm;
