import { useEffect,useState } from "react";
import { Input, Row, Col } from "reactstrap";
import SOURCES from "../../utils/constants";


const PaginateSearch = ({ setPaginationSearch, totalRecords }) => {
  const [currentPage, setCurrentpage] = useState(1)
  const [perPage, setPerPage] = useState(SOURCES.DEFAULT_RECORDS_SEARCH)
  const [offset, setOffset] = useState(0)
  const [pagesLength, setPagesLength] = useState([]);

  useEffect(() => {
    setOffset((parseInt(currentPage) * parseInt(perPage)) - parseInt(perPage));    
  }, [currentPage]);

  useEffect(() => {
    setCurrentpage(1);
    const temp = [];
    const pageCount = Math.ceil(totalRecords / perPage);
    for (let i = 1; i <= pageCount; i++) {
      temp.push(i);
      setPagesLength(temp);
    }
  }, [perPage]);

  useEffect(() => {
    setPaginationSearch({offsetSearch:offset,limitSearch:parseInt(perPage)});
  }, [offset]);

  useEffect(() => {
    setPaginationSearch({offsetSearch:offset,limitSearch:parseInt(perPage)});
  }, [pagesLength])

  return (
    <>
      <Row className="d-flex justify-content-center">
        <Col lg={2} md={4} sm={5} xs={6}>
        <Input
          id="paginate"
          name="select"
          type="select"
          className="w-100 p-2 mr-2"
          onChange={(e) => setCurrentpage(e.target.value)}
        >
          {pagesLength && pagesLength.map((opt) => {
            return <option value={opt} selected={currentPage===opt}>Page {opt}</option>;
          })}
        </Input>
        </Col>
        {/* <Label for="perpage">Per Page</Label> */}
        <Col lg={2} md={4} sm={5} xs={6}>
        <Input
          id="perpage"
          name="select"
          type="select"
          className="w-100 p-2 "
          onChange={(e) => {
            setPerPage(e.target.value);
          }}
        >
          <option value="100" selected={perPage === "100"}>100</option>
          <option value="200" selected={perPage === "200"}>200</option>
          <option value="300" selected={perPage === "300"}>300</option>
          <option value="400" selected={perPage === "400"}>400</option>
          <option value="500" selected={perPage === "500"}>500</option>

        </Input>
        </Col>
      </Row>
    </>
  );
};
export default PaginateSearch;