import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faCopy,
  faSignOutAlt,
  faUser,
  faBell,
  faMapMarker,
  faCogs,
  faCalculator,
  faPhone,
  faCoins,
  faBug,
  faFileImport,
  faExchangeAlt,
  faTimes,
  faEnvelope,
  faHistory,
  faReply,
  faLink,
  faBroadcastTower,
  faWrench,
  faUsers,
  faDatabase
} from "@fortawesome/free-solid-svg-icons";
import { NavItem, NavLink, Nav } from "reactstrap";
import classNames from "classnames";
import LocalStorageService from "../utils/localStorageServices";
import "./sidebar.css";
import { Link, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";
import SOURCES from "../utils/constants";

const localStorageServices = LocalStorageService.getService();

const SideBar = ({ isOpen, toggle }) => {
  const location = useLocation();
  const currentUser = localStorageServices.getCurrentUser();
  const userRole = currentUser.user?.userRole ? currentUser.user?.userRole : currentUser.user?.userType;

  const logOut = (e) => {
    e.preventDefault();
    localStorageServices.clearToken();
    window.location.reload();
  };

  const linkHighlited = (path) => {
    return location.pathname === path ? "text-primary" : ""
  }

  return (
    <div className={classNames("sidebar", { "is-open": isOpen })}>
      <div className="sidebar-header">
        <span color="info" onClick={toggle}>
          &times;
        </span>
        <h3>
          <NavLink tag={Link} to={userRole === SOURCES.USER_ROLE_AFFILIATE ? "/dashboard-affiliate" : "/dashboard"} >
            <img src="/logo.png" alt="Leads Import" width="120px" />
          </NavLink>
        </h3>
      </div>
      <div className="side-menu">
        <Nav vertical className="list-unstyled pb-3">
          <NavItem>
            {userRole !== SOURCES.USER_ROLE_AFFILIATE ?
              <NavLink tag={Link} to={"/dashboard"} onClick={toggle} className={linkHighlited("/dashboard")}>
                <FontAwesomeIcon icon={faHome} className="mr-2" />
                Dashboard
              </NavLink>
              :
              <>
                <NavLink tag={Link} to={"/dashboard-affiliate"} onClick={toggle} className={linkHighlited("/dashboard-affiliate")}>
                  <FontAwesomeIcon icon={faHome} className="mr-2" />
                  Dashboard
                </NavLink>
                <SubMenu
                  title="Reports"
                  icon={faCopy}
                  items={submenus[2]}
                  toggle={toggle}
                />
              </>
            }
          </NavItem>
          {userRole !== SOURCES.USER_ROLE_ADMIN && userRole !== SOURCES.USER_ROLE_AFFILIATE ?
            <>
              <NavItem>
                <NavLink tag={Link} to={"/autoresponders"} onClick={toggle} className={linkHighlited("/autoresponders")}>
                  <FontAwesomeIcon icon={faReply} className="mr-2" />
                  Autoresponders
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/alerts"} onClick={toggle} className={linkHighlited("/alerts")}>
                  <FontAwesomeIcon icon={faBell} className="mr-2" />
                  Alerts
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/zapierConfigration"} onClick={toggle} className={linkHighlited("/zapierConfigration")}>
                  <FontAwesomeIcon icon={faWrench} className="mr-2" />
                  Zapier Configration
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/destinations"} onClick={toggle} className={linkHighlited("/destinations")}>
                  <FontAwesomeIcon icon={faMapMarker} className="mr-2" />
                  Destinations
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/leadsources"} onClick={toggle} className={linkHighlited("/leadsources")}>
                  <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                  Lead Import Jobs
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/leadsImport-Csv"} onClick={toggle} className={linkHighlited("/leadsImport-Csv")}>
                  <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                  Lead Import (CSV)
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/webLink"} onClick={toggle} className={linkHighlited("/webLink")}>
                  <FontAwesomeIcon icon={faLink} className="mr-2" />
                  Contact Request Forms
                </NavLink>
              </NavItem>

              <SubMenu
                title="Reports"
                icon={faCopy}
                items={submenus[0]}
                toggle={toggle}
              />
              <SubMenu
                title="Accounting"
                icon={faCalculator}
                items={submenus[1]}
                toggle={toggle}
              />

              <NavItem>
                <NavLink tag={Link} to={"/profile"} onClick={toggle} className={linkHighlited("/profile")}>
                  <FontAwesomeIcon icon={faUser} className="mr-2" />
                  Profile
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/settings"} onClick={toggle} className={linkHighlited("/settings")}>
                  <FontAwesomeIcon icon={faCogs} className="mr-2" />
                  Settings
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} to={"/cancel-subscription"} onClick={toggle} className={linkHighlited("/cancel-subscription")}>
                  <FontAwesomeIcon icon={faTimes} className="mr-2" />
                  Cancel Subscription
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink tag={Link} onClick={() => window.location = 'mailto:support@leadsimport.com'} className={linkHighlited("")}>
                  <FontAwesomeIcon icon={faPhone} className="mr-2" />
                  Help/Support
                </NavLink>
              </NavItem>
            </>
            :
            <>
              {userRole === SOURCES.USER_ROLE_AFFILIATE ? <div></div> :
                <>
                  <NavItem>
                    <NavLink tag={Link} to={"/admin/users"} onClick={toggle} className={linkHighlited("/admin/users")}>
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                      Users
                    </NavLink>
                  </NavItem>
                  {/* my code */}
                  {/* <NavItem>
                    <NavLink tag={Link} to={"/admin/testaccounts"} onClick={toggle} className={linkHighlited("/admin/testaccounts")}>
                      <FontAwesomeIcon icon={faUser} className="mr-2" />
                      Test Accounts
                    </NavLink>
                  </NavItem> */}
                  {/* my code */}

                  {/* <NavItem>
                    <NavLink tag={Link} to={"/admin/leadsImported"} onClick={toggle} className={linkHighlited("/admin/leadsImported")}>
                      <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                      Leads Imported
                    </NavLink>
                  </NavItem> */}

                  <NavItem>
                    <NavLink tag={Link} to={"/admin/coupons"} onClick={toggle} className={linkHighlited("/admin/coupons")}>
                      <FontAwesomeIcon icon={faCoins} className="mr-2" />
                      Coupons
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink tag={Link} to={"/admin/affiliate"} onClick={toggle} className={linkHighlited("/admin/affiliate")}>
                      <FontAwesomeIcon icon={faBroadcastTower} className="mr-2" />
                      Affiliates / Partners
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink tag={Link} to={"/admin/zapierAccounts"} onClick={toggle} className={linkHighlited("/admin/zapierAccounts")}>
                      <FontAwesomeIcon icon={faUsers} className="mr-2" />
                      Zapier Accounts
                    </NavLink>
                  </NavItem>

                  <SubMenu
                    title="Reports"
                    icon={faCopy}
                    items={submenus[3]}
                    toggle={toggle}
                  />

                  {/* <NavItem>
                    <NavLink tag={Link} to={"/admin/scrappingerrors"} onClick={toggle} className={linkHighlited("/admin/scrappingerrors")}>
                      <FontAwesomeIcon icon={faBug} className="mr-3" />
                      Scrapping Errors
                    </NavLink>
                  </NavItem> */}

                  {/* <NavItem>
                    <NavLink tag={Link} to={"/admin/emaillogs"} onClick={toggle} className={linkHighlited("/admin/emaillogs")}>
                      <FontAwesomeIcon icon={faEnvelope} className="mr-3" />
                      Email Logs
                    </NavLink>
                  </NavItem> */}

                  {/* <NavItem>
                    <NavLink tag={Link} to={"/admin/paymenthistory"} onClick={toggle} className={linkHighlited("/admin/paymenthistory")}>
                      <FontAwesomeIcon icon={faHistory} className="mr-3" />
                      Payment History
                    </NavLink>
                  </NavItem> */}

                  {/* <NavItem>
                    <NavLink tag={Link} to={"/admin/cancelled/subscriptions"} onClick={toggle} className={linkHighlited("/admin/cancelled/subscriptions")}>
                      <FontAwesomeIcon icon={faTimes} className="mr-3" />
                      Cancelled Subscriptions
                    </NavLink>
                  </NavItem> */}

                  <NavItem>
                    <NavLink tag={Link} to={"/admin/changeuseremail"} onClick={toggle} className={linkHighlited("/admin/changeuseremail")}>
                      <FontAwesomeIcon icon={faExchangeAlt} className="mr-3" />
                      Change User Email
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink tag={Link} to={"/admin/dataBaseBackup"} onClick={toggle} className={linkHighlited("/admin/dataBaseBackup")}>
                      <FontAwesomeIcon icon={faDatabase} className="mr-2" />
                      Database Backup
                    </NavLink>
                  </NavItem>
                </>
              }
            </>
          }
          <NavItem>
            <NavLink tag={Link} onClick={logOut}>
              <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
              Sign Out
            </NavLink>
          </NavItem>
        </Nav>
      </div>
    </div>
  );
};

const submenus = [
  [
    {
      title: "Leads Imported",
      target: "leadsImported",
    },
    {
      title: "Hot Leads",
      target: "hotLeads",
    },
    {
      title: "Leads Imported Via (CSV)",
      target: "leadsImportedViaCsv",
    },
    {
      title: "Leads Imported Via (Form)",
      target: "leadsImportedViaForm",
    },
    {
      title: "Leads Sent",
      target: "leadsSent",
    },
    {
      title: "Notifications",
      target: "notifications",
    },
    {
      title: "Autoresponse Sent",
      target: "autoresponseSent",
    },
    {
      title: "Activity Log",
      target: "activityLog",
    },
    {
      title: "New Lead Alert",
      target: "newLeadAlert",
    },
  ],

  [
    {
      title: "Payment History",
      target: "paymentHistory",
    },
    {
      title: "Invoices",
      target: "invoices",
    },
  ],

  [
    {
      title: "Customers",
      target: "customers",
    },
  ],

  [
    {
      title: "Leads Imported",
      target: "/admin/leadsImported",
    },
    {
      title: "Leads Not Sent",
      target: "/admin/leads-not-send",
    },
    {
      title: "Scrapping Errors",
      target: "/admin/scrappingerrors",
    },
    {
      title: "Email Logs",
      target: "/admin/emaillogs",
    },
    {
      title: "Payment Customerwise",
      target: "/admin/paymenthistory",
    },
    {
      title: "Payment Monthwise",
      target: "/admin/incomereport",
    },
    {
      title: "Cancelled Subscriptions",
      target: "/admin/cancelled/subscriptions",
    },
  ],
];



export default SideBar;
