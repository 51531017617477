import { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_BOOTHBOOK = process.env.REACT_APP_INTEGRATION_BOOTHBOOK;

const validationSchema = yupValidationSchema({
  boothBookApiKey: { required: true, customName: "API key" },
  boothBookApiSecret: { required: true, customName: "API secret" },
  boothBookUrl: { required: true, customName: "Boothbook url" },
});

const BoothbookForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [boothBookApiKey, setBoothBookApiKey] = useState("");
  const [boothBookApiSecret, setBoothBookApiSecret] = useState("");
  const [boothBookUrl, setBoothBookUrl] = useState("");
  const [sendLeadsLimitBoothBook, setSendLeadsLimitBoothBook] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_BOOTHBOOK) {
          setBoothBookApiKey(dest.publicKey);
          setBoothBookApiSecret(dest.secretKey);
          setSendLeadsLimitBoothBook(leadSentConfig?.sentLimit);

          // setAgents(dest?.agentProvider);
          if (dest?.extraInfo) {
            setBoothBookUrl(dest?.extraInfo?.boothBookUrl);
          }
        }
      }
    }
  }, [destinations]);

  const handleBoothBookForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          boothBookApiKey,
          boothBookApiSecret,
          boothBookUrl,
          sendLeadsLimitBoothBook,
        },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      await saveIntegration(
        {
          publicKey: boothBookApiKey,
          secretKey: boothBookApiSecret,
          destinationAccountEmail: null,
          destinationAccountPassword: null,
          sendLeadsLimit: sendLeadsLimitBoothBook,
          integrationCompany: DESTINATION_BOOTHBOOK,
          extraInfo: { boothBookUrl },
          agentProvider: null,
          locations: [],
        },
        MSG_DESTINATION.SAVE_BOOTHBOOK
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader
      headerTitle="BoothBook"
      headerImage="images/boothbook.png"
    >
      <Form onSubmit={handleBoothBookForm} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="boothbookurl">BoothBook URL </Label>
              <Input
                id="boothbookurl"
                name="boothbookurl"
                placeholder="Get BoothBook Url from configuration page"
                autocomplete="off"
                type="text"
                defaultValue={boothBookUrl}
                value={boothBookUrl}
                onChange={(e) => setBoothBookUrl(e.target.value)}
                invalid={errors.boothBookUrl}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.boothBookUrl} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="Api_key">Api Key</Label>
              <Input
                id="Api_key"
                name="Api_key"
                placeholder="Get Api key from BoothBook Configuration page"
                autocomplete="off"
                type="text"
                defaultValue={boothBookApiKey}
                value={boothBookApiKey}
                onChange={(e) => setBoothBookApiKey(e.target.value)}
                invalid={errors.boothBookApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.boothBookApiKey} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Secret</Label>
              <Input
                id="api_secret"
                name="api_secret"
                placeholder="Get API secret from BoothBook Configuration page"
                autocomplete="off"
                type="text"
                defaultValue={boothBookApiSecret}
                value={boothBookApiSecret}
                onChange={(e) => setBoothBookApiSecret(e.target.value)}
                invalid={errors.boothBookApiSecret}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.boothBookApiSecret} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitBoothBook}
                onChange={(e) => setSendLeadsLimitBoothBook(e.target.value)}
                invalid={errors.sendLeadsLimitBoothBook}
                autoComplete="off"
                autoFocus
              />
              <ValidationErrorHandling error={errors.sendLeadsLimitBoothBook} />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleBoothBookForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default BoothbookForm;
