import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Collapse,
  Row,
  Col,
  Button,
  Fade
} from "reactstrap";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import Paginate from "../common/pagination";
import AdvanceSearch from "../common/advanceSearch"
import PaginateSearch from "../common/paginationSearch";
import LeadDetail from "./modules/leadDetail";
import LeadList from "./modules/leadList";
import usePost from "../../services/usePost";
import HeaderSearchInput from "../common/headerSearchInput";

const LeadsImported = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });
  const [paginationSearch, setPaginationSearch] = useState({
    offsetSearch: 0,
    limitSearch: SOURCES.DEFAULT_RECORDS_SEARCH
  });

  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)
  const [leadsImported, setLeadsImported] = useState();
  const [leadDetail, setLeadDetail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [advanceSearch, setAdvanceSearch] = useState({
    advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    startDate: "",
    endDate: "",
    leadSource: "",
    searchTerm: "",
  });
  const [totalRecords, setTotalRecords] = useState(0);


  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `leadinfo/search?offset=${paginationSearch.offsetSearch}&limit=${paginationSearch.limitSearch}&q=${searchInput}&advance_search=${advanceSearch.advance_search_enabled}&startdate=${advanceSearch.startDate}&enddate=${advanceSearch.endDate}&leadsource=${advanceSearch.leadSource}&term=${advanceSearch.searchTerm}`,
  });

  const { callApi: getLeadSourcesOptions, data: leadSource } = useGet({
    endpoint: `leadsource/options`,
  });

  const {
    callApi: getLeadsImported,
    data: leadsData,
    isLoading: getLeadsLoading,
  } = useGet({
    endpoint: `leadinfo?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });


  const {
    callApi: saveHotLeads,
    processSuccess
  } = usePost({
    endpoint: `leadinfo/hotlead`,
  });

  const isLoading = searchDataLoading || getLeadsLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const dataExist = leadsImported && leadsImported.results.length > 0 ? true : false;

  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
      else if (!debouncedSearchTerm) getLeadsImported();
    },
    [debouncedSearchTerm]
  );

  useEffect(() => {
    setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
    setShowAdvanceSearch(false);
  }, [searchInput])

  useEffect(() => {
    if ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM].indexOf(advanceSearch.advance_search_enabled) >= 0) {
      getLeadsImported();
      getLeadSourcesOptions();
      if (advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM) setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
    }
  }, [pagination.offset, pagination.limit, sortBy, sortOrder, advanceSearch.advance_search_enabled]);


  useEffect(() => {
    if ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT].indexOf(advanceSearch.advance_search_enabled) >= 0) getSearchData();
  }, [paginationSearch.offsetSearch, paginationSearch.limitSearch]);


  useEffect(() => {
    setLeadsImported(leadsData);
  }, [leadsData]);

  useEffect(() => {
    if (leadsImported && totalRecords <= 0) setTotalRecords(leadsImported?.totalReords);
  }, [leadsImported])

  useEffect(() => {
    setLeadsImported(searchData);
  }, [searchData]);

  const viewDetail = (item) => {
    setLeadDetail(item);
    setShowDetail(true);
  };

  return (
    <Fade>
      <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
        <CardBody>
          <CardTitle>
            <Row>
              <Col xl={9} lg={9} style={{ display: "flex" }} tag={"h4"}>
                <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                Leads Imported
              </Col>
              <Col xl={3} lg={"auto"} md="auto" >
                <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                  <HeaderSearchInput
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <Button
                    color="primary"
                    onClick={() => {
                      setShowAdvanceSearch(true);
                    }}
                    style={{
                      minWidth: "max-content"
                    }}
                  >
                    <FontAwesomeIcon icon={faFilter} className="mr-2" />
                    Advance Search
                  </Button>
                </div>
                <Collapse isOpen={showAdvanceSearch} >
                  <AdvanceSearch
                    searchInput={searchInput}
                    isOpen={showAdvanceSearch}
                    advanceSearch={advanceSearch}
                    setShowAdvanceSearch={setShowAdvanceSearch}
                    setAdvanceSearch={setAdvanceSearch}
                    getSearchData={getSearchData}
                    filteredData={{ key: "LEAD-SOURCES", data: leadSource }}
                  />
                </Collapse>
              </Col>
            </Row>
          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
          </CardSubtitle>
          <CardText>
            <LeadList
              isLoading={isLoading}
              dataExist={dataExist}
              leadsImported={leadsImported}
              viewDetail={viewDetail}
              getLeadsImported={getLeadsImported}
              saveHotLeads={saveHotLeads}
              processSuccess={processSuccess}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
          </CardText>
        </CardBody>
        <div className="px-4">
          {!searchInput && [SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM].indexOf(advanceSearch.advance_search_enabled) >= 0 && totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
            <FormGroup>
              <Paginate
                setPagination={setPagination}
                totalRecords={totalRecords}
                isLoading={isLoading}
              />
            </FormGroup>
          )}

          {
            ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT].indexOf(advanceSearch.advance_search_enabled) >= 0 || searchInput) && totalRecords > SOURCES.DEFAULT_RECORDS_SEARCH &&
            <FormGroup>
              <PaginateSearch
                setPaginationSearch={setPaginationSearch}
                totalRecords={leadsImported?.totalReords}
              />
            </FormGroup>
          }
        </div>
      </Card>
      {
        leadDetail &&
        <Fade>
          <LeadDetail
            leadDetail={leadDetail}
            showDetail={showDetail}
            setShowDetail={setShowDetail}
            saveHotLeads={saveHotLeads}
            processSuccess={processSuccess}
            getLeadsImported={getLeadsImported}
            setLeadDetail={setLeadDetail}
          />
        </Fade>
      }

    </Fade>
  );
};

export default LeadsImported;