import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons"

const TableHeader = ({ headers, sortData, sortBy, sortOrder }) => {
    const sortIcon = (title) => {
        if (sortBy === title) {
            return sortOrder === 1 ? faArrowUp : faArrowDown
        } else return sortOrder === -1 ? faArrowUp : faArrowDown
    }

    return (
        <thead>
            <tr style={{ whiteSpace: "nowrap" }}>
                {headers?.map((hd, index) => {
                    if (hd) {
                        return (
                            <th style={{ verticalAlign: 'inherit', ...(hd?.style && hd.style) }} key={index}>
                                <div className="d-flex justify-content-between align-items-center" >
                                    {hd?.title}
                                    {hd?.showIcon ? (
                                        <FontAwesomeIcon
                                            size="xs"
                                            icon={sortIcon(hd?.title)}
                                            className="mx-2 "
                                            onClick={() => sortData(hd?.title)}
                                            style={{
                                                transition: 'transform 0.8s ease-in-out',
                                                transform: hd?.title === sortBy && sortOrder === 1 ? 'rotate(180deg)' : 'rotate(-180deg)',
                                                cursor: "pointer",
                                                color: hd?.title === sortBy ? "#2dbdf2" : "black"
                                            }}
                                        />
                                    ) : null}
                                </div>
                            </th>
                        )
                    }
                })}
            </tr>
        </thead>
    )
}

export default TableHeader
