import { useEffect, useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
} from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import DestinationHeader from "./common/destinationHeader";
const DESTINATION_17HATS = process.env.REACT_APP_INTEGRATION_17HATS;

const validationSchema = yupValidationSchema({
  hatsUsername: { required: true, customName: "Username/email" },
  hatsPassword: { required: true, customName: "Password" },
});

const SeventeenhatsForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [hatsUsername, setHatsUsername] = useState("");
  const [hatsPassword, setHatsPassword] = useState("");
  const [sendLeadsLimit17Hats, setSendLeadsLimit17Hats] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          integrationCompany,
          destinationAccountEmail,
          destinationAccountPassword,
          leadSentConfig,
        } = dest;
        if (integrationCompany === DESTINATION_17HATS) {
          setHatsUsername(destinationAccountEmail);
          setHatsPassword(destinationAccountPassword);
          setSendLeadsLimit17Hats(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handle17HatsForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { hatsUsername, hatsPassword },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      await saveIntegration(
        {
          destinationAccountEmail: hatsUsername,
          destinationAccountPassword: hatsPassword,
          sendLeadsLimit: sendLeadsLimit17Hats,
          integrationCompany: DESTINATION_17HATS,
          locations: [],
        },
        MSG_DESTINATION.SAVE_17HATS
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    // <div className="rounded border shadow p-3 my-3">
    <DestinationHeader headerTitle="17Hats" headerImage="images/17Hats.png">
      <Form onSubmit={handle17HatsForm} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="username">Email</Label>
              <Input
                id="username"
                name="username"
                placeholder="Username"
                autocomplete="off"
                type="email"
                defaultValue={hatsUsername}
                value={hatsUsername}
                onChange={(e) => setHatsUsername(e.target.value)}
                invalid={errors.hatsUsername}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.hatsUsername} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                placeholder="Password"
                autocomplete="off"
                type="password"
                defaultValue={hatsPassword}
                value={hatsPassword}
                onChange={(e) => setHatsPassword(e.target.value)}
                invalid={errors.hatsPassword}
                autoComplete="new-password"
              />
              <ValidationErrorHandling error={errors.hatsPassword} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimit17Hats}
                onChange={(e) => setSendLeadsLimit17Hats(e.target.value)}
                invalid={errors.sendLeadsLimit17Hats}
                autoComplete="off"
                autoFocus
              />
              <ValidationErrorHandling error={errors.sendLeadsLimit17Hats} />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter handleSubmit={handle17HatsForm} loading={loading} />
      </Form>
    </DestinationHeader>
    // </div>
  );
};

export default SeventeenhatsForm;
