import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../redux/actions";
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import {
  Input,
  Form,
  FormGroup,
  Card,
  CardBody,
  CardText,
  Row,
  Col,
  Fade,
  Label
} from "reactstrap";
import LocalStorageService from "../utils/localStorageServices";
import usePost from "../services/usePost";
import { handleYupValidationErrors, showUserNotification, yupValidationSchema } from "../utils/functions";
import ValidationErrorHandling from "./common/validationErrorHandling";
import SubmitButton from "./common/submitButton";

const localStorageServices = LocalStorageService.getService();

const validationSchema = yupValidationSchema({
  email: { email: true, required: true, customName: "Email" },
  password: { required: true, customName: "Password" },
});

const Login = () => {
  const history = useHistory();
  const currentUser = localStorageServices.getCurrentUser()
  const token = currentUser?.token;

  useEffect(() => {
    if (currentUser === "undefined" || currentUser === null) localStorageServices.clearToken();
  }, [currentUser]);


  if (token && token !== null) {
    history.push('/dashboard')
  }

  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({})

  const { callApi: doLogin, isLoading } = usePost({
    endpoint: `/user/signin`,
  });

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          email,
          password,
        },
        { abortEarly: false }
      );
      const result = await doLogin({
        email,
        password,
      });
      if (result?.token) {
        localStorageServices.setCurrentUser(result);
        dispatch(setCurrentUser(result));
        const redirectTo = history.location?.state?.redirectTo;
        history.push(redirectTo ? redirectTo : "/dashboard");
      }
      setErrors({})
    } catch (err) {
      setErrors(handleYupValidationErrors(err));
      dispatch(showUserNotification(err.response.data.data.error, "danger"));
    }
  };
  const changeFormValues = (e) => {
    if (e.target.name === "email") setEmail(e.target.value);
    else setPassword(e.target.value);
  };
  return (
    <Fade>
      <Row className="m-0 d-flex align-items-center" style={{ minHeight: "100vh" }}>
        <Col lg="4" md="3" className="mb-2"></Col>
        <Col lg="4" md="6" className="mb-2 mx-auto">
          <Card className="light shadow mt-2 p-4">
            <div className="brand-logo text-center mb-2 ">
              <img src="/logo.png" alt="Leads Import" width="110px" />
            </div>
            <CardBody>
              <CardText>
                <Form onSubmit={handleForm}>
                  <FormGroup>
                    <Input
                      className="form-control form-control-lg"
                      id="exampleInputEmail1"
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={changeFormValues}
                      autoComplete="off"
                      autoFocus
                      invalid={errors.email}
                    />
                    <ValidationErrorHandling error={errors.email} />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      className="form-control form-control-lg"
                      id="exampleInputPassword1"
                      name="password"
                      placeholder="Password"
                      type="password"
                      value={password}
                      onChange={changeFormValues}
                      autoComplete="off"
                      autoFocus
                      invalid={errors.password}
                    />
                    <ValidationErrorHandling error={errors.password} />
                  </FormGroup>
                  <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
                    <FormGroup check className="mb-2 mb-md-0">
                      <Label check>
                        <Input type="checkbox" name="rememberMe" onChange={changeFormValues} /> Remember Me
                      </Label>
                    </FormGroup>
                    <Link to="/forgotpassword" className="auth-link text-primary mt-2 mt-md-0">
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="mt-3">
                    <SubmitButton
                      type="submit"
                      buttonClassName="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={handleForm}
                      isLoading={isLoading}
                      title="Sign In"
                      icon={faSignInAlt}
                      cancleButton={false}
                    />
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Don't have an account?{" "}
                    <Link to="/signup" className="text-primary">
                      Sign Up Now
                    </Link>
                  </div>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4" md="3" className="mb-2"></Col>
      </Row>
    </Fade>

    // <Fade>
    //   <Row className="m-0">
    //     <Col lg="4" md="3" className="mb-2"></Col>
    //     <Col lg="4" md="6" className="mb-2 m-4">
    //       <div className="brand-logo text-center mb-4">
    //         <img src="/logo.png" alt="Leads Import" width="110px" />
    //       </div>
    //       <Card className="light shadow mt-2 p-4">
    //         <CardBody>
    //           <CardText>
    //             <Form onSubmit={handleForm}>
    //               <FormGroup>
    //                 <Input
    //                   className="form-control form-control-lg"
    //                   id="exampleInputEmail1"
    //                   name="email"
    //                   placeholder="Email"
    //                   type="email"
    //                   value={email}
    //                   onChange={changeFormValues}
    //                   autoComplete="off"
    //                   autoFocus
    //                   invalid={errors.email}
    //                 />
    //                 <ValidationErrorHandling error={errors.email} />
    //               </FormGroup>
    //               <FormGroup>
    //                 <Input
    //                   className="form-control form-control-lg"
    //                   id="exampleInputPassword1"
    //                   name="password"
    //                   placeholder="Password"
    //                   type="password"
    //                   value={password}
    //                   onChange={changeFormValues}
    //                   autoComplete="off"
    //                   autoFocus
    //                   invalid={errors.password}
    //                 />
    //                 <ValidationErrorHandling error={errors.password} />
    //               </FormGroup>
    //               <div className="mt-3">
    //                 <SubmitButton
    //                   type="submit"
    //                   buttonClassName="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
    //                   onClick={handleForm}
    //                   isLoading={isLoading}
    //                   title="Sign In"
    //                   icon={faSignInAlt}
    //                   cancleButton={false}
    //                 />
    //               </div>
    //               <div className="my-2 d-flex justify-content-between align-items-center">
    //                 <Link to="/forgotpassword" className="auth-link text-primary">
    //                   Forgot Password?
    //                 </Link>
    //               </div>
    //               <div className="text-center mt-4 font-weight-light">
    //                 Don't have an account?{" "}
    //                 <Link to="/signup" className="text-primary">
    //                   Sign Up Now
    //                 </Link>
    //               </div>
    //             </Form>
    //           </CardText>
    //         </CardBody>
    //       </Card>
    //     </Col>
    //     <Col lg="4" md="3" className="mb-2"></Col>
    //   </Row>
    // </Fade>
  );
};
export default Login;
