import { CardBody, CardTitle, CardSubtitle, Card } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";

const Notification = ({ msg }) => {
    return (
        <>
            <Card className="shadow">
                <CardBody>
                    <CardTitle tag="h4">
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                        <FontAwesomeIcon icon={faBell} className="mr-2" />
                        {msg}
                    </CardSubtitle>
                </CardBody>
            </Card>
            <br />
        </>
    );
};

export default Notification;