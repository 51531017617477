import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { faBell  , faPlus} from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, Row, Col, Fade } from "reactstrap";
import useGet from "../services/useGet";
import AlertEmailSmsForm from "./forms/alertEmailSmsForm";
import Header from "./common/header";
import AlertEmailSmsList from "./modules/alertEmailSmsList";

const AlertEmailSms = ({ showFormInitial = false, parentMethod = null }) => {

  const [showForm, setShowForm] = useState(showFormInitial);
  const [id, setId] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const { callApi: getAlerts, data: alerts, isLoading: getAlertsLoader } = useGet({
    endpoint: `alert?sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const { callApi: getAlert, data: alert, isLoading: getAlertLoader } = useGet({
    endpoint: `alert`,
  });

  useEffect(() => {
    getAlerts();
  }, [sortBy, sortOrder]);

  const isLoading = getAlertsLoader || getAlertLoader;
  return (
    <div>
      {showForm && (
        <AlertEmailSmsForm
          parentMethod={parentMethod}
          setId={setId}
          setShowForm={setShowForm}
          getAlerts={getAlerts}
          id={id}
          alert={alert}
        />
      )}

      {!showForm && (
        <Row>
          <Col sm="12" className="mb-2">
            <Fade>
            <Card color="shadow">
              <CardBody>
                <Header
                  icon={faBell}
                  buttonIcon={faPlus}
                  title="Email/Sms Alerts"
                  buttonTitle="New Alert"
                  setShowForm={setShowForm}
                />
                <AlertEmailSmsList
                  isLoading={isLoading}
                  alerts={alerts}
                  getAlerts={getAlerts}
                  getAlert={getAlert}
                  setShowForm={setShowForm}
                  setId={setId}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                />
              </CardBody>
            </Card>
            </Fade>
          </Col>
        </Row>
      )}

    </div>
  );
};

export default AlertEmailSms;