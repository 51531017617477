import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
  FormGroup,
  Row,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import SOURCES from "../../utils/constants";
import Paginate from "../common/pagination";
import HeaderSearchInput from "../common/headerSearchInput";
import ZapierAccountList from "./modules/zapierAccountList";
import ZapierAccountDetail from "./modules/zapierAccountDetail";

const ZapierAccounts = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING,
  });
  const [showList, setShowList] = useState(true);
  const [zapierAccountDetail, setZapierAccountDetail] = useState(null);

  const [searchInput, setSearchInput] = useState("");
  const [zapierAccount, setZapierAccount] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getZapierAccounts,
    data: zapierAccounts,
    isLoading: getZapierAccountsLoading,
  } = useGet({
    endpoint: `zapier/zap/setup/pending?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `zapier/zap/setup/search?searchTerm=${searchInput}`,
  });

  const isLoading =
    searchDataLoading || getZapierAccountsLoading || searchDataLoading;

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getZapierAccounts();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getZapierAccounts();
  }, []);

  useEffect(() => {
    getZapierAccounts();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    setZapierAccount(zapierAccounts);
  }, [zapierAccounts]);

  useEffect(() => {
    setZapierAccount(searchData);
  }, [searchData]);

  return (
    <div>
      {showList ? (
        <Fade>
          <Card color="shadow">
            <CardBody>
              <CardTitle tag="h4">
                <Row>
                  <Col sm={9}>
                    <FontAwesomeIcon icon={faUsers} className="mr-2" />
                    Zapier Accounts
                  </Col>
                  <Col sm={3}>
                    <HeaderSearchInput
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Col>
                </Row>
              </CardTitle>
              <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>

              <CardText>
                <ZapierAccountList
                  setShowList={setShowList}
                  setZapierAccountDetail={setZapierAccountDetail}
                  zapierAccount={zapierAccount}
                  getZapierAccounts={getZapierAccounts}
                  isLoading={isLoading}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                />
              </CardText>
            </CardBody>
            <div className="px-4">
              {!searchInput &&
                zapierAccount?.totalRecords >
                  SOURCES.DEFAULT_RECORDS_LISTING && (
                  <FormGroup>
                    <Paginate
                      setPagination={setPagination}
                      totalRecords={zapierAccount?.totalRecords}
                      isLoading={isLoading}
                    />
                  </FormGroup>
                )}
            </div>
          </Card>
        </Fade>
      ) : (
        <ZapierAccountDetail
          showList={showList}
          setShowList={setShowList}
          zapierAccountDetail={zapierAccountDetail}
        />
      )}
    </div>
  );
};

export default ZapierAccounts;
