import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Row,
  Col,
  Button,
  Fade,
  ButtonGroup,
  FormGroup,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faPaperPlane,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  capitalizeFirstLetter,
  formatDateTime,
  formatLeadSource,
  listSearchable,
} from "../../../../utils/functions";
import { useKey } from "../../../../services/useKey";
import TableRow from "../../../common/tableRow";
import usePost from "../../../../services/usePost";
import Select from "react-select";
import { useEffect, useState } from "react";
import NoRecordsFound from "../../../common/noRecordsFound";
import SOURCES from "../../../../utils/constants";
import TableHeader from "../../../common/tableHeader";

const NoLeadsSendDetail = ({
  leadDetail,
  showDetail,
  setshowDetail,
  allDestinations,
  setLeadDetail,
}) => {
  const [filterDestinations, setFilterDestinations] = useState("");
  const [destinations, setDestinations] = useState([]);
  const [filteredLeads, setFilteredLeads] = useState(null);

  const { callApi: resendingAllLeads } = usePost({
    endpoint: `admin/lead/resend-to-destinations`,
  });

  useEffect(() => {
    if (leadDetail && allDestinations) {
      setDestinations(listSearchable(allDestinations, "name", "destination"));
      setFilteredLeads(
        filterDestinations
          ? leadDetail.leads.filter(
              (ele) =>
                ele.destination ===
                filterDestinations.toLowerCase().replace(/\s+/g, "")
            )
          : null
      );
    }
  }, [allDestinations, filterDestinations, leadDetail]);

  const leadsToDisplay = filteredLeads || leadDetail?.leads;

  useKey(() => {
    setshowDetail(false);
  }, "Escape");

  const sendLeads = async (userEmail, leads) => {
    const leadIDs = leads.map((lead) => lead.id);
    const res = await resendingAllLeads(
      { userEmail, leads: leadIDs, destination: filterDestinations },
      "Resend successfully",
      true
    );
    if (res.status === SOURCES.HTTP_CODE_200) setshowDetail(false);
  };

  return (
    <Fade>
      <Card className={`shadow ${showDetail ? "d-block" : "d-none"}`}>
        <CardBody>
          <CardTitle>
            <Row className="justify-content-between">
              <Col
                lg={6}
                md={"auto"}
                xs={"auto"}
                style={{
                  fontSize: "1.5rem",
                  fontWeight: "500",
                  lineHeight: "1.2",
                  marginBottom: "0.5rem",
                }}
              >
                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                Leads To Resend
              </Col>

              <Col lg={"auto"} md={"auto"} xs={"auto"}>
                <ButtonGroup style={{ gap: "6px" }}>
                  <Select
                    placeholder="Filter via destination"
                    timezonesLoading={false}
                    options={destinations}
                    onChange={(opt) => {
                      setFilterDestinations(opt.value);
                    }}
                    value={
                      filterDestinations &&
                      filterDestinations?.name?.find((option) => {
                        return option.value === filterDestinations.name;
                      })
                    }
                  />
                  <Button
                    color="primary"
                    onClick={() => {
                      sendLeads(leadDetail?.userEmail, leadDetail?.leads);
                    }}
                    style={{
                      minWidth: "max-content",
                    }}
                    disabled={!filterDestinations}
                  >
                    <FontAwesomeIcon
                      size={"sm"}
                      icon={faPaperPlane}
                      className="mr-2"
                    />
                    Send Leads
                  </Button>

                  <Button
                    color="primary"
                    onClick={() => setshowDetail(false)}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faWindowClose} size="sm" />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardTitle>

          <CardText>
            <CardText>
              <Card className="mb-3 shadow">
                <CardBody>
                  <Table responsive striped shadow bordered hover size="sm">
                    <TableHeader
                      headers={[
                        { title: "Name" },
                        { title: "Phone" },
                        { title: "Email" },
                        { title: "Destination to Send" },
                        { title: "Imported At" },
                      ]}
                    />
                    <tbody>
                      {leadsToDisplay.length > 0 ? (
                        leadsToDisplay.map((lead, idx) => {
                          return (
                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                              <td>
                                {lead.fullName
                                  ? lead.fullName
                                  : `${lead.firstName} ${lead.lastName}`}
                              </td>
                              <td>{lead.phone}</td>
                              <td>{lead.email}</td>
                              <td>{capitalizeFirstLetter(lead.destination)}</td>
                              <td>{formatDateTime(lead.importedAt)}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecordsFound colSpan="5" />
                      )}
                    </tbody>
                    {/* <TableRow
                            label="Lead Name"
                            value={
                              lead.fullName
                                ? lead.fullName
                                : `${lead.firstName} ${lead.lastName}`
                            }
                          />
                          <TableRow label="Lead Phone" value={lead.phone} />
                          <TableRow label="Lead Email" value={lead.email} />
                          <TableRow
                            label="Destination to Send"
                            value={capitalizeFirstLetter(lead.destination)}
                          />
                          <TableRow
                            label="Lead Imported At"
                            value={formatDateTime(lead.importedAt)}
                          /> */}
                    {/* <TableRow label={
                               <Button
                            color="primary"
                             onClick={() => sendLeads(leadDetail?.userEmail, [lead])}
                          >
                            Send To {capitalizeFirstLetter(lead.destination)}
                                   </Button>
                                    } value="" colSpanTD={2} /> */}
                  </Table>
                </CardBody>
              </Card>
            </CardText>
          </CardText>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default NoLeadsSendDetail;
