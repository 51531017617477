import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { Table } from "reactstrap";
import { capitalizeFirstLetter, formatDateTime, formatLeadSource } from '../../../utils/functions';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';
import NoRecordsFound from '../../common/noRecordsFound';
import { useState } from 'react';

const ScrappingErrorList = ({ scrapErrors, isLoading, viewDetail, setSortBy, sortBy, setSortOrder, sortOrder }) => {
  const [expandedRow, setExpandedRow] = useState(null); // Store the expanded row index

  const sortData = (title) => {
    setSortBy(title);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };
  return (
    <Table responsive striped bordered hover size="sm" className="shadow">
      <TableHeader
        headers={[
          { title: "User Email", showIcon: true },
          { title: "Lead Source", showIcon: true },
          { title: "Error", showIcon: true },
          { title: "DateTime", showIcon: true },
          { title: "" },
        ]}
        sortData={sortData}
        setSortBy={setSortBy}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
      <tbody>
        {isLoading ? (
          <TableLoader colSpan="5" />
        ) : (
          <>
            {scrapErrors && scrapErrors?.logs.length > 0 ? (
              scrapErrors.logs.map((item, idx) => {
                 const toggleText = (index) => {
                   setExpandedRow(expandedRow === index ? null : index);
                 };
                return (
                  <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                    <td style={{ width: "200px" }}>
                      <Link to="#" onClick={() => viewDetail(item)}>
                        {item?.userEmail}
                      </Link>
                    </td>
                    <td className="autoCapital" style={{ width: "200px" }}>
                      {formatLeadSource(capitalizeFirstLetter(item?.leadSource))}
                    </td>
                    {/* <td>{item?.errorLog}</td> */}
                    <td
                      style={{
                        maxWidth: "170px",
                        whiteSpace: expandedRow === idx ? "normal" : "nowrap",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        cursor: "pointer",
                        position: "relative",
                        height: expandedRow === idx ? "auto" : "40px",
                      }}
                      onClick={() => toggleText(idx)}
                    >
                      <div
                        style={{
                          maxWidth: "100%",
                          maxHeight: expandedRow === idx ? "100px" : "20px",
                          overflowY: expandedRow === idx ? "auto" : "hidden",
                          overflowX: "hidden",
                          whiteSpace: expandedRow === idx ? "normal" : "nowrap",
                          textOverflow: "ellipsis",
                     
                        }}
                      >
                        {item?.errorLog}
                      </div>
                    </td>
                    <td style={{ width: "200px" }}>{formatDateTime(item?.createdAt)}</td>
                    <td style={{ textAlign: "center", fontSize: "20px"  , width:"70px"}}>
                      <NavLink to="#" onClick={() => viewDetail(item)}>
                        <FontAwesomeIcon icon={faInfo} className="m-2" title="View Detail" />
                      </NavLink>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordsFound colSpan="5" />
            )}
          </>
        )}
      </tbody>
    </Table>
  );
}

export default ScrappingErrorList