import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, NavLink } from "react-router-dom";
import { Table } from "reactstrap";
import { formatDateTime } from "../../../utils/functions";
import NoRecordsFound from "../../common/noRecordsFound";
import TableHeader from "../../common/tableHeader";
import TableLoader from "../../common/tableLoader";

const AutoresponseSentList = ({
  isLoading,
  dataExist,
  autoresponseSentList,
  viewDetail,
  setSortBy,
  sortBy,
  setSortOrder,
  sortOrder,
}) => {
  const sortData = (title) => {
    setSortBy(title);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };

  return (
    <Table responsive striped bordered hover size="sm" className="shadow">
      <TableHeader
        headers={[
          { title: "Send Date", showIcon: true },
          { title: "Total Sent (Leads)" },
          { title: "" },
        ]}
        sortData={sortData}
        setSortBy={setSortBy}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
      <tbody>
        {isLoading ? (
          <TableLoader colSpan="3" />
        ) : (
          <>
            {dataExist ? (
              autoresponseSentList.results.map((item, idx) => {
                return (
                  <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                    <td>
                      <Link onClick={() => viewDetail(item?._id)}>
                        {formatDateTime(item?.dateAsPerUserTimezone)}
                      </Link>{" "}
                    </td>
                    <td>{item?.leadSent.length}</td>
                    <td style={{ textAlign: "center", fontSize: "18px" }}>
                      <NavLink to="#" onClick={() => viewDetail(item?._id)}>
                        <FontAwesomeIcon icon={faInfo} />
                      </NavLink>
                    </td>
                  </tr>
                );
              })
            ) : (
              <NoRecordsFound colSpan="3" />
            )}
          </>
        )}
      </tbody>
    </Table>
  );
};

export default AutoresponseSentList;
