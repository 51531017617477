import React from "react"

const NoRecordsFound = ({ colSpan, className="text-center" }) => {
    return (
        <tr>
            <td colSpan={colSpan}><div className={className}>No record found.</div> </td>
        </tr>
    )
}

export default NoRecordsFound;