import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCopy, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Badge, Input, InputGroup, InputGroupText, Table } from "reactstrap";
import { formatDateTime } from '../../../utils/functions';
import NoRecordsFound from '../../common/noRecordsFound';
import TableHeader from '../../common/tableHeader';
import TableLoader from '../../common/tableLoader';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import DataIcons from '../../common/dataIcons';

const ZapierAccountList = ({
    setShowList,
    setZapierAccountDetail,
    zapierAccount,
    getZapierAccounts,
    isLoading,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
}) => {
    const [password, setPassword] = useState(''); // Replace with your actual password state
    const [copyClipboardTextPassword, setCopyClipboardTextPassword] = useState(false);
    const [copyClipboardTextEmail, setCopyClipboardTextEmail] = useState(false);
    const [passwordTypes, setPasswordTypes] = useState([]);

    // const { callApi: getZapierConfigration } = useGet({
    //     endpoint: `zapier`
    // });

    useEffect(() => {
        // getZapierConfigration();
        getZapierAccounts()
    }, []);

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }

    const showHideDetail = (data) => {
        setZapierAccountDetail(data);
        setShowList(false);
    }

    const toggleCopyIcon = (idx, type) => {
        const copyStateSetter = type === 'password' ? setCopyClipboardTextPassword : setCopyClipboardTextEmail;

        copyStateSetter((prevCopyStates) => {
            return { ...prevCopyStates, [idx]: !prevCopyStates[idx] };
        });
    };

    const copyToClipboard = (text, idx, type) => {
        navigator.clipboard.writeText(text)
        toggleCopyIcon(idx, type);
        setTimeout(() => {
            toggleCopyIcon(idx, type);
        }, 1000);

    };

    return (
        <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader
                headers={[{ title: 'Name', showIcon: true }, { title: 'Email', showIcon: true }, { title: 'Phone', showIcon: true }, { title: 'Zapier Email' }, { title: 'Zapier Password' }, { title: 'Created At', showIcon: true }, { title: 'Status', showIcon: true }]}
                sortData={sortData}
                setSortBy={setSortBy}
                sortBy={sortBy}
                sortOrder={sortOrder}
            />
            <tbody>
                {isLoading ?
                    <TableLoader colSpan="7" />
                    :
                    <>
                        {
                            zapierAccount?.result && zapierAccount?.result?.length > 0 ?
                                zapierAccount?.result?.map((item, idx) => {
                                    return (
                                        <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                            <td>
                                                <Link to="#" className="auth-link text-black" onClick={() => showHideDetail(item)}>
                                                    {item?.userDetails?.name}
                                                </Link>
                                            </td>
                                            <td>{item?.userDetails?.email}</td>
                                            <td>{item?.userDetails?.phone}</td>
                                            <td>
                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    {item?.email}
                                                    <FontAwesomeIcon
                                                        size='sm'
                                                        className='mx-2'
                                                        cursor="pointer"
                                                        title='Copy Email'
                                                        onClick={() => copyToClipboard(item?.email, idx, "email")}
                                                        icon={copyClipboardTextEmail[idx] ? faCheck : faCopy}
                                                    />
                                                </div>

                                            </td>
                                            <td>
                                                <DataIcons value={item?.password} idx={idx} arrayData={zapierAccount?.result} data={zapierAccount}/>
                                                {/* <InputGroup style={{ alignItems: 'center' }}>
                                                    <Input
                                                        id={`password-${idx}`}
                                                        name="password"
                                                        placeholder="Password"
                                                        className="form-control form-control-lg"
                                                        type={passwordTypes[idx]}
                                                        value={item?.password}
                                                        autoComplete="off"
                                                        style={{
                                                            borderRight: '0px',
                                                            border: 'none',
                                                            background: 'transparent',
                                                            fontSize: '16px',
                                                            padding: 'inset',
                                                            height: '0px',
                                                            paddingLeft:"0px"
                                                        }}
                                                    />
                                                    <InputGroupText style={{ borderLeft: '0px', marginLeft: '-2px', border: 'none', background: 'transparent' }}>
                                                        <FontAwesomeIcon
                                                            size='sm'
                                                            cursor="pointer"
                                                            icon={passwordTypes[idx] === 'password' ? faEyeSlash : faEye}
                                                            onClick={() => togglePassword(idx)}
                                                        />
                                                    </InputGroupText>
                                                    <FontAwesomeIcon
                                                        className='mx-1'
                                                        size='sm'
                                                        cursor="pointer"
                                                        title='Copy Password'
                                                        onClick={() => copyToClipboard(item?.password, idx, "password")}
                                                        icon={copyClipboardTextPassword[idx] ? faCheck : faCopy}
                                                    />
                                                </InputGroup> */}
                                            </td>
                                            <td>{formatDateTime(item?.createdAt)}</td>
                                            <td>
                                                <Badge color={item?.zapCreatedByAdmin === true ? "success" : "warning"}>{item?.zapCreatedByAdmin === true ? "Completed" : "Pending"}</Badge>
                                            </td>
                                        </tr>
                                    );
                                })
                                :
                                <NoRecordsFound colSpan="8" />
                        }
                    </>
                }
            </tbody>
        </Table>
    )
}

export default ZapierAccountList