import React, { useEffect, useState } from 'react'
import {
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';


const DataIcons = ({ copyIcon = false, toggleVisibilityIcon = false, value, idx, arrayData, data }) => {
  const [destinationInputType, setDestinationInputType] = useState([]);
  const [copyClipboardTextPassword, setCopyClipboardTextPassword] = useState(false);
  const [copyClipboardTextEmail, setCopyClipboardTextEmail] = useState(false);

  useEffect(() => {
    if (Array.isArray(arrayData)) {
      const initialPasswordTypes = arrayData?.map(() => true);
      setDestinationInputType(initialPasswordTypes);
    }
  }, [data]);

  const toggleDestinationPassword = (idx) => {
    setDestinationInputType((prevTypes) => {
      const newTypes = [...prevTypes];
      newTypes[idx] = newTypes[idx] === true ? false : true;
      console.log("newTypes", newTypes[idx])
      return newTypes;
    });
  };

  const toggleCopyIcon = (idx, type) => {
    const copyStateSetter = type === "password" ? setCopyClipboardTextPassword : setCopyClipboardTextEmail;

    copyStateSetter((prevCopyStates) => {
      return { ...prevCopyStates, [idx]: !prevCopyStates[idx] };
    });
  };

  const copyToClipboard = (text, idx, type) => {
    navigator.clipboard.writeText(text)
    toggleCopyIcon(idx, type);
    setTimeout(() => {
      toggleCopyIcon(idx, type);
    }, 1000);

  };

  return (
    <div className="d-flex justify-content-between">
      <span style={destinationInputType[idx] ? { filter: "blur(5px)" } : {}}>{value}</span>
      <InputGroup className="align-items-center justify-content-end" style={{ flexWrap: "noWrap" }}>
        <InputGroupText style={{ borderLeft: '0px', marginLeft: '-2px', border: 'none', background: 'transparent' }}>
          <FontAwesomeIcon
            size='sm'
            cursor="pointer"
            icon={destinationInputType[idx] === true ? faEye : faEyeSlash}
            onClick={() => toggleDestinationPassword(idx)}
          />
        </InputGroupText>
        <FontAwesomeIcon
          className='mx-1'
          size='sm'
          cursor="pointer"
          title='Copy Password'
          onClick={() => copyToClipboard(value, idx, 'password')}
          icon={copyClipboardTextPassword[idx] ? faCheck : faCopy}
        />
      </InputGroup>
    </div>
  )
}

export default DataIcons