import { Link, NavLink } from "react-router-dom";
import { Table } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo } from "@fortawesome/free-solid-svg-icons";
import { formatDateTime } from "../../../utils/functions";
import NoRecordsFound from "../../common/noRecordsFound";
import TableHeader from "../../common/tableHeader";
import TableLoader from "../../common/tableLoader";

const EmailLogList = ({
    emailLogs,
    isLoading,
    viewDetail,
    setSortBy,
    sortBy,
    setSortOrder,
    sortOrder }) => {
    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    return (
        <Table responsive striped bordered hover size="sm" className="shadow">
            <TableHeader headers={[{ title: 'Client Email', showIcon: true }, { title: 'Email To', showIcon: true }, { title: 'Sent At', showIcon: true }, { title: '' }]} 
            sortData={sortData}
            setSortBy={setSortBy}
            sortBy={sortBy}
            sortOrder={sortOrder}
            />
            <tbody>
                {isLoading ?
                    <TableLoader colSpan="4" />
                    :
                    <>
                        {emailLogs && emailLogs?.logs.length > 0 ? (
                            emailLogs.logs.map((item, idx) => {
                                return (
                                    <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                        <td>
                                            <Link onClick={() => viewDetail(item)}>
                                                {item?.userEmail}
                                            </Link>
                                        </td>
                                        <td>{item?.emailTo}</td>
                                        <td>{formatDateTime(item?.createdAt)}</td>
                                        <td style={{ textAlign: "center", fontSize: "18px" }}>
                                            <NavLink to="#" onClick={() => viewDetail(item)} >
                                                <FontAwesomeIcon
                                                    icon={faInfo}
                                                    className="m-2"
                                                    title='View Detail'
                                                />
                                            </NavLink>
                                        </td>
                                    </tr>
                                );
                            })
                        ) :
                            <NoRecordsFound colSpan="4" />
                        }
                    </>
                }
            </tbody>
        </Table>
    );
}

export default EmailLogList;