import React,{ useEffect, useState } from "react";
import { faBriefcase, faFileImport , faPlus} from "@fortawesome/free-solid-svg-icons";
import { Card, CardBody, Row, Col, Fade } from "reactstrap";
import useGet from "../services/useGet";
import LeadSourcesForm from "./forms/leadSourcesForm";
import Header from "./common/header";
import LeadSourceList from "./modules/leadSourceList";

const LeadSources = () => {
  const [showForm, setShowForm] = useState(false);
  const [id, setId] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const { callApi: getLeadSources, data: jobs, isLoading: getLeadSourcesLoader } = useGet({
    endpoint: `leadsource?sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  useEffect(() => {
    getLeadSources();
  }, [sortBy, sortOrder]);

  return (
    <div>
      {showForm && (

        <LeadSourcesForm
          setShowForm={setShowForm}
          setId={setId}
          getLeadSources={getLeadSources}
          id={id}
        />
      )}

      {!showForm && (
        <Row>
          <Col sm="12" className="mb-2">
            <Fade>
            <Card color="shadow">
              <CardBody>
                <Header
                  icon={faFileImport}
                  buttonIcon={faPlus}
                  title="Leads Import Jobs"
                  buttonTitle="New Job"
                  setShowForm={setShowForm}
                />
                <LeadSourceList
                  getLeadSources={getLeadSources}
                  setShowForm={setShowForm}
                  setId={setId}
                  jobs={jobs}
                  getLeadSourcesLoader={getLeadSourcesLoader}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  setSortOrder={setSortOrder}
                  sortOrder={sortOrder}
                />
              </CardBody>
            </Card>
            </Fade>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default LeadSources;