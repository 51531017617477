import { SHOW_ALERT } from "../actions";

const initialState = {
  alert: {
      show:false,
      type: "",
      msg: "",
  },
};
const AlertReducer = (state = initialState, action) => {
  if (action.type === SHOW_ALERT) {
    return {
      ...state,
      alert: action.payload,
    };
  }
  return state;
};
export default AlertReducer;