import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faFilter } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Row,
  Col,
  Button,
  Collapse,
  Fade,
} from "reactstrap";
import Paginate from "../../common/pagination";
import useGet from "../../../services/useGet";
import useDebounce from "../../../utils/customHooks";
import SOURCES from "../../../utils/constants";
import AdvanceSearch from "../../common/advanceSearch";
import PaginateSearch from "../../common/paginationSearch";
import NoLeadsSendDetail from "./modules/noLeadsSendDetails";
import NoLeadsSendList from "./modules/noLeadsSendList";
import HeaderSearchInput from "../../common/headerSearchInput";

const NoLeadsSend = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: 1000, //This 1000 limit is important otherwise backend will not going to work
  });
  const [paginationSearch, setPaginationSearch] = useState({
    offsetSearch: 0,
    limitSearch: SOURCES.DEFAULT_RECORDS_SEARCH,
  });
  const [noLeadsSend, setNoLeadsSend] = useState([]);
  const [leadDetail, setLeadDetail] = useState(null);
  const [showDetail, setshowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getNoLeadsSend,
    data: noLeadsSendData,
    isLoading: getNoLeadsSendLoading,
  } = useGet({
    endpoint: `leadstatistic/leads-not-sent?offset=${pagination.offset}&limit=${pagination.limit}&searchTerm=${searchInput}`,
  });

  const {
    callApi: getDestinations,
    data: allDestinations,
    isLoading: getDestinationsLoader,
  } = useGet({
    endpoint: `destinations/all-destinations`,
  });

  const isLoading = getNoLeadsSendLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const dataExist = noLeadsSend && noLeadsSend?.length > 0 ? true : false;

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2)
      getNoLeadsSend();
    else if (!debouncedSearchTerm) getNoLeadsSend();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getDestinations();
  }, []);
  // console.log(destinations,"destinations")
  // useEffect(() => {
  // }, [searchInput])

  useEffect(() => {
    getNoLeadsSend();
  }, [pagination.offset, pagination.limit]);

  useEffect(() => {
    setNoLeadsSend(noLeadsSendData);
  }, [noLeadsSendData]);

  const viewDetail = (item) => {
    setLeadDetail(item);
    setshowDetail(true);
  };

  return (
    <div>
      <Fade>
        <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
          <CardBody>
            <CardTitle>
              <Row style={{ alignItems: "center" }}>
                <Col lg={10} style={{ display: "flex" }} tag={"h4"}>
                  <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                  Leads Not Sent
                </Col>
                <Col lg={2}>
                  <div>
                    <HeaderSearchInput
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </div>
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
            <CardText>
              <NoLeadsSendList
                isLoading={isLoading}
                dataExist={dataExist}
                noLeadsSend={noLeadsSend}
                viewDetail={viewDetail}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
              />
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput &&
              noLeadsSend?.totalReords > SOURCES.DEFAULT_RECORDS_LISTING && (
                <FormGroup>
                  <Paginate
                    setPagination={setPagination}
                    totalRecords={noLeadsSend?.totalReords}
                    isLoading={isLoading}
                  />
                </FormGroup>
              )}

            {searchInput &&
              noLeadsSend?.totalReords > SOURCES.DEFAULT_RECORDS_SEARCH && (
                <FormGroup>
                  <PaginateSearch
                    setPaginationSearch={setPaginationSearch}
                    totalRecords={noLeadsSend?.totalReords}
                  />
                </FormGroup>
              )}
          </div>
        </Card>
      </Fade>
      {leadDetail && (
        <NoLeadsSendDetail
          leadDetail={leadDetail}
          showDetail={showDetail}
          setshowDetail={setshowDetail}
          allDestinations={allDestinations}
          setLeadDetail={setLeadDetail}
        />
      )}
    </div>
  );
};

export default NoLeadsSend;
