import React from "react";
import { Card, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";

const DestinationHeader = ({ headerTitle, headerImage, children }) => {
  return (
    <Card className="my-2 shadow">
      <CardHeader color="white">
        <CardTitle
          // tag={"h4"}
          style={{ display: "flex", alignItems: "center", marginBottom: "0px" ,fontSize:"18px"}}
        >
          <img src={headerImage} alt="" width="30px" className="mr-1" />
          {headerTitle}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <CardText>{children}</CardText>
      </CardBody>
    </Card>
  );
};

export default DestinationHeader;
