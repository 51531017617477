import React, { useEffect, useState, useMemo } from "react";
import Select from "react-select";
import { Accordion, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

const GenericTabs = ({ tabs, activeTabindex = 1 }) => {
  const [currentActiveTab, setCurrentActiveTab] = useState(activeTabindex.toString());

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  useEffect(() => {
    setCurrentActiveTab(activeTabindex.toString());
  }, [activeTabindex]);

  const options = useMemo(
    () =>
      tabs?.map((tab) => ({
        value: tab?.title,
        tabId: tab?.tabId,
        label: (
          <div style={{ display: "flex", alignItems: "center" }}>
            {tab?.icon && <img src={tab?.icon} alt="" width="24px" style={{ marginRight: "8px" }} />}
            {tab?.title}
          </div>
        ),
      })),
    [tabs]
  );

  const selectedOption = options.find((option) => option?.tabId === currentActiveTab);

  const handleSelectChange = (selectedOption) => {
    if (selectedOption) {
      toggle(selectedOption.tabId);
    }
  };
  return (
    <>
      <div className="w-100">
        <Nav tabs className="d-none d-lg-flex d-md-flex d-sm-flex">
          <Row>
            {tabs?.map((tab, index) => (
              <Col lg="auto" md="4" sm="6" xs="12" className="p-0" key={index}>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: currentActiveTab === tab?.tabId,
                    })}
                    onClick={() => {
                      toggle(tab?.tabId);
                    }}
                    style={{
                      fontWeight: currentActiveTab === tab?.tabId ? "bold" : "normal",
                      cursor: "pointer",
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    {tab?.icon && <img src={tab?.icon} alt="" width="30px" />}
                    {tab?.title}
                  </NavLink>
                </NavItem>
              </Col>
            ))}
          </Row>
        </Nav>
      </div>

      <Select
        value={selectedOption}
        options={options}
        onChange={handleSelectChange}
        isSearchable={true}
        className="d-sm-block d-md-none"
      />

      <TabContent activeTab={currentActiveTab}>
        {tabs?.map((tab, index) => (
          <TabPane key={index} tabId={tab?.tabId}>
            <Accordion targetId={tab?.tabId} icon={tab?.icon} className={tab?.className}>
              {tab?.component}
            </Accordion>
          </TabPane>
        ))}
      </TabContent>
    </>
  );
};
export default GenericTabs;
