import React, { useEffect } from 'react'
import { faCheck, faInfo, faTrash, faWindowClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Row,
  Col,
  Button,
  ButtonGroup,
} from "reactstrap";
import { useKey } from '../../../services/useKey';
import { formatDateTime } from '../../../utils/functions';
import TableRow from '../../common/tableRow';

const LeadViaFormDetail = ({ showDetail, setShowDetail, leadDetail, handleAcceptReject, processSuccess }) => {
  useKey(() => {
    setShowDetail(false);
  }, "Escape")

  useEffect(() => {
    if (processSuccess) setShowDetail(false);
  }, [processSuccess])

  return (
    <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
      <CardBody>
        <CardTitle tag="h4">
          <Row className='justify-content-between align-items-center flex-nowrap' >
            <Col md={"auto"} xs={"auto"} className='pr-1'>
              <div className='d-flex'>
                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                Lead Detail
              </div>
            </Col>
            <Col md={"auto"} xs={"auto"} className='pl-0'>
              <ButtonGroup>
                <Button
                  onClick={() => handleAcceptReject(leadDetail?._id)}
                  color="success"
                  title='Accept'
                  size='sm'
                >
                  <FontAwesomeIcon icon={faCheck} size='sm' />
                </Button>
                <Button
                  onClick={() => handleAcceptReject(leadDetail?._id, 'REJECT')}
                  className="mx-2"
                  color='danger'
                  title='Reject'
                  size='sm'
                >
                  <FontAwesomeIcon icon={faTrash} size='sm' />
                </Button>
                <Button
                  onClick={() => setShowDetail(false)}
                  color="primary"
                  title='Close'
                  size='sm'
                >
                  <FontAwesomeIcon icon={faWindowClose} size='sm' />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </CardTitle>

        <CardText>
          <Card color="light" className="mb-3 shadow">
            <CardBody>
              <CardTitle tag="h5">
                <Row>
                  <Col sm={12}>
                    Basic Information
                  </Col>
                </Row>
              </CardTitle>
              <CardText>
                <Table responsive striped bordered hover size="sm">
                  {leadDetail?.photo && (
                    <TableRow
                      colSpanTH={2}
                      label={
                        <img src={leadDetail.photo} alt="Lead" width={100} />
                      }
                    />
                  )}
                  <TableRow
                    label="Name"
                    value=
                    {
                      leadDetail.fullName
                        ?
                        leadDetail.fullName
                        :
                        `${leadDetail.firstName} ${leadDetail.lastName}`
                    }
                  />
                  <TableRow label="Email" value={leadDetail?.email} />
                  <TableRow label="Phone" value={leadDetail?.phone} />
                  <TableRow label="Imported At" value={formatDateTime(leadDetail?.dateAsPerUserTimezone)} />
                </Table>
              </CardText>
            </CardBody>
          </Card>
          <Card color="light" className="shadow">
            <CardBody>
              <CardTitle tag="h5">
                <Row>
                  <Col sm={12}>
                    Event Information
                  </Col>
                </Row>
              </CardTitle>
              <CardText>
                <Table responsive striped bordered hover size="sm">
                <TableRow label="Event Name" value={leadDetail?.eventName} />
                <TableRow label="Event Type" value={leadDetail?.eventType} />
                <TableRow label="Event Date" value={leadDetail?.eventDate} />
                <TableRow label="Guest Count" value={leadDetail?.guestCount} />
                <TableRow label="Budget" value={leadDetail?.budget} />
                <TableRow label="Company" value={leadDetail?.company} />
                <TableRow label="Imported At" value={formatDateTime(leadDetail?.createdAt)} />
                <TableRow label="Notes/Inquiry" value={leadDetail?.inquiry} />                  
                </Table>
              </CardText>
            </CardBody>
          </Card>
        </CardText>
      </CardBody>
    </Card>
  )
}

export default LeadViaFormDetail