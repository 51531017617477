import { Card, CardBody, CardSubtitle, CardTitle, CardText, Col, Row, Table, Button, Fade } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { useKey } from "../../../services/useKey";
import TableRow from "../../common/tableRow";
const EmailLogDetail = ({ emailLogDetail, showDetail, setShowDetail }) => {

  useKey(() => {
    setShowDetail(false);
  }, "Escape")

  return (
    <Fade>
    <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
      <CardBody>
        <CardTitle tag="h4">
          <Row className="justify-content-between">
            <Col md={"auto"} xs={"auto"}>
              <FontAwesomeIcon icon={faInfo} className="mr-2" />
              Email Log Detail
            </Col>

            <Col md={"auto"} xs={"auto"}>
              <Button
                color="primary"
                onClick={() => {
                  setShowDetail(false);
                }}
                size="sm"
              >
                <FontAwesomeIcon
                  icon={faWindowClose}
                  size="sm"
                />
              </Button>
            </Col>
          </Row>
        </CardTitle>
        <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
        <CardText>
          <Table responsive striped bordered hover size="sm">
            <TableRow label="Client Email" value={emailLogDetail.userEmail} />
            <TableRow label="Email To" value={emailLogDetail?.emailTo} />
            <TableRow label="Message"
              value={
                <div dangerouslySetInnerHTML={{ __html: emailLogDetail?.emailBody }} />
              } />
            {/* <tr style={{ whiteSpace: "nowrap" }}>
              <th>Client Email</th>
              <td>
                {emailLogDetail.userEmail}
              </td>
            </tr>
            <tr style={{ whiteSpace: "nowrap" }}>
              <th>Email To</th>
              <td>{emailLogDetail?.emailTo}</td>
            </tr>
            <tr>
              <th>Message</th>
              <td>
                <div dangerouslySetInnerHTML={{ __html: emailLogDetail?.emailBody }} />
              </td>
            </tr> */}
          </Table>
        </CardText>
      </CardBody>
    </Card>
    </Fade>
  );
};

export default EmailLogDetail;