import { useState } from "react";
import { useDispatch } from 'react-redux';
import { Alert } from "reactstrap";
import { showAlert } from "../../redux/actions"

const ShowAlert = ({ msg, type }) => {
  const [open, SetOpen] = useState(true);
  const dispatch = useDispatch();

  setTimeout(() => {
    SetOpen(false);
    dispatch(showAlert({ show: false, }));
  }, 5000);

  return (
    <div className="mt-2 position-fixed float-right" style={{ width: "fit-content", top: "1px", right: "10px" }}>
      <Alert color={type} isOpen={open}>
        {msg}
      </Alert>
    </div>
  );
};

export default ShowAlert;
