import { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFilter,
  faHistory,
  faInfo,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Table,
  FormGroup,
  Button,
  Collapse,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import { useKey } from "../../services/useKey";
import { formatDateTime, formatLeadSource } from "../../utils/functions";
import Paginate from "../common/pagination";
import NoRecordsFound from "../common/noRecordsFound";
import SOURCES from "../../utils/constants";
import TableLoader from "../common/tableLoader";
import TableHeader from "../common/tableHeader";
import AdvanceSearch from "../common/advanceSearch";
import HeaderSearchInput from "../common/headerSearchInput";
import TableRow from "../common/tableRow";

const PaymentHistory = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING,
  });
  const [leadsSent, setLeadsSent] = useState();
  const [leadsSentDetails, setLeadsSentDetails] = useState();
  const [showDetail, setshowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);
  const [advanceSearch, setAdvanceSearch] = useState({
    advance_search_enabled:
      SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    startDate: "",
    endDate: "",
    destination: "",
    searchTerm: "",
  });

  const {
    callApi: getLeadHistory,
    data: leadHistory,
    isLoading: getLeadHistoryLoader,
  } = useGet({
    endpoint: `leadstatistic?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: getDestinations,
    data: destinations,
    isLoading: getDestinationsLoader,
  } = useGet({
    endpoint: `integration`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: getSearchDataLoader,
  } = useGet({
    endpoint: `leadstatistic/search?q=${searchInput}&advance_search=${advanceSearch.advance_search_enabled}&startdate=${advanceSearch.startDate}&enddate=${advanceSearch.endDate}&destination=${advanceSearch.destination}&term=${advanceSearch.searchTerm}`,
  });

  const isLoading =
    getLeadHistoryLoader || getSearchDataLoader || getDestinationsLoader;

  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useKey(() => {
    setshowDetail(false);
  }, "Escape");

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getLeadHistory();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    setAdvanceSearch({
      ...advanceSearch,
      advance_search_enabled:
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
    });
    setShowAdvanceSearch(false);
  }, [searchInput]);

  useEffect(() => {
    if (
      [
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM,
      ].indexOf(advanceSearch.advance_search_enabled) >= 0
    ) {
      getLeadHistory();
      getDestinations();
      if (
        advanceSearch.advance_search_enabled ===
        SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM
      )
        setAdvanceSearch({
          ...advanceSearch,
          advance_search_enabled:
            SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
        });
    }
  }, [
    pagination.offset,
    pagination.limit,
    sortBy,
    sortOrder,
    advanceSearch.advance_search_enabled,
  ]);

  useEffect(() => {
    setLeadsSent(leadHistory);
  }, [leadHistory]);

  useEffect(() => {
    setLeadsSent(searchData);
  }, [searchData]);

  const viewDetail = (item) => {
    setLeadsSentDetails(item);
    setshowDetail(true);
  };

  const sortData = (title) => {
    setSortBy(title);
    setSortOrder(sortOrder === 1 ? -1 : 1);
  };
  const dataExist = leadsSent && leadsSent.infos.length > 0 ? true : false;

  const { firstName, lastName, fullName } = leadsSentDetails || {};
  const intialName = fullName ? fullName : `${firstName} ${lastName}`;

  return (
    <div>
      <Fade>
        <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
          <CardBody>
            <CardTitle>
              <Row>
                <Col xl={9} lg={"auto"} style={{ display: "flex" }} tag={"h4"}>
                  <FontAwesomeIcon icon={faHistory} className="mr-2" />
                  Leads Sent History
                </Col>
                <Col xl={3} lg={"auto"} md="auto">
                  <div
                    style={{
                      display: "flex",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <HeaderSearchInput
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                    <Button
                      color="primary"
                      onClick={() => {
                        setShowAdvanceSearch(true);
                      }}
                      style={{
                        minWidth: "max-content",
                      }}
                    >
                      <FontAwesomeIcon icon={faFilter} className="mr-2" />
                      Advance Search
                    </Button>
                  </div>
                  <Collapse isOpen={showAdvanceSearch}>
                    <AdvanceSearch
                      searchInput={searchInput}
                      isOpen={showAdvanceSearch}
                      advanceSearch={advanceSearch}
                      setShowAdvanceSearch={setShowAdvanceSearch}
                      setAdvanceSearch={setAdvanceSearch}
                      getSearchData={getSearchData}
                      filteredData={{ key: "DESTINATION", data: destinations }}
                    />
                  </Collapse>
                </Col>
              </Row>
            </CardTitle>

            <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
            <CardText>
              <Table
                responsive
                striped
                bordered
                hover
                size="sm"
                className="shadow"
              >
                <TableHeader
                  sortData={sortData}
                  setSortBy={setSortBy}
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  headers={[
                    {
                      title: "Name",
                      showIcon: true,
                    },
                    {
                      title: "Email",
                      showIcon: true,
                    },
                    {
                      title: "Phone",
                      showIcon: true,
                    },
                    {
                      title: "Sent At",
                      showIcon: true,
                    },
                    {
                      title: "Destination",
                      showIcon: true,
                    },
                    { title: "" },
                  ]}
                />
                <tbody>
                  {isLoading ? (
                    <TableLoader colSpan="6" />
                  ) : (
                    <>
                      {dataExist ? (
                        leadsSent.infos.map((item, idx) => {
                          return (
                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                              <td>
                                <Link
                                  onClick={() =>
                                    viewDetail(item.LeadsInformation)
                                  }
                                >
                                  {item.LeadsInformation?.fullName
                                    ? item.LeadsInformation?.fullName
                                    : `${item?.LeadsInformation?.firstName} ${item?.LeadsInformation?.lastName}`}
                                </Link>
                              </td>
                              <td>{item.LeadsInformation?.email}</td>
                              <td>{item.LeadsInformation?.phone}</td>
                              <td>
                                {formatDateTime(item?.dateAsPerUserTimezone)}
                              </td>
                              <td className="autoCapital">
                                {item.destinationId}
                              </td>
                              <td
                                style={{
                                  textAlign: "center",
                                  fontSize: "18px",
                                }}
                              >
                                <NavLink
                                  to="#"
                                  onClick={() =>
                                    viewDetail(item.LeadsInformation)
                                  }
                                >
                                  <FontAwesomeIcon icon={faInfo} />
                                </NavLink>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <NoRecordsFound colSpan="6" />
                      )}
                    </>
                  )}
                </tbody>
              </Table>
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput &&
              leadHistory?.count > SOURCES.DEFAULT_RECORDS_LISTING && (
                <FormGroup>
                  <Paginate
                    setPagination={setPagination}
                    totalRecords={leadHistory?.count}
                    isLoading={isLoading}
                  />
                </FormGroup>
              )}
          </div>
        </Card>
      </Fade>
      {leadsSentDetails && (
        <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
          <CardBody>
            <CardTitle tag="h4">
              <Row className="justify-content-between">
                <Col md={"auto"} xs={"auto"}>
                  <FontAwesomeIcon icon={faInfo} className="mr-2" />
                  Lead Detail
                </Col>
                <Col md={"auto"} xs={"auto"}>
                  <Button
                    onClick={() => {
                      setshowDetail(false);
                    }}
                    color="primary"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faWindowClose} size="sm" />
                  </Button>
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
            <CardText>
              <Table
                responsive
                striped
                bordered
                hover
                size="sm"
                className="shadow"
              >
                <TableRow label="Name" value={intialName} />
                <TableRow label="Email" value={leadsSentDetails?.email} />
                <TableRow label="Phone" value={leadsSentDetails?.phone} />
                <TableRow
                  label="Guest Count"
                  value={leadsSentDetails?.guestCount}
                />
                <TableRow label="Company" value={leadsSentDetails?.company} />
                <TableRow
                  label="Imported At"
                  value={leadsSentDetails?.createdAt}
                />
                <TableRow
                  label="Source"
                  value={formatLeadSource(leadsSentDetails?.leadSource)}
                  classNameTD="autoCapital"
                />
              </Table>
            </CardText>
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default PaymentHistory;
