import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  CardText,
  Table,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label,
  ButtonGroup,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfo,
  faUser,
  faUserNinja,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatDateTime,
  formatLeadSource,
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import usePost from "../../../services/usePost";
import { useKey } from "../../../services/useKey";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import SubmitButton from "../../common/submitButton";
import TableRow from "../../common/tableRow";

const validationSchema = yupValidationSchema({
  notes: { required: true, customName: "Notes" },
});

const LeadDetail = ({
  setLeadDetail,
  leadDetail,
  showDetail,
  setShowDetail,
  saveHotLeads,
}) => {
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});
  const { callApi: saveExtraNotes } = usePost({
    endpoint: `leadinfo/notes`,
  });

  useEffect(() => {
    if (leadDetail?.notes) setNotes(leadDetail?.notes);
    else setNotes("");
  }, [leadDetail]);

  const submitNote = async () => {
    try {
      await validationSchema.validate(
        {
          notes,
        },
        { abortEarly: false }
      );
      setErrors({});
      saveExtraNotes({
        leadId: leadDetail._id,
        notes,
      });
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    }
  };

  useKey(() => {
    setShowDetail(false);
  }, "Escape");

  const markUnMarkHotLead = async () => {
    const resp = await saveHotLeads(
      { leadId: leadDetail._id, isHotLead: !leadDetail.isHotLead },
      null,
      true
    );
    if (resp.status === 200)
      setLeadDetail({ ...leadDetail, isHotLead: !leadDetail.isHotLead });
  };

  return (
    <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
      <CardBody>
        <CardTitle tag="h4">
          <Row className="justify-content-between align-items-center flex-nowrap">
            <Col xs={"auto"} md={"auto"}>
              <div className="d-flex">
                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                <h4>Lead Detail</h4>
              </div>
            </Col>
            <Col xs={"auto"} md={"auto"}>
              <ButtonGroup>
                <NavLink
                  to="#"
                  onClick={markUnMarkHotLead}
                  className={`btn-sm mr-2 ${
                    leadDetail?.isHotLead === true
                      ? "btn-danger"
                      : "btn-primary"
                  }`}
                  title="Mark/unmark Hot Lead"
                >
                  <FontAwesomeIcon
                    icon={leadDetail?.isHotLead === true ? faUserNinja : faUser}
                    size="xs"
                  />
                </NavLink>
                <Button
                  onClick={() => setShowDetail(false)}
                  color="primary"
                  size="sm"
                >
                  <FontAwesomeIcon icon={faWindowClose} size="xs" />
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </CardTitle>

        <CardText>
          <Card  className="mb-3 shadow">
            <CardBody>
              <CardTitle tag="h5">Basic Information</CardTitle>
              <CardText>
                <Table responsive striped bordered hover size="sm">
                  {leadDetail.photo && (
                    <TableRow
                      colSpanTH={2}
                      styleTD={{ display: "none" }}
                      label={
                        <img
                          alt="Lead"
                          width={100}
                          src={leadDetail.photo}
                          style={{ borderRadius: "50%", margin: "10px" }}
                        />
                      }
                    />
                  )}
                  <TableRow
                    label="Name"
                    value={
                      leadDetail.fullName
                        ? leadDetail.fullName
                        : `${leadDetail.firstName} ${leadDetail.lastName}`
                    }
                  />
                  <TableRow label="Email" value={leadDetail?.email} />
                  <TableRow label="Phone" value={leadDetail?.phone} />
                  <TableRow
                    label="Imported At"
                    value={formatDateTime(leadDetail?.dateAsPerUserTimezone)}
                  />
                  {/* {leadDetail.photo && (
                                        <tr style={{ whiteSpace: "nowrap" }}>
                                            <th colSpan={2}>
                                                <img src={leadDetail.photo} alt="Lead" width={100} style={{ borderRadius: "50%" }} />
                                            </th>
                                        </tr>
                                    )}
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Name</th>
                                        <td>
                                            {
                                                leadDetail.fullName
                                                    ?
                                                    leadDetail.fullName
                                                    :
                                                    `${leadDetail.firstName} ${leadDetail.lastName}`
                                            }
                                        </td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Email</th>
                                        <td>{leadDetail?.email}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Phone</th>
                                        <td>{leadDetail?.phone}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Imported At</th>
                                        <td>{formatDateTime(leadDetail?.dateAsPerUserTimezone)}</td>
                                    </tr> */}
                </Table>
              </CardText>
            </CardBody>
          </Card>
          <Card className="mb-3 shadow">
            <CardBody>
              <CardTitle tag="h5">Event Information</CardTitle>
              <CardText>
                <Table responsive striped bordered hover size="sm">
                  <TableRow label="Address" value={leadDetail?.address} />
                  <TableRow label="Event Name" value={leadDetail?.eventName} />
                  <TableRow label="Event Type" value={leadDetail?.eventType} />
                  <TableRow label="Event Date" value={leadDetail?.eventDate} />
                  <TableRow label="Event Time" value={leadDetail?.eventTime} />
                  <TableRow
                    label="Guest Count"
                    value={leadDetail?.guestCount}
                  />
                  <TableRow label="Budget" value={leadDetail?.budget} />
                  <TableRow label="Company" value={leadDetail?.company} />
                  <TableRow label="Imported At" value={leadDetail?.company} />
                  <TableRow
                    label="Source"
                    value={formatLeadSource(leadDetail?.leadSource)}
                    classNameTD="autoCapital"
                  />
                  <TableRow
                    label="Notes/Inquiry"
                    value={leadDetail?.inquiry}
                    styleTD={{ whiteSpace: "normal" }}
                  />
                  {/* <tr style={{ whiteSpace: "nowrap" }} >
                                        <th>Event Name</th>
                                        <td>{leadDetail?.eventName}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Event Type</th>
                                        <td>{leadDetail?.eventType}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Event Date</th>
                                        <td>{leadDetail?.eventDate}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Event Time</th>
                                        <td>{leadDetail?.eventTime}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Guest Count</th>
                                        <td>{leadDetail?.guestCount}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Budget</th>
                                        <td>{leadDetail?.budget}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Company</th>
                                        <td>{leadDetail?.company}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Imported At</th>
                                        <td>{formatDateTime(leadDetail?.dateAsPerUserTimezone)}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Source</th>
                                        <td className="autoCapital">{formatLeadSource(leadDetail?.leadSource)}</td>
                                    </tr>
                                    <tr >
                                        <th>Notes/Inquiry</th>
                                        <td >{leadDetail?.inquiry}</td>
                                    </tr> */}
                </Table>
              </CardText>
            </CardBody>
          </Card>

          <Card className="shadow">
            <CardBody>
              <CardTitle tag="h5">Extra Information</CardTitle>
              <CardText>
                <FormGroup>
                  <Label for="extraInfo">
                    You can add extra information regarding lead
                  </Label>
                  <Input
                    id="extraInfo"
                    name="text"
                    type="textarea"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    invalid={errors.notes}
                  />
                  <ValidationErrorHandling error={errors.notes} />
                </FormGroup>
              </CardText>
              <CardFooter>
                <SubmitButton onClick={submitNote} cancleButton={false} />
              </CardFooter>
            </CardBody>
          </Card>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default LeadDetail;
