/**
 * NOTE: This is exactly same as GoHighLevel CRM because bloom is underneath using gohighlevel API
 * Customer just want bloom icon and title instead of GoHighLevel branding
 */
import React, { useEffect, useState } from "react";
import { Col, Form, FormGroup, FormText, Input, Label, Row } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_BLOOM = process.env.REACT_APP_INTEGRATION_BLOOM;

const validationSchema = yupValidationSchema({
  bloomApiKey: { required: true, customName: "Api key" },
});

const BloomForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [bloomApiKey, setBloomApiKey] = useState("");
  const [sendLeadsLimitBloom, setSendLeadsLimitBloom] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log(
      "Javed Blooming->",
      DESTINATION_BLOOM,
      process.env.REACT_APP_INTEGRATION_BLOOM
    );
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_BLOOM) {
          setBloomApiKey(secretKey);
          setSendLeadsLimitBloom(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleBloomForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ bloomApiKey }, { abortEarly: false });
      setLoading(true);
      await saveIntegration(
        {
          secretKey: bloomApiKey,
          sendLeadsLimit: sendLeadsLimitBloom,
          integrationCompany: DESTINATION_BLOOM,
        },
        MSG_DESTINATION.SAVE_BLOOM
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader headerTitle="Bloom" headerImage="images/bloom.png">
      <Form onSubmit={handleBloomForm} autoComplete="off">
        <FormGroup>
          <FormText color="info"></FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="API key"
                autocomplete="off"
                type="text"
                defaultValue={bloomApiKey}
                value={bloomApiKey}
                onChange={(e) => setBloomApiKey(e.target.value)}
                invalid={errors.bloomApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.bloomApiKey} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitBloom}
                onChange={(e) => setSendLeadsLimitBloom(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter handleSubmit={handleBloomForm} loading={loading} />
      </Form>
    </DestinationHeader>
  );
};

export default BloomForm;