import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_SMPLSYSTEM = process.env.REACT_APP_INTEGRATION_CATEREASE;

const validationSchema = yupValidationSchema({
  webformLink: { required: true, customName: "Webform link", url: true },
});

const SmplSystem = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [webformLink, setWebformLink] = useState("");
  const [sendLeadsLimitSmplSystem, setLeadsLimitSmplSystem] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const { integrationCompany, secretKey, leadSentConfig } = dest;
        if (integrationCompany === DESTINATION_SMPLSYSTEM) {
          setWebformLink(secretKey);
          setLeadsLimitSmplSystem(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleSmplSystem = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate({ webformLink }, { abortEarly: false });
      setLoading(true);
      await saveIntegration(
        {
          secretKey: webformLink,
          sendLeadsLimit: sendLeadsLimitSmplSystem,
          integrationCompany: DESTINATION_SMPLSYSTEM,
        },
        MSG_DESTINATION.SAVE_SMPLSYSTEM
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    // <div className="rounded border shadow p-3 my-3">

    <DestinationHeader headerTitle="SMPL System" headerImage="images/SMPLsystem.png">
      <Form onSubmit={handleSmplSystem} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="webform_link">
                Leads Form Link{" "}
                {/* <small style={{ color: "#17a2b8" }}>
                  (<strong>Sample Link:</strong> https://inquiries.com/25XXXX-XC/123/)
                </small> */}
              </Label>
              <Input
                id="webform_link"
                name="webform_link"
                placeholder=" URL"
                autocomplete="off"
                type="url"
                defaultValue={webformLink}
                value={webformLink}
                onChange={(e) => setWebformLink(e.target.value)}
                invalid={errors.webformLink}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.webformLink} />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">How Many Leads Do You Want To Send Initially?</Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitSmplSystem}
                onChange={(e) => setLeadsLimitSmplSystem(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter handleSubmit={handleSmplSystem} loading={loading} />
      </Form>
    </DestinationHeader>
    // </div>
  );
};

export default SmplSystem;
