import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  FormGroup,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useDebounce from "../../utils/customHooks";
import SOURCES from "../../utils/constants";
import useGet from "../../services/useGet";
import Paginate from "../common/pagination";
import CouponsForm from "../forms/admin/couponsForm";
import CouponsList from "./modules/couponsList";
import CouponDetail from "./modules/couponDetail";
import HeaderSearchInput from "../common/headerSearchInput";

const Coupons = ({ showFormInitial = false, parentMethod = null }) => {
  const { DEFAULT_RECORDS_LISTING } = SOURCES;

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: DEFAULT_RECORDS_LISTING,
  });

  const [showForm, setShowForm] = useState(showFormInitial);
  const [id, setId] = useState("");
  const [couponId, setCouponId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [couponInfo, setCouponInfo] = useState();
  const [showDetail, setShowDetail] = useState(false);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getCoupons,
    data: coupons,
    isLoading: getCouponsLoader,
  } = useGet({
    endpoint: `coupon?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: getCoupon,
    data: coupon,
    isLoading: getCouponLoader,
  } = useGet({
    endpoint: `coupon`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoader,
  } = useGet({
    endpoint: `coupon/search?q=${searchInput}`,
  });

  const isLoading = getCouponsLoader || getCouponLoader || searchDataLoader;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getCoupons();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getCoupons();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    setCouponInfo(coupons);
  }, [coupons]);

  useEffect(() => {
    setCouponInfo(searchData);
  }, [searchData]);

  return (
    <>
      <div>
        {showForm && !showDetail && (
          <CouponsForm
            id={id}
            setId={setId}
            setShowForm={setShowForm}
            parentMethod={parentMethod}
            coupon={coupon}
            getCoupons={getCoupons}
          />
        )}

        {!showForm && !showDetail && (
          <Row>
            <Col sm="12" className="mb-2">
              <Fade>
                <Card color="shadow">
                  <CardBody>
                    <CardTitle tag="h4">
                      <Row>
                        <Col sm={12}>
                          <FontAwesomeIcon icon={faCoins} className="mr-2" />
                          Coupons
                        </Col>
                      </Row>
                      <Row className="d-flex justify-content-space-between mt-2">
                        <Col>
                          <Button
                            color="primary"
                            className="mb-1"
                            onClick={() => setShowForm(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} className="mr-2" />
                            New Coupon
                          </Button>
                        </Col>
                        <Col sm={3}>
                          <HeaderSearchInput
                            className="mb-1"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                          />
                        </Col>
                      </Row>
                    </CardTitle>
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                    ></CardSubtitle>
                    <CardText>
                      <CouponsList
                        getCoupon={getCoupon}
                        isLoading={isLoading}
                        couponInfo={couponInfo}
                        setId={setId}
                        setShowForm={setShowForm}
                        getCoupons={getCoupons}
                        setShowDetail={setShowDetail}
                        setCouponId={setCouponId}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                      />
                    </CardText>
                  </CardBody>
                  <div className="px-4">
                    {!searchInput &&
                      couponInfo?.totalRecords >
                        SOURCES.DEFAULT_RECORDS_LISTING && (
                        <FormGroup>
                          <Paginate
                            setPagination={setPagination}
                            totalRecords={couponInfo?.totalRecords}
                            isLoading={isLoading}
                          />
                        </FormGroup>
                      )}
                  </div>
                </Card>
              </Fade>
            </Col>
          </Row>
        )}
      </div>
      <CouponDetail
        setShowDetail={setShowDetail}
        showDetail={showDetail}
        setShowForm={setShowForm}
        couponId={couponId}
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </>
  );
};

export default Coupons;
