import React, { useCallback, useEffect, useState } from 'react'
import { Pie, PieChart, ResponsiveContainer, Sector } from 'recharts'
import { Card } from "reactstrap";
import { capitalizeFirstLetter } from '../../utils/functions';

const CustomPieChart = ({ pieData, cardLabel }) => {
    const [chartData, setChartData] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    
    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    useEffect(() => {
        if (pieData) {
            const temp = [];
            pieData.map((cd) => {
                temp.push({
                    name: capitalizeFirstLetter(cd._id),
                    value: cd.count,
                    fill: fillColor()
                });
            });

            setChartData(temp);
        }
    }, [pieData]);

    const fillColor = () => {
        const colorCodes = [];

        for (let i = 0; i < 50; i++) {
            const red = Math.floor(Math.random() * 256);
            const green = Math.floor(Math.random() * 256);
            const blue = Math.floor(Math.random() * 256);

            const colorCode = `#${red.toString(16).padStart(2, "0")}${green.toString(16).padStart(2, "0")}${blue.toString(16).padStart(2, "0")}`;
            colorCodes.push(colorCode);
        }

        const randomIndex = Math.floor(Math.random() * colorCodes.length);
        return colorCodes[randomIndex];
    }

    const CustomActiveShape = ({ cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent }) => {
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;


        return (
            <g>
                <text x={cx} y={cy} dy={4} textAnchor="middle" style={{ width: "fit-content" }} fill={fill}>{payload.name}</text>
                <text x={cx} y={cy} dy={27} textAnchor="middle" style={{ fontWeight: "bold" }} fill={fill}>({payload.value})</text>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
            </g>
        );
    };

    return (
        <Card className="shadow-sm" color="light">
            {/* <CardTitle className="text-center mt-2" tag={"h4"}>{cardLabel}</CardTitle> */}
            <ResponsiveContainer width="100%" height={440}>
                <PieChart width={400} height={400}>
                    <text
                        x="50%" y="7%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="chart-label"
                        style={{
                            fontSize: "24px",
                            fontWeight: "500",
                            fill: "#333",
                            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)"
                        }}
                    >
                        {cardLabel}
                    </text>
                    <Pie
                        activeIndex={activeIndex}
                        activeShape={<CustomActiveShape />}
                        data={chartData}
                        // cx={250}
                        // cy={200}
                        innerRadius={60}
                        outerRadius={80}
                        dataKey="value"
                        onMouseEnter={onPieEnter}
                    />
                </PieChart>
            </ResponsiveContainer>
        </Card>
    )
}

export default CustomPieChart