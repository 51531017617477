import { faInfo, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Table,
  Row,
  Col,
  Spinner,
  Button,
} from "reactstrap";
import { useKey } from "../../../services/useKey";
import {
  capitalizeFirstLetter,
  formatDateTime,
  formatLeadSource,
} from "../../../utils/functions";
import TableRow from "../../common/tableRow";

const AutoresponseSentDetail = ({
  showDetail,
  setShowDetail,
  autoresponseSentDetailLoading,
  autoresponseSentDetail,
}) => {
  useKey(() => {
    setShowDetail(false);
  }, "Escape");

  return (
    <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
      <CardBody>
        {autoresponseSentDetailLoading ? (
          <Spinner className="ml-2" children=""></Spinner>
        ) : (
          <>
            <CardTitle tag="h4">
              <Row className="justify-content-between flex-nowrap">
                <Col md={"auto"}>
                  <div className="d-flex">
                    <FontAwesomeIcon icon={faInfo} className="mr-2" />
                    <h4>Autoresponse Sent Detail</h4>
                  </div>
                </Col>
                <Col md={"auto"} xs={"auto"}>
                  <Button
                    onClick={() => setShowDetail(false)}
                    color="primary"
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faWindowClose} size="sm" />
                  </Button>
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle
              className="mb-2 text-muted text-align-center"
              tag="h6"
            ></CardSubtitle>

            <CardText>
              <div className="border p-3 my-3 shadow rounded">
                <CardTitle tag="h4"> Message</CardTitle>
                <Table responsive striped bordered hover size="sm">
                  {autoresponseSentDetail?.results[0]?.Autoresponder &&
                    autoresponseSentDetail?.results[0]?.Autoresponder.map(
                      (Autoresponder) => {
                        return (
                          <>
                            <TableRow
                              label="Sender Name"
                              value={Autoresponder?.senderName}
                            />
                            <TableRow
                              label="Sender Email"
                              value={Autoresponder?.title}
                            />
                            <TableRow
                              label="Autoresponder Name"
                              value={Autoresponder?.name}
                            />
                            <TableRow
                              label="Autoresponder Message"
                              value={
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      Autoresponder.message.length > 100
                                        ? Autoresponder.message.substring(
                                            0,
                                            150
                                          ) + "..."
                                        : Autoresponder.message,
                                  }}
                                />
                              }
                            />

                            {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                            <th>Sender Name</th>
                                                            <td>{Autoresponder?.senderName} </td>
                                                            </tr>
                                                        <tr style={{ whiteSpace: "nowrap" }}>
                                                            <th>Sender Email</th>
                                                            <td>{Autoresponder?.title}</td>
                                                        </tr>
                                                        <tr style={{ whiteSpace: "nowrap" }}>
                                                            <th>Autoresponder Name</th>
                                                            <td>{Autoresponder?.name} </td>
                                                        </tr>
                                                        <tr style={{ whiteSpace: "nowrap" }}>
                                                            <th>Autoresponder Message</th>
                                                            <td>
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            Autoresponder.message.length > 100
                                                                                ? Autoresponder.message.substring(0, 150) + "..."
                                                                                : Autoresponder.message,
                                                                    }}
                                                                />
                                                            </td>
                                                        </tr> */}
                          </>
                        );
                      }
                    )}
                </Table>
              </div>
            </CardText>
            <CardText>
              <Table
                responsive
                striped
                hover
                size="sm"
                className="shadow rounded"
              >
                <TableRow
                  colSpanTD={5}
                  label="Send Date"
                  value={formatDateTime(
                    autoresponseSentDetail?.results[0]?.dateAsPerUserTimezone,
                    "MMM Do YY"
                  )}
                />
                <TableRow colSpanTD={6} label="Autoresponder Sent Lead(s)" />
                <tr style={{ whiteSpace: "nowrap" }}>
                  <th>S#</th>
                  <th>Sent Time</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Lead Source</th>
                </tr>
                {/* <tr style={{ whiteSpace: "nowrap" }}>
                                    <th>Send Date</th>
                                    <td colSpan={5}>{formatDateTime(autoresponseSentDetail?.results[0]?.dateAsPerUserTimezone, "MMM Do YY")}</td>
                                </tr>
                                <tr style={{ whiteSpace: "nowrap" }}>
                                    <th colSpan={6}>Autoresponder Sent Lead(s)</th>
                                </tr> */}

                {autoresponseSentDetail?.results[0]?.leads &&
                  autoresponseSentDetail?.results[0]?.leads?.map(
                    (lead, index) => {
                      // const { firstName, lastName, email, phone, leadSource } = lead?.leadDetail
                      return (
                        <>
                          <tr style={{ whiteSpace: "nowrap" }}>
                            <th>{index + 1}</th>
                            <td>{formatDateTime(lead?.sentAt, "h:mm")}</td>
                            <td>
                              {lead?.leadDetail?.firstName}{" "}
                              {lead?.leadDetail?.lastName}
                            </td>
                            <td>{lead?.leadDetail?.email}</td>
                            <td>{lead?.leadDetail?.phone}</td>
                            <td>
                              {formatLeadSource(
                                capitalizeFirstLetter(
                                  lead?.leadDetail?.leadSource
                                )
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    }
                  )}
              </Table>
            </CardText>
          </>
        )}
      </CardBody>
    </Card>
  );
};

export default AutoresponseSentDetail;
