import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import {
  Input,
  InputGroup,
  InputGroupText,
  Form,
  FormGroup,
  FormFeedback,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useGet from "../services/useGet";
import CheckoutForm from "./stripe/CheckoutForm";
import usePost from "../services/usePost";
import {
  checkYupError,
  handleYupValidationErrors,
  showUserNotification,
  yupValidationSchema,
} from "../utils/functions";
import SOURCES from "../utils/constants";
import useDebounce from "../utils/customHooks";
import ValidationErrorHandling from "./common/validationErrorHandling";
import SubmitButton from "./common/submitButton";

const siteKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY_LIVE;

const validationSchema = yupValidationSchema({
  name: { required: true, customName: "Name" },
  company: { required: true, customName: "Company name" },
  phone: { required: true, customName: "Phone no" },
  email: { email: true, required: true, customName: "Email" },
  password: { required: true, customName: "Password" },
  confirmPassword: { required: true, customName: "Confirm password" },
  paymentPlanId: { required: true, customName: "Plan" },
});

const Signup = () => {
  const { DISCOUNT_TRIAL } = SOURCES;
  const queryParams = new URLSearchParams(window.location.search);
  const plan = queryParams.get("plan");
  const trial = queryParams.get("trial") === null ? "n" : queryParams.get("trial");
  const referral = queryParams.get("ref") === null ? "" : queryParams.get("ref");
  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [paymentPlanId, setPaymentPlanId] = useState("");
  const [paymentPlanDescription, setPaymentPlanDescription] = useState("");
  const [paymentStripePlanId, setPaymentStripePlanId] = useState("-1");
  const [paymentPlanCode, setPaymentPlanCode] = useState("");
  const [coupon, setCoupon] = useState("");
  const [couponId, setCouponId] = useState("");
  const [couponTrial, setCouponTrial] = useState(false);
  const [captchaToken, setCaptchaToken] = useState();
  const [showPasswordHint, setShowPasswordHint] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [errors, setErrors] = useState({});

  const togglePassword = () => {
    if (passwordType === "password") setPasswordType("text");
    else setPasswordType("password");
  };

  const debouncedSearchTerm = useDebounce(coupon, 500);

  const { callApi: getPaymentPlans, data: paymentPlans } = useGet({
    endpoint: `payment/plans`,
  });

  const { callApi: checkUser } = usePost({
    endpoint: `/user/check`,
  });

  const { callApi: doSubscription } = usePost({
    endpoint: `/payment/subscription`,
  });

  const { callApi: doSignup } = usePost({
    endpoint: `/user/signup`,
  });

  const { callApi: doPaymentVerify } = usePost({
    endpoint: `user/payment/verified`,
  });

  const { callApi: validateCoupon } = usePost({
    endpoint: `/coupon/validate-coupon`,
  });

  const { callApi: validateReferral } = usePost({
    endpoint: `/affiliate/validate-referral`,
  });

  useEffect(() => {
    getPaymentPlans();
  }, []);

  useEffect(() => {
    if (plan) getPaymentPlanDetail(plan);
  }, [plan, paymentPlans]);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) checkTrialCoupon();
  }, [debouncedSearchTerm]);

  /**
   * Check if COUPON added is a TRAIL signup coupon so that we must hide Stripe Card
   */
  const checkTrialCoupon = async () => {
    setCouponTrial(false);
    const couponCheck = await validateCoupon({ coupon });
    if (couponCheck) {
      setCouponId(couponCheck._id);
      if (couponCheck?.couponType === DISCOUNT_TRIAL) setCouponTrial(true);
    }
  };

  const signupProcess = async (paymentStatus = false, isTrial = "n") => {
    // window.grecaptcha.ready(() => {
    // window.grecaptcha.execute(siteKey, { action: 'submit' }).then(async(token) => {

    let result = null;
    try {
      result = await doSignup({
        email,
        password,
        confirmPassword,
        name,
        phone,
        company,
        paymentStatus,
        paymentPlanId,
        captchaToken,
        isTrial,
        couponId,
        referral,
      });
      return result;
    } catch (err) {
      dispatch(showUserNotification(err.response.data.data.error, "danger"));
      return result;
    }

    // });
    //});
  };

  const paymentStatus = async (email, verificationCode, stripeSubscriptionId) => {
    try {
      await doPaymentVerify({ email, verificationCode, stripeSubscriptionId });
    } catch (err) {
      dispatch(showUserNotification(err.response.data.data.error, "danger"));
    }
  };

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          email,
          password,
          confirmPassword,
          name,
          phone,
          company,
          paymentPlanId,
        },
        { abortEarly: false }
      );

      setLoading(true);
      setErrors({});
      const userCheck = await checkUser({ email }, null, true, false);
      if (userCheck?.status === 403) {
        setLoading(false);
        dispatch(showUserNotification(userCheck.data.error, "danger"));
        return;
      }

      /**
       * If TRIAL account creating via COUPON or pass trial params in signup url
       */
      //if (coupon || trial === 'y') {
      if (coupon) {
        /*if(trial === 'y') {
          const signupResult = await signupProcess(true,"y");
            if (!signupResult) {
              setLoading(false);
              return;
            }
            dispatch(showUserNotification("Please check your email"));
            setTimeout(() => {
              history.push("/");
            }, 2000);
            return;
        }//*/

        const couponCheck = await validateCoupon({ coupon });

        if (!couponCheck) {
          setCoupon("");
          setCouponId(null);
          setLoading(false);
          return;
        } else {
          if (couponCheck?.couponType === DISCOUNT_TRIAL) {
            const signupResult = await signupProcess(true, "y");
            if (!signupResult) {
              setLoading(false);
              return;
            }
            dispatch(showUserNotification("Please check your email"));
            setTimeout(() => {
              history.push("/");
            }, 2000);
            return;
          }
        }
      }

      if (paymentStripePlanId === "-1") {
        setLoading(false);
        dispatch(showUserNotification("Choose Plan", "danger"));
        return;
      }
      if (paymentStripePlanId) {
        if (!stripe || !elements) {
          setLoading(false);
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }

        const resultStripe = await stripe.createPaymentMethod({
          type: "card",
          card: elements.getElement(CardElement),
          billing_details: {
            email: email,
          },
        });
        if (resultStripe.error) {
          setLoading(false);
          dispatch(showUserNotification(resultStripe.error.message, "danger"));
        } else {
          const signupResult = await signupProcess(false, trial);
          if (!signupResult) return;
          const verificationCode = signupResult.verificationCode;

          try {
            const res = await doSubscription({
              payment_method: resultStripe.paymentMethod.id,
              email,
              name,
              stripePlanId: paymentStripePlanId,
              coupon,
              trial,
              referral,
            });
            const { client_secret, status, subscription_id } = res;

            if (status === "requires_action") {
              stripe.confirmCardPayment(client_secret).then(async (resultStripe) => {
                if (resultStripe.error) {
                  console.log(resultStripe.error);
                  // Display error message in your UI.
                  // The card was declined (i.e. insufficient funds, card has expired, etc)
                  dispatch(showUserNotification(resultStripe.error, "danger"));
                } else {
                  await paymentStatus(email, verificationCode, subscription_id);
                  dispatch(showUserNotification("Please check your email"));
                  setTimeout(() => {
                    history.push("/");
                  }, 2000);
                }
              });
            } else {
              // No additional information was needed
              await paymentStatus(email, verificationCode, subscription_id);
              dispatch(showUserNotification("Please check your email"));
              setTimeout(() => {
                history.push("/");
              }, 2000);
            }
          } catch (errSub) {
            setLoading(false);
            dispatch(showUserNotification("Subscription failed", "danger"));
          }
          // eslint-disable-next-line camelcase
          // console.log('sub dadta',res);
        }
      } else {
        /*  If PAY AS YOU GO Plan is selected then no need to charge customer now,
         *   because we can create invoice for each lead import and show them under invoice section
         */
        const signupResult = await signupProcess(true);
        setLoading(false);
        if (!signupResult) return;
        dispatch(showUserNotification("Please check your email"));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      }
      // }
    } catch (err) {
      setErrors(handleYupValidationErrors(err));
      setLoading(false);
      if (!checkYupError(err)) {
        dispatch(showUserNotification("Unexpected error occurred please try again", "danger"));
      }
    } finally {
    }
  };

  const getPaymentPlanDetail = async (planId) => {
    setPaymentPlanId(planId);
    setPaymentPlanDescription("");

    paymentPlans &&
      paymentPlans.map((plan) => {
        if (planId === plan._id) {
          setPaymentStripePlanId(plan.stripePlanId);
          setPaymentPlanDescription(plan.planDetail);
          setPaymentPlanCode(plan.planCode);
        }
      });
  };

  useEffect(() => {
    let isValid = /[A-Z]/.test(password) && /[0-9]/.test(password) && password.length >= 8;
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (specialChars.test(password)) isValid = false;
    if (password.includes(" ")) isValid = false;

    if (isValid) setShowPasswordHint(false);
    else setShowPasswordHint(true);
  }, [password]);

  return (
    <Fade>
      <Row className="m-0">
        <Col sm="3" className="mb-2"></Col>
        <Col sm="6" className="mb-2 m-4">
          <Card className="light shadow mt-2 p-4">
            <div className="brand-logo text-center mb-4">
              <img src="/logo.png" alt="Leads Import" width="110px" />
            </div>
            <CardBody>
              <CardTitle tag="h4">Sign Up</CardTitle>
              <CardText>
                <Form onSubmit={signupProcess}>
                  <FormGroup>
                    <Input
                      className="form-control form-control-lg"
                      id="name"
                      name="name"
                      placeholder="Full name"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      autoComplete="off"
                      autoFocus
                      invalid={errors.name}
                    />
                    <ValidationErrorHandling error={errors.name} />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      id="company"
                      name="company"
                      placeholder="Company name"
                      className="form-control form-control-lg"
                      type="text"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      autoComplete="off"
                      invalid={errors.company}
                    />
                    <ValidationErrorHandling error={errors.company} />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      id="phone"
                      name="phone"
                      placeholder="Phone number"
                      className="form-control form-control-lg"
                      type="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      autoComplete="off"
                      invalid={errors.phone}
                    />
                    <ValidationErrorHandling error={errors.phone} />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      id="email"
                      name="email"
                      placeholder="Email address"
                      className="form-control form-control-lg"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      invalid={errors.email}
                    />
                    <ValidationErrorHandling error={errors.email} />
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <Input
                        id="password"
                        name="password"
                        placeholder="Password"
                        className="form-control form-control-lg"
                        type={passwordType}
                        value={password}
                        autoComplete="off"
                        invalid={errors.password}
                        style={{ borderRight: "0px" }}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <InputGroupText style={{ borderLeft: "0px", marginLeft: "-2px" }}>
                        <FontAwesomeIcon
                          icon={passwordType === "password" ? faEyeSlash : faEye}
                          onClick={togglePassword}
                        />
                      </InputGroupText>
                    </InputGroup>

                    {showPasswordHint && (
                      <FormFeedback className="form-group" style={{ fontSize: "14px", display: "inline" }}>
                        Must include (1) Capital Letter, (1) Numeric Digit, Minimum Length of (8) and must not
                        include Special Character.
                      </FormFeedback>
                    )}
                    <ValidationErrorHandling error={errors.password} />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm password"
                      className="form-control form-control-lg"
                      type={passwordType}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      autoComplete="off"
                      invalid={errors.confirmPassword}
                    />
                    <ValidationErrorHandling error={errors.confirmPassword} />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      name="confirm_password"
                      className="form-control form-control-lg"
                      type="select"
                      value={paymentPlanId}
                      invalid={errors.paymentPlanId}
                      onChange={(e) => getPaymentPlanDetail(e.target.value)}
                      autoComplete="off"
                    >
                      <option value="-1">Choose Plan</option>
                      {paymentPlans &&
                        paymentPlans.map((plan) => {
                          return (
                            <option value={plan._id} selected={plan._id === paymentStripePlanId}>
                              {plan.planName}
                            </option>
                          );
                        })}
                    </Input>
                    <ValidationErrorHandling error={errors.paymentPlanId} />
                  </FormGroup>
                  <FormGroup>{paymentPlanDescription}</FormGroup>

                  {paymentPlanCode && paymentPlanCode !== "p1" && (
                    <FormGroup>
                      <Input
                        id="coupon"
                        name="coupon"
                        placeholder="Enter coupon If any..."
                        className="form-control form-control-lg"
                        type="text"
                        value={coupon}
                        onChange={(e) => setCoupon(e.target.value)}
                        autoComplete="off"
                      />
                    </FormGroup>
                  )}
                  {!couponTrial && paymentPlanCode !== "p1" && (
                    <FormGroup 
                     
                    >
                      <CheckoutForm />
                    </FormGroup>
                  )}
                  <FormGroup>
                    <GoogleReCaptchaProvider reCaptchaKey={siteKey}>
                      <GoogleReCaptcha onVerify={(token) => setCaptchaToken(token)} />
                    </GoogleReCaptchaProvider>
                  </FormGroup>
                  <div className="mt-3">
                    <SubmitButton
                      title="Sign Up"
                      buttonClassName="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      onClick={handleForm}
                      icon={faUserPlus}
                      isLoading={loading}
                      cancleButton={false}
                      type="submit"
                    />
                    {/* <button
                    type="submit"
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={handleForm}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faUserPlus} className="mr-3"/>
                    Sign Up
                    {loading && (
                      <Spinner
                        className="ml-2" 
                        color="light"
                        size="sm"
                        children=""
                      />
                    )}
                  </button> */}
                  </div>
                  <div className="text-center mt-4 font-weight-light">
                    Already have an account?{" "}
                    <Link to="/" className="text-primary">
                      Sign In
                    </Link>
                  </div>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col sm="3" className="mb-2"></Col>
      </Row>
    </Fade>
  );
};

export default Signup;
