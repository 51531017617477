import CheckoutForm from "./CheckoutForm";
import './stripe.css';


const Stripe = () => {


  return (    
    <>
      <CheckoutForm />          
     </>
  );
};

export default Stripe;
