import React, { useEffect, useState } from 'react'
import { Card, CardBody } from "reactstrap";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts'
import { formatDateTime } from '../../utils/functions';

const CustomBarChart = ({ getData, data, title }) => {
  
  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data) {
      const temp = [];
      data.map((cd) => {
        temp.push({
          name: formatDateTime(cd._id, "MM-D-YYYY"),
          [title]: cd.count,
        });
      });
      setChartData(temp);
    }
  }, [data]);

  return (
    <Card className="shadow-sm" color="light" >
      <CardBody className="px-0">
        <ResponsiveContainer width="100%" height={400} >
          <BarChart
            width={600}
            height={300}
            totalJobs
            data={chartData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Bar dataKey={title} fill="#007bff" />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
          </BarChart>
        </ResponsiveContainer>
      </CardBody>
    </Card>
  )
}

export default CustomBarChart