import { CardTitle, CardSubtitle, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Header = ({ title, icon, buttonTitle = null, buttonIcon, setShowForm = null, callBack = null }) => {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap">
                <CardTitle tag="h4">
                    <FontAwesomeIcon icon={icon} className="mr-2" />
                    {title}
                </CardTitle>
                {buttonTitle &&
                    <Button
                        color="primary"
                        className="float-right mb-2"
                        onClick={() => {
                            setShowForm(true);
                            if (callBack) callBack();
                        }}
                    >
                        <FontAwesomeIcon icon={buttonIcon} className="mr-2" />
                        {buttonTitle}
                    </Button>
                }
            </div>
            <CardSubtitle
                className="mb-2 text-muted"
                tag="h6"
            >
            </CardSubtitle>
        </>
    );
}

export default Header;