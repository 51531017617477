import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey, faLock } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Form,
  FormGroup,
  Label,
  Input,
  CardFooter,
  Row,
  Col,
  Fade,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import usePost from "../services/usePost";
import LocalStorageService from "../utils/localStorageServices";
import {
  handleYupValidationErrors,
  showUserNotification,
  yupValidationSchema,
} from "../utils/functions";
import ValidationErrorHandling from "./common/validationErrorHandling";
import SubmitButton from "./common/submitButton";

const localStorageServices = LocalStorageService.getService();

const validationSchema = yupValidationSchema({
  currentPassword: { required: true, customName: "Current Password" },
  newPassword: { required: true, customName: "New Password" },
  confirmPassword: { required: true, customName: "Confirm Password" },
});
const Changepassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = localStorageServices.getCurrentUser();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const { callApi: changeUserPassword } = usePost({
    endpoint: `user/changepassword`,
  });

  const handleForm = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await validationSchema.validate(
        {
          currentPassword,
          newPassword,
          confirmPassword,
        },
        { abortEarly: false }
      );

      await changeUserPassword(
        {
          email: currentUser?.user?.email,
          currentPassword,
          newPassword,
          confirmPassword,
        },
        "Password changed successfully"
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
      setIsLoading(false);
      dispatch(showUserNotification(e.response.data.data.error, "danger"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fade>
      <div className="mt-4">
        <Card color="shadow">
          <CardBody>
            <CardTitle tag="h4">
              <FontAwesomeIcon icon={faKey} className="mr-2" />
              Change Password
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
            <CardText>
              <Form onSubmit={handleForm}>
                <Row>
                  <Col lg={6} sm={12}>
                    <FormGroup>
                      <Label for="currentPassword">Current Password</Label>
                      <Input
                        id="currentPassword"
                        name="currentPassword"
                        placeholder="Current password"
                        type="password"
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        value={currentPassword}
                        autoFocus
                        invalid={errors.currentPassword}
                      />
                      <ValidationErrorHandling error={errors.currentPassword} />
                    </FormGroup>
                  </Col>

                  <Col lg={6} sm={12}></Col>

                  <Col lg={6} sm={12}>
                    <FormGroup>
                      <Label for="newPassword">New Password</Label>
                      <Input
                        id="newPassword"
                        name="newPassword"
                        placeholder="New password"
                        type="password"
                        onChange={(e) => setNewPassword(e.target.value)}
                        value={newPassword}
                        invalid={errors.newPassword}
                      />
                      <ValidationErrorHandling error={errors.newPassword} />
                    </FormGroup>
                  </Col>

                  <Col lg={6} sm={12}>
                    <FormGroup>
                      <Label for="confirmPassword">Confirm Password</Label>
                      <Input
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm password"
                        type="password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        value={confirmPassword}
                        invalid={errors.confirmPassword}
                      />
                      <ValidationErrorHandling error={errors.confirmPassword} />
                    </FormGroup>
                  </Col>
                </Row>

                <CardFooter>
                  <SubmitButton
                    title="Change Password"
                    icon={faLock}
                    isLoading={isLoading}
                    cancleButton={false}
                    goBackButton={true}
                    goBackButtonOnClick={() => history.push("/profile")}
                  />
                  {/* <Button type="submit" ty color="primary" onClick={handleForm}>
               <FontAwesomeIcon icon={faLock} className="mr-2" />
                  Change Password
                  {isLoading && <Spinner className="ml-2" color="light" size="sm" children="" />}
                </Button> */}
                </CardFooter>
              </Form>
            </CardText>
          </CardBody>
        </Card>
      </div>
    </Fade>
  );
};

export default Changepassword;
