import {
  Card,
  CardBody,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
} from "reactstrap";

const SettingBasics = ({
  settingMaxUploadLimit,
  setSettingAutomaticallyAcceptLead,
  settingOptions,
  settingAutomaticallyAcceptLead,
}) => {
  return (
    <Card className="shadow" style={{ marginTop: "10px" }}>
      <CardBody>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label for="maxLeadCsvUploadLimit">
                Max CSV Leads Upload Limit
              </Label>
              <Input
                id="maxLeadCsvUploadLimit"
                name="maxLeadCsvUploadLimit"
                type="text"
                className="w-100 p-2 "
                value={settingMaxUploadLimit}
                tabIndex={1}
                readOnly
              ></Input>
            </FormGroup>
          </Col>

          <Col sm={6}>
            <FormGroup>
              <Label for="acceptLeadViaLeadForm">
                Automatically Accept Leads(Imported via Lead Form)
              </Label>
              <Input
                id="acceptLeadViaLeadForm"
                name="acceptLeadViaLeadForm"
                type="select"
                className="w-100 p-2 "
                onChange={(e) => {
                  setSettingAutomaticallyAcceptLead(e.target.value);
                }}
                tabIndex={1}
                autoFocus
              >
                {settingOptions.settingAutomaticallyAcceptLead?.map((opt) => {
                  return (
                    <option
                      value={opt?.id}
                      selected={
                        opt?.id.toString() === settingAutomaticallyAcceptLead
                      }
                    >
                      {opt.val}
                    </option>
                  );
                })}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default SettingBasics;
