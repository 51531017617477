import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLock } from "@fortawesome/free-solid-svg-icons";
import {
  Input,
  Form,
  FormGroup,
  Card,
  CardBody,
  CardTitle,
  CardText,
  InputGroupText,
  Row,
  Col,
  InputGroup,
  Fade,
  FormFeedback,
} from "reactstrap";
import { useDispatch } from "react-redux";
import usePost from "../services/usePost";
import {
  handleYupValidationErrors,
  showUserNotification,
  yupValidationSchema,
} from "../utils/functions";
import SubmitButton from "./common/submitButton";
import ValidationErrorHandling from "./common/validationErrorHandling";

const validationSchema = yupValidationSchema({
  code: { required: true, customName: "Verification Code" },
  newPassword: { required: true, customName: "New Password" },
  confirmPassword: { required: true, customName: "Confirm Password" },
});

const VerifyPassword = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const paramEmail = queryParams.get("email");
  const paramCode = queryParams.get("code");

  const dispatch = useDispatch();
  const history = useHistory();
  const [email, setEmail] = useState(paramEmail);
  const [code, setCode] = useState(paramCode);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPasswordHint, setShowPasswordHint] = useState(true);
  const [passwordType, setPasswordType] = useState("password");
  const [errors, setErrors] = useState({});

  const { callApi: doPasswordVerify, isLoading } = usePost({
    endpoint: `/user/forgotpassword-verify`,
  });

 useEffect(() => {
   let isValid =
     /[A-Z]/.test(newPassword) && /[0-9]/.test(newPassword) && newPassword.length >= 8;
   const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
   if (specialChars.test(newPassword)) isValid = false;
   if (newPassword.includes(" ")) isValid = false;

   if (isValid) setShowPasswordHint(false);
   else setShowPasswordHint(true);
 }, [newPassword]);

  const handleForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        {
          code,
          newPassword,
          confirmPassword,
        },
        { abortEarly: false }
      );

      const result = await doPasswordVerify({
        code,
        email,
        new_password: newPassword,
        confirm_password: confirmPassword,
      });
      if (result) {
        dispatch(showUserNotification("Password has been changed"));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      }
      setErrors({});
    } catch (err) {
      setErrors(handleYupValidationErrors(err));
      // dispatch(showUserNotification(err?.response?.data?.data?.error, "danger"));
    }
  };
  const changeFormValues = (e) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    else if (name === "code") setCode(value);
    else if (name === "new_password") setNewPassword(value);
    else if (name === "confirm_password") setConfirmPassword(value);
  };

  const togglePassword = () => {
    if (passwordType === "password") setPasswordType("text");
    else setPasswordType("password");
  };
  return (
    <Fade>
      <Row className="mr-0">
        <Col lg="4" md="3" className="mb-2"></Col>
        <Col lg="4" md="6" className="mb-2 m-4">
          <div className="brand-logo text-center mb-4">
            <img src="/logo.png" alt="Leads Import" width="110px" />
          </div>
          <Card className="light shadow mt-2 p-4">
            <CardBody>
              <CardTitle tag="h4">Change Password</CardTitle>
              <CardText>
                <Form onSubmit={handleForm}>
                  <FormGroup>
                    <Input
                      className="form-control form-control-lg"
                      id="code"
                      name="code"
                      placeholder="Check email for Verification Code"
                      type="text"
                      value={code}
                      onChange={changeFormValues}
                      invalid={errors.code}
                    />
                    <ValidationErrorHandling error={errors.code} />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      className="form-control form-control-lg"
                      id="email"
                      name="email"
                      placeholder="Email"
                      type="email"
                      value={email}
                      onChange={changeFormValues}
                      readOnly
                    />
                  </FormGroup>

                  <FormGroup>
                    <InputGroup>
                      <Input
                        className="form-control form-control-lg"
                        id="new_password"
                        name="new_password"
                        placeholder="New password"
                        type={passwordType}
                        value={newPassword}
                        onChange={changeFormValues}
                        style={{ borderRight: "0" }}
                        invalid={errors.newPassword}
                      />
                      <InputGroupText
                        style={{ borderLeft: "0", marginLeft: "-2px" }}
                      >
                        <FontAwesomeIcon
                          icon={
                            passwordType === "password" ? faEyeSlash : faEye
                          }
                          onClick={togglePassword}
                        />
                      </InputGroupText>
                    </InputGroup>
                    {showPasswordHint && (
                      <FormFeedback
                        className="form-group"
                        style={{ fontSize: "14px", display: "inline" }}
                      >
                        Must include (1) Capital Letter, (1) Numeric Digit,
                        Minimum Length of (8) and must not include Special
                        Character.
                      </FormFeedback>
                    )}
                    <ValidationErrorHandling error={errors.newPassword} />
                  </FormGroup>

                  <FormGroup>
                    <Input
                      className="form-control form-control-lg"
                      id="confirm_password"
                      name="confirm_password"
                      placeholder="Confirm password"
                      type="password"
                      value={confirmPassword}
                      onChange={changeFormValues}
                      invalid={errors.confirmPassword}
                    />
                    <ValidationErrorHandling error={errors.confirmPassword} />
                  </FormGroup>
                  <div className="mt-3">
                    <SubmitButton
                      title="Change"
                      buttonClassName="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                      icon={faLock}
                      isLoading={isLoading}
                      cancleButton={false}
                    />
                    {/* <button
                    type="button"
                    className="btn btn-block btn-primary btn-lg font-weight-medium auth-form-btn"
                    onClick={handleForm}
                    disabled={isLoading}
                  >
                      <FontAwesomeIcon icon={faLock} className="mr-2" />
                    Change
                    {isLoading && (
                      <Spinner
                        className="ml-2"
                        color="light"
                        size="sm"
                        children=""
                      />
                    )}
                  </button> */}
                  </div>
                  <div className="my-2 d-flex justify-content-between align-items-center">
                    <Link to="/" className="auth-link text-black">
                      Go to Login
                    </Link>
                  </div>
                </Form>
              </CardText>
            </CardBody>
          </Card>
        </Col>
        <Col lg="4" md="3" className="mb-2"></Col>
      </Row>
    </Fade>
  );
};
export default VerifyPassword;
