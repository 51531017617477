import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormGroup,
  FormText,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Select from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  listSearchable,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import useGet from "../../../services/useGet";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_PERFECTVENUE = process.env.REACT_APP_INTEGRATION_PERFECTVENUE;

const validationSchema = yupValidationSchema({
  perfectVenueUsername: { required: true, customName: "Email" },
  perfectVenuePassword: { required: true, customName: "Password" },
});

const PerfectVenueForm = ({ destinations, saveIntegration }) => {
  const [loading, setLoading] = useState(false);
  const [perfectVenueUsername, setPerfectVenueUsername] = useState("");
  const [perfectVenuePassword, setPerfectVenuePassword] = useState("");
  const [sendLeadsLimitPerfectVenue, setSendLeadsLimitPerfectVenue] =
    useState(0);
  const [venueOptions, setVenueOptions] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState("");
  const [errors, setErrors] = useState({});

  const {
    callApi: getVenues,
    data: venuesData,
    isLoading: venuesLoading,
  } = useGet({
    endpoint: `integration/perfectvenue-venues?destinationAccountEmail=${perfectVenueUsername}&destinationAccountPassword=${perfectVenuePassword}`,
  });

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          integrationCompany,
          destinationAccountEmail,
          destinationAccountPassword,
          leadSentConfig,
        } = dest;
        if (integrationCompany === DESTINATION_PERFECTVENUE) {
          setPerfectVenueUsername(destinationAccountEmail);
          setPerfectVenuePassword(destinationAccountPassword);
          setSendLeadsLimitPerfectVenue(leadSentConfig?.sentLimit);
        }
        if (dest?.perfectVenues) {
          setVenueOptions(dest?.perfectVenues);
        }
        if (dest?.extraInfo) {
          setSelectedVenue(dest?.extraInfo?.venue_id);
          console.log(selectedVenue);
        }
      }
    }
  }, [destinations]);

  const handleGetVenues = async () => {
    await getVenues();
  };

  useEffect(() => {
    if (venuesData) {
      const venues = venuesData.map((v) => v.venue);
      const formattedOptions = listSearchable(venues, "id", "name");
      setVenueOptions(formattedOptions);
    }
  }, [venuesData]);

  const handleHubspotFormForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { perfectVenueUsername, perfectVenuePassword },
        { abortEarly: false }
      );
      setLoading(true);
      await saveIntegration(
        {
          destinationAccountEmail: perfectVenueUsername,
          destinationAccountPassword: perfectVenuePassword,
          sendLeadsLimit: sendLeadsLimitPerfectVenue,
          extraInfo: { venue_id: selectedVenue },
          perfectVenues: venueOptions,
          integrationCompany: DESTINATION_PERFECTVENUE,
        },
        MSG_DESTINATION.SAVE_PERFECTVENUE
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };

  return (
    <DestinationHeader
      headerTitle="Perfect Venue"
      headerImage="images/perfectvenue.png"
    >
      <Form onSubmit={handleHubspotFormForm} autoComplete="off">
        <FormGroup>
          <FormText color="info"></FormText>
        </FormGroup>
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="username">Email</Label>
              <Input
                id="username"
                name="username"
                placeholder="Username"
                autocomplete="off"
                type="email"
                defaultValue={perfectVenueUsername}
                value={perfectVenueUsername}
                onChange={(e) => setPerfectVenueUsername(e.target.value)}
                invalid={errors.perfectVenueUsername}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.perfectVenueUsername} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                placeholder="Password"
                autocomplete="off"
                type="password"
                defaultValue={perfectVenuePassword}
                value={perfectVenuePassword}
                onChange={(e) => setPerfectVenuePassword(e.target.value)}
                invalid={errors.perfectVenuePassword}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.perfectVenuePassword} />
            </FormGroup>
          </Col>
          <Col lg={12}>
            <FormGroup>
              <div
                style={{
                  display: "flex",
                  gap: "8px",
                  marginBottom: "8px",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  onClick={() => {
                    handleGetVenues();
                  }}
                >
                  {venuesLoading ? (
                    <Spinner
                      className="mr-2"
                      color="light"
                      size="sm"
                      children=""
                    />
                  ) : (
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
                  )}
                  Get Venue(s)
                </Button>

                {venueOptions && (
                  <Select
                    isDisabled={venuesLoading}
                    placeholder="Select Venue"
                    timezonesLoading={false}
                    options={venueOptions}
                    onChange={(opt) => setSelectedVenue(opt.value)}
                    value={
                      venueOptions.constructor === Array &&
                      venueOptions.filter((option) => {
                        return (
                          option.value?.toString() === selectedVenue?.toString()
                        );
                      })
                    }
                  />
                )}
              </div>
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total Lead(s) Send Initially"
                type="text"
                value={sendLeadsLimitPerfectVenue}
                onChange={(e) => setSendLeadsLimitPerfectVenue(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleHubspotFormForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default PerfectVenueForm;
