import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfo, faWindowClose } from '@fortawesome/free-solid-svg-icons'
import { Button, Card, CardBody, CardText, CardTitle, Col, Row, Table } from 'reactstrap'
import useGet from '../../../services/useGet'
import { useKey } from '../../../services/useKey'
import { formatDateTime } from '../../../utils/functions'
import NoRecordsFound from '../../common/noRecordsFound'
import TableHeader from '../../common/tableHeader'
import TableLoader from '../../common/tableLoader'

const CouponDetail = ({ showDetail, setShowDetail, setShowForm, couponId,searchInput,setSearchInput }) => {
  useKey(() => {
    closeDetail()
  }, "Escape")

  const {
    callApi: getCouponDetails,
    data: couponDetail,
    isLoading: getCouponsDetailLoader,
  } = useGet({
    endpoint: `coupon/usage-history?couponId=${couponId}`,
  });

  useEffect(() => {
    if(couponId) getCouponDetails()
  }, [couponId]);

  const closeDetail = ()=>{
    setShowDetail(false);
    setShowForm(false);
    setSearchInput(searchInput);
    console.log('searchInput',searchInput)
  }

  return (
    <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
      <CardBody>
        <CardTitle tag="h4">
        <Row className="justify-content-between flex-nowrap">
            <Col md={"auto"}>
              <FontAwesomeIcon icon={faInfo} className="mr-2" />
              Usage History
            </Col>

            <Col md={"auto"} xs={"auto"}>
              <Button
                color="primary"
                onClick={() => {
                  closeDetail();
                }}
                size="sm"
              >
                <FontAwesomeIcon
                  icon={faWindowClose}
                  size="sm"
                />
              </Button>
            </Col>
          </Row>
      
        </CardTitle>
        <CardText>
          <Table responsive striped bordered hover size="sm">
            <TableHeader headers={[{ title: 'Name' }, { title: 'Email' }, { title: 'Phone' },{ title: 'Usage at' }]} />
            <tbody>
              {getCouponsDetailLoader ?
                <TableLoader colSpan="4" />
              :
              <>
                {couponDetail && couponDetail.length > 0 ?
                  couponDetail.map((cd, idx) => {
                    return (
                      <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                        <td>
                          {cd.name}
                        </td>
                        <td>{cd.email}</td>
                        <td>{cd.phone}</td>
                        <td>{formatDateTime(cd.createdAt)}</td>
                      </tr>
                    );
                  })
                  :
                  <NoRecordsFound colSpan="4" />
                }
              </>
}
            </tbody>

          </Table>
        </CardText>
      </CardBody>
    </Card>
  )
}

export default CouponDetail