import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input } from "reactstrap";

const HeaderSearchInput = ({ value, onChange, className = "my-2" }) => {
  return (
    <div style={{ position: "relative" }}>
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          position: "absolute",
          top: "50%",
          left: "10px",
          transform: "translateY(-50%)",
          fontSize: "1rem",
          color: "#8888",
          pointerEvents: "none",
        }}
      />
      <Input
        placeholder="Search"
        value={value}
        className={className}
        onChange={onChange}
        style={{ paddingLeft: "30px" }}
      />
    </div>
  );
};

export default HeaderSearchInput;
