
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import usePost from "../../services/usePost";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import Paginate from "../common/pagination";
import LeadViaFormList from "./modules/leadViaFormList";
import LeadViaFormDetail from "./modules/leadViaFormDetail";
import HeaderSearchInput from "../common/headerSearchInput";


const LeadsImportedViaCSV = () => {
  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });

  const [leadsImported, setLeadsImported] = useState();
  const [leadDetail, setLeadDetail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [leadList, setLeadList] = useState([]);
  const [selectAll, setSelectAll] = useState(false);


  // const [advanceSearch, setAdvanceSearch] = useState({
  //   advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
  //   startDate: "",
  //   endDate: "",
  //   leadSource: "",
  //   searchTerm: "",
  // });
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `weblink/lead-search?q=${searchInput}`,
  });

  // const {
  //   callApi: getSearchData,
  //   data: searchData,
  //   isLoading: searchDataLoading,
  // } = useGet({
  //   endpoint: `leadimportcsv/search?offset=${paginationSearch.offsetSearch}
  //   &limit=${paginationSearch.limitSearch}
  //   &q=${searchInput}
  //   &advance_search=${advanceSearch.advance_search_enabled}
  //   &startdate=${advanceSearch.startDate}
  //   &enddate=${advanceSearch.endDate}
  //   &leadsource=${advanceSearch.leadSource}
  //   &term=${advanceSearch.searchTerm}`,
  // });

  const { callApi: getLeadSourcesOptions } = useGet({
    endpoint: `leadsource/options`,
  });

  const {
    callApi: getLeadsImportedForm,
    data: leadsFormData,
    isLoading: getLeadsLoading,
  } = useGet({
    endpoint: `weblink/list/leads?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: processLeadImportedForm,
    processSuccess,
  } = usePost({
    endpoint: `weblink/lead/process`,
  });


  const isLoading = searchDataLoading || getLeadsLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const dataExist = leadsImported?.results && leadsImported?.results.length > 0 ? true : false;

  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
      else if (!debouncedSearchTerm) getLeadsImportedForm();
    },
    [debouncedSearchTerm]
  );


  // useEffect(() => {
  //   // setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
  //   setShowAdvanceSearch(false);
  // }, [searchInput])

  useEffect(() => {
    getLeadsImportedForm();
    getLeadSourcesOptions();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);


  // useEffect(() => {
  //   if ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT].indexOf(advanceSearch.advance_search_enabled) >= 0) getSearchData();
  // }, [paginationSearch.offsetSearch, paginationSearch.limitSearch]);


  useEffect(() => {
    setLeadsImported(leadsFormData);
  }, [leadsFormData]);



  useEffect(() => {
    if (leadsImported && totalRecords <= 0) setTotalRecords(leadsImported?.totalReords);
  }, [leadsImported])

  useEffect(() => {
    setLeadsImported(searchData);
    console.log(searchData, "searchData")
  }, [searchData]);

  const viewDetail = (item) => {
    setLeadDetail(item);
    setShowDetail(true);
  };

  const handleAcceptReject = (leadId, type = 'ACCEPT') => {
    processLeadImportedForm({ leadId, type })
  }
  return (
    <>
      <div>
        <Fade>
        <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
          <CardBody>
            <CardTitle>
              <Row className="justify-content-between">
                <Col md={12} lg={9} style={{ display: "flex" }} tag={"h4"}>
                  <FontAwesomeIcon icon={faFileImport} className="mr-2" />
                  Leads Imported Via Contact Form
                </Col>
                <Col md={12} lg={3}>
                  <HeaderSearchInput
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
            </CardSubtitle>
            <CardText>
              <LeadViaFormList
                isLoading={isLoading}
                dataExist={dataExist}
                leadsImported={leadsImported}
                viewDetail={viewDetail}
                // getLeadsImportedForm={getLeadsImportedForm}
                // setToggleDeleteBtn={setToggleDeleteBtn}
                setLeadList={setLeadList}
                leadList={leadList}
                setSelectAll={setSelectAll}
                selectAll={selectAll}
                getLeadsImportedForm={getLeadsImportedForm}
                processLeadImportedForm={processLeadImportedForm}
                processSuccess={processSuccess}
                handleAcceptReject={handleAcceptReject}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
              />
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput && totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
              <FormGroup>
                <Paginate
                  setPagination={setPagination}
                  totalRecords={totalRecords}
                  isLoading={isLoading}
                />
              </FormGroup>
            )}

            {/* {
              ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_DO_NOT_CALL_API_PARENT].indexOf(advanceSearch.advance_search_enabled) >= 0 || searchInput) && totalRecords > SOURCES.DEFAULT_RECORDS_SEARCH &&
              <FormGroup>
                <PaginateSearch
                  setPaginationSearch={setPaginationSearch}
                  totalRecords={leadsImported?.totalReords}
                />
              </FormGroup>
            } */}

          </div>
        </Card>
        </Fade>
        {leadDetail && (
          <Fade>
          <LeadViaFormDetail
            showDetail={showDetail}
            setShowDetail={setShowDetail}
            leadDetail={leadDetail}
            handleAcceptReject={handleAcceptReject}
            processSuccess={processSuccess}
          />
          </Fade>
        )
        }
      </div>
      {/* {toggleModal && <Popup showPopup={toggleModal} hidePopup={() => setToggleModal(false)} actionMethod={removeBulkLeadsImportedCSV} />} */}

    </>
  );
};

export default LeadsImportedViaCSV;