import React from 'react'
import { Card, CardBody, Fade, Table } from "reactstrap";
import { formatDateTime } from "../../../utils/functions";
import NoRecordsFound from '../../common/noRecordsFound';
import TableRow from '../../common/tableRow';

const ChangeUserEmailDetail = ({ userDetail }) => {
    return (
        <>
            {userDetail?.length != 0 ?
                <Fade>
                    <Card>
                        <CardBody>
                            <Table responsive striped bordered hover size="sm">
                                <>
                                    <TableRow label="Name" value={userDetail[0]?.name} />
                                    <TableRow label="Phone" value={userDetail[0]?.phone} />
                                    <TableRow label="Email" value={userDetail[0]?.email} />
                                    <TableRow label="Company" value={userDetail[0]?.company} />
                                    <TableRow label="Plan Name" value={userDetail[0]?.PaymentPlanInfo[0]?.planName} />
                                    <TableRow label="Registered At" value={formatDateTime(userDetail[0]?.createdAt)} />
                                </>
                            </Table>

                        </CardBody>
                    </Card>
                </Fade>
                :
                <center style={{ fontWeight: "500", padding: "10px 0px" }}>
                    <NoRecordsFound />
                </center>
            }
        </>
    )
}

export default ChangeUserEmailDetail