import { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_EVENTTEMPLE = process.env.REACT_APP_INTEGRATION_EVENTTEMPLE;

const validationSchema = yupValidationSchema({
  eventtempleOrganizationKey: {
    required: true,
    customName: "Organization key",
  },
  eventtempleApiKey: { required: true, customName: "Api key" },
});
const EventtempleForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [eventtempleOrganizationKey, setEventtempleOrganizationKey] =
    useState("");
  const [eventtempleApiKey, setEventtempleApiKey] = useState("");
  const [sendLeadsLimitEventtemple, setSendLeadsLimitEventtemple] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          integrationCompany,
          destinationAccountEmail,
          destinationAccountPassword,
          leadSentConfig,
        } = dest;
        if (integrationCompany === DESTINATION_EVENTTEMPLE) {
          setEventtempleOrganizationKey(destinationAccountEmail);
          setEventtempleApiKey(destinationAccountPassword);
          setSendLeadsLimitEventtemple(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handleEventTempleForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { eventtempleOrganizationKey, eventtempleApiKey },
        { abortEarly: false }
      );
      setErrors({});
      setLoading(true);
      await saveIntegration(
        {
          destinationAccountEmail: eventtempleOrganizationKey,
          destinationAccountPassword: eventtempleApiKey,
          sendLeadsLimit: sendLeadsLimitEventtemple,
          integrationCompany: DESTINATION_EVENTTEMPLE,
          locations: [],
        },
        MSG_DESTINATION.SAVE_EVENTTEMPLE
      );
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader
      headerTitle="EventTemple"
      headerImage="images/eventtemple.png"
    >
      <Form onSubmit={handleEventTempleForm} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="organization_key">Organization Key</Label>
              <Input
                id="organization_key"
                name="organization_key"
                placeholder="Get Organization key from EventTemple Application"
                autocomplete="off"
                type="text"
                defaultValue={eventtempleOrganizationKey}
                value={eventtempleOrganizationKey}
                onChange={(e) => setEventtempleOrganizationKey(e.target.value)}
                invalid={errors.eventtempleOrganizationKey}
                autoComplete="off"
              />
              <ValidationErrorHandling
                error={errors.eventtempleOrganizationKey}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="api_key">API Key</Label>
              <Input
                id="api_key"
                name="api_key"
                placeholder="Get API key from EventTemple Application"
                autocomplete="off"
                type="text"
                defaultValue={eventtempleApiKey}
                value={eventtempleApiKey}
                onChange={(e) => setEventtempleApiKey(e.target.value)}
                invalid={errors.eventtempleApiKey}
                autoComplete="off"
              />
              <ValidationErrorHandling error={errors.eventtempleApiKey} />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitEventtemple}
                onChange={(e) => setSendLeadsLimitEventtemple(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handleEventTempleForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default EventtempleForm;
