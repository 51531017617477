import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy, faFileAlt, faLink, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardFooter,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
  Button,
  CardTitle,
  Fade,
} from "reactstrap";
import { useFormik } from "formik";
import WebLinkForm from "./modules/webLinkForm";
import usePost from "../services/usePost";
import useGet from "../services/useGet";
import Header from "./common/header";
import Select from "react-select";
import LeadGenerateFormList from "./modules/leadGenerateFormList";
import { useKey } from "../services/useKey";
import ValidationErrorHandling from "./common/validationErrorHandling";
import { yupValidationSchema } from "../utils/functions";
import SOURCES from "../utils/constants";
import SubmitButton from "./common/submitButton";

const INITIAL_STATE = {
  firstName: "",
  firstNameRequired: false,
  firstNameHidden: false,
  lastName: "",
  lastNameRequired: false,
  lastNameHidden: false,
  phone: "",
  phoneRequired: false,
  phoneHidden: false,
  email: "",
  emailRequired: false,
  emailHidden: false,
  eventName: "",
  eventNameRequired: false,
  eventNameHidden: false,
  eventDate: "",
  eventDateRequired: false,
  eventDateHidden: false,
  eventType: "",
  eventTypeRequired: false,
  eventTypeHidden: false,
  budget: "",
  budgetRequired: false,
  budgetHidden: false,
  guestCount: "",
  guestCountRequired: false,
  guestCountHidden: false,
  company: "",
  companyRequired: false,
  companyHidden: false,
  address: "",
  addressRequired: false,
  addressHidden: false,
  notesInquiry: "",
  notesInquiryRequired: false,
  notesInquiryHidden: false,
  formName: "",
  autoresponseDelay: "",
  conversateInbox: "",
  conversateAgent: "",
  conversateType: "",
  conversateMessage: "",
};

const WebLink = () => {
  const [id, setId] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [copyClipboardText, setCopyClipboardText] = useState(false);
  const [copyClipboardTextIframe, setCopyClipboardTextIframe] = useState(false);
  const [copyClipboardTextHtml, setCopyClipboardTextHtml] = useState(false);
  const [iframeCode, setIframeCode] = useState("");
  const [htmlCode, setHtmlCode] = useState();
  const [webformUrl, setWebFormUrl] = useState("");
  const [customFields, setCustomFields] = useState([]);

  const [initialValues, setInitialValues] = useState(INITIAL_STATE);

  const validationSchema = yupValidationSchema({
    formName: { required: true, customName: "Form name" },
    autoresponseDelay: {
      required: true,
      customName: "Enter a valid value (minimum: 1)",
    },
  });

  const formikRef = useRef();

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: validationSchema,

    onSubmit: async (values) => {
      const resp = await saveWebLink(
        {
          id,
          settings: values,
          formName: values.formName,
          autoresponseDelay: values.autoresponseDelay,
          customFields,
        },
        "Webform saved successfully",
        true
      );
      if (resp?.status === SOURCES.HTTP_CODE_200) {
        setShowForm(false);
        clearForm();
        handleResetForm();
      }
    },
    innerRef: formikRef,
  });

  const { callApi: getConversateDestination, data: conversateDestinationsData } = useGet({
    endpoint: `integration?integrationCompany=${SOURCES.DESTINATION_CONVERSATE}`,
  });

  const {
    callApi: saveWebLink,
    isLoading: saveLoading,
    processSuccess: saveWebLinkStatus,
  } = usePost({ endpoint: `weblink` });

  const {
    callApi: getWebLinks,
    data: weblink,
    isLoading: getWebLinkLoader,
  } = useGet({
    endpoint: `weblink`,
  });

  const {
    callApi: getWebLinkDetails,
    data: weblinkDetails,
    isLoading: getWebLinkDetailsLoader,
  } = useGet({
    endpoint: `weblink/detail`,
  });

  const handleResetForm = () => {
    formik.resetForm();
    setInitialValues(INITIAL_STATE);
    formik.setValues(INITIAL_STATE);
  };

  useEffect(() => {
    getWebLinks();
    getConversateDestination();
  }, []);

  useEffect(() => {
    if (!saveLoading) getWebLinks();
  }, [saveLoading]);

  useEffect(() => {
    if (id && weblinkDetails?.results?.settings) {
      const {
        firstNameRequired,
        lastNameRequired,
        phoneRequired,
        emailRequired,
        eventNameRequired,
        eventTypeRequired,
        budgetRequired,
        eventDateRequired,
        guestCountRequired,
        companyRequired,
        addressRequired,
        notesInquiryRequired,
        firstNameHidden,
        lastNameHidden,
        phoneHidden,
        emailHidden,
        eventNameHidden,
        eventTypeHidden,
        budgetHidden,
        eventDateHidden,
        guestCountHidden,
        companyHidden,
        addressHidden,
        notesInquiryHidden,
        conversateInbox,
        conversateAgent,
        conversateType,
        conversateMessage,
      } = weblinkDetails?.results?.settings;
      const { formName, autoresponseDelay, extraInfo } = weblinkDetails?.results;

      const customFieldsData = weblinkDetails?.results?.customFields || [];
      setCustomFields(customFieldsData);

      const customFieldsHtml = customFieldsData
        .map((field, index) => {
          return `
        <div class="mb-3 ${!field ? "d-none" : "d-block"}"><label class="form-label">${
            field.inputValue
          }</label>
          <input id="${field.inputValue}" name="${
            field.inputValue
          }" type="text" class="form-control" value="">
        </div>
      `;
        })
        .join("");

      formik.setValues({
        firstNameRequired,
        lastNameRequired,
        phoneRequired,
        emailRequired,
        eventNameRequired,
        eventTypeRequired,
        budgetRequired,
        eventDateRequired,
        guestCountRequired,
        companyRequired,
        addressRequired,
        notesInquiryRequired,
        firstNameHidden,
        lastNameHidden,
        phoneHidden,
        emailHidden,
        eventNameHidden,
        eventTypeHidden,
        budgetHidden,
        eventDateHidden,
        guestCountHidden,
        companyHidden,
        addressHidden,
        notesInquiryHidden,
        formName,
        autoresponseDelay,
        conversateInbox,
        conversateAgent,
        conversateType,
        conversateMessage,
        extraInfo,
        // customFields,
      });

      setWebFormUrl(weblinkDetails.webformUrl);
      setIframeCode(
        `<iframe width="100%" height="1000" frameBorder="0" id="leadsimport_embed_iframe" src="${weblinkDetails?.webformUrl}" />`
      );
      setHtmlCode(`<!-- BEGIN LEADSIMPORT CODE -->
            <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-KK94CHFLLe+nY2dmCWGMq91rCGa5gtU4mk92HdvYe+M/SXH301p5ILy+dN9+nJOZ" crossorigin="anonymous"><script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha3/dist/js/bootstrap.bundle.min.js" integrity="sha384-ENjdO4Dr2bkBIFxQpeoTz1HIcje39Wm4jDKdf19U8gI4ddQ3GYNS7NTKfAdVQSZe" crossorigin="anonymous"></script><script src="https://code.jquery.com/jquery-3.6.4.min.js" integrity="sha256-oP6HI9z1XaZNBrJURtCoUT5SUnxFr8s3BzRl+cbzUq8=" crossorigin="anonymous"></script>
            <script> $(document).ready(function () { 
                $('div.alert-success').hide();
                $('div.error').hide();
                $('#form-lead').submit(function (e) {e.preventDefault();
                    $("#submit-btn").prop("disabled", true);                    
                    var formData = $(this).serializeArray();var dataObject = {};
            $(formData).each(function (index, obj) { dataObject[obj.name] = obj.value; });var jsonData = JSON.stringify(dataObject);
            $.ajax({url: 'https://api.leadsimport.com/api/weblink/present/webform',type: 'POST',data: jsonData,dataType: 'json',
            contentType: 'application/json',success: function (response) {
                console.log(response,'res');
                $("#submit-btn").prop("disabled", false);
                $('div.alert-success').html("Lead submitted");
                $('div.alert-success').show();
                setTimeout(function(){ $('div.alert-success').hide(250); },3000);
                $('#form-lead input[type="text"], #form-lead textarea').val('');
            },error: function (xhr, status, error) {
                if (xhr.status !== 200) {
                    $('div.error').html(xhr.responseJSON.data.error);
                    $('div.error').show(250);
                    setTimeout(function(){ $('div.error').hide(250); },3000);
                    $("#submit-btn").prop("disabled", false);
                }
            }});});});</script>
            <h2 style="padding: 1.75rem; font-weight: 700; text-align: center;">${
              weblinkDetails?.results?.formName
            }</h2>
            <form class="border p-5 rounded shadow-sm bg-transparent" color="ligh" method="post" id="form-lead">
            <div class="mb-3" ><div class="row"><div class="col ${
              firstNameHidden ? "d-none" : "d-block"
            }"><label class="form-label">First Name${
        firstNameRequired ? "*" : ""
      }</label><input id="firstName" name="firstName" type="text" class="form-control" value=""></div> <div class="col  ${
        lastNameHidden ? "d-none" : "d-block"
      }"><label class="form-label">Last Name${
        lastNameRequired ? "*" : ""
      }</label><input id="lastName" name="lastName" type="text" class="form-control" value=""></div></div></div>
            <div class="mb-3"><div class="row"> <div class="col ${
              phoneHidden ? "d-none" : "d-block"
            }"><label class="form-label">Phone${
        phoneRequired ? "*" : ""
      }</label><input id="phone" name="phone" type="text" class="form-control" value=""></div> <div class="col ${
        emailHidden ? "d-none" : "d-block"
      }"><label class="form-label">Email${
        emailRequired ? "*" : ""
      }</label><input id="email" name="email" type="email" class="form-control" value=""></div></div></div>
            <div class="mb-3 ${eventNameHidden ? "d-none" : "d-block"}"><label class="form-label">Event Name${
        eventNameRequired ? "*" : ""
      }</label><input id="eventName" name="eventName" type="text" class="form-control" value=""></div>
            <div class="mb-3 ${eventDateHidden ? "d-none" : "d-block"}"><label class="form-label">Event Date${
        eventDateRequired ? "*" : ""
      }</label><input id="eventDate" name="eventDate" type="date" class="form-control" value=""></div>
            <div class="mb-3 ${eventTypeHidden ? "d-none" : "d-block"}"><label class="form-label">Event Type${
        eventTypeRequired ? "*" : ""
      }</label><input id="eventType" name="eventType" type="text" class="form-control" value=""></div>
            <div class="mb-3"><div class="row"><div class="col ${
              budgetHidden ? "d-none" : "d-block"
            }"><label class="form-label">Budget${
        budgetRequired ? "*" : ""
      }</label><input id="budget" name="budget" type="number"class="form-control" value=""></div><div class="col ${
        guestCountHidden ? "d-none" : "d-block"
      }"><label class="form-label">Guest Count${
        guestCountRequired ? "*" : ""
      }</label><input id="guestCount" name="guestCount" type="number" class="form-control" value=""></div></div> </div>
            <div class="mb-3 ${companyHidden ? "d-none" : "d-block"}"><label class="form-label">Company${
        companyRequired ? "*" : ""
      }</label><input id="company" name="company" type="text" class="form-control" value=""></div>
            <div class="mb-3 ${addressHidden ? "d-none" : "d-block"}"><label class="form-label">Address${
        addressRequired ? "*" : ""
      }</label><input id="address" name="address" type="address" class="form-control" value=""></div> 
      ${customFieldsHtml}  
      <div class="mb-3 ${
        notesInquiryHidden ? "d-none" : "d-block"
      }"><label class="form-label">Notes / Inquiry${
        notesInquiryRequired ? "*" : ""
      }</label><textarea id="notesInquiry" name="notesInquiry" class="form-control"></textarea></div>
            <input type="hidden" name="uniqueCode" value="${
              weblinkDetails?.results?.uniqueCode
            }" /> <button type="submit" id="submit-btn" class="w-100 py-2 mt-4 btn btn-primary mb-3">Submit</button><br/><div class="alert alert-danger error"></div><div class="alert alert-success"></div></form>            
            <!-- END LEADSIMPORT CODE -->`);
    }
  }, [id, weblinkDetails?.results?.settings, weblinkDetails?.results?.customFields]);

  const clearForm = () => {
    setInitialValues(INITIAL_STATE);
    setWebFormUrl("");
    setIframeCode("");
    setHtmlCode("");
  };

  const copyWebformLink = () => {
    navigator.clipboard.writeText(weblinkDetails.webformUrl);
    setCopyClipboardText(true);
    setTimeout(() => {
      setCopyClipboardText(false);
    }, 2000);
  };

  const copyIframeCode = async () => {
    navigator.clipboard.writeText(
      `<iframe width="100%" height="1000" frameBorder="0" id="leadsimport_embed_iframe" src="${webformUrl}"></iframe>`
    );
    setCopyClipboardTextIframe(true);
    setTimeout(() => {
      setCopyClipboardTextIframe(false);
    }, 2000);
  };

  const copyHtmlCode = () => {
    navigator.clipboard.writeText(htmlCode);
    setCopyClipboardTextHtml(true);
    setTimeout(() => {
      setCopyClipboardTextHtml(false);
    }, 2000);
  };

  useKey(() => {
    setShowForm(false);
    clearForm();
    formik.resetForm();
  }, "Escape");

  const conversateTypeOptions = [
    { value: "sms", label: "Sms" },
    { value: "email", label: "Email" },
  ];

  const addCustomField = () => {
    const newCustomField = {
      label: `Custom Field ${customFields?.length + 1}`,
      name: `customField${customFields?.length + 1}`,
      inputValue: "",
    };
    setCustomFields([...customFields, newCustomField]);
  };

  const addCustomSelectField = () => {
    const newCustomField = {
      label: `Custom Select ${customFields.length + 1}`,
      name: `customField${customFields.length + 1}`,
      selectValue: null, // For React Select
    };
    setCustomFields([...customFields, newCustomField]);
  };

  const removeCustomField = (index) => {
    const updatedFields = [...customFields];
    updatedFields.splice(index, 1);
    setCustomFields(updatedFields);
  };

  const handleCustomFieldChange = (index, value) => {
    const updatedFields = [...customFields];
    updatedFields[index].inputValue = value;
    setCustomFields(updatedFields);
  };
  console.log("testtt", customFields);
  return (
    <Fade>
      <form onSubmit={formik.handleSubmit}>
        <Card color="shadow" className="shadow-sm d-flex">
          <CardBody>
            {!showForm && (
              <>
                <Header
                  icon={faLink}
                  buttonIcon={faPlus}
                  title="Contact Request Forms"
                  buttonTitle="New Contact Request Form"
                  setShowForm={setShowForm}
                  callBack={() => {
                    setId("");
                  }}
                />
                <LeadGenerateFormList
                  setShowForm={setShowForm}
                  getWebLink={getWebLinks}
                  getWebLinkDetails={getWebLinkDetails}
                  weblink={weblink}
                  getWebLinkLoader={getWebLinkLoader}
                  id={id}
                  setId={setId}
                />
              </>
            )}

            {showForm && (
              <>
                <div className="d-flex justify-content-between flex-wrap">
                  <CardTitle tag="h4">
                    <FontAwesomeIcon icon={faLink} className="mr-2" />
                    Contact Request Form
                  </CardTitle>
                </div>
                <Fade>
                  <Row>
                    <Col lg="6">
                      <Card className="shadow">
                        <CardBody>
                          <h5>Basic Info</h5>
                          <FormGroup>
                            <Label>Form Name</Label>
                            <Input
                              type="text"
                              name="formName"
                              placeholder="Enter Form Name..."
                              value={formik.values.formName}
                              invalid={formik.errors.formName}
                              onChange={formik.handleChange}
                            />
                            <ValidationErrorHandling error={formik.errors.formName} />
                          </FormGroup>

                          <FormGroup>
                            <Label>Autoresponse Delay (Minutes)</Label>
                            <Input
                              type="number"
                              placeholder=""
                              name="autoresponseDelay"
                              min={1}
                              value={formik.values.autoresponseDelay}
                              invalid={formik.errors.autoresponseDelay}
                              onChange={formik.handleChange}
                            />
                            <ValidationErrorHandling error={formik.errors.autoresponseDelay} />
                          </FormGroup>
                        </CardBody>
                      </Card>

                      {conversateDestinationsData?.inboxProvider?.length > 0 && (
                        <Card className="mt-3 shadow">
                          <CardBody>
                            <h5>Conversate</h5>
                            <Row>
                              <Col lg={6}>
                                <FormGroup>
                                  <Label for="conversateInbox">Inbox</Label>
                                  {conversateDestinationsData?.inboxProvider && (
                                    <Select
                                      id="conversateInbox"
                                      name="conversateInbox"
                                      placeholder="Select Inbox"
                                      options={conversateDestinationsData?.inboxProvider.map((provider) => ({
                                        value: provider.value,
                                        label: provider.label,
                                      }))}
                                      value={conversateDestinationsData?.inboxProvider.find(
                                        (item) => item.value === formik.values.conversateInbox
                                      )}
                                      onChange={(opt) => {
                                        formik.setFieldValue("conversateInbox", opt.value);
                                      }}
                                    />
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg={6}>
                                <FormGroup>
                                  <Label for="conversateAgent">Agent</Label>
                                  {conversateDestinationsData?.agentProvider && (
                                    <Select
                                      id="conversateAgent"
                                      name="conversateAgent"
                                      placeholder="Select Agent"
                                      options={conversateDestinationsData?.agentProvider.map((provider) => ({
                                        value: provider.value,
                                        label: provider.label,
                                      }))}
                                      value={conversateDestinationsData?.agentProvider.find(
                                        (item) => item.value === formik.values.conversateAgent
                                      )}
                                      onChange={(opt) => {
                                        formik.setFieldValue("conversateAgent", opt.value);
                                      }}
                                    />
                                  )}
                                </FormGroup>
                              </Col>

                              <Col lg={6}>
                                <FormGroup>
                                  <Label for="conversateType">Type</Label>
                                  <Select
                                    id="conversateType"
                                    name="conversateType"
                                    placeholder="Select Type"
                                    options={conversateTypeOptions}
                                    value={conversateTypeOptions.find(
                                      (item) => item.value === formik.values.conversateType
                                    )}
                                    onChange={(opt) => {
                                      formik.setFieldValue("conversateType", opt.value);
                                    }}
                                  />
                                </FormGroup>
                              </Col>

                              <Col lg={12}>
                                <FormGroup>
                                  <Label for="conversateMessage">
                                    Enter Initial Message (Only if you want to create Conversation)
                                  </Label>
                                  <Input
                                    id="conversateMessage"
                                    name="conversateMessage"
                                    type="textarea"
                                    placeholder="Write Initital Message. If You Want To Start Conversation..."
                                    value={formik.values.conversateMessage}
                                    onChange={formik.handleChange}
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      )}

                      <Card className="mt-3 shadow">
                        <CardBody>
                          <FormGroup>
                            <Label>
                              <h5>Web Form Url</h5>
                            </Label>
                            <Input type="Url" value={webformUrl} readOnly />
                          </FormGroup>
                        </CardBody>
                        <CardFooter>
                          <Button
                            type="button"
                            color={copyClipboardText ? "success" : "primary"}
                            onClick={copyWebformLink}
                            disabled={!webformUrl}
                          >
                            <FontAwesomeIcon icon={copyClipboardText ? faCheck : faCopy} className="mr-2" />
                            {copyClipboardText ? "Copied!" : "Copy"}
                          </Button>
                        </CardFooter>
                      </Card>

                      <Card className="mt-3 shadow">
                        <CardBody>
                          <FormGroup>
                            <Label>
                              <h5>iFrame</h5>
                            </Label>
                            <Input type="textarea" value={iframeCode} readOnly />
                          </FormGroup>
                        </CardBody>
                        <CardFooter>
                          <Button
                            type="button"
                            color={copyClipboardTextIframe ? "success" : "primary"}
                            onClick={copyIframeCode}
                            disabled={!iframeCode}
                          >
                            <FontAwesomeIcon
                              icon={copyClipboardTextIframe ? faCheck : faCopy}
                              className="mr-2"
                            />
                            {copyClipboardTextIframe ? "Copied!" : "Copy"}
                          </Button>
                        </CardFooter>
                      </Card>

                      <Card className="my-3 shadow">
                        <CardBody>
                          <FormGroup>
                            <Label>
                              <h5>Html</h5>
                            </Label>
                            <Input type="textarea" value={htmlCode} readOnly rows="2" />
                          </FormGroup>
                        </CardBody>
                        <CardFooter>
                          <Button
                            type="button"
                            color={copyClipboardTextHtml ? "success" : "primary"}
                            onClick={copyHtmlCode}
                            disabled={!htmlCode}
                          >
                            <FontAwesomeIcon
                              icon={copyClipboardTextHtml ? faCheck : faCopy}
                              className="mr-2"
                            />
                            {copyClipboardTextHtml ? "Copied!" : "Copy"}
                          </Button>
                        </CardFooter>
                      </Card>
                    </Col>
                    <Col lg="6" className="mb-2">
                      <div className="border p-5 rounded shadow" color="ligh">
                        <WebLinkForm
                          values={formik.values}
                          errors={formik.errors}
                          touched={formik.touched}
                          handleChange={formik.handleChange}
                          handleBlur={formik.handleBlur}
                          handleSubmit={formik.handleSubmit}
                          isSubmitting={formik.isSubmitting}
                          saveLoading={saveLoading}
                          processSuccess={saveWebLinkStatus}
                          addCustomField={addCustomField}
                          addCustomSelectField={addCustomSelectField}
                          removeCustomField={removeCustomField}
                          handleCustomFieldChange={handleCustomFieldChange}
                          customFields={customFields}
                        />
                      </div>
                    </Col>
                  </Row>
                </Fade>
              </>
            )}

            {showForm && (
              <CardFooter style={{ display: "flex", gap: "10px" }}>
                <SubmitButton
                  buttonClassName="py-2"
                  isLoading={saveLoading}
                  cancleButtonOnClick={() => {
                    clearForm();
                    formik.resetForm();
                    setShowForm(false);
                  }}
                />
              </CardFooter>
            )}
          </CardBody>
        </Card>
      </form>
    </Fade>
  );
};

export default WebLink;
