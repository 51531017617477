import { Col, Fade, FormGroup, Input, Label, Row } from 'reactstrap'

const WebLinkForm = ({ values,
    handleChange,
    handleBlur}) => {

    const checkbox = {
        position: "static",
        margin: "0px",
        marginBottom: "0.5rem",
        marginLeft: "0.3rem"
    }

    const LabelWithInput = ({ title, name, value, hiddenName, hiddenValue, handleChange }) => {
        return (
            <div className='d-flex align-items-center'>
                <Label className='pr-1'>{title}</Label>
                <Label>[<Input type='checkbox' id={name} name={name} style={checkbox} checked={value} onChange={handleChange} /> Required </Label >
                <Label> <Input type='checkbox' id={hiddenName} name={hiddenName} style={checkbox} checked={hiddenValue} onChange={handleChange} /> Hide] </Label >
            </div>
        );
    }
    return (
        <>
            <FormGroup >
                <Row>
                    <Col>
                        <LabelWithInput
                            title="First Name"
                            name="firstNameRequired"
                            hiddenName="firstNameHidden"
                            value={values?.firstNameRequired}
                            hiddenValue={values?.firstNameHidden}
                            handleChange={handleChange} />
                        <Input
                            type='text'
                            id="firstName"
                            name="firstName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.firstName}
                            readOnly
                        />
                    </Col>
                    <Col>
                        <LabelWithInput
                            title="Last Name"
                            name="lastNameRequired"
                            hiddenName="lastNameHidden"
                            value={values?.lastNameRequired}
                            hiddenValue={values?.lastNameHidden}
                            handleChange={handleChange} />
                        <Input
                            type='text'
                            id="lastName"
                            name="lastName"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.lastName}
                            readOnly
                        />
                    </Col>
                </Row>
            </FormGroup>
            <FormGroup>
                <Row>
                    <Col>
                        <LabelWithInput
                            title="Phone"
                            name="phoneRequired"
                            hiddenName="phoneHidden"
                            value={values?.phoneRequired}
                            hiddenValue={values?.phoneHidden}
                            handleChange={handleChange} />
                        <Input
                            type='text'
                            id="phone"
                            name="phone"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.phone}
                            readOnly
                        />
                    </Col>
                    <Col>
                        <LabelWithInput
                            title="Email"
                            name="emailRequired"
                            hiddenName="emailHidden"
                            value={values?.emailRequired}
                            hiddenValue={values?.emailHidden}
                            handleChange={handleChange} />
                        <Input
                            type='email'
                            id="email"
                            name="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.email}
                            readOnly
                        />
                    </Col>
                </Row>


            </FormGroup>

            <FormGroup>
                <LabelWithInput
                    title="Event Name"
                    name="eventNameRequired"
                    hiddenName="eventNameHidden"
                    value={values?.eventNameRequired}
                    hiddenValue={values?.eventNameHidden}
                    handleChange={handleChange} />
                <Input
                    type='text'
                    id="eventName"
                    name="eventName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.eventName}
                    readOnly

                />
            </FormGroup>

            <FormGroup>
                <LabelWithInput
                    title="Event Date"
                    name="eventDateRequired"
                    hiddenName="eventDateHidden"
                    value={values?.eventDateRequired}
                    hiddenValue={values?.eventDateHidden}
                    handleChange={handleChange} />
                <Input
                    type='date'
                    id="eventDate"
                    name="eventDate"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.eventDate}
                    readOnly
                />
            </FormGroup>

            <FormGroup>
                <LabelWithInput
                    title="Event Type"
                    name="eventTypeRequired"
                    hiddenName="eventTypeHidden"
                    value={values?.eventTypeRequired}
                    hiddenValue={values?.eventTypeHidden}
                    handleChange={handleChange} />
                <Input
                    type='text'
                    id="eventType"
                    name="eventType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.eventType}
                    readOnly
                />

            </FormGroup>

            <FormGroup>
                <Row>
                    <Col>
                        <LabelWithInput
                            title="Budget"
                            name="budgetRequired"
                            hiddenName="budgetHidden"
                            value={values?.budgetRequired}
                            hiddenValue={values?.budgetHidden}
                            handleChange={handleChange} />
                        <Input
                            type='text'
                            id="budget"
                            name="budget"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.budget}
                            readOnly
                        />
                    </Col>
                    <Col>
                        <LabelWithInput
                            title="Guest Count"
                            name="guestCountRequired"
                            hiddenName="guestCountHidden"
                            value={values?.guestCountRequired}
                            hiddenValue={values?.guestCountHidden}
                            handleChange={handleChange} />
                        <Input
                            type='text'
                            id="guestCount"
                            name="guestCount"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values?.guestCount}
                            readOnly
                        />
                    </Col>
                </Row>
            </FormGroup>

            <FormGroup>
                <LabelWithInput
                    title="Service Address"
                    name="addressRequired"
                    hiddenName="addressHidden"
                    value={values?.addressRequired}
                    hiddenValue={values?.addressHidden}
                    handleChange={handleChange} />
                <Input
                    type='text'
                    id="address"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.address}
                    readOnly
                />
            </FormGroup>

            <FormGroup>
                <LabelWithInput
                    title="Company"
                    name="companyRequired"
                    hiddenName="companyHidden"
                    value={values?.companyRequired}
                    hiddenValue={values?.companyHidden}
                    handleChange={handleChange} />
                <Input
                    type='text'
                    id="company"
                    name="company"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.company}
                    readOnly
                />
            </FormGroup>

            <FormGroup>
                <LabelWithInput
                    title="Notes / Inquiry"
                    name="notesInquiryRequired"
                    hiddenName="notesInquiryHidden"
                    value={values?.notesInquiryRequired}
                    hiddenValue={values?.notesInquiryHidden}
                    handleChange={handleChange} />
                <Input
                    type='textarea'
                    id="notesInquiry"
                    name="notesInquiry"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values?.notesInquiry}
                    readOnly
                />
            </FormGroup>
        </>
    )
}

export default WebLinkForm