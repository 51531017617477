import { useEffect, useState } from "react";
import { Form, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { MSG_DESTINATION } from "../../../utils/message";
import DestinationFooter from "./common/destinationFooter";
import {
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import DestinationHeader from "./common/destinationHeader";

const DESTINATION_PLANNING = process.env.REACT_APP_INTEGRATION_PLANNINGPOD;

const validationSchema = yupValidationSchema({
  planningPodUsername: { required: true, customName: "Username" },
  planningPodPassword: { required: true, customName: "Password" },
});

const PlanningpodForm = ({ saveIntegration, destinations }) => {
  const [loading, setLoading] = useState(false);
  const [planningPodUsername, setPlanningPodUsername] = useState("");
  const [planningPodPassword, setPlanningPodPassword] = useState("");
  const [sendLeadsLimitPlanningPod, setSendLeadsLimitPlanningPod] = useState(0);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (destinations) {
      for (let i = 0; i < destinations.length; i++) {
        const dest = destinations[i];
        const {
          integrationCompany,
          destinationAccountEmail,
          destinationAccountPassword,
          leadSentConfig,
        } = dest;
        if (integrationCompany === DESTINATION_PLANNING) {
          setPlanningPodUsername(destinationAccountEmail);
          setPlanningPodPassword(destinationAccountPassword);
          setSendLeadsLimitPlanningPod(leadSentConfig?.sentLimit);
        }
      }
    }
  }, [destinations]);

  const handlePlanningPodForm = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(
        { planningPodUsername, planningPodPassword },
        { abortEarly: false }
      );
      setLoading(true);
      await saveIntegration(
        {
          destinationAccountEmail: planningPodUsername,
          destinationAccountPassword: planningPodPassword,
          sendLeadsLimit: sendLeadsLimitPlanningPod,
          integrationCompany: DESTINATION_PLANNING,
          locations: [],
        },
        MSG_DESTINATION.SAVE_PLANNINGPOD
      );
      setErrors({});
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    } finally {
      setLoading(false);
    }
  };
  return (
    <DestinationHeader
      headerTitle="PlanningPod"
      headerImage="images/planningpod.png"
    >
      <Form onSubmit={handlePlanningPodForm} autoComplete="off">
        <Row>
          <Col lg={6}>
            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                id="username"
                name="username"
                placeholder="Username"
                autocomplete="off"
                type="text"
                defaultValue={planningPodUsername}
                value={planningPodUsername}
                onChange={(e) => setPlanningPodUsername(e.target.value)}
                invalid={
                  planningPodUsername === "" && errors.planningPodUsername
                }
                autoComplete="off"
              />
              <ValidationErrorHandling
                error={planningPodUsername === "" && errors.planningPodUsername}
              />
            </FormGroup>
          </Col>
          <Col lg={6}>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                placeholder="Password"
                autocomplete="off"
                type="password"
                defaultValue={planningPodPassword}
                value={planningPodPassword}
                onChange={(e) => setPlanningPodPassword(e.target.value)}
                invalid={
                  planningPodPassword === "" && errors.planningPodPassword
                }
                autoComplete="off"
              />
              <ValidationErrorHandling
                error={planningPodPassword === "" && errors.planningPodPassword}
              />
            </FormGroup>
          </Col>

          <Col lg={6}>
            <FormGroup>
              <Label for="send_leads_initially">
                How Many Leads Do You Want To Send Initially?
              </Label>
              <Input
                id="send_leads_initially"
                name="send_leads_initially"
                placeholder="Total lead(s) send initially"
                type="text"
                value={sendLeadsLimitPlanningPod}
                onChange={(e) => setSendLeadsLimitPlanningPod(e.target.value)}
                autoComplete="off"
                autoFocus
              />
            </FormGroup>
          </Col>
        </Row>

        <DestinationFooter
          handleSubmit={handlePlanningPodForm}
          loading={loading}
        />
      </Form>
    </DestinationHeader>
  );
};

export default PlanningpodForm;
