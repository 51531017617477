import { Route, Redirect, } from "react-router-dom";
import LocalStorageService from "../utils/localStorageServices";
const localStorageServices = LocalStorageService.getService()

const MiddleWare = ({
  component: Component,
  layout: Layout,
  path,
  ...rest
}) => {
  const currentUser = localStorageServices.getCurrentUser()
  let isvalid = currentUser != null;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isvalid ? (
          <Layout>
            <Component currentUser={currentUser} />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location, redirectTo: path }
            }}
          />
        )
      }
    />
  );
};
export default MiddleWare;