import { Card, CardBody, Col, Fade, FormGroup, Input, Row, Spinner } from "reactstrap";

const TripleseatLocationModule = ({
  getTripleseatSelectedLocationsLoader,
  setLocationIdTripleseat,
  tripleseatSelectedLocations,
  extraInfo,
}) => {
  return (
    <Fade>
      <Card className="shadow-sm" style={{ marginTop: "10px" }}>
        <CardBody>
          <Row>
            <Col sm={6} md={7} lg={7}>
              <FormGroup>
                <legend>
                  <h5>Choose Tripleseat Location to Send Leads</h5>
                </legend>

                {getTripleseatSelectedLocationsLoader ? (
                  <Spinner
                   className="ml-2" children="" />
                ) : (
                  <Input
                    id="tripleseatLocation"
                    name="select"
                    type="select"
                    className="w-100 p-2 mr-2"
                    onChange={(e) => setLocationIdTripleseat(e.target.value)}
                  >
                    <option value="">Select tripleseat location</option>
                    {tripleseatSelectedLocations &&
                      tripleseatSelectedLocations.map((opt) => {
                        return (
                          <option
                            value={opt.id}
                            selected={
                              extraInfo?.locationIdTripleseat &&
                              opt?.id.toString() ===
                                extraInfo?.locationIdTripleseat.toString()
                            }
                          >
                            {opt.name}
                          </option>
                        );
                      })}
                  </Input>
                )}
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default TripleseatLocationModule;