import { Button, Spinner } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faClone, faSave } from '@fortawesome/free-solid-svg-icons'

const SubmitButton = ({
    type = "submit",
    color = "primary",
    title = "Save",
    hoverTitle = "",
    icon = faSave,
    cancleButton = true,
    cloneButton = false,
    goBackButton = false,
    isLoading,
    disabled = isLoading,
    buttonClassName,
    onClick,
    cancleButtonOnClick,
    cloneButtonOnClick,
    goBackButtonOnClick,
    recordId,
    tabIndex = 1,
    tabIndexCancelButton = 2
}) => {
    return (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
            <Button
                type={type}
                className={buttonClassName}
                onClick={onClick}
                disabled={disabled}
                color={color}
                tabIndex={tabIndex}
                title={hoverTitle}
            >
                {isLoading ?
                    <Spinner
                        className="mr-2"
                        color="light"
                        size="sm"
                        children=""
                    /> :
                    <FontAwesomeIcon icon={icon} className="mr-2" />}
                {title}
            </Button>
            {recordId && cloneButton &&
                <Button
                    color="primary"
                    onClick={cloneButtonOnClick}
                >
                    <FontAwesomeIcon icon={faClone} className="mr-2" />
                    Clone
                </Button>
            }
            {cancleButton && <Button
                tabIndex={tabIndexCancelButton}
                color="secondary"
                onClick={cancleButtonOnClick}
            >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Close
            </Button>}
            {goBackButton && <Button
                color="secondary"
                onClick={goBackButtonOnClick}
            >
                <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                Go Back
            </Button>}
        </div>
    )
}

export default SubmitButton