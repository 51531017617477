import { Card, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const MessageCard = ({ message, icon, title, onButtonClick, buttonText, showButton = false }) => {
    const cardStyle = {
        border: 'none',
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: '#f7f7f7',
        textAlign: 'center',
        maxWidth: '400px',
        margin: '0 auto',
    };

    const iconStyle = {
        color: `${title === "Success!" ? '#4caf50' : '#dc3545'}`,
        fontSize: '64px',
    };

    const titleStyle = {
        fontSize: '28px',
        fontWeight: 'bold',
        color: `${title === "Success!" ? '#4caf50' : '#dc3545'}`,
        marginBottom: '10px',
    };

    const messageStyle = {
        fontSize: '18px',
        marginBottom: '20px',
        color: `${title === "Success!" ? '#4caf50' : '#dc3545'}`,
    };

    const buttonStyle = {
        backgroundColor:  '#dc3545',
        color: '#fff',
        borderRadius: '24px',
        padding: '10px 20px',
        fontSize: '16px',
        cursor: 'pointer',
        border: "none"
    };

    const fadeAnimation = useSpring({
        opacity: 2,
        from: { opacity: 0 },
        config: { duration: 600 },
    });

    return (
        <animated.div style={fadeAnimation} className="shadow-sm">
            <Card style={cardStyle}>
                <CardBody>
                    <div style={iconStyle}>
                        <FontAwesomeIcon icon={icon} />
                    </div>
                    <CardTitle style={titleStyle}>{title}</CardTitle>
                    <CardText style={messageStyle}>{message}</CardText>
                    {showButton && <Button style={buttonStyle} onClick={onButtonClick}>
                        {buttonText}
                    </Button>}
                </CardBody>
            </Card>
        </animated.div>
    );
};

export default MessageCard;
