import { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardFooter,
  CardText,
  Table,
  FormGroup,
  Input,
  Row,
  Col,
  Button,
  Label,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import {
  formatDateTime,
  formatLeadSource,
  handleYupValidationErrors,
  yupValidationSchema,
} from "../../../utils/functions";
import usePost from "../../../services/usePost";
import { useKey } from "../../../services/useKey";
import SubmitButton from "../../common/submitButton";
import ValidationErrorHandling from "../../common/validationErrorHandling";
import TableRow from "../../common/tableRow";

const validationSchema = yupValidationSchema({
  notes: { required: true, customName: "Notes" },
});

const HotLeadDetail = ({ hotLeadDetail, showDetail, setShowDetail }) => {
  const [notes, setNotes] = useState("");
  const [errors, setErrors] = useState({});

  const { callApi: saveExtraNotes } = usePost({
    endpoint: `leadinfo/notes`,
  });

  useEffect(() => {
    if (hotLeadDetail?.notes) setNotes(hotLeadDetail?.notes);
    else setNotes("");
  }, [hotLeadDetail]);

  useKey(() => {
    setShowDetail(false);
  }, "Escape");

  const submitNote = async () => {
    try {
      await validationSchema.validate(
        {
          notes,
        },
        { abortEarly: false }
      );
      setErrors({});
      saveExtraNotes({
        leadId: hotLeadDetail._id,
        notes,
      });
    } catch (e) {
      setErrors(handleYupValidationErrors(e));
    }
  };

  return (
    <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
      <CardBody>
        <CardTitle tag="h4">
          <Row className="justify-content-between align-items-center flex-nowrap">
            <Col lg={"auto"} xs={"auto"}>
              <div className="d-flex">
                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                Hot Lead Detail
              </div>
            </Col>
            <Col lg={"auto"} xs={"auto"}>
              <Button
                onClick={() => setShowDetail(false)}
                color="primary"
                size="sm"
              >
                <FontAwesomeIcon icon={faWindowClose} size="sm" />
              </Button>
            </Col>
          </Row>
        </CardTitle>

        <CardText>
          <Card color="shadow mb-3">
            <CardBody>
              <CardTitle tag="h5">Basic Information</CardTitle>
              <CardText>
                <Table responsive striped bordered hover size="sm">
                  <TableRow
                    label="Name"
                    value={
                      hotLeadDetail.fullName
                        ? hotLeadDetail.fullName
                        : `${hotLeadDetail.firstName} ${hotLeadDetail.lastName}`
                    }
                  />
                  <TableRow label="Email" value={hotLeadDetail?.email} />
                  <TableRow label="Phone" value={hotLeadDetail?.phone} />
                  <TableRow
                    label="Imported At"
                    value={formatDateTime(hotLeadDetail?.dateAsPerUserTimezone)}
                  />

                  {/* <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Name</th>
                                        <td>

                                            {
                                                hotLeadDetail.fullName
                                                    ?
                                                    hotLeadDetail.fullName
                                                    :
                                                    `${hotLeadDetail.firstName} ${hotLeadDetail.lastName}`
                                            }

                                        </td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Email</th>
                                        <td>{hotLeadDetail?.email}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Phone</th>
                                        <td>{hotLeadDetail?.phone}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Imported At</th>
                                        <td>{formatDateTime(hotLeadDetail?.dateAsPerUserTimezone)}</td>
                                    </tr> */}
                </Table>
              </CardText>
            </CardBody>
          </Card>
          <Card color="shadow mb-3">
            <CardBody>
              <CardTitle tag="h5">Event Information</CardTitle>
              <CardText>
                <Table responsive striped bordered hover size="sm">
                  <TableRow
                    label="Event Name"
                    value={hotLeadDetail?.eventName}
                  />
                  <TableRow
                    label="Event Type"
                    value={hotLeadDetail?.eventType}
                  />
                  <TableRow
                    label="Event Date"
                    value={hotLeadDetail?.eventDate}
                  />
                  <TableRow
                    label="Guest Count"
                    value={hotLeadDetail?.guestCount}
                  />
                  <TableRow label="Company" value={hotLeadDetail?.company} />
                  <TableRow
                    label="Imported At"
                    value={formatDateTime(hotLeadDetail?.dateAsPerUserTimezone)}
                  />
                  <TableRow
                    label="Source"
                    value={formatLeadSource(hotLeadDetail?.leadSource)}
                    classNameTD="autoCapital"
                  />
                  <TableRow
                    label="Notes/Inquiry"
                    value={hotLeadDetail?.inquiry}
                  />
                  {/* <tr style={{ whiteSpace: "nowrap" }} >
                                        <th>Event Name</th>
                                        <td>{hotLeadDetail?.eventName}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Event Type</th>
                                        <td>{hotLeadDetail?.eventType}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Event Date</th>
                                        <td>{hotLeadDetail?.eventDate}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Guest Count</th>
                                        <td>{hotLeadDetail?.guestCount}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Company</th>
                                        <td>{hotLeadDetail?.company}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Imported At</th>
                                        <td>{formatDateTime(hotLeadDetail?.dateAsPerUserTimezone)}</td>
                                    </tr>
                                    <tr style={{ whiteSpace: "nowrap" }}>
                                        <th>Source</th>
                                        <td className="autoCapital">{formatLeadSource(hotLeadDetail?.leadSource)}</td>
                                    </tr>
                                    <tr >
                                        <th>Notes/Inquiry</th>
                                        <td >{hotLeadDetail?.inquiry}</td>
                                    </tr> */}
                </Table>
              </CardText>
            </CardBody>
          </Card>

          <Card color="shadow">
            <CardBody>
              <CardTitle tag="h5">Extra Information</CardTitle>
              <CardText>
                <FormGroup>
                  <Label for="exampleText">
                    You can add extra information regarding lead
                  </Label>
                  <Input
                    id="exampleText"
                    name="text"
                    type="textarea"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    invalid={errors.notes}
                  />
                  <ValidationErrorHandling error={errors.notes} />
                </FormGroup>
              </CardText>
              <CardFooter>
                <SubmitButton onClick={submitNote} cancleButton={false} />
              </CardFooter>
            </CardBody>
          </Card>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default HotLeadDetail;
