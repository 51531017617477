import React, { useState } from "react";
import { Collapse, NavItem, NavLink } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import classNames from "classnames";

const SubMenu = (props) => {
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const toggleMenu = () => setCollapsed(!collapsed);
  const { icon, title, items, toggle } = props;

  return (
    <>
      <NavItem className={classNames({ "menu-open": !collapsed })}>
        <NavLink onClick={toggleMenu}>
          <FontAwesomeIcon icon={icon} className="mr-2" />
          {title}
        </NavLink>
        <Collapse
          isOpen={!collapsed}
          navbar
          className={classNames("items-menu", { "mb-1": !collapsed })}
        >
          {items.map((item, index) => (
            <NavItem onClick={toggle} key={index} className="pl-4">
              <NavLink
                tag={Link}
                to={item.target}
                onClick={() => setCollapsed(false)}
                className={location.pathname === "/" + item.target ? "text-primary" : ""}
                style={{ fontSize: "15px" }}
              >
                {item.title}
              </NavLink>
            </NavItem>
          ))}
        </Collapse>
      </NavItem>
    </>
  );
};

export default SubMenu;
