import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Row,
  Col,
  Button,
  Table,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfo, faWindowClose } from "@fortawesome/free-solid-svg-icons";
import { useKey } from "../../../services/useKey";
import TableRow from "../../common/tableRow";
import { formatDateTime } from "../../../utils/functions";

const NewLeadAlertDetail = ({
  newLeadAlertDeatail,
  showDetail,
  setShowDetail,
}) => {
  useKey(() => {
    setShowDetail(false);
  }, "Escape");

  return (
    <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
      <CardBody>
        <CardTitle tag="h4">
          <Row className="justify-content-between align-items-center flex-nowrap">
            <Col lg={"auto"} xs={"auto"}>
              <div className="d-flex">
                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                New Lead Alert Detail
              </div>
            </Col>
            <Col lg={"auto"} xs={"auto"}>
              <Button
                onClick={() => setShowDetail(false)}
                color="primary"
                size="sm"
              >
                <FontAwesomeIcon icon={faWindowClose} size="sm" />
              </Button>
            </Col>
          </Row>
        </CardTitle>

        <CardText>
          <Card color="shadow mb-3">
            <CardBody>
              <CardText>
                <Table
                  responsive
                  striped
                  bordered
                  hover
                  size="sm"
                  className="shadow"
                >
                  <TableRow
                    label="Lead Source"
                    value={newLeadAlertDeatail?.leadSource}
                  />
                  <TableRow
                    label={`Alert ${
                      newLeadAlertDeatail?.alertEmail === null ? "Sms" : "Email"
                    }`}
                    value={
                      newLeadAlertDeatail?.alertEmail === null
                        ? newLeadAlertDeatail?.alertSms
                        : newLeadAlertDeatail?.alertEmail
                    }
                  />
                  <TableRow
                    label="Lead(s)"
                    value={newLeadAlertDeatail?.leadsImported}
                  />
                  <TableRow
                    label="Detail"
                    value={newLeadAlertDeatail?.alertDetail}
                  />
                  <TableRow
                    label="Imported At"
                    value={formatDateTime(newLeadAlertDeatail?.createdAt)}
                  />
                </Table>
              </CardText>
            </CardBody>
          </Card>
        </CardText>
      </CardBody>
    </Card>
  );
};

export default NewLeadAlertDetail;
