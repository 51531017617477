import React from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import "./sidebar.css";
import Topbar from "./Topbar";
import Footer from "./footer";

const Header = ({ children,sidebarIsOpen, toggleSidebar }) => (
  <Container
    fluid
    style={{
      height: "100vh",
      overflowX:"scroll",
      // overflowY:"hidden"
    }}
    className={classNames("content", { "is-open": sidebarIsOpen })}
  >
    <div>
      <Topbar toggleSidebar={toggleSidebar} />
      {children}
    </div>
    <Footer />
  </Container>
);

export default Header;
