import { useState, useEffect } from "react";
import {
    CardBody,
    CardTitle,
    CardSubtitle,
    CardText,
    Table,
    Row,
    Col,
    Card,
    FormGroup,
    Collapse,
    Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faUsers } from "@fortawesome/free-solid-svg-icons";
import { formatDateTime } from "../../utils/functions";
import useGet from "../../services/useGet";
import NoRecordsFound from "./../common/noRecordsFound";
import TableLoader from "./../common/tableLoader";
import TableHeader from "./../common/tableHeader";
import SOURCES from "../../utils/constants";
import Paginate from "../common/pagination";
import useDebounce from "../../utils/customHooks";
import AdvanceSearch from "../common/advanceSearch";
import HeaderSearchInput from "../common/headerSearchInput";


const Customers = ({ currentUser }) => {
    const [pagination, setPagination] = useState({
        offset: 0,
        limit: SOURCES.DEFAULT_RECORDS_LISTING
    });

    var currentDate = new Date();
    var after3Years = new Date(currentDate.getFullYear() + 3, currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());
    var before1Year = new Date(currentDate.getFullYear() - 1, currentDate.getMonth(), currentDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds());

    const [advanceSearch, setAdvanceSearch] = useState({
        advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER,
        startDate: before1Year,
        endDate: after3Years,
        searchTerm: "",
        searchInput: ""
    });
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false)
    const [affiliateList, setAffiliateList] = useState()
    const [sortBy, setSortBy] = useState("");
    const [sortOrder, setSortOrder] = useState(1);
    const [searchInput, setSearchInput] = useState('')
    const [totalRecords, setTotalRecords] = useState(0);
    const affiliateId = currentUser?.user?._id;


    const getDate = (currentDate) => {
        const year = currentDate.getFullYear();
        const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Adding 1 to the month because it is zero-based
        const day = String(currentDate.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        return formattedDate
    }

    // const { callApi: getCurrentMonthRefferal, data: currentMonthRefferal, isLoading } = useGet({
    //     endpoint: `admin/affiliatecommission/current-month-referrals?affiliateId=${affiliateId}`,
    // });

    // const { callApi: getAffiliatecommission, data: affiliatecommission } = useGet({
    //     endpoint: `admin/affiliatecommission/?affiliateId=${affiliateId}&offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
    // });

    const { callApi: getAffiliatecommission, data: affiliatecommission, isLoading: getSearchDataLoader, } = useGet({
        endpoint: `admin/affiliatecommission/search?startDate=${getDate(advanceSearch.startDate)}&endDate=${getDate(advanceSearch.endDate
        )}&affiliateId=${affiliateId}&searchTerm=${advanceSearch.searchTerm || advanceSearch.searchInput}`
    });

    const debouncedSearchTerm = useDebounce(advanceSearch.searchInput, 500);

    const isLoading = getSearchDataLoader

    useEffect(
        () => {
            if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getAffiliatecommission();
            else if (!debouncedSearchTerm) getAffiliatecommission();
        },
        [debouncedSearchTerm]
    );


    useEffect(() => {
        setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
        setShowAdvanceSearch(false);
    }, [advanceSearch.searchInput])

    useEffect(() => {
        getAffiliatecommission()
    }, [])

    useEffect(() => {
        if ([SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM].indexOf(advanceSearch.advance_search_enabled) >= 0) {
            getAffiliatecommission()
            if (advanceSearch.advance_search_enabled === SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM) setAdvanceSearch({ ...advanceSearch, advance_search_enabled: SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER });
        }

    }, [advanceSearch.advance_search_enabled]);

    useEffect(() => {
        setAffiliateList(affiliatecommission)
    }, [affiliatecommission])

    useEffect(() => {
        if (affiliateList && totalRecords <= 0) setTotalRecords(affiliateList?.totalReords);
    }, [affiliateList])

    // useEffect(() => {
    //     setAffiliateList(searchData)
    // }, [searchData])

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }

    const dataExist = affiliateList?.results && affiliateList?.results.length > 0 ? true : false;

    return (
        <Card className="shadow" >
            <CardBody>
                <CardTitle>
                    <Row>
                        <Col lg={9} style={{ display: "flex" }} tag={"h4"}>
                            <FontAwesomeIcon icon={faUsers} className="mr-2" />
                            Customers
                        </Col>
                        <Col lg={3}>
                            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                <HeaderSearchInput
                                    value={advanceSearch.searchInput}
                                    onChange={(e) => setAdvanceSearch({ ...advanceSearch, searchInput: e.target.value })}
                                />
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setShowAdvanceSearch(true);
                                    }}
                                    style={{
                                        minWidth: "max-content"
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFilter} className="mr-2" />
                                    Advance Search
                                </Button>
                            </div>
                            <Collapse isOpen={showAdvanceSearch} delay={{
                                show: 5000,
                                hide: 5000
                            }}>
                                <AdvanceSearch
                                    searchInput={advanceSearch.searchInput}
                                    isOpen={showAdvanceSearch}
                                    advanceSearch={advanceSearch}
                                    setShowAdvanceSearch={setShowAdvanceSearch}
                                    setAdvanceSearch={setAdvanceSearch}
                                    getSearchData={getAffiliatecommission}
                                    filteredData={{ key: "", data: {} }}
                                />

                            </Collapse>
                        </Col>
                    </Row>
                </CardTitle>
                <CardSubtitle className="mb-2 text-muted" tag="h6"></CardSubtitle>
                <CardText>
                    <Table responsive striped bordered hover size="sm" className="shadow">
                        <TableHeader
                            sortData={sortData}
                            setSortBy={setSortBy}
                            sortBy={sortBy}
                            sortOrder={sortOrder}
                            headers={[
                                { title: 'Name', showIcon: true },
                                { title: 'Email', showIcon: true },
                                { title: 'Phone' },
                                { title: 'Registered At', showIcon: true },
                                { title: 'Plan / Price' },
                                { title: 'Commission' }
                            ]}
                        />
                        <tbody>
                            {isLoading ?
                                <TableLoader colSpan="6" />
                                : (
                                    <>
                                        {dataExist ? (
                                            affiliateList?.results?.map((item, idx) => {

                                                return (
                                                    <tr key={idx} style={{ whiteSpace: "nowrap" }}>
                                                        <td>{item?.userId?.name}</td>
                                                        <td>{item?.userId?.email}</td>
                                                        <td>{item?.userId?.phone}</td>
                                                        <td>{formatDateTime(item?.userId?.createdAt)}</td>
                                                        <td>{item?.userId?.paymentPlanId.planName} / {item?.userId?.paymentPlanId.planPrice}</td>
                                                        <td>{item.commissionAmount} $</td>
                                                    </tr>

                                                );
                                            })
                                        )
                                            : (
                                                <NoRecordsFound colSpan="6" />
                                            )}
                                    </>
                                )}
                            {/* <td colSpan="5"><strong>Total Commission:{totalCommission} $</strong></td> */}
                        </tbody>

                    </Table>

                </CardText>
            </CardBody>
            <div className="px-4">
                {!searchInput && [SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_NOT_FILTER, SOURCES.ADVANCE_SEARCH_FIELDS.SEARCH_TYPE_CLOSED_SEARCH_FORM].indexOf(advanceSearch.advance_search_enabled) >= 0 && totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
                    <FormGroup>
                        <Paginate
                            setPagination={setPagination}
                            totalRecords={affiliatecommission?.totalRecords}
                            isLoading={isLoading}
                        />
                    </FormGroup>
                )}
            </div>
        </Card>
    )
}

export default Customers