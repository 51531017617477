import React, { useEffect, useState } from 'react'
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import usePost from '../services/usePost';
import MessageCard from './common/messageCard';
import SOURCES from '../utils/constants';


const StripeOnBoard = () => {
    const [error, setError] = useState("");
    const [stripeConnected, setStripeConnected] = useState(false)
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const uniqueCode = queryParams.get('code');
    const stripeConnectedAccountId = queryParams.get('accountId');


    const { callApi: stripeOnBoard } = usePost({
        endpoint: `affiliate/stripeonboard`,
    });

    const { callApi: stripeOnBoardCompleted } = usePost({
        endpoint: `affiliate/stripeonboardCompleted`,
    });

    const onBoard = async () => {
        try {
            if (uniqueCode) {
                const res = await stripeOnBoard({ uniqueCode }, null, true,false);
                if (res?.status === SOURCES.HTTP_CODE_200) {
                    if (res?.data?.results?.url) window.location.href = res?.data?.results?.url;
                } else setError(res.data.error)
            } else if (stripeConnectedAccountId) {
                const res = await stripeOnBoardCompleted({ stripeConnectedAccountId }, null, true);
                if (res?.status === SOURCES.HTTP_CODE_200) {
                    setStripeConnected(true)
                } else {
                    setError(res.data.error)
                    setStripeConnected(false)
                }
            }
        } catch (error) { }
    }

    useEffect(() => {
        onBoard()
    }, [])

    const refresh = () => {
        window.location.reload(true);
    }

    return (
        <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
            {uniqueCode && !error &&
                <MessageCard
                    title="Success!"
                    icon={faCheckCircle}
                    message="You will be redirected to onboarding link..." />}

            {stripeConnected && !error &&
                <MessageCard
                    title="Success!"
                    icon={faCheckCircle}
                    message="Your stripe account connected with LeadsImport successfully...!!!!" />}

            {error &&
                <MessageCard
                    title="Error!"
                    icon={faTimesCircle}
                    message="Invalid code or Affiliate/partner already onboarded"
                    showButton={true}
                    buttonText="Try again"
                    onButtonClick={refresh}
                />
            }
        </div>
    )
}

export default StripeOnBoard;