import { useEffect, useState } from 'react';
import { FormFeedback } from 'reactstrap'

const ValidationErrorHandling = ({ error, position = true }) => {

    const [showError, setShowError] = useState(false);

    useEffect(() => {
        setShowError(!!error);
    }, [error]);

    const FormFeedbackStyle = {
        width: "fit-content",
        position: position ? "static" : "absolute",
        marginTop: "3px",
        fontSize: "12px",
        fontStyle: "italic",
        opacity: showError ? '0.9' : '0',
        transition: 'opacity 0.8s ease-in-out',
    }
    return (
        <>
            {error &&
                <FormFeedback tooltip style={FormFeedbackStyle}>
                    {error}
                </FormFeedback>
            }
        </>
    )
}

export default ValidationErrorHandling