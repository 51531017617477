import { useEffect, useState } from "react";
import { Input, Row, Col } from "reactstrap";
import SOURCES from "../../utils/constants";


const Paginate = ({ setPagination, totalRecords,isLoading}) => {
  const [currentPage, setCurrentpage] = useState(1)
  const [perPage, setPerPage] = useState(SOURCES.DEFAULT_RECORDS_LISTING);
  const [offset, setOffset] = useState(0)
  const [pagesLength, setPagesLength] = useState([]);

  useEffect(() => {
    setOffset((parseInt(currentPage) * parseInt(perPage)) - parseInt(perPage));
  }, [currentPage]);

  useEffect(() => {
    setCurrentpage(1);
    const temp = [];
    const pageCount = Math.ceil(totalRecords / perPage);
    for (let i = 1; i <= pageCount; i++) {
      temp.push(i);
      setPagesLength(temp);
    }
  }, [perPage]);

  useEffect(() => {
    setPagination({ offset, limit: parseInt(perPage) });
  }, [offset])

  useEffect(() => {
    setPagination({ offset, limit: parseInt(perPage) });
  }, [pagesLength])

  return (
    <>
      
        <Row className="d-flex justify-content-center">
          {/* <Label for="paginate">Go to</Label> */}
          <Col lg={2} md={4} sm={5} xs={6}>
            <Input
              id="paginate"
              name="select"
              type="select"
              disabled={isLoading}
              className="w-100 p-2 mr-2"
              onChange={(e) => setCurrentpage(e.target.value)}
            >
              {pagesLength && pagesLength.map((opt) => {
                return <option value={opt} selected={currentPage === opt}>Page {opt}</option>;
              })}
            </Input>
          </Col>
          {/* <Label for="perpage">Per Page</Label> */}
          <Col lg={2} md={4} sm={5} xs={6}>
            <Input
              id="perpage"
              name="select"
              type="select"
              className="w-100 p-2 "
              disabled={isLoading}
              onChange={(e) => {
                setPerPage(e.target.value);
              }}
            >
              {/* <option value="10" selected={perPage === "10"}>10</option> */}
              <option value="20" selected={perPage === "20"}>20</option>
              <option value="40" selected={perPage === "40"}>40</option>
              <option value="60" selected={perPage === "60"}>60</option>
              <option value="100" selected={perPage === "100"}>100</option>

            </Input>
          </Col>
        </Row>
    </>
  );
};
export default Paginate;