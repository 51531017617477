import React, { useEffect, useState } from 'react'
import { faClone, faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Table, NavLink } from "reactstrap";
import usePost from '../../services/usePost';
import { MSG_AUTORESPONDER } from '../../utils/message';
import NoRecordsFound from '../common/noRecordsFound';
import Popup from '../common/popup';
import TableHeader from '../common/tableHeader';
import TableLoader from '../common/tableLoader';

const AutoresponderList = ({ 
    setId, 
    setShowForm, 
    getAutoResponders,
     autoresponders, 
     isLoading,
     doCloneAutoresponder ,
     setSortBy,
    sortBy,
    setSortOrder,
    sortOrder
    }) => {
    const [deleteId, setDeleteId] = useState(0);
    const [toggleModal, setToggleModal] = useState(false);

    const {
        callApi: deleteAutoResponder,
        processSuccess: removeSuccess,
    } = usePost({
        endpoint: `autoresponder/delete`,
    });



    useEffect(() => {
        if (removeSuccess) {
            setToggleModal(false);
            getAutoResponders();
        }
    }, [removeSuccess]);

    const handleEdit = async (id) => {
        setId(id);
        setShowForm(true);
    };

    const removeAutoResponder = async () => {
        const postData = {
            id: deleteId,
        };
        deleteAutoResponder(postData, MSG_AUTORESPONDER.DELETE);
    };

    const sortData = (title) => {
        setSortBy(title)
        setSortOrder(sortOrder === 1 ? -1 : 1)
    }
    const dataExist = autoresponders && autoresponders.length > 0 ? true : false;
    return (
        <>
            <Table responsive striped bordered hover size="sm" className="shadow">
                <TableHeader
                    headers={[{ title: 'Internal Autoresponse Name', showIcon: true }, { title: 'Title',showIcon: true }, { title: 'Message' }, { style: { width: "1%" } }]}
                    sortData={sortData}
                    setSortBy={setSortBy}
                    sortBy={sortBy}
                    sortOrder={sortOrder}
                />
                <tbody>
                    {
                        isLoading ?
                            <TableLoader colSpan="4" />
                            :
                            <>
                                {dataExist ?
                                    autoresponders.map((res, idx) => {
                                        return (
                                            <tr id={idx} style={{ whiteSpace: "nowrap" }}>
                                                <td>
                                                    <Link onClick={() => {
                                                        handleEdit(res._id);
                                                    }}>
                                                        {res?.name}
                                                    </Link>
                                                </td>
                                                <td>{res?.title}
                                                </td>
                                                <td >
                                                    <div
                                                        id={idx}
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                res.message.length > 100
                                                                    ? res.message.substring(0, 100) + "..."
                                                                    : res.message,
                                                        }}
                                                    />
                                                </td>

                                                <td>
                                                    <div className="d-flex">
                                                        <NavLink
                                                            title="Clone Autoresponder"
                                                            href="#"
                                                            onClick={() => doCloneAutoresponder(res._id)}
                                                        >
                                                            <FontAwesomeIcon icon={faClone} />
                                                        </NavLink>

                                                        <NavLink
                                                            title="Edit Autoresponder"
                                                            href="#"
                                                            onClick={() => {
                                                                handleEdit(res._id);
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faPen} />
                                                        </NavLink>
                                                        <NavLink
                                                            title="Delete Autoresponder"
                                                            href="#">
                                                            <FontAwesomeIcon
                                                                icon={faTrashAlt}
                                                                style={{ color: "#dc3545" }}
                                                                onClick={() => {
                                                                    setDeleteId(res._id);
                                                                    setToggleModal(true);
                                                                }}
                                                            />
                                                        </NavLink>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                    :
                                    <NoRecordsFound colSpan="4" />
                                }
                            </>
                    }
                </tbody>
            </Table>
            {
                toggleModal && <Popup
                    title="Delete Autoresponder"
                    promtMessage="Are you sure you want to delete Autoresponder?"
                    showPopup={toggleModal}
                    hidePopup={() => setToggleModal(false)} actionMethod={removeAutoResponder}
                />
            }
        </>
    )
}

export default AutoresponderList