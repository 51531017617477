import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Table,
  Button,
  Badge,
  Row,
  Col,
  Input,
  ButtonGroup,
  Fade,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faCog,
  faDollarSign,
  faHourglass,
  faInfo,
  faInfoCircle,
  faSignInAlt,
  faSync,
  faTrash,
  faWindowClose,
} from "@fortawesome/free-solid-svg-icons";
import { capitalizeFirstLetter, formatDateTime } from "../../../utils/functions";
import SOURCES from "../../../utils/constants";
import usePost from "../../../services/usePost";
import Popup from "../../common/popup";
import { useKey } from "../../../services/useKey";
import { MSG_USER } from "../../../utils/message";
import TableRow from "../../common/tableRow";

const UserDetail = ({
  showDetail,
  setshowDeatail,
  leadDetail,
  userInfo,
  setUserInfo,
  paymentPlans,
  loginAsUser,
  deleteUser,
  removeSuccess,
  getUsers,
}) => {
  const [trialPeriod, setTrialPeriod] = useState("");
  const [activateTill, setActivateTill] = useState("");
  const [userSettings, setUserSettings] = useState({
    settingKey: "MAX_LEADS_UPLOAD_VIA_CSV",
    settingValue: "",
  });

  const [toggleModalOptions, setToggleModalOptions] = useState({
    showModal: false,
    title: "",
    postMessage: "",
    actionMethod: "",
  });

  const { callApi: changeUserPaymentPlan, processSuccess: paymentPlanSuccess } = usePost({
    endpoint: `admin/change-user-plan`,
  });

  const { callApi: changeUserTrialPeriod, processSuccess: trialPeriodSuccess } = usePost({
    endpoint: `admin/change-user-trial-period`,
  });

  const { callApi: changeVerificationCode, processSuccess: resendSuccess } = usePost({
    endpoint: `admin/resent-verification-code`,
  });

  const { callApi: updateUserSettings, processSuccess: maxLeadsUploadSuccess } = usePost({
    endpoint: `user/settings`,
  });

  const { callApi: reactivateAccount, processSuccess: reavtivateAcountSuccess } = usePost({
    endpoint: `admin/reactivate-account`,
  });
  useEffect(() => {
    if (
      resendSuccess ||
      paymentPlanSuccess ||
      trialPeriodSuccess ||
      maxLeadsUploadSuccess ||
      reavtivateAcountSuccess
    )
      setToggleModalOptions({ ...toggleModalOptions, showModal: false });
  }, [resendSuccess, paymentPlanSuccess, trialPeriodSuccess, maxLeadsUploadSuccess, reavtivateAcountSuccess]);

  const setSettings = () => {
    if (leadDetail?.UserSettings?.length > 0) {
      leadDetail?.UserSettings?.map((setting) => {
        setUserSettings((prevState) => ({
          ...prevState,
          settingValue: setting.settingValue,
        }));
      });
    } else {
      setUserSettings((prevState) => ({
        ...prevState,
        settingValue: "",
      }));
    }
  };

  useEffect(() => {
    setSettings();
  }, [leadDetail]);

  useEffect(() => {
    if (removeSuccess) {
      setToggleModalOptions({
        ...toggleModalOptions,
        showModal: false,
      });
      setshowDeatail(false);
      getUsers();
    }
  }, [removeSuccess]);

  useKey(() => {
    setshowDeatail(false);
  }, "Escape");

  const resendVerificationCode = async () => {
    const res = await changeVerificationCode({ email: leadDetail.email });
    if (res.status !== SOURCES.HTTP_CODE_200)
      setToggleModalOptions({ ...toggleModalOptions, showModal: false });
  };

  const changeUserPayment = async () => {
    const res = await changeUserPaymentPlan(
      {
        email: leadDetail.email,
        paymentPlanId: userInfo.plan,
      },
      MSG_USER.PAYMENTPLAN_CHANGE,
      true
    );

    await getUsers();

    if (res.status !== SOURCES.HTTP_CODE_200)
      setToggleModalOptions({ ...toggleModalOptions, showModal: false });
  };

  const changeUserTrial = async () => {
    const res = await changeUserTrialPeriod(
      {
        email: leadDetail.email,
        trialPeriod,
      },
      MSG_USER.TRIALPERIOD_CHANGE,
      true
    );

    if (res.status !== SOURCES.HTTP_CODE_200)
      setToggleModalOptions({ ...toggleModalOptions, showModal: false });
  };

  const saveUserSettings = async () => {
    const res = await updateUserSettings(
      {
        userId: leadDetail._id,
        settings: [userSettings],
      },
      MSG_USER.MAXLEADSUPLOAD_CHANGE,
      true
    );

    if (res.status !== SOURCES.HTTP_CODE_200)
      setToggleModalOptions({ ...toggleModalOptions, showModal: false });
  };

  const saveReactivateAccount = async () => {
    const res = await reactivateAccount(
      {
        email: leadDetail.email,
        reactivatedTillDate: activateTill,
      },
      MSG_USER.REACTIVATE_ACCOUNT,
      true
    );

    if (res.status !== SOURCES.HTTP_CODE_200)
      setToggleModalOptions({ ...toggleModalOptions, showModal: false });
  };

  return (
    <Fade>
      <Card color="shadow" className={`${showDetail ? "d-block" : "d-none"}`}>
        <CardBody>
          <CardTitle tag="h4">
            <Row className="justify-content-between">
              <Col md={"auto"} xs={"auto"}>
                <FontAwesomeIcon icon={faInfo} className="mr-2" />
                User Detail
              </Col>
              <Col md={"auto"} xs={"auto"}>
                <ButtonGroup>
                  {leadDetail?.userRole !== SOURCES.USER_ROLE_ADMIN && leadDetail?.verified === "y" && (
                    <Button
                      color="primary"
                      size="sm"
                      className="mr-2"
                      onClick={() => loginAsUser(leadDetail?.email)}
                      title="Login"
                    >
                      <FontAwesomeIcon icon={faSignInAlt} size="sm" />
                    </Button>
                  )}
                  {leadDetail?.userRole !== SOURCES.USER_ROLE_ADMIN && leadDetail?.verified === "n" && (
                    <Button
                      size="sm"
                      className="mr-2"
                      color="danger"
                      title="Delete"
                      onClick={() => {
                        setToggleModalOptions({
                          ...toggleModalOptions,
                          showModal: true,
                          title: "Delete User",
                          postMessage: "Are you sure you want to Delete User?",
                          actionMethod: () => deleteUser(leadDetail?._id),
                        });
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} size="sm" />
                    </Button>
                  )}
                  <Button
                    color="primary"
                    onClick={() => {
                      setshowDeatail(false);
                    }}
                    size="sm"
                  >
                    <FontAwesomeIcon icon={faWindowClose} size="sm" />
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
          </CardTitle>
          <Row>
            <Col>
              <Card color="white shadow">
                <CardBody>
                  <CardTitle tag="h5">
                    <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                    Basic Detail
                  </CardTitle>
                  <CardText>
                    <Table responsive striped bordered hover size="sm" className="shadow">
                      <TableRow label="Name" value={leadDetail?.name} />
                      <TableRow label="Email" value={leadDetail?.email} />
                      <TableRow label="Phone" value={leadDetail?.phone} />
                      <TableRow label="Company" value={leadDetail?.company} />
                      <TableRow label="Registered On" value={formatDateTime(leadDetail?.createdAt)} />
                    </Table>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            {leadDetail && (
              <Col>
                <Card color="white shadow">
                  <CardBody>
                    <CardTitle tag="h5">
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                      Verification Status
                    </CardTitle>
                    <CardText>
                      <Table responsive striped bordered hover size="sm" className="shadow">
                        <TableRow
                          label="Verified"
                          value={
                            leadDetail?.verified === "y" ? (
                              <Badge color="success">Yes</Badge>
                            ) : (
                              <Badge color="danger">No</Badge>
                            )
                          }
                        />
                        <TableRow
                          label="Role"
                          value={<Badge color="success">{capitalizeFirstLetter(leadDetail?.userRole)}</Badge>}
                        />
                        <TableRow
                          label="Status"
                          value={
                            <Badge color={leadDetail?.userStatus === "active" ? "success" : "danger"}>
                              {capitalizeFirstLetter(leadDetail?.userStatus)}
                            </Badge>
                          }
                        />
                        <TableRow
                          colSpanTD={2}
                          value={
                            <Button
                              color={leadDetail?.verified === "n" ? "primary" : "secondary"}
                              className="mb-1 w-100"
                              disabled={leadDetail?.verified !== "n"}
                              style={{
                                marginTop: "20px",
                                backgroundColor: leadDetail?.verified !== "n" ? "#6c757d" : undefined,
                                borderColor: leadDetail?.verified !== "n" ? "#6c757d" : undefined,
                              }}
                              onClick={() => {
                                setToggleModalOptions({
                                  ...toggleModalOptions,
                                  showModal: true,
                                  title: "Resend Verification Code",
                                  postMessage: "Are you sure you want to Resend Verification Code?",
                                  actionMethod: () => resendVerificationCode(),
                                });
                              }}
                            >
                              Resend Verification Code
                            </Button>

                            // <Button
                            //   color="primary"
                            //   className="mb-1 w-100"
                            //   disabled={
                            //     leadDetail?.verified === "n" ? false : true
                            //   }
                            //   style={{ marginTop: "20px" }}
                            //   onClick={() => {
                            //     setToggleModalOptions({
                            //       ...toggleModalOptions,
                            //       showModal: true,
                            //       title: "Resend Verification Code",
                            //       postMessage:
                            //         "Are you sure you want to Resend Verification Code?",
                            //       actionMethod: () => resendVerificationCode(),
                            //     });
                            //   }}
                            // >
                            //   Resend Verification Code
                            // </Button>
                          }
                        />

                        {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Verified</th>
                                                    <td>{leadDetail?.verified === 'y' ? <Badge color="success">Yes</Badge> : <Badge color="danger">No</Badge>}</td>
                                                </tr> */}
                        {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Role</th>
                                                    <td><Badge color="success">{capitalizeFirstLetter(leadDetail?.userRole)}</Badge></td>
                                                </tr> */}
                        {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Status</th>
                                                    <td><Badge color={leadDetail?.userStatus === "active" ? "success" : "danger"}>{capitalizeFirstLetter(leadDetail?.userStatus)}</Badge></td>
                                                </tr> */}
                        {/* <tr className="pt-4">
                                                    <td colSpan={2}>
                                                        <Button
                                                            color="primary"
                                                            className="mb-1 w-100"
                                                            disabled={leadDetail?.verified === 'n' ? false : true}
                                                            style={{ marginTop: "20px" }}
                                                            onClick={() => {
                                                                setToggleModalOptions({
                                                                    ...toggleModalOptions,
                                                                    showModal: true,
                                                                    title: "Resend Verification Code",
                                                                    postMessage: "Are you sure you want to Resend Verification Code?",
                                                                    actionMethod: () => resendVerificationCode()
                                                                });
                                                            }}
                                                        >
                                                            Resend Verification Code
                                                        </Button>
                                                    </td>
                                                </tr> */}
                      </Table>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>

          {leadDetail?.userRole !== SOURCES.USER_ROLE_ADMIN && (
            <Row>
              <Col md="6" className={` ${showDetail ? "d-block" : "d-none"}`}>
                <Card color="white shadow" className="mt-3">
                  <CardBody>
                    <CardTitle tag="h5">
                      <FontAwesomeIcon icon={faHourglass} size="sm" className="mr-2" />
                      Trial Period
                    </CardTitle>
                    <CardText>
                      <Table responsive striped bordered hover size="sm" className="shadow">
                        <TableRow
                          label="On Trial"
                          value={
                            leadDetail?.isTrial === "y" ? (
                              <Badge color="danger">Yes</Badge>
                            ) : (
                              <Badge color="success">No</Badge>
                            )
                          }
                        />
                        <TableRow
                          label="Trial Ended At"
                          value={
                            (leadDetail?.userStatus === SOURCES.USER_STATUS_TRIAL_END ||
                              leadDetail?.userStatus === SOURCES.USER_STATUS_TRIAL) &&
                            formatDateTime(leadDetail?.updatedAt, "MMM Do YY")
                          }
                        />
                        <TableRow
                          label="Change Trial Period"
                          value={
                            <Row className="mr-0">
                              <Col lg={6} md={6} xs={12}>
                                <Input
                                  id="change-trial-period"
                                  name="change-trial-period"
                                  type="date"
                                  className="form-control form-control-md p-2"
                                  onChange={(e) => setTrialPeriod(e.target.value)}
                                  value={trialPeriod}
                                />
                              </Col>
                            </Row>
                          }
                        />
                        <TableRow
                          colSpanTD={2}
                          value={
                            <Button
                              color="primary"
                              className="mt-2 w-100"
                              onClick={() => {
                                setToggleModalOptions({
                                  ...toggleModalOptions,
                                  showModal: true,
                                  title: "Update Trial Period",
                                  postMessage: "Are you sure you want to Update Trial Period?",
                                  actionMethod: () => changeUserTrial(),
                                });
                              }}
                            >
                              Update Trial Period
                            </Button>
                          }
                        />
                        {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>On Trial</th>
                                                    <td>{leadDetail?.isTrial === 'y' ? <Badge color="danger">Yes</Badge> : <Badge color="success">No</Badge>}</td>
                                                </tr>
                                                <tr>
                                                    <th>Trial Ended At</th>
                                                    <td>{(leadDetail?.userStatus === SOURCES.USER_STATUS_TRIAL_END || leadDetail?.userStatus === SOURCES.USER_STATUS_TRIAL) && formatDateTime(leadDetail?.updatedAt, "MMM Do YY")}</td>
                                                </tr> */}

                        {/* <tr>
                                                    <th>Change Trial Period</th>
                                                    <td>
                                                        <Row className="mr-0">
                                                            <Col lg={6} md={6} xs={12}>
                                                                <Input
                                                                    id="change-trial-period"
                                                                    name="change-trial-period"
                                                                    type="date"
                                                                    className="form-control form-control-md p-2"
                                                                    onChange={(e) => {
                                                                        setTrialPeriod(e.target.value);
                                                                    }}
                                                                    value={trialPeriod}
                                                                />
                                                            </Col>
                                                        </Row>

                                                    </td>
                                                </tr> */}

                        {/* <tr>
                                                    <td colSpan={2}>
                                                        <Button
                                                            color="primary"
                                                            className="mt-2 w-100"
                                                            onClick={() => {
                                                                setToggleModalOptions({
                                                                    ...toggleModalOptions,
                                                                    showModal: true,
                                                                    title: "Update Trial Period",
                                                                    postMessage: "Are you sure you want to Update Trial Period?",
                                                                    actionMethod: () => changeUserTrial()
                                                                });
                                                            }}
                                                        >
                                                            Update Trial Period
                                                        </Button>
                                                    </td>
                                                </tr> */}
                      </Table>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>

              <Col md="6" className={`mb-2 ${showDetail ? "d-block" : "d-none"}`}>
                <Card color="white shadow" className="mt-3">
                  <CardBody>
                    <CardTitle tag="h5">
                      <FontAwesomeIcon icon={faDollarSign} size="sm" className="mr-2" />
                      Payment Plan
                    </CardTitle>
                    <CardText>
                      <Table responsive striped bordered hover size="sm" className="shadow">
                        <TableRow label="Payment Status" value={leadDetail?.paymentStatus} />
                        <TableRow label="Current Plan" value={leadDetail?.PlanInformation[0]?.planName} />
                        <TableRow
                          label="Change Plan"
                          value={
                            <Row className="mr-0">
                              <Col lg={6} md={6} xs={12}>
                                <Input
                                  name="plan"
                                  type="select"
                                  className="form-control form-control-md p-2"
                                  value={userInfo.plan}
                                  onChange={(e) =>
                                    setUserInfo({
                                      ...userInfo,
                                      plan: e.target.value,
                                    })
                                  }
                                  tabIndex={7}
                                >
                                  <option value="-1">Select One</option>
                                  {paymentPlans &&
                                    paymentPlans.map((plan) => {
                                      return <option value={plan._id}>{plan.planName}</option>;
                                    })}
                                </Input>
                              </Col>
                            </Row>
                          }
                        />
                        <TableRow
                          colSpanTD={2}
                          value={
                            <Button
                              color="primary"
                              className="mt-2 w-100"
                              disabled={userInfo?.plan === "-1" ? true : false}
                              onClick={() => {
                                setToggleModalOptions({
                                  ...toggleModalOptions,
                                  showModal: true,
                                  title: "Update Payment Plan",
                                  postMessage: "Are you sure you want to Update Payment Plan?",
                                  actionMethod: () => changeUserPayment(),
                                });
                              }}
                            >
                              Update Payment Plan
                            </Button>
                          }
                        />
                        {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Payment Status</th>
                                                    <td>{leadDetail?.paymentStatus}</td>
                                                </tr>
                                                <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Current Plan</th>
                                                    <td>{leadDetail?.PlanInformation[0]?.planName}</td>
                                                </tr> 
                                                 <tr>
                                                    <th>Change Plan</th>
                                                    <td>
                                                        <Row className="mr-0">
                                                            <Col lg={6} md={6} xs={12}>
                                                                <Input
                                                                    name="plan"
                                                                    type="select"
                                                                    className="form-control form-control-md p-2"
                                                                    value={userInfo.plan}
                                                                    onChange={(e) =>
                                                                        setUserInfo({ ...userInfo, plan: e.target.value })
                                                                    }
                                                                    tabIndex={7}
                                                                >
                                                                    <option value="-1">Select One</option>
                                                                    {paymentPlans &&
                                                                        paymentPlans.map((plan) => {
                                                                            return (
                                                                                <option value={plan._id}>
                                                                                    {plan.planName}
                                                                                </option>
                                                                            )
                                                                        })
                                                                    }
                                                                </Input>
                                                            </Col>
                                                        </Row>

                                                    </td>
                                                </tr> */}
                        {/* <tr>
                                                    <td colSpan={2}>
                                                        <Button
                                                            color="primary"
                                                            className="mt-2 w-100"
                                                            disabled={userInfo?.plan === "-1" ? true : false}
                                                            onClick={() => {
                                                                setToggleModalOptions({
                                                                    ...toggleModalOptions,
                                                                    showModal: true,
                                                                    title: "Update Payment Plan",
                                                                    postMessage: "Are you sure you want to Update Payment Plan?",
                                                                    actionMethod: () => changeUserPayment()
                                                                });
                                                            }}
                                                        >
                                                            Update Payment Plan
                                                        </Button>
                                                    </td>
                                                </tr> */}
                      </Table>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
          <Row>
            <Col md="6" className={`mb-2 ${showDetail ? "d-block" : "d-none"}`}>
              <Card color="white shadow" className="mt-3">
                <CardBody>
                  <CardTitle tag="h5">
                    <FontAwesomeIcon icon={faCog} size="sm" className="mr-2" />
                    Settings
                  </CardTitle>
                  <CardText>
                    <Table responsive striped bordered hover size="sm" className="shadow">
                      <TableRow
                        label="CSV Leads Upload Limit "
                        value={
                          <Input
                            type="text"
                            value={userSettings.settingValue}
                            onChange={(e) =>
                              setUserSettings({
                                ...userSettings,
                                settingValue: e.target.value,
                              })
                            }
                          />
                        }
                      />
                      <TableRow
                        colSpanTD={2}
                        value={
                          <Button
                            color="primary"
                            className="mt-2 w-100"
                            disabled={userInfo?.plan === "-1" ? true : false}
                            onClick={() => {
                              setToggleModalOptions({
                                ...toggleModalOptions,
                                showModal: true,
                                title: "Update Settings",
                                postMessage: "Are you sure you want to Update Settings?",
                                actionMethod: () => saveUserSettings(),
                              });
                            }}
                          >
                            Update Settings
                          </Button>
                        }
                      />
                      {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                <th>CSV Leads Upload Limit </th>
                                                <td>
                                                <Input type="text" value={userSettings.settingValue} onChange={e => setUserSettings({ ...userSettings, settingValue: e.target.value })} /></td>
                                            </tr>

                                            <tr>
                                                <td colSpan={2}>
                                                    <Button
                                                        color="primary"
                                                        className="mt-2 w-100"
                                                        disabled={userInfo?.plan === "-1" ? true : false}
                                                        onClick={() => {
                                                            setToggleModalOptions({
                                                                ...toggleModalOptions,
                                                                showModal: true,
                                                                title: "Update Settings",
                                                                postMessage: "Are you sure you want to Update Settings?",
                                                                actionMethod: () => saveUserSettings()
                                                            });
                                                        }}
                                                    >
                                                        Update Settings
                                                    </Button>
                                                </td>
                                            </tr> */}
                    </Table>
                  </CardText>
                </CardBody>
              </Card>
            </Col>
            {leadDetail?.userStatus === SOURCES.USER_STATUS_SUSPENDED && (
              <Col md="6" className={`mb-2 ${showDetail ? "d-block" : "d-none"}`}>
                <Card color="white shadow" className="mt-3">
                  <CardBody>
                    <CardTitle tag="h5">
                      <FontAwesomeIcon icon={faSync} size="sm" className="mr-2" />
                      Reactivate Account
                    </CardTitle>
                    <CardText>
                      <Table responsive striped bordered hover size="sm" className="shadow">
                        <TableRow
                          label="Activate till"
                          value={
                            <Row className="mr-0">
                              <Col lg={6} md={6} xs={12}>
                                <Input
                                  id="activate-till"
                                  name="activate-till"
                                  type="date"
                                  className="form-control form-control-md p-2"
                                  onChange={(e) => {
                                    setActivateTill(e.target.value);
                                  }}
                                  value={activateTill}
                                />
                              </Col>
                            </Row>
                          }
                        />
                        <TableRow
                          colSpanTD={2}
                          value={
                            <Button
                              color="primary"
                              className="mt-2 w-100"
                              disabled={userInfo?.plan === "-1" ? true : false}
                              onClick={() => {
                                setToggleModalOptions({
                                  ...toggleModalOptions,
                                  showModal: true,
                                  title: "Reactivate Account",
                                  postMessage: "Are you sure you want to Reactivate Account?",
                                  actionMethod: () => saveReactivateAccount(),
                                });
                              }}
                            >
                              Reactivate Account
                            </Button>
                          }
                        />
                        {/* <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Activate till </th>
                                                    <td>
                                                        <Row className="mr-0">
                                                            <Col lg={6} md={6} xs={12}>
                                                                <Input
                                                                    id="activate-till"
                                                                    name="activate-till"
                                                                    type="date"
                                                                    className="form-control form-control-md p-2"
                                                                    onChange={(e) => {
                                                                        setActivateTill(e.target.value);
                                                                    }}
                                                                    value={activateTill}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </td>
                                                </tr>

                                                <tr>
                                                    <td colSpan={2}>
                                                        <Button
                                                            color="primary"
                                                            className="mt-2 w-100"
                                                            disabled={userInfo?.plan === "-1" ? true : false}
                                                            onClick={() => {
                                                                setToggleModalOptions({
                                                                    ...toggleModalOptions,
                                                                    showModal: true,
                                                                    title: "Reactivate Account",
                                                                    postMessage: "Are you sure you want to Reactivate Account?",
                                                                    actionMethod: () => saveReactivateAccount()
                                                                });
                                                            }}
                                                        >
                                                            Reactivate Account
                                                        </Button>
                                                    </td>
                                                </tr> */}
                      </Table>
                    </CardText>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </CardBody>
      </Card>

      {toggleModalOptions.showModal && (
        <Popup
          showPopup={toggleModalOptions.showModal}
          hidePopup={() => setToggleModalOptions({ ...toggleModalOptions, showModal: false })}
          actionMethod={toggleModalOptions.actionMethod}
          title={toggleModalOptions.title}
          promtMessage={toggleModalOptions.postMessage}
        />
      )}
    </Fade>
  );
};
export default UserDetail;
