import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  FormGroup,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import SOURCES from "../../utils/constants";
import useDebounce from "../../utils/customHooks";
import Paginate from "../common/pagination";
import HeaderSearchInput from "../common/headerSearchInput";
import NewLeadAlertList from "./modules/newLeadAlertList";
import NewLeadAlertDetail from "./modules/newLeadAlertDetail";

const NewLeadAlert = () => {

  const [pagination, setPagination] = useState({
    offset: 0,
    limit: SOURCES.DEFAULT_RECORDS_LISTING
  });
  const [newLeadAlertImported, seNewLeadAlertImported] = useState();
  const [newLeadAlertDeatail, setNewLeadAlertDeatail] = useState(null);
  const [showDetail, setShowDetail] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `alert/alert-logs-search?q=${searchInput}`,
  });

  const {
    callApi: getNewAlertLeads,
    data: newLeadAlert,
    isLoading: getLeadsLoading,
  } = useGet({
    endpoint: `alert/alert-logs?offset=${pagination.offset}&limit=${pagination.limit}&sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const isLoading = searchDataLoading || getLeadsLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);
  const dataExist = newLeadAlertImported && newLeadAlertImported?.results?.length > 0 ? true : false;

  useEffect(
    () => {
      if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
      else if (!debouncedSearchTerm) getNewAlertLeads();
    }, [debouncedSearchTerm]
  );

  useEffect(() => {
    getNewAlertLeads();
  }, [pagination.offset, pagination.limit, sortBy, sortOrder]);

  useEffect(() => {
    seNewLeadAlertImported(newLeadAlert);
  }, [newLeadAlert]);

    

  useEffect(() => {
    seNewLeadAlertImported(searchData);
  }, [searchData]);

  const viewDetail = (item) => {
    setNewLeadAlertDeatail(item);
    setShowDetail(true);
  };
  
  return (
    <div>
      <Fade>
        <Card color="shadow" className={`${showDetail ? "d-none" : "d-block"}`}>
          <CardBody>
            <CardTitle>
              <Row className="justify-content-between">
                <Col lg={9} style={{ display: "flex" }} tag={"h4"}>
                  <FontAwesomeIcon icon={faBell} className="mr-2" />
                  New Lead Alert
                </Col>
                <Col lg={3}>
                  <HeaderSearchInput
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                </Col>
              </Row>
            </CardTitle>
            <CardSubtitle className="mb-2 text-muted" tag="h6">
            </CardSubtitle>
            <CardText>
              <NewLeadAlertList
                isLoading={isLoading}
                dataExist={dataExist}
                newLeadAlertImported={newLeadAlertImported}
                viewDetail={viewDetail}
                setSortBy={setSortBy}
                sortBy={sortBy}
                setSortOrder={setSortOrder}
                sortOrder={sortOrder}
              />
            </CardText>
          </CardBody>
          <div className="px-4">
            {!searchInput && newLeadAlertImported?.totalRecords > SOURCES.DEFAULT_RECORDS_LISTING && (
              <FormGroup>
                <Paginate
                  setPagination={setPagination}
                  totalRecords={newLeadAlertImported?.totalRecords}
                  isLoading={isLoading}
                />
              </FormGroup>
            )}
          </div>
        </Card>
      </Fade>
      {
        newLeadAlertDeatail &&
        <NewLeadAlertDetail
          newLeadAlertDeatail={newLeadAlertDeatail}
          showDetail={showDetail}
          setShowDetail={setShowDetail}
        />
      }

    </div>
  )
}

export default NewLeadAlert