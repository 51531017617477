import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory} from "@fortawesome/free-solid-svg-icons";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Row,
  Col,
  Fade,
} from "reactstrap";
import useGet from "../../services/useGet";
import useDebounce from "../../utils/customHooks";
import PaymentHistoryList from "./modules/paymentHistoryList";
import HeaderSearchInput from "../common/headerSearchInput";

const PaymentHistory = () => {
  const [searchInput, setSearchInput] = useState("");
  const [history, setHistory] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState(1);

  const {
    callApi: getPayments,
    data: payments,
    isLoading: getPaymentLoading,
  } = useGet({
    endpoint: `payment/history?sortBy=${sortBy}&sortOrder=${sortOrder}`,
  });

  const {
    callApi: getSearchData,
    data: searchData,
    isLoading: searchDataLoading,
  } = useGet({
    endpoint: `payment/history-search?q=${searchInput}`,
  });

  const isLoading = searchDataLoading || getPaymentLoading;
  const debouncedSearchTerm = useDebounce(searchInput, 500);

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 2) getSearchData();
    else if (!debouncedSearchTerm) getPayments();
  }, [debouncedSearchTerm]);

  useEffect(() => {
    getPayments();
  }, [sortBy, sortOrder]);

  useEffect(() => {
    setHistory(payments);
  }, [payments]);

  useEffect(() => {
    setHistory(searchData);
  }, [searchData]);

  return (
    <Fade>
      <Card color="shadow">
        <CardBody>
          <CardTitle tag="h4">
            <Row>
              <Col sm={9} style={{ display: "flex", alignItems: "center" }} tag={"h4"}>
                <FontAwesomeIcon icon={faHistory} className="mr-2" />
                Payment History
              </Col>
              <Col sm={3}>
                <HeaderSearchInput
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)}
                />
              </Col>
            </Row>

          </CardTitle>
          <CardSubtitle className="mb-2 text-muted" tag="h6">
          </CardSubtitle>
          <CardText>
            <PaymentHistoryList
              isLoading={isLoading}
              history={history}
              setSortBy={setSortBy}
              sortBy={sortBy}
              setSortOrder={setSortOrder}
              sortOrder={sortOrder}
            />
          </CardText>
        </CardBody>
      </Card>
    </Fade>
  );
};

export default PaymentHistory;